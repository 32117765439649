import * as c3 from 'c3';
import * as d3 from 'd3';
import Chart from 'src/javascripts/components/Chart';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import MetricScoresScatterTooltip from 'src/javascripts/components/charts/tooltips/MetricScoresScatterTooltip';

export default class MetricScoresScatter extends Chart {

  getData() {

    // Set a spinner to indicate chart loading
    Spinner(this.chartId);

    // Set url
    const url = this.el.attr('data-api-url');

    // Get data
    return $.getJSON(url);

  }

  render(data) {

    // Set chartId
    const chartId = this.chartId;

    // Set metric scores data
    let ms = data.data;

    // Get top weight
    let max = data.max;
    let min = data.min;
    let maxVal = data.maxVal;
    let minVal = data.minVal;
    let rangeVal = maxVal - minVal;

    // Render chart
    let chart = c3.generate({
      bindto: "#" + chartId,
      padding: {right: 15},
      data: {
        json: ms,
        keys: {
          x: 'weight',
          value: ['score']
        },
        type: 'scatter',
        color: function (color, d) {
          // Set color based on score
          if (d.value >= 80) {
            return '#28a745';
          } else if (d.value >= 60) {
            return '#79d979';
          } else if (d.value >= 40) {
            return '#b8b514';
          } else if (d.value >= 20) {
            return '#c2820c';
          } else {
            return '#c2510c';
          }
        }
      },
      point: {
        r: function(d) {return d.x * 3;},
      },
      axis: {
        x: { 
          max: max,
          min: min,
          padding: {left: 0, right: 0},
          tick: {
            fit: false,
            format: function (x) { return x + '%'; }
          },
          label: 'Weight'
        },
        y: {
          max: 105,
          min: -3,
          padding: {top: 5, bottom: 0},
          tick: {
            values: [0, 20, 40, 60, 80, 100]
          },
          label: 'Score'
        }
      },
      grid: {
        focus: {show: false}},
      legend: {show: false},
      tooltip: {
        grouped: false,
        format: {},
        contents(d, defaultTitleFormat, defaultValueFormat, color) {
          return MetricScoresScatterTooltip(this, d, defaultTitleFormat, defaultValueFormat, color, ms);
        },
        position: function (data, width, height, element) {
          let position = c3.chart.internal.fn.tooltipPosition.apply(this, arguments);
          let elWidth = parseInt( $(element).attr('width') );
          let left = position.left;
          let x = parseFloat(data[0].x);
          let xPercent = (x - rangeVal) / (maxVal - rangeVal);
          let xPosition = xPercent * elWidth;
          if ( (xPosition + 350) > elWidth) {
            position.left = position.left - ((elWidth - xPosition) + 75);
          }
          return position;
        },

      },
      onrendered: function () {

        // Disable click
        d3.select('#' + chartId).selectAll(".c3-event-rect").on("click", null);

        // if the target of the click isn't the container nor a descendant of the container
        $(document).mouseup(function(e) {
          let container = $('.c3-tooltip-container');
          if (!container.is(e.target) && container.has(e.target).length === 0) {container.hide();}
        });

      }
    });

    // Set chart data to allow for destroying chart before Turbolinks cache
    // https://stackoverflow.com/questions/25765596/how-to-get-object-reference-from-selector-for-c3-js-charts
    $('#' + chartId).data('c3-chart', chart);

    c3.chart.internal.fn.hideTooltip = function () {
      setTimeout(c3.chart.internal.fn.hideTooltip, 100);
    };

    // Change opacity and spinner
    $('#metricScoreScatterCard').find('.spinner-container').remove();
    $('#metricScoreScatterCard .card-body').removeClass('opaque');

    return chart;

  }
};
