// Convert prices data to returns -- one array of data

export default function(data) {

  // Set name as the first item in the array
  const name = data[0];  

  // Add name to final array to be returned
  const returnsArr = [name];

  // Remove the name from the data
  data = data.slice(1);

  // Now set the starting price as the new first item in the data
  let startingPrice = data[0];  

  // If starting price is 0, get other first non-zero price
  if (startingPrice === 0) {
    let i = 0;
    while ((startingPrice === 0) && (i < data.length)) {
      startingPrice = data[i];
      i++;
    }
  } 

  // If starting price is null, set returns as 0 for every day
  // Otherwise add calculated return for each day in the data
  if (startingPrice === null) { 
    let i = 0
    while (i < data.length) {
      returnsArr.push(0);
      i++;
    }
    return returnsArr;

  } else {

    // Start at 0 (first day / todayPrice) -- means that returns calc (below) will always start at 0, 
    // which is what we want for returns calcs, so can compare across user-selected companies/securities/etc.
    // starting at common baseline of 0    
    let i = 0;
    while (i < data.length) {
      let r;
      let todayPrice = data[i];      

      // If no data, set price as 0
      if (todayPrice === null) { todayPrice = 0; }

      // If the price is zero (multiply and parseInt to check), set return as 0 
      // this is for days before we have data
      if (parseFloat(todayPrice) === 0) {      
        r = 0;
      } else {
        r = (((todayPrice - startingPrice) / startingPrice) * 100).toFixed(2);
      }

      // Add to array
      returnsArr.push(r);
      i++;
    }
    return returnsArr;

  }

}
