import ImpactDistByOneFormulaChart from 'src/javascripts/components/charts/ImpactDistByOneFormulaChart';
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import TopTable from 'src/javascripts/components/tables/TopTable';
import ExpandExcerpt from 'src/javascripts/components/utilities/ExpandExcerpt';

export default class ResearchCausesShow {

  constructor() {}

  render() {

    // Expand problem statement on click
    ExpandExcerpt();

    // Render impact chart, if chart does not already have c3-data (already initialized)
    // Needed to prevent re-init when navigating away from page, before new body has loaded
    if (typeof $('#impact_dist_by_one_formula').data('c3-chart') === 'undefined') {

      // Get data, render chart, set up chart, and create connected table, if exists
      const chart = new ImpactDistByOneFormulaChart( $('#impact_dist_by_one_formula') );
      $.when( chart.getData() )
       .then( data => chart.render(data) );

    }

    // Metric details table
    if ( $("#metric_details_table_wrapper").length === 0) {
      const table = new DefaultTable($('#metric_details_table'));
      $.when( table.render() ).then( status => table.setUp());
    }

    // Render top table
    if ( $('#top_rated_companies_table_wrapper').length === 0 ) {
      let table = new TopTable($('#top_rated_companies'));
      $.when( table.render() ).then( status => table.setUp('top_rated_companies') )
    }

  }

}