import FormatString from 'src/javascripts/components/utilities/FormatString';
import * as moment from 'moment';

// ==============================================================
// Returns chart tooltip format
// ==============================================================

export default function(chart, tooltip, d, defaultTitleFormat, defaultValueFormat, changeFormat, decimals, color) {
  let text; let i = 0;

  // Set prices from data array attached to chart element
  const prices = $(chart.element).data('prices');

  // Create tooltip item for every value on the date
  while (i < d.length) {

    // Move to next item if value not present
    if (typeof d[i].value === 'undefined') {i++; continue;}

    // Create initial text
    if (!text) {
      const title = moment(d[i].x).calendar();
      text = '<table class=\'' + tooltip.CLASS.tooltip + '\'>' + (title || (title === 0) ? '<tr><th colspan=\'2\' class=\'bg-primary-light\'>' + title + '</th></tr>' : '');
    }

    // Set name from data
    let name = (d[i].name.substr(0, 1).toUpperCase() + d[i].name.substr(1)).replace(/_/g,' ');
    
    // Set return value from data
    let value = FormatString(parseFloat(d[i].value.toFixed(decimals)), changeFormat, decimals, true, true, false);

    // Set change value and format
    const scopedData = chart.data(d[i].id);
    const { values } = scopedData[0];
    const start = values[0].value;
    const end = values[d[i].index].value;
    const change = end - start;
    const changeString = FormatString(change, changeFormat, decimals, true, true, true);

    // Add text, including change string unless this is for returns
    const bgcolor = tooltip.levelColor ? tooltip.levelColor(d[i].value) : color(d[i].id);
    text += '<tr class=\'' + tooltip.CLASS.tooltipName + '-' + d[i].id + '\'>';
    text += '<td class=\'name\'><span style=\'background-color:' + bgcolor + '\'></span>' + name + '</td>';

    // Add price from prices array attached to chart data, if exists and 
    // if not for current portfolio, which is often used for comparison. Don't want to 
    // show artificial prices for portfolio or accounts
    let portfolioNames = [$('.page-wrapper').attr('data-portfolio-name'), 'Typical portfolio', 'Current portfolio', 'Proposed portfolio'];
    if (typeof prices !== 'undefined' && portfolioNames.indexOf(d[i].name) === -1) {
      let price;
      // Determine index of data on chart
      let dataIndex = d[i].index
      // Set price from prices array
      for (var x = 0; x < prices.length; x++) if (prices[x][0] === d[i].name) {price = prices[x][dataIndex + 1];};
      // Format price string for display in tooltip
      price = FormatString(parseFloat(price), 'currency', decimals, false, false, false);
      // Build text for tooltip
      text += '<td class=\'value\'>' + price + ' (' + value + ')</td>';
      text += '</tr>';
      i++;
    } else {
      text += '<td class=\'value\'>' + value + '</td>';
      text += '</tr>';
      i++;
    }

  }
  return text + '</table>';
};

