import Spinner from 'src/javascripts/components/utilities/Spinner';
import ReloadResearch from 'src/javascripts/components/research/ReloadResearch'
import {filterTypeahead, screensTypeahead, companiesBloodhound} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default function() {

  // Constants
  const researchScope = $('#researchResultsCard').attr('data-research-scope');

  // Init bloodhounds
  companiesBloodhound.initialize();

  // Init typeaheads
  screensTypeahead();
  filterTypeahead('.impact-causes-name-short-filter', 'causes', '/causes/search?', 100, 'cause');

  if (researchScope === 'companies') {

    // Initiate companies typeahead as one
    $('.companies-symbol-filter').typeahead({
      hint: true,
      highlight: true,
      minLength: 0,
    }, {
      name: 'companies',
      display: 'name',
      limit: 44,
      source: companiesBloodhound.ttAdapter(),
      templates: {
        suggestion(el) {
          if (el.name === null) {
            return noResultsTemplate();
          } else {
            return logoTemplate(el.name, el.symbol, el.logo_url);
          }
        }
      },
    });

  } else {
    filterTypeahead('.funds-symbol-filter', 'funds', '/funds/search?', 45, 'logo');

  }

  // On typeahead select
  $('.research-filters-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {

    // Prevent default
    ev.preventDefault();

    // Set value
    let value;
    if (typeof suggestion.symbol !== 'undefined') {
      value = suggestion.symbol;
    } else if (typeof suggestion.is_screen !== 'undefined') {
      value = suggestion.key;
    } else if (typeof suggestion.name !== 'undefined') {
      value = suggestion.value;
    } else if (typeof suggestion.value !== 'undefined') {
      value = suggestion.value;
    } else {
      value = suggestion;
    }

    // Continue if suggestion present
    if ((typeof value !== 'undefined') && (value !== null)) {

      // Add spinner and opacity
      Spinner($('#researchResultsCard'));
      $('#researchResultsCard .card-body').css('opacity', 0.25);

      // Set filter scope
      let filter = $(this).attr('data-filter');

      // Reload page with updated params
      ReloadResearch(filter, value);

    }

  });


  // On click of remove filter
  $('.remove-filter').click(function(ev) {

    // Prevent default
    ev.preventDefault();

    // Add spinner and opacity
    Spinner($('#researchResultsCard'));
    $('#researchResultsCard .card-body').css('opacity', 0.25);

    // Set filter and suggestion
    let filter = $(this).attr('data-filter');
    let suggestion = $(this).attr('data-suggestion');

    // Reload page with updated params
    ReloadResearch(filter, suggestion, 'sub');

  });


}