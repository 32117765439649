import SignUpForm from 'src/javascripts/components/forms/SignUpForm';
import PasswordVisibility from 'src/javascripts/components/forms/PasswordVisibility';
import CustomValidators from 'src/javascripts/components/forms/CustomValidators';

export default class AssessmentsSave {

  constructor() {}

  render() {

    // Sign up form and password visibility
    SignUpForm();
    PasswordVisibility();

    // Custom validators
    CustomValidators();


  }

}