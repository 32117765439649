import ManualTable from 'src/javascripts/components/tables/ManualTable';

export default class SettingsAccountsMembershipsIndex {

  constructor() {}

  render() {

    // Render table
    if ( $('#account_memberships_table').length !== 0 ) {
      let table = new ManualTable($('#account_memberships_table'));
      $.when( table.render() ).then( status => table.setUp('account_memberships_table') )
    }

  }

}