import Spinner from 'src/javascripts/components/utilities/Spinner';

export default class QuestionnairesCollectionDashboardsIndex {

  constructor() {}

  render() {


    // If scorecards present
    if ($('.summary-scorecard').length !== 0) { 
      $('.summary-scorecard').each(function() {
        let totToday = 0;
        let countToday = 0;
        let totYe = 0;
        let countYe = 0;
        $(this).find('tbody tr:not(:last-child)').each(function() {
          let st = parseFloat($(this).find('.scorecard-circle.today').text());
          let sy = parseFloat($(this).find('.scorecard-circle.year-end').text());
          if (!isNaN(st)) {
            totToday += st;
            countToday += 1;
          }
          if (!isNaN(sy)) {
            totYe += sy;
            countYe += 1;
          }
        });
        if (totToday !== 0) {
          $(this).closest('.table-container').find('.total-today .dark').text(totToday.toFixed(1) + ' / ' + countToday.toFixed(1));
          $(this).closest('.table-container').find('.total-year-end .dark').text(totYe.toFixed(1) + ' / ' + countYe.toFixed(1));
        }
      });
    }

  }

}