import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {genericDisplayTemplate, noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class PortfoliosItemsRecommendationSummariesNew {

  constructor() {}

  render() {

	 	// Get households for account
		let accountUrl = $('body').attr('data-account-url');

	  // On selecting a questionnaire response
	  filterTypeahead('#questionnaire_responses_search', 'questionnaire_responses', '/' + accountUrl + '/questionnaires/responses/search?', 382, 'generic');
	  $('#questionnaire_responses_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
	    let itemId = suggestion.value;
	    $('#hidden_questionnaire_response_id').val(itemId);
	  })

	  // On selecting a portfolio item
	  filterTypeahead('#portfolio_items_search', 'portfolio_items', '/' + accountUrl + '/portfolios/items/search?', 382, 'generic');
	  $('#portfolio_items_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
	    let itemId = suggestion.value;
	    $('#hidden_portfolio_item_id').val(itemId);
	  })

	  // Questionnaires and portfolio items search
	  filterTypeahead('#summary_modules_search', 'recommendation_summary_modules', '/' + accountUrl + '/recommendations/summary_modules/search?', 382, 'generic');
	  $('#summary_modules_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
	    let moduleId = suggestion.value;
	    $(this).blur();
	    $('#hidden_summary_module_id').val(itemId);
	  })

	  // Questionnaires and portfolio items search
	  filterTypeahead('#action_plans_search', 'recommendation_action_plans', '/' + accountUrl + '/recommendations/action_plans/search?', 382, 'generic');
	  $('#summary_modules_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
	    let moduleId = suggestion.value;
	    $(this).blur();
	    $('#hidden_action_plan_id').val(itemId);
	  })

	  // On selecting a fund as benchmark
	  $('.clear-typeahead').click(function() {
	    let t = $(this).closest('.form-group').find('.typeahead');
	    t.typeahead('val', '');
	    t.focus();
	  })

  }

}