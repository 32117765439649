import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
import Spinner from 'src/javascripts/components/utilities/Spinner';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class FundsTypeahead {

  constructor(el) { this.el = el; }

  render() {

    let url = '/funds/search?query=%QUERY';
    let pc = $(this.el).attr('data-country');
    if (typeof pc !== 'undefined') {url += '&country=' + pc;}

    const fundsBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: url,
        wildcard: "%QUERY"
      }});

    fundsBloodhound.initialize();

    const noResultsTemplate = () => '<div class="empty-message">Unable to find anything</div>';

    const logoDisplayTemplate = (name, symbol, logo_url) => '<div class="d-flex align-items-center">' +
      '<div class="d-block mr-3"><img alt="logo" width="25px" src="' + logo_url + '"></div>' +
      '<div class="d-block">' + name + ' (' + symbol + ') </div>' +
      '<div class="ml-auto mr-2">Fund</div>' +
      '</div>';

    const genericDisplayTemplate = (name, symbol) => '<div class="d-flex align-items-center">' +
      '<div class="d-block">' + name + ' (' + symbol + ')' + '</div>' +
      '<div class="ml-auto mr-2">Fund</div>' +
      '</div>';

    const typeahead = () => $(this.el).typeahead({
      hint: true,
      highlight: true,
      minLength: 1,
    }, {
      name: 'funds',
      display: 'name',
      limit: 211,
      source: fundsBloodhound.ttAdapter(),
      templates: {
        suggestion(el) {
          if (el.name === null) {
            return noResultsTemplate();
          } else if ((el.logo_url !== null) && (el.logo_url.length > 0)) {
            return logoDisplayTemplate(el.name, el.symbol, el.logo_url);
          } else {
            return genericDisplayTemplate(el.name, el.symbol);
          }
        }
      },
    });

    // Initiate typeaheads
    typeahead();

    $('.funds-typeahead.visit-record-on-click').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      let t = suggestion.record_type;
      let v = suggestion.value;
      let params = {};
      params['share'] = suggestion.symbol;
      if ((typeof v !== 'undefined') && (v !== null)) {
        return Turbolinks.visit('/' + t + '/' + v + '?' + $.param(params));
      }
    });
    
  }

}
