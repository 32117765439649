import Spinner from 'src/javascripts/components/utilities/Spinner';
import ImpactDistByAllFormulasChart from 'src/javascripts/components/charts/ImpactDistByAllFormulasChart';
import ToggleReturnsChart from 'src/javascripts/components/charts/actions/ToggleReturnsChart';
import ImpactDistMetricScores from 'src/javascripts/components/charts/ImpactDistMetricScores';
import ImpactByFormulaTable from 'src/javascripts/components/tables/ImpactByFormulaTable';
import ExpandExcerpt from 'src/javascripts/components/utilities/ExpandExcerpt';
import ReturnsChart from 'src/javascripts/components/charts/ReturnsChart';
import ReturnsDollarsChart from 'src/javascripts/components/charts/ReturnsDollarsChart';

export default class PublicFundsShow {

  constructor() {}

  render() {

    // Expand problem statement on click
    ExpandExcerpt();

    // Render impact distribution chart, if chart does not already have c3-data (already initialized)
    // Needed to prevent re-init when navigating away from page, before new body has loaded    
    if (typeof $('#impact_dist_by_all_formulas').data('c3-chart') === 'undefined') {
      const chart = new ImpactDistByAllFormulasChart( $('#impact_dist_by_all_formulas') );
      $.when( chart.getData() )
       .then( data => chart.render(data) );
    }

    // Impact by formula table
    if ($(".dataTables_wrapper").length === 0) {
      let tableId = 'impact_by_formula';
      let table = new ImpactByFormulaTable($('#' + tableId));
      $.when( table.render(tableId) ).then( status => table.setUp(tableId) );
    }

    // On show/hide of disclosure or overall impact modal; only if in display mod mode
    if ($('body').hasClass('modal-open')) {
      $('.detail-modal').on('show.bs.modal', function(e) {
        $('#fundProfileShow').css('opacity', 0.2);
        $('.banner-container').css('opacity', 0.05);
      }).on('hide.bs.modal', function(e) {
        $('#fundProfileShow').css('opacity', 1.0);
        $('.banner-container').css('opacity', 1.0);
      }).on('hidden.bs.modal', function(e) {
        $('body').addClass('modal-open');
      });
    }

    // Re-flow dist chart on click of 'Scorecard' tab
    $('a[href="#scorecardTab"]').on('shown.bs.tab', function (e) {
      setTimeout(function() {
        let distChart = $('#impact_dist_by_all_formulas').data('c3-chart');
        if (typeof distChart !== 'undefined') { 
          distChart.flush(); 
        }
      }, 500);
    });

    // Financial tab
    $('a[href="#financialTab"]').on('shown.bs.tab', function (e) {

      // Initialize a new chart instance for each chart on the page
      if (($('#returns_chart').attr('data-return-option') === 'percentage') && (typeof $('#returns_chart').data('c3-chart') === 'undefined')) {

        // Set chartId and chart object
        // Get data, render chart, set up chart, and create connected table, if exists
        let chart = new ReturnsChart($('#returns_chart'));
        $.when(chart.getData())
         .then( data => chart.render(data))
         .then( chartObj => chart.setUp(chartObj));

      } else if (($('#returns_chart').attr('data-return-option') === 'dollar') && (typeof $('#returns_chart').data('c3-chart') === 'undefined')) {

        // Set chartId and chart object
        // Get data, render chart, set up chart, and create connected table, if exists
        let chart = new ReturnsDollarsChart($('#returns_chart'));
        $.when(chart.getData())
         .then( data => chart.render(data))
         .then( chartObj => chart.setUp(chartObj));

      }

      // Toggle returns chart between hypothetical growth and percentage returns
      ToggleReturnsChart();

    });

  }

}