import * as d3 from 'd3';
import * as c3 from 'c3';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import Chart from 'src/javascripts/components/Chart';
import SetAxisMin from 'src/javascripts/components/charts/axes/SetAxisMin';
import SetAxisMax from 'src/javascripts/components/charts/axes/SetAxisMax';
import SetAxisTicks from 'src/javascripts/components/charts/axes/SetAxisTicks';
import ApiRequestsTooltip from 'src/javascripts/components/charts/tooltips/ApiRequestsTooltip';

export default class ReturnsChart extends Chart {

  getData() {

    // Set chart element id
    const chartId = this.el.attr('id');

    // Set a spinner to indicate chart loading
    Spinner(this.el);

    // Build url
    const url = this.el.attr('data-url');

    // Get data
    return $.getJSON(url);

  }

  render(data) {

    // Set id
    let chartId = this.el.attr('id');

    // Set min value for y axis, using returned ajax data or chart data attributes
    // Remove first sub array, which is dates for chart
    const yAxisMin = SetAxisMin([data[1]], this.el.selector);

    // Set max value for y axis, using returned ajax data or chart data attributes
    // Remove first sub array, which is dates for chart
    const yAxisMax = SetAxisMax([data[1]], this.el.selector);

    // Set y axis tick values, using value range or chart data attributes
    const yAxisTickValues = SetAxisTicks(yAxisMin, yAxisMax, this.el.selector);

    // Render chart
    var chart = c3.generate({
      bindto: '#' + chartId,
      padding: {top: -10, bottom: -10, left: 35, right: 15},
      data: {
        x: 'date',
        columns: data,
        type: 'line',
        color: function (color, d) { return '#8c81d1'; }
      },
      point: {show: false},
      axis: {
        x: {
          type: 'timeseries',
          padding: {left: 0, right: 0}
        },
        y: {
          min: yAxisMin,
          max: yAxisMax,
          padding: {bottom: 20},
          tick: {
            outer: false,
            values: yAxisTickValues,
            format(v, id, i, j) { return parseInt(v); }
          }
        }
      },
      legend: {show: false},
      tooltip: {
        format: {},
        contents(d, defaultTitleFormat, defaultValueFormat, color) {
          return ApiRequestsTooltip(chart, this, d, defaultTitleFormat, defaultValueFormat, 'decimal', 0, color);
        }
      },
      grid: {
        y: {show: false},
        x: {show: false}
      }
    });

    return chart;
  }

};
