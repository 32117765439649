import NavQuestionnaire from 'src/javascripts/components/nav/NavQuestionnaire';
import Swal from 'sweetalert2';
import Spinner from 'src/javascripts/components/utilities/Spinner';

export default class QuestionnairesCollectionModulesIndex {

  constructor() {}

  render() {

    // Set account url
    let accountUrl = $('body').attr('data-account-url');

    // Add border to navbar on scroll
    NavQuestionnaire();

    // On submit
    $('#submitQuestionnaireResponse').click(function(el) {
      el.preventDefault();

      // Add field to form to indicate we're submitting it
      $('#hidden_submit_questionnaire').val("true");

      // Opacity on page
      $('#approvalMessageModal').modal('hide');
      $('#save_response_form .data-collection-content').css('opacity', 0.25)
      $('.submit-response-spinner-container').removeClass('d-none');
      Spinner($('.submit-response-spinner-container'));

      // Submit form
      $('#hiddenSaveResponse').click();

    })

    // On submit
    $('.unsubmit-questionnaire-response').click(function(el) {
      el.preventDefault();

      // Confirm and then continue
      return Swal.fire({
        title: "Confirm",
        text: "Please confirm that you would like to change the status of this questionnaire back to 'draft'.",
        animation: false,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-light border',
          popup: 'animated fadeIn faster'
        }
      }).then((result) => {
        if (result.value) {
          // Add field to form to indicate we're submitting it
          $('#hidden_submit_questionnaire').val("false");

          // Opacity on page
          $('#save_response_form .data-collection-content').css('opacity', 0.25)
          $('.submit-response-spinner-container').removeClass('d-none');
          Spinner($('.submit-response-spinner-container'));

          // Submit form
          $('#hiddenSaveResponse').click();

        }
      });

    })

    // Custom methodology ratings
    if ( $('#customMethodologyRatingsList').length !== 0 ) {
      let companyId = $('#customMethodologyRatingsList').attr('data-company-id');
      let url = "/" + accountUrl + "/companies/" + companyId + "/ratings/custom_methodology_scorecard";
      let params = {};
      Spinner($('#customMethodologyRatingsList'));
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url + $.param(params)
      });
    }

  }

}