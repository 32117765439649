import AssessmentSliders from 'src/javascripts/components/forms/AssessmentSliders';

export default class ClientsAssessmentsSetCauses {

  constructor() {}

  render() {

	  // Draggable sections
	  $('#sortableSections').sortable({
	    placeholder: "available-section-space",
	    update: function(event, ui) {
	      let sortOrder = $(this).sortable('toArray');
	      $.each(sortOrder, function(ind, sectionId) {
	        $('#' + sectionId).find('.hidden-rank').val(ind + 1);
	      });
	    }
	  });

	  $('.collapse-item').click(function() {
	  	let isOpen = $(this).closest('.card').hasClass('minimized');
	  	if (!isOpen) {
	  		$('#sortableSections').sortable('disable');
	  		$('.drag-section').attr('data-title', 'Close the cause section to re-enable sorting');
	  		$('.drag-section').attr('data-original-title', 'Close the cause section to re-enable sorting');
	  	} else {
	  		$('#sortableSections').sortable('enable');
	  		$('.drag-section').attr('data-title', 'Drag cause to change importance');
	  		$('.drag-section').attr('data-original-title', 'Drag cause to change importance');
	  	}
	  });

	  // Update classes of excluded/included sections
	  $('.section-option select').change(function() {
	    let val = $(this).val();
	    let section = $(this).closest('.section-option');
	    if (val === 'true') {
	      section.addClass('excluded');
	    } else {
	      section.removeClass('excluded');
	    }
	    let selected = $(".section-option:not(.excluded)");
      if (selected.length === 0) {
				$('.submit-button').addClass('d-none');
      	$('.no-causes-button').removeClass('d-none');
      } else {
      	$('.no-causes-button').addClass('d-none');
				$('.submit-button').removeClass('d-none');
      }
	  });

	  // Sliders
	  AssessmentSliders();


  }

}