import Spinner from 'src/javascripts/components/utilities/Spinner';
import 'summernote/dist/summernote-bs4.min.js'

export default class QuestionnairesTablesEdit {

  constructor() {}

  render() {

  	// Set constant vars
  	const accountUrl = $('body').attr('data-account-url');

	  // Summernote text editor
	  if ($('.summernote').length !== 0) {
	    $('.summernote').summernote({
	      minHeight: 150,
	      dialogsInBody: true,
	      toolbar: [
	        ['font', ['bold', 'italic', 'underline', 'clear']],
	        ['para', ['ul']],
	        ['insert', ['link']],
	      ]}
	    );
	  }

	  // Update column title on blur
	  $('.column-title-field').blur(function() {
	  	let questionnaireId = $(this).attr('data-questionnaire-id');
	  	let tableId = $(this).attr('data-table-id');
	  	let columnId = $(this).attr('data-column-id');
      let url = '/' + accountUrl + '/questionnaires/' + questionnaireId + '/tables/columns/' + columnId;
      let params = {};
      params['table_id'] = tableId;
      params['questionnaire_table_column'] = {}
      params['questionnaire_table_column']['title'] = $(this).val();
      url = url + '?' + $.param(params);
      $.ajax({
        type: 'PATCH',
        dataType: 'application/json',
        url: url,
        complete(result) { 
          let responseText = JSON.parse(result.responseText);
        },
      });
	  })

	  // Update row title on blur
	  $('.row-title-field').blur(function() {
	  	let questionnaireId = $(this).attr('data-questionnaire-id');
	  	let tableId = $(this).attr('data-table-id');
	  	let rowId = $(this).attr('data-row-id');
      let url = '/' + accountUrl + '/questionnaires/' + questionnaireId + '/tables/rows/' + rowId;
      let params = {};
      params['table_id'] = tableId;
      params['questionnaire_table_row'] = {}
      params['questionnaire_table_row']['title'] = $(this).val();
      url = url + '?' + $.param(params);
      $.ajax({
        type: 'PATCH',
        dataType: 'application/json',
        url: url,
        complete(result) { 
          let responseText = JSON.parse(result.responseText);
        },
      });
	  })


  }

}