// Get range of values from data for Y axis calc -- one array of data
// Used to set min/max values on y-axis
// Need separate functions for single and multiple arrays so can use
// when add a single array of data to chart

export default function(data) {

  const numbers = [];

  // Set array based on type of data sent
  if (data[0].values.length !== 0) {

    let arr = data[0].values;
    let i = 0;
    while (i < arr.length) {
      if (arr[i].value !== 'NaN') { numbers.push(parseFloat(arr[i].value)); }
      i++;
    }

  } else {

    let arr = data[0].slice(1);
    let i = 0;

    while (i < arr.length) {
      if (arr[i] !== 'NaN') { numbers.push(parseFloat(arr[i])); }
      i++;
    }
  }


  return numbers;

};