import Spinner from 'src/javascripts/components/utilities/Spinner';
import SignUpForm from 'src/javascripts/components/forms/SignUpForm';
import PasswordVisibility from 'src/javascripts/components/forms/PasswordVisibility';
import CustomValidators from 'src/javascripts/components/forms/CustomValidators';

export default class Auth {

  constructor() {}

  render() {

    // Sign up form if on sign-up page
    if ($('#signup_form').length > 0) {
    	SignUpForm();
    }

    // Show cookies request if indicated
    if ( $('#cookiesNotice').length > 0 ) {
    	$('#cookiesNotice').modal('show');
    }

    // Password visibility
    PasswordVisibility();

    // Custom validators
    CustomValidators();

    // Show toast message if present
    if ($('.toast.auth-message').length > 0) {
      $('.toast.auth-message').toast({autohide: false});
      $('.toast.auth-message').toast('show');
    }

    // Submit Okta form if present
    let okta = $('.btn-okta');
    if (okta.length !== 0) {
      let iss = okta.attr('data-iss');
      if (typeof iss !== 'undefined') {
        Spinner('.row .col-12.col-sm-7');
        $('.row .col-12.col-sm-7 .row').css('opacity', 0.25);
        $('#oktaAuth').submit();
      }
    }

    // Submit Okta form on click
    $('.btn-okta').on('click', function() {
      $('#oktaAuth').submit();
    })

  }

}