import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import AddRowHoverButtons from 'src/javascripts/components/tables/helpers/AddRowHoverButtons';
import AddRowClickLinks from 'src/javascripts/components/tables/actions/AddRowClickLinks';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';
import CheckPaging from 'src/javascripts/components/tables/helpers/CheckPaging';
import Swal from 'sweetalert2';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

export default class PortfolioItemMetricScoresTable extends Table {

  render() {

    // Set a spinner to indicate chart loading
    if (this.el.closest('.card').find('.spinner-container').length === 0) {
      Spinner(this.el.closest('.card-body'));
    }

    // Set table id
    const tableId = this.el.attr('id');

    // Build url, use to get filtered data with server-side processing
    let dataUrl = BuildApiUrl(tableId);

    // Set additional data vars (used below in table creation)
    let cols = GetCols(this.el);

    // Set order cols
    let orderCol = $('#' + tableId).attr('data-order-col');
    if (typeof orderCol === 'undefined') {orderCol = 0};
    let orderDir = $('#' + tableId).attr('data-order-dir');
    if (typeof orderDir === 'undefined') {orderDir = 'asc'};

    // Left fixed
    let leftFixed = $('#' + tableId).attr('data-left-fixed');
    if (typeof leftFixed === 'undefined') {leftFixed = 1};

    // Length menu
    let lengthMenu;
    let lengthAll = $('#' + tableId).attr('data-length-all');
    if (typeof lengthAll === 'undefined') {
      lengthMenu = [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]];
    } else if (lengthAll === 'false') {
      lengthMenu = [[10, 25, 50, 100], [10, 25, 50, 100]];
    } else {
      lengthMenu = [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]];      
    }

    // Auto width
    let autoWidth = $('#' + tableId).attr('data-auto-width');
    if (typeof autoWidth === 'undefined') {autoWidth = true;}

    // Render table
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      orderCellsTop: true,
      retrieve: true,
      serverSide: true,
      scrollY: 535,
      scrollX: true,
      autoWidth: true,
      scrollCollapse: true,
      fixedColumns: {leftColumns: leftFixed},
      paging: true,
      pageLength: 50,
      lengthChange: true,
      lengthMenu: lengthMenu,
      info: true,
      ordering: true,
      order: [[orderCol, orderDir]],
      searching: true,
      ajax: dataUrl,
      dom: 'tlp',
      columns: cols,
      language: { 
        lengthMenu: "Show _MENU_ questions",
        infoEmpty: "No data found. Reach out to us with any questions.",
        emptyTable: "No data found. Reach out to us with any questions.",
        zeroRecords: "No data found. Reach out to us with any questions."
      },
      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        $(row).attr("data-metric-id", data.metric_id);
        $(row).attr("data-record-id", data.record_id);
        $(row).attr("data-account-url", data.account_url);

      },

      drawCallback: function( settings ) {

        // Remove spinner after table has drawn
        $('#' + tableId).closest('.card').find('.spinner-container').remove();
        $('#' + tableId).closest('.card-body').css('opacity', 1);

      }


    });

    return 'rendered';
  }

  setUp(tableId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Set searchable function for table
    $('#search_' + tableId).keyup(function(){
      oTable.search( $(this).val() ).draw();
    });

    // Ensure search input starts blank
    $('#search_' + tableId).val('');

    // // Set click areas
    // let firstTd; 
    // let secondTd;
    // firstTd = 'tbody > tr > td:first-child';
    // secondTd = 'tbody > tr > td:nth-child(2)';
    // $('#questionnaire_questions_table').on('click', firstTd, function() { 
    //   let r = $(this).closest('tr');
    //   let accountUrl = r.attr('data-account-url');
    //   let recordId = r.attr('data-record-id');
    //   let url = "/" + accountUrl + "/questionnaires/questions/" + recordId + "/edit";
    //   return window.location.href = url;
    // });

    // $('#questionnaire_questions_table').on('click', secondTd, function() { 
    //   let r = $(this).closest('tr');
    //   let accountUrl = r.attr('data-account-url');
    //   let recordId = r.attr('data-record-id');
    //   let url = "/" + accountUrl + "/questionnaires/questions/" + recordId + "/edit";
    //   return window.location.href = url;
    // });

    return 'setup';

  }


}
