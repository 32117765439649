import GetReturnsSingleArr from 'src/javascripts/components/charts/helpers/GetReturnsSingleArr';

// Convert prices data to returns -- multiple arrays of data
export default function(dates, data) {
  
  // Change prices data to daily returns (compared to first price in the array)
  const returns = [dates];
  let i = 1;
  while (i < data.length) {
    const returnsArr = GetReturnsSingleArr(data[i]);
    returns.push(returnsArr);
    i++;
  }

  return returns;
}