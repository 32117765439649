import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

export default function() {

  // Toggle adding/removing featured metric 
  $('.humanized-col').click(function() {
    let params = {};
    let el = $(this);
    let cards = $(this).find('.card');
    let accountUrl = $('body').attr('data-account-url');
    let reportId = el.attr('data-report-id');
    let templateId = el.attr('data-template-id');
    let portfolioId = el.attr('data-portfolio-id');
    let metricId = el.attr('data-metric-id');
    let humanizedMetricId = el.attr('data-humanized-metric-id');
    let active = el.hasClass('active');
    params['metric_id'] = metricId;
    params['humanized_metric_id'] = humanizedMetricId;
    if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId;}

    // Different depending on whether adding or removing metric
    if (active) {

      // Set url
      let url;
      if (typeof templateId !== 'undefined') {
        url = '/' + accountUrl + '/reports/' + templateId + '/remove_featured_metric?metric_id=' + metricId + '&humanized_metric_id=' + humanizedMetricId;
      } else {
        url = '/' + accountUrl + '/reports/' + reportId + '/remove_featured_metric?metric_id=' + metricId + '&humanized_metric_id=' + humanizedMetricId;
      }
      $.ajax({
        type: "DELETE",
        dataType: "application/json",
        url: url,
        complete(result) {

          // Update active status of metric
          el.removeClass('active').addClass('inactive');
          cards.removeClass('active').addClass('inactive');
          cards.find('.included-indicator').removeClass('d-flex').addClass('d-none');
          cards.find('.excluded-indicator').removeClass('d-none').addClass('d-flex');
          el.tooltip('hide');
          el.attr('data-original-title', "Metric not included in report. Click to add to report");
          el.attr('data-title', "Metric not included in report. Click to add to report");

        }
      });

    } else {

      // Set url for reports or templates
      let url;
      if (typeof templateId !== 'undefined') {
        url = '/' + accountUrl + '/reports/' + templateId + '/add_featured_metric?' + $.param(params)
      } else {
        url = '/' + accountUrl + '/reports/' + reportId + '/add_featured_metric?' + $.param(params)
      }

      // Ajax call
      $.ajax({
        type: "PATCH",
        dataType: "application/json",
        url: url,
        complete(result) {
          let responseText = JSON.parse(result.responseText);
          let current_hm = responseText.current_humanized_metric_id
          let currentCol = $('.humanized-col[data-humanized-metric-id=\'' + current_hm + '\']');
          let currentCard = currentCol.find('.card');

          // Remove current
          currentCol.removeClass('active').addClass('inactive');
          currentCard.removeClass('active').addClass('inactive');
          currentCard.find('.included-indicator').removeClass('d-flex').addClass('d-none');
          currentCard.find('.excluded-indicator').removeClass('d-none').addClass('d-flex');
          currentCol.tooltip('hide');
          currentCol.attr('data-original-title', "Metric not included in report. Click to add to report");
          currentCol.attr('data-title', "Metric not included in report. Click to add to report");

          // Update active status of metric
          el.removeClass('inactive').addClass('active');
          cards.removeClass('inactive').addClass('active');
          cards.find('.included-indicator').removeClass('d-none').addClass('d-flex');
          cards.find('.excluded-indicator').removeClass('d-flex').addClass('d-none');
          el.tooltip('hide');
          el.attr('data-original-title', "Metric included in report. Click to remove");
          el.attr('data-title', "Metric included in report. Click to remove");

        }
      });

    }

  })

}