import Spinner from 'src/javascripts/components/utilities/Spinner';
import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import StickySidebar from 'src/javascripts/components/nav/StickySidebar';

export default class QuestionnairesIndex {

  constructor() {}

  render() {

    // Sticky sidebar scrolling
    StickySidebar();

    // Expand when click on table of contents item
    $(window).scroll(function(e) {
      let scroll = $(window).scrollTop();
      if (scroll > 130) {
        $('.sidebar-title-info').removeClass('min-height');
      } else {
        $('.sidebar-title-info').addClass('min-height');
      }
    });
    let scroll = $(window).scrollTop();
    if (scroll > 130) {
      $('.sidebar-title-info').removeClass('min-height');
    } else {
      $('.sidebar-title-info').addClass('min-height');
    }

    // Expand when click on table of contents item
    $('.sticky-sidebar a.scroll-to-section').click(function(e) {
      e.preventDefault();
      let sectionId = $(this).attr('data-tag');
      setTimeout(function() {
        ScrollToTag(sectionId);  
      }, 500);
    });

    // Render table
    $('#questionnairesList').on('shown.bs.collapse', function (e) {
      if ( $('#questionnaires_table_wrapper').length === 0 ) {
        let table = new DefaultTable($('#questionnaires_table'));
        $.when( table.render() ).then( status => table.setUp('questionnaires_table') )
      }
    })

    // If tag present
    let tag = $('#questionnairesContent').attr('data-tag');
    if ((typeof tag !== 'undefined') && (tag.length > 0)) {
      let toExpand = $('#' + tag).find('.collapse');
      toExpand.collapse('show');
      $('.collapse').not(toExpand).collapse('hide');
      setTimeout(function() {
        ScrollToTag(tag);
      }, 1000);
    }

    // Render table
    $('#questionsList').on('shown.bs.collapse', function (e) {
      if ( $('#questionnaire_questions_table_wrapper').length === 0 ) {
        let table = new DefaultTable($('#questionnaire_questions_table'));
        $.when( table.render() ).then( status => table.setUp('questionnaire_questions_table') )
      }
    })

    // Render table
    $('#modulesList').on('shown.bs.collapse', function (e) {
      if ( $('#questionnaire_modules_table_wrapper').length === 0 ) {
        let table = new DefaultTable($('#questionnaire_modules_table'));
        $.when( table.render() ).then( status => table.setUp('questionnaire_modules_table') )
      }
    })

    // Render table
    $('#sectionsList').on('shown.bs.collapse', function (e) {
      if ( $('#questionnaire_sections_table_wrapper').length === 0 ) {
        let table = new DefaultTable($('#questionnaire_sections_table'));
        $.when( table.render() ).then( status => table.setUp('questionnaire_sections_table') )
      }
    })

    // Render table
    $('#textsList').on('shown.bs.collapse', function (e) {
      if ( $('#questionnaire_texts_table_wrapper').length === 0 ) {
        let table = new DefaultTable($('#questionnaire_texts_table'));
        $.when( table.render() ).then( status => table.setUp('questionnaire_texts_table') )
      }
    })

    // Render table
    $('#tablesList').on('shown.bs.collapse', function (e) {
      if ( $('#questionnaire_tables_table_wrapper').length === 0 ) {
        let table = new DefaultTable($('#questionnaire_tables_table'));
        $.when( table.render() ).then( status => table.setUp('questionnaire_tables_table') )
      }
    })


  }

}