import QuestionnairesIncludedQuestionsForm from 'src/javascripts/components/questionnaires/QuestionnairesIncludedQuestionsForm';

export default class QuestionnairesIncludedQuestionsEdit {

  constructor() {}

  render() {

  	QuestionnairesIncludedQuestionsForm();

  }

}