import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import Swal from 'sweetalert2';
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';

export default class SettingsCustomMetricsIndex {

  constructor() {}

  render() {
    let accountUrl = $('body').attr('data-account-url');

    // Render top table
    if ( $('#custom_metric_scores_table_wrapper').length === 0 ) {
      let table = new DefaultTable( $('#custom_metric_scores_table') );
      $.when( table.render() ).then( status => table.setUp('custom_metric_scores_table') )
    }

    // Select all reports
    $('.select-all-data').click(function() {
      $('#custom_metric_scores_table tr .checkbox').prop('checked', this.checked);
    });

    // On delete all
    // Export bulk report PDFs
    $('#deleteMetricScoresBulk').click(function() {

      // Set params
      let accountUrl = $('body').attr('data-account-url');
      let url = '/' + accountUrl + '/custom_metrics/metric_scores/destroy_bulk?';
      let params = {};
      let metricScoreIds = []
      $('#custom_metric_scores_table input.checkbox:checked').each(function() {
        let r = $(this).closest('tr').attr('data-record-id');
        if (typeof r !== 'undefined') {metricScoreIds.push(r);}
      })
      params['metric_score_ids'] = metricScoreIds;

      // Send ajax call
      return Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete the selected metric scores?",
        animation: false,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-light border',
          popup: 'animated fadeIn faster'
        }
      }).then((result) => {
        if (result.value) {
          $('#custom_metric_scores_table').closest('.card-body').css('opacity', 0.2);
          Spinner($('#custom_metric_scores_table').closest('.card'));
          $.ajax({
            type: 'DELETE',
            dataType: 'application/json',
            url: url + $.param(params),
            complete(result) { 
              let responseText = JSON.parse(result.responseText);
              $('#custom_metric_scores_table').closest('.card').find('.spinner-container').remove();
              $('#custom_metric_scores_table').closest('.card-body').css('opacity', 1);
              let table = $('#custom_metric_scores_table').DataTable();
              table.ajax.reload();
            },
          });
        }
      });

    })


  }

}