import Spinner from 'src/javascripts/components/utilities/Spinner';

export default class SettingsAccountsRolesEdit {

  constructor() {}

  render() {
    const accountUrl = $('body').attr('data-account-url');

    // Draggable dashboard items
    $('#sortableDashboardRoleInclusions').off().sortable({
      placeholder: "available-module-space",
      handle: 'h5.drag-module',
      update: function(event, ui) {

        // Opacity to indicate loading
        $('#newRoleModal .modal-body').css('opacity', 0.25)
        Spinner($('#newRoleModal .modal-content'));

        // Set url and params for updating in database
        let sortOrder = $(this).sortable('toArray');
        let roleId = $(this).attr('data-role-id');
        let url = '/' + accountUrl + '/roles/' + roleId + '/role_dashboard_inclusions/update_all.js?' + $.param(params);
        let params = {};
        params["role_dashboard_inclusions"] = {}

        // Iterate through sections
        $.each(sortOrder, function(ind, moduleId) {

          // Set li and params
          let li = $('#' + moduleId);
          let id = li.attr('data-inclusion-id');
          li.attr('data-sort-order', ind + 1);
          params["role_dashboard_inclusions"][id] = {};
          params["role_dashboard_inclusions"][id]["sort_order"] = ind + 1;

        });

        // Update in database
        $.ajax({
          type: 'PATCH',
          dataType: 'application/json',
          url: url + $.param(params),
          complete(result) { 
            $('#newRoleModal').attr('data-changed', 'true');
            $('#newRoleModal .modal-content').find('.spinner-container').remove();
            $('#newRoleModal .modal-body').css('opacity', 1)
          }
        });

      }
    });

    // Update user's settings preferences on select
    let $settingInput = $('input.dashboard-inclusion-input');
    if (typeof $settingInput !== 'undefined') {
      $settingInput.change(function() {
        $('#dashboardModulesModal .modal-body').css('opacity', 0.25)
        Spinner($('#dashboardModulesModal .modal-content'));

        // Set attrs
        let roleId = $(this).attr('data-role-id');
        let inclusionId = $(this).attr('data-inclusion-id');
        let attribute = $(this).attr('data-attribute');
        let val = $(this).is(':checked');

        // Set params as true or false
        let params = {};
        params['role_dashboard_inclusions'] = {}
        params['role_dashboard_inclusions'][attribute] = val;

        $.ajax({
          type: 'PATCH',
          dataType: "json",
          url: '/' + accountUrl + '/roles/' + roleId + '/role_dashboard_inclusions/' + inclusionId + '.js?' + $.param(params),
          complete(result) { 
            $('#dashboardModulesModal').attr('data-changed', 'true');
            $('#dashboardModulesModal .modal-content').find('.spinner-container').remove();
            $('#dashboardModulesModal .modal-body').css('opacity', 1)
          }
        })

      });

    }

    // Update name of preferences on select
    let $settingText = $('input.role-dashboard-name');
    if (typeof $settingText !== 'undefined') {
      $settingText.blur(function() {
        $('#dashboardModulesModal .modal-body').css('opacity', 0.25)
        Spinner($('#dashboardModulesModal .modal-content'));

        // Set attrs
        let roleId = $(this).attr('data-role-id');
        let accountUrl = $('body').attr('data-account-url');
        let inclusionId = $(this).attr('data-inclusion-id');

        // Set params as true or false
        let params = {};
        params['role_dashboard_inclusions'] = {}
        params['role_dashboard_inclusions']['name'] = $(this).val();

        $.ajax({
          type: 'PATCH',
          dataType: "json",
          url: '/' + accountUrl + '/roles/' + roleId + '/role_dashboard_inclusions/' + inclusionId + '.js?' + $.param(params),
          complete(result) { 
            $('#dashboardModulesModal').attr('data-changed', 'true');
            $('#dashboardModulesModal .modal-content').find('.spinner-container').remove();
            $('#dashboardModulesModal .modal-body').css('opacity', 1)
          }
        })

      });

    }

  }

}
