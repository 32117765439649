import SignUpForm from 'src/javascripts/components/forms/SignUpForm';

export default class AssessmentsContactInfo {

  constructor() {}

  render() {

    // Sign up form -- phone formatting
    SignUpForm();

  }

}