import * as d3 from 'd3';
import * as c3 from 'c3';
import Chart from 'src/javascripts/components/Chart';
import SetColors from 'src/javascripts/components/charts/helpers/SetColors';
import SetAxisMin from 'src/javascripts/components/charts/axes/SetAxisMin';
import SetAxisMax from 'src/javascripts/components/charts/axes/SetAxisMax';
import SetAxisTicks from 'src/javascripts/components/charts/axes/SetAxisTicks';
import ReturnsDollarsChartTooltip from 'src/javascripts/components/charts/tooltips/ReturnsDollarsChartTooltip';
import NoDataMessage from 'src/javascripts/components/alerts/NoDataMessage';
import GetReturnsAll from 'src/javascripts/components/charts/helpers/GetReturnsAll';
import FormatString from 'src/javascripts/components/utilities/FormatString';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildChartUrl from 'src/javascripts/components/charts/url/BuildChartUrl';
import SetChartTotal from 'src/javascripts/components/charts/actions/SetChartTotal';
import CreateChartLegend from 'src/javascripts/components/charts/legend/CreateChartLegend';
import ChangeChartDate from 'src/javascripts/components/charts/actions/ChangeChartDate';
import ChangeRebalancingOption from 'src/javascripts/components/charts/actions/ChangeRebalancingOption';

export default class ReturnsDollarsChart extends Chart {

  render(data) {

    // Show no data message if none available
    // data[1] is the chart data; data[0] is dates array
    if ((data === null) || (typeof data[1] === 'undefined') || (data[1].length <= 1)) { 
      return NoDataMessage(this.el); 
    }

    // Set id
    let chartId = this.el.attr('id');

    // Set colors for chart
    const colors = SetColors(data, chartId);

    // If indicated in chart data, set prices data and add to chart element for inclusion
    // in chart tooltip. Used with company, fund, holding and held company returns charts
    if ($('#' + chartId).attr('data-include-prices') === 'true') {

      // Set prices data for tooltip before transform to returns (next)
      // Drop first item in data, which is dates
      const prices = data.slice(1)    

      // Add prices data to chart element for tooltip -- using chart data for aggregating prices
      // data when add/remove items from chart
      $('#' + chartId).data('prices', prices);

    }

    // Set min value for y axis as 10000
    const yAxisMin = SetAxisMin([data[1]], this.el.selector);

    // Set max value for y axis, using dollars value
    const yAxisMax = SetAxisMax([data[1]], this.el.selector);

    // Set y axis tick values, using value range or chart data attributes
    const yAxisTickValues = SetAxisTicks(yAxisMin, yAxisMax, '#' + chartId);

    // Render chart
    var chart = c3.generate({
      bindto: '#' + chartId,
      padding: {top: 0, bottom: -10, left: 50},
      data: {
        x: 'date',
        columns: data,
        type: 'line',
        colors: colors
      },
      point: {show: false},
      axis: {
        x: {
          show: false,
          type: 'timeseries',
          padding: {left: 0, right: 0}
        },
        y: {
          min: yAxisMin,
          max: yAxisMax,
          inner: false,
          padding: {bottom: 25},
          tick: {
            outer: false,
            values: yAxisTickValues,
            format(v, id, i, j) { return "$" + Math.abs(v).toLocaleString("en", {minimumFracctionDigits: 0, maximumFractionDigits: 0}); }
          }
        }
      },
      legend: {show: false},
      tooltip: {
        format: {},
        contents(d, defaultTitleFormat, defaultValueFormat, color) {
          return ReturnsDollarsChartTooltip(chart, this, d, defaultTitleFormat, defaultValueFormat, 'currency', 0, color);
        }
      },
      grid: {
        y: {show: true},
        x: {show: false}
      }
    });

    return chart;
  }

  setUp(chart) {

    // Return if no chart available (don't attempt to set up below)
    if (typeof chart === 'undefined') {return;}

    // Set name and total at top of chart
    SetChartTotal(chart, chart.data()[0].id);

    // Listen for when user updates dates of chart
    ChangeChartDate(chart);

    // Set chart data to allow for accessing chart programmatically (used to allow tables to add/remove data)
    // Also allows for destroying chart before Turbolinks cache
    // https://stackoverflow.com/questions/25765596/how-to-get-object-reference-from-selector-for-c3-js-charts
    let chartId = '#' + $(chart.element).attr('id');
    $(chartId).data('c3-chart', chart);

    // Reload on change rebalancing option
    ChangeRebalancingOption(chart);

    // Get selected records to show in legend
    // Causing issues when before other functions above; appears to be returning for net-impact and returns chart
    // and not running functions after it
    CreateChartLegend(chart);

    // Remove spinner
    $(chart.element).find('.spinner-container').remove();

    return chart;
  }

};
