// Reset y axis on chart, using data from above functions
import SetAxisMin from 'src/javascripts/components/charts/axes/SetAxisMin';
import SetAxisMax from 'src/javascripts/components/charts/axes/SetAxisMax';
import SetAxisTicks from 'src/javascripts/components/charts/axes/SetAxisTicks';

export default function(chart) {

  // Get resize data option from chart element
  const resizeOption = $(chart.element).attr('data-chart-resize');

  // Resize the chart, unless the resize option exists and is false
  if ((typeof resizeOption === 'undefined') || (resizeOption !== 'false')) {

    // Get chart class to select chart on page
    const chartClass = $(chart.element).attr('class');

    // Set newmin value for y axis
    const yAxisMin = SetAxisMin(chart.data.shown(), chartClass);

    // Set max value for y axis
    const yAxisMax = SetAxisMax(chart.data.shown(), chartClass);

    // Set y axis tick values, using value range
    const yAxisTickValues = SetAxisTicks(yAxisMin, yAxisMax, '#' + $(chart.element).attr('id'));

    // Set y axis min and max
    chart.axis.min({y: yAxisMin});
    chart.axis.max({y: yAxisMax});

    // Set y axis ticks (needs to be after chart load)
    return setTimeout((function() {
      chart.internal.yAxisTickValues = yAxisTickValues;
      chart.internal.config.axis_y_tick_values = yAxisTickValues;
      return chart.flush();
    }), 200);
  }

};