import Spinner from 'src/javascripts/components/utilities/Spinner';
import ImpactDistByAllFormulasChart from 'src/javascripts/components/charts/ImpactDistByAllFormulasChart';
import ToggleReturnsChart from 'src/javascripts/components/charts/actions/ToggleReturnsChart';
import StickySidebar from 'src/javascripts/components/nav/StickySidebar';
import ImpactDistMetricScores from 'src/javascripts/components/charts/ImpactDistMetricScores';
import ImpactDistControversies from 'src/javascripts/components/charts/ImpactDistControversies';
import ImpactByFormulaTable from 'src/javascripts/components/tables/ImpactByFormulaTable';
import ExpandExcerpt from 'src/javascripts/components/utilities/ExpandExcerpt';
import ExpandContent from 'src/javascripts/components/utilities/ExpandContent';
import ReturnsChart from 'src/javascripts/components/charts/ReturnsChart';
import ReturnsDollarsChart from 'src/javascripts/components/charts/ReturnsDollarsChart';

export default class PublicCompaniesShow {

  constructor() {}

  render() {
    const accountUrl = $('body').attr('data-account-url');

    // Expand excerpted statements on click
    ExpandExcerpt();

    // Expand/minimize profile details
    ExpandContent();

    // On show/hide of disclosure or overall impact modal; only if in display mod mode
    if ($('body').hasClass('modal-open')) {
      $('.detail-modal').on('show.bs.modal', function(e) {
        $('#companyProfileShow').css('opacity', 0.2);
        $('.banner-container').css('opacity', 0.05);
      }).on('hide.bs.modal', function(e) {
        $('#companyProfileShow').css('opacity', 1.0);
        $('.banner-container').css('opacity', 1.0);
      }).on('hidden.bs.modal', function(e) {
        $('body').addClass('modal-open');
      });
    }

    // On click on a screen
    $('.impact-screens-list tr').click(function() {
      let url = $(this).attr('data-url');
      if (typeof url !== 'undefined') {
        return $.ajax({
          type: "GET",
          dataType: "script",
          url: url + '.js'
        });
      }
    })

    // Metric scores distribution and table, only once click on data tab for first time
    $('a[href="#scorecardTab"]').on('shown.bs.tab', function (e) {

      // Render impact chart, if chart does not already have c3-data (already initialized)
      // Needed to prevent re-init when navigating away from page, before new body has loaded    
      if (typeof $('#impact_dist_by_all_formulas').data('c3-chart') === 'undefined') {
        let chart = new ImpactDistByAllFormulasChart( $('#impact_dist_by_all_formulas') );
        $.when( chart.getData() )
         .then( data => chart.render(data) );
      } else {
        let chart = $('#impact_dist_by_all_formulas').data('c3-chart');
        chart.flush();
      }

      // Impact by formula table
      if ($("#impact_by_formula_wrapper").length === 0) {
        let tableId = 'impact_by_formula';
        let table = new ImpactByFormulaTable($('#' + tableId));
        $.when( table.render(tableId) ).then( status => table.setUp(tableId) );
      }

    });

    // Financial tab
    $('a[href="#financialTab"]').on('shown.bs.tab', function (e) {

      // Initialize a new chart instance for each chart on the page
      if (($('#returns_chart').attr('data-return-option') === 'percentage') && (typeof $('#returns_chart').data('c3-chart') === 'undefined')) {

        // Set chartId and chart object
        // Get data, render chart, set up chart, and create connected table, if exists
        let chart = new ReturnsChart($('#returns_chart'));
        $.when(chart.getData())
         .then( data => chart.render(data))
         .then( chartObj => chart.setUp(chartObj));

      } else if (($('#returns_chart').attr('data-return-option') === 'dollar') && (typeof $('#returns_chart').data('c3-chart') === 'undefined')) {

        // Set chartId and chart object
        // Get data, render chart, set up chart, and create connected table, if exists
        let chart = new ReturnsDollarsChart($('#returns_chart'));
        $.when(chart.getData())
         .then( data => chart.render(data))
         .then( chartObj => chart.setUp(chartObj));

      }

      // Toggle returns chart between hypothetical growth and percentage returns
      ToggleReturnsChart();

    });


    // Google map
    $('a[href="#aboutTab"]').on('show.bs.tab', function (e) {

      if ($('.business-address-map').length !== 0) {

        // Set id
        let mapId = $('.business-address-map').attr('id');
        
        // Add spinner to indicate loading map
        Spinner('#' + mapId);

        // Set Geocoder
        let geocoder = new google.maps.Geocoder();

        // Set address
        let address = $('#' + mapId).attr('data-address');

        // Geocode
        geocoder.geocode({ address: address }, function(results, status) {
          if (status === "OK") {

            // Create map
            let map = new google.maps.Map(document.getElementById(mapId), {
              zoom: 8,
              center: results[0].geometry.location,
              mapTypeControlOptions: { mapTypeIds: [] },
              streetViewControl: false,
            });

            // Create market
            var marker = new google.maps.Marker({
              map: map,
              position: results[0].geometry.location
            });

            // Remove spinner
            $('#' + mapId).find('.spinner-container').remove();

          } else {
            $('#' + mapId).empty().append("We weren't able to find the address for this company.");
          }

        });

      }
    })

    // Show active tab
    let activeTab = $('#profileNavTabs').attr('data-active-tab');
    $('a[href="#' + activeTab + '"]').tab('show');


  }

}