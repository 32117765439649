import {noResultsTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default function() {

 	// Get households for account
	let accountUrl = $('body').attr('data-account-url');

  let emptyHousehold = function() {
    return '<div class="empty-message">Search for a household or enter new (min 3 letters)...</div>';      
  }

  let newHouseholdTemplate = function(name) { 
    let text = '<div class=\'d-flex align-items-center\'>';
    text += '<div class=\'mr-2\'>Click to create a new household named \'' + name + '\'</div>';
    text += '</div>';
    return text;
  }

  // Init households bloodhound
  const householdsBloodhound = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: '/' + accountUrl + '/households/search?query=%QUERY',
      wildcard: "%QUERY"
    }
  });
  householdsBloodhound.initialize();

  // Initiate apply models typeahead
  $('.households-typeahead').typeahead({
    hint: true,
    highlight: true,
    minLength: 0,
  }, {
    name: 'households',
    display: 'name',
    limit: 54,
    remote: {cache: false},
    source: householdsBloodhound.ttAdapter(),
    templates: {
      empty: emptyHousehold,
      suggestion(el) {
        if (el.is_new_household === "true") {
          return newHouseholdTemplate(el.name);
        } else if (el.name === null) {
          return noResultsTemplate();
        } else {
          return genericDisplayTemplate(el.name);
        }
      }
    },
  });

  // Select a household
  $('.households-typeahead').bind('typeahead:beforeselect', function(ev, suggestion) {

    if (suggestion.is_new_household == "true") {

      // Add a household
      let val = $('.households-typeahead.tt-input').val();
      if (val.length < 3) {
        ToastCustom('Name too short', 'Household name must be at least 3 characters', 5000);
        $('.households-typeahead').focus();
      } else {
        $('.modal-body').css('opacity', 0.2);
        Spinner($('.modal-content'));
        let url = '/' + accountUrl + '/households?';
        let params = {};
        params['client_household'] = {};
        params['client_household']['name'] = val;
        $.ajax({
          type: 'POST',
          dataType: 'application/json',
          url: url + $.param(params),
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            $('.modal-content').find('.spinner-container').remove();
            $('.modal-body').css('opacity', 1);
            if (responseText.status === 400) {
              ToastCustom('There was a problem', responseText["error"], 5000);
            } else {
              let hId = responseText.household_id;
              $('#hidden_household_id').val(hId);
              ToastCustom('Household created', 'Household created. Click save to associate the client with the selected household', 5000);
            }
          },
        });
      }

    } else {

      // Update hidden field
      $('#hidden_household_id').val(suggestion.value);

    }

  });



}


 