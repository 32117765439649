import MethodologiesCausesForm from 'src/javascripts/components/forms/MethodologiesCausesForm';

export default class SettingsAccountsMethodologiesCausesNew {

  constructor() {}

  render() {

  	MethodologiesCausesForm();

  }

}