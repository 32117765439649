import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import AddRowDataAttrs from 'src/javascripts/components/tables/helpers/AddRowDataAttrs';
import AddRowHoverButtons from 'src/javascripts/components/tables/helpers/AddRowHoverButtons';
import AddRowClickLinks from 'src/javascripts/components/tables/actions/AddRowClickLinks';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';
import CheckPaging from 'src/javascripts/components/tables/helpers/CheckPaging';
import Swal from 'sweetalert2';

export default class ResearchTable extends Table {

  render(tableId) {

    // Build url, use to get filtered data with server-side processing
    let dataUrl = $('#' + tableId).attr('data-api-url');
    let date = $('#researchResultsCard').attr('data-date');
    let contCatId = $('#researchResultsCard').attr('data-controversy-category-id');
    if (typeof date !== 'undefined') { dataUrl = dataUrl + "&date=" + date; }
    if (typeof contCatId !== 'undefined') { dataUrl = dataUrl + "&controversy_category_id=" + contCatId; }

    // Set additional data vars (used below in table creation)
    let cols = GetCols($('#' + tableId));

    // Set search var
    let domVar; 
    let pagingType;
    if ($('#portfolio_model_items_table').length > 0) {
      domVar = 'lirtp';
      pagingType = 'simple';
    } else {
      domVar = 'tlp';
      pagingType = 'simple_numbers';
    }

    // Set column to sort by 
    let orderCol = $('#' + tableId).attr('data-order-col');
    if (typeof orderCol === 'undefined') {orderCol = cols.length - 2;}

    // Set order dir
    let orderDir = $('#' + tableId).attr('data-order-dir');
    if (typeof orderDir === 'undefined') {orderDir = 'desc';}

    // Set scroll Y option
    let scrollY = $('#' + tableId).attr('data-scroll-y');
    if (typeof scrollY === 'undefined') {scrollY = false};

    // Set height if in full-window mode
    let $card = this.el.closest('.card');
    let $cardBody = this.el.closest('.card-body');
    let $height = $card.attr('data-scroll-height');
    if (typeof $height === 'undefined') {
      if ($card.hasClass('full-view')) {
        $height = $card.height() - 180;
      } else {
        $height = $cardBody.height() - 20;
      }
    }

    // Render table
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      retrieve: true,
      serverSide: true,
      ajax: dataUrl,
      paging: true,
      scrollY: $height,
      scrollX: false,
      scrollCollapse: true,
      fixedColumns: {leftColumns: 1, rightColumns: 1},
      pageLength: 50,
      pagingType: pagingType,
      lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
      lengthChange: true,
      // info: true,
      searching: true,
      searchDelay: 600,
      ordering: true,
      order: [[orderCol, orderDir]],
      columns: cols,
      dom: domVar,
      responsive: { details: false },
      language: { 
        search: "_INPUT_",
        searchPlaceholder: "Search results...",
        lengthMenu: "Show _MENU_",
        info: "of _TOTAL_ results",
        infoEmpty: "",
        emptyTable: "No results found. Please contact us with any questions.",
        zeroRecords: "No results found. Please contact us with any questions."
      },

      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        AddRowDataAttrs(row, data);

        // Add hover button links to rows, if passed in data
        // if (typeof data.hover_links !== 'undefined') { AddRowHoverButtons(row, data.hover_links); }
      },

      drawCallback: function( settings ) {

        // Remove spinner after table has drawn
        $('#' + tableId).closest('.card').find('.spinner-container').remove();
        $('#researchResultsCard .card-body').css('opacity', 1);

        // Set total count
        if ( (('.total-research-count').length > 0) && (typeof settings.json.recordsTotalPrecision !== 'undefined') ) {
          let totalRecords = settings.json.recordsTotalPrecision;
          $('.total-research-count').empty().append("(" + totalRecords + ")");
        }

        // Listen for length change
        $('#' + tableId).on('length.dt', function (e, settings, len) {
          Spinner($('#' + tableId));
          if (len === 10) {
            $('#researchResultsCard').removeClass('h-100');
          } else {
            $('#researchResultsCard').addClass('h-100');
          }
        });

        // Add a model item (with filter) on button select, if present    
        if ( $('.add-model-item').length > 0 ) {

          $('.add-model-item').click(function(ev) {

            // Prevent default
            ev.preventDefault();

            // Return if has class disabled
            if ($(this).hasClass('disabled')) {return;}

            // Hide tooltip
            $(this).tooltip('hide');

            // Hide modals
            $('.modal').modal('hide');

            // Temporarily disable typeahead and add filter buttons
            $('.model-interaction').addClass('disabled');

            // Add spinner and opacity to table
            Spinner($('#portfolioAssetsCard'));
            $('#portfolio_model_items_body').css('opacity', 0.25);

            // Build ajax call to add/remove selection from model filters
            let accountUrl = $('#portfolioAssetsCard').attr('data-account-url');
            let portfolioId = $('#portfolioAssetsCard').attr('data-portfolio-id');
            let modelId = $('#portfolioAssetsCard').attr('data-model-id');
            let params = {};
            params['model_id'] = modelId;
            params['item_type'] = $(this).attr('data-item-type');
            params['item_ids'] = $(this).attr('data-item-id');
            if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId;}
            let url = '/' + accountUrl + '/portfolios/models/' + modelId + '/items?' + $.param(params);

            // Create a filter for model
            $.ajax({
              type: 'POST',
              url: url,
              dataType: 'script',
              complete(result) { 

                // Reload table data
                $('#portfolio_model_items_table').DataTable().ajax.reload();

                // Re-enable typeaheads and add filters
                $('.model-interaction').removeClass('disabled');

                // Set unsaved changes to true
                $('.save-portfolio-model').attr('data-unsaved-changes', true);

              },
            });

          });

          // Init tooltips
          $('[data-toggle="tooltip"]').tooltip();

        }

        // Create/update a filter on button select, if present    
        if ( $('.add-model-items-all').length > 0 ) {

          $('.add-model-items-all').click(function(ev) {

            // Prevent default
            ev.preventDefault();

            // Return if has class disabled
            if ($(this).hasClass('disabled')) {return;}

            // Hide tooltip
            $(this).tooltip('hide');

            // Build ajax call to add/remove selection from model items
            let includedIds = [];
            let addModelItemOpts = $('#' + tableId).find('.btn.add-model-item');
            $.each(addModelItemOpts, function() {
              includedIds.push($(this).attr("data-item-id"));
            });
            let lenToAdd = includedIds.length;

            // Confirm and then continue with request
            return Swal.fire({
              title: "Confirm",
              text: "Confirm that you would like to add " + lenToAdd + " assets to your model",
              animation: false,
              focusConfirm: false,
              showCancelButton: true,
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light border',
                popup: 'animated fadeIn faster'
              }
            }).then((result) => {
              if (result.value) {

                // Temporarily disable typeahead and add filter buttons
                $('.model-interaction').addClass('disabled');

                // Add spinner and opacity to table
                Spinner($('#portfolioAssetsCard'));
                $('#portfolio_model_items_body').css('opacity', 0.25);

                // Build url and params
                let accountUrl = $('#portfolioAssetsCard').attr('data-account-url');
                let portfolioId = $('#portfolioAssetsCard').attr('data-portfolio-id');
                let modelId = $('#portfolioAssetsCard').attr('data-model-id');
                let params = {};
                params['model_id'] = modelId;
                params['item_type'] = $(this).attr('data-item-type');
                params['item_ids'] = includedIds;
                if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId;}
                let url = '/' + accountUrl + '/portfolios/models/' + modelId + '/items?' + $.param(params);

                // Create a filter for model
                $.ajax({
                  type: 'POST',
                  url: url,
                  dataType: 'script',
                  complete(result) { 

                    // Reload table data
                    $('#portfolio_model_items_table').DataTable().ajax.reload();

                    // Re-enable typeaheads and add filters
                    $('.model-interaction').removeClass('disabled');

                    // Set unsaved changes to true
                    $('.save-portfolio-model').attr('data-unsaved-changes', true);

                  },
                });

              }
            });

          });

          // Init tooltips
          $('[data-toggle="tooltip"]').tooltip();

        }

      }

    });

    return 'rendered';
  }

  setUp(tableId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Set searchable function for table
    $('#search_' + tableId).keyup(function(){
      oTable.search( $(this).val() ).draw();
    });

    // Ensure search input starts blank
    $('#search_' + tableId).val('');

    return 'setup';

  }

}
