import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import AddRowClickLinks from 'src/javascripts/components/tables/actions/AddRowClickLinks';

export default class {

  constructor(el) { this.el = el; }

  getData() {

    // Set table element id
    const tableId = this.el.attr('id');

    // Set a spinner to indicate chart loading
    Spinner(this.el);

    // Build url
    const url = BuildApiUrl(tableId);

    // Get data
    return $.getJSON(url);
  }


  render() {}
    // handled in sub-classes

  setUp(tableId, chartId = null) {

    // Set on click action for table rows
    AddRowClickLinks(tableId);

    return 'setup';

  }
};