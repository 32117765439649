import SignUpForm from 'src/javascripts/components/forms/SignUpForm';
import PasswordVisibility from 'src/javascripts/components/forms/PasswordVisibility';
import CustomValidators from 'src/javascripts/components/forms/CustomValidators';
import PublicSearchTypeahead from 'src/javascripts/components/typeaheads/PublicSearchTypeahead';

export default class Public {

  constructor() {}

  render() {

    // Multi-search causes, companies, funds
    // Will only be present if visitor (not user signed in)
    if ( $('#search_menu_expanded_typeahead') ) {
      let multi = new PublicSearchTypeahead('search_menu_expanded_typeahead');
      $.when( multi.render() );
    }

    // Show name in navbar on scroll
    if ($(".navbar-title").length !== 0) {
      $(window).scroll(function (event) {
        let scroll = $(window).scrollTop();
        if (scroll > 165) {
          $('.nav-sub .navbar-title').removeClass('opaque');
          $('.nav-sub #navbarSearchSm').addClass('scrolled');
        } else {
          $('.nav-sub .navbar-title').addClass('opaque');
          $('.nav-sub #navbarSearchSm').removeClass('scrolled');
        }
      });
    }

    // Ease in top image
    if ($('.bg-image-top').hasClass('opaque')) {
      $('.bg-image-top, .bg-image-prev').toggleClass('opaque');
    }

    // Ease in any feature image
    if ( ($('.feature-image-top').length !== 0) && ($('.feature-image-top').hasClass('opaque')) ) {
      $('.feature-image-top').toggleClass('opaque');
    }

    // Cancel modal view
    if ($('.cancel-modal').length !== 0) {
      $('.cancel-modal').click(function() {
        let path = $(this).attr('data-path');
        Turbolinks.visit(path);
      });
    }

    $(document).on("turbolinks:before-cache", function() { 

      // Hide dropdown before cache, otherwise reappears 
      // for a second when Turbolinks reloads the page
      $('.top-nav-dropdown').removeClass('show');

      // Remove critical path css -- it conflicts with main stylesheets
      $('#criticalPathStylesheet').remove();

      // Tear down slick
      $('.customer-logos').slick('unslick');

    });


    // Sign up form if on sign-up page
    if ($('#signup_form').length > 0) {
      SignUpForm();
      PasswordVisibility();
      CustomValidators();
    }


    $('.modal a').click(function(e) {
      e.preventDefault();
      let href = $(this).attr('href');
      let modal = $(this).closest('.modal');

      // Hide modals      
      modal.modal('hide');

      // Visit link when hidden
      modal.on('hidden.bs.modal', function (e) {
        Turbolinks.visit(href);
      });

    });

    $('.customer-logos').slick({
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
      dots: false,
      pauseOnHover: false,
      responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 4
        }
      }, {
        breakpoint: 520,
        settings: {
          slidesToShow: 3
        }
      }]
    });
    $('.customer-logos').removeClass('d-none');

  }

}