export default class AccountsImpactCalculatorsShow {

  constructor() {}

  render() {

      // Include/exclude sections
    $('.include-exclude-section').change(function() {
      let val = $(this).val();
      let section = $(this).closest('.tab-pane').find('.include-options');
      if (val === 'false') {
        section.addClass('excluded');
      } else {
        section.removeClass('excluded');
      }
    });

    // Draggable sections
    $('#calculatorSectionsSortable').sortable({
      placeholder: "available-section-space",
      update: function(event, ui) {
        let sortOrder = $(this).sortable('toArray');
        $.each(sortOrder, function(ind, sectionId) {
          $('#' + sectionId).find('.hidden-sort-order').val(ind + 1);
        });
      }
    });

    // Draggable sections
    $('.calc-tiles-sortable').sortable({
      placeholder: "available-section-space",
      update: function(event, ui) {
        let sortOrder = $(this).sortable('toArray');
        $.each(sortOrder, function(ind, sectionId) {
          $('#' + sectionId).find('.hidden-sort-order').val(ind + 1);
        });
      }
    });

    // Include options
    let includeOption = $('.include-options');
    if (includeOption.length > 0) {
      $('.include-options .card').click(function() {
        let ic = $(this).closest('.include-options');
        if (!ic.hasClass('excluded')) {
          let col = $(this).closest('.include-opt-col');
          let includedOpt = col.find('.include-option');
          if (includedOpt.val() === 'true') {
            includedOpt.val('false');
            $(this).addClass('excluded');
            col.find('.included-indicator').removeClass('d-flex').addClass('d-none');
            col.find('.excluded-indicator').removeClass('d-none').addClass('d-flex');
          } else {
            includedOpt.val('true');
            $(this).removeClass('excluded');
            col.find('.excluded-indicator').removeClass('d-flex').addClass('d-none');
            col.find('.included-indicator').addClass('d-flex').removeClass('d-none');
          }
        }
      });
    }

    // Toggle display options
    $('.toggle-display-options').click(function() {
      $('.display-options').toggleClass('minimized');
      $('.toggle-display-options i, .toggle-display-options svg').toggleClass('fa-chevron-left').toggleClass('fa-chevron-right');
    });

  }

}