// Set default tooltip for charts
import FormatString from 'src/javascripts/components/utilities/FormatString';
import * as moment from 'moment';

export default function(chart, tooltip, d, defaultTitleFormat, defaultValueFormat, changeFormat, decimals, color) {

  // Order by value (score)
  let text;  
  let i = 0;

  // Create tooltip item for every value on the date
  while (i < d.length) {
    var value;
    
    // Skip to next if value not present
    if (typeof d[i].value === 'undefined') {i++; continue;}

    // Create initial text
    if (!text) {
      const title = moment(d[i].x).calendar(null, {
        sameDay: '[Today]',
        lastDay: '[Yesterday]',
        lastWeek: 'MM/DD/YYYY',
        sameElse: 'MM/DD/YYYY'
      });
      text = '<table class=\'' + tooltip.CLASS.tooltip + '\'>' + (title || (title === 0) ? '<tr><th colspan=\'2\' class=\'bg-primary-light\'>' + title + '</th></tr>' : '');
    }

    // Set name and value
    const name = (d[i].name.substr(0, 1) + d[i].name.substr(1)).replace(/_/g,' ');
    value = defaultValueFormat(d[i].value.toFixed(decimals), d[i].ratio, d[i].id, d[i].index);

    // Set change value and format
    const scopedData = chart.data(d[i].id);
    const { values } = scopedData[0];
    const start = values[0].value;
    const end = values[d[i].index].value;
    const change = end - start;
    const changeString = FormatString(change, changeFormat, decimals, true, true, true);

    // If end and change are 0, set as n/a
    if ((end === 0) && (change === 0)) {
      value = 'n/a';
      changeString = '';      
    }

    // Add text, including change string unless this is for returns
    const bgcolor = tooltip.levelColor ? tooltip.levelColor(d[i].value) : color(d[i].id);
    text += '<tr class=\'' + tooltip.CLASS.tooltipName + '-' + d[i].id + '\'>';
    text += '<td class=\'name\'><span style=\'background-color:' + bgcolor + '\'></span>' + name + '</td>';
    text += '<td class=\'value\'>' + value;
    text += ' ' + changeString;
    text += '</td>';
    text += '</tr>';
    i++;
  }
  return text + '</table>';

};

