// Change loading state of a button 
// Need to allow loading = true and false for ajax calls

export default function(el, isLoading) {

  if (isLoading) {

    el.find('.text').addClass('d-none');
    el.find('.spinner').removeClass('d-none').addClass('button-loading');
    el.addClass('disabled');

  } else {

    el.find('.spinner').addClass('d-none').removeClass('button-loading');
    el.find('.text').removeClass('d-none');
    el.removeClass('disabled');

  }

};