import Spinner from 'src/javascripts/components/utilities/Spinner';
import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {genericDisplayTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');


export default class QuestionnairesCollectionResponsesDashboard {

  constructor() {}

  render() {

	  // Set account id
	  const accountUrl = $('body').attr('data-account-url');

	  // Questionnaires and portfolio items search
    filterTypeahead('#users_search', 'users', '/' + accountUrl + '/users/search?', 282, 'generic');

    // Auto-focus on expanding dropdown
    $('.dropdown-toggle').on('click', function() {
      let tp = $(this).closest('.btn-group').find('.typeahead');
      setTimeout(function() { tp.focus() }, 300)
    });

    // Reload with attrs
    let reloadWithSelectedParams = function() {
	    let url = '/' + accountUrl + '/data-collection/dashboard';
      let params = {};
      params['status'] = $('#questionnaire_responses_table').attr('data-status');
      let userId = $('#questionnaire_responses_table').attr('data-user-id');
      if (typeof userId === 'undefined') {
        params['questionnaire_user_id'] = 'all';
      } else {
        params['questionnaire_user_id'] = userId;
      }
      Turbolinks.visit(url + '?' + $.param(params));
    }

    // On selecting a user
    $('#users_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      let existingUserIds = $('#questionnaire_responses_table').attr('data-user-id');
      let uids = suggestion.value;
      if (typeof existingUserIds !== 'undefined') {uids = uids + ',' + existingUserIds;}
    	$('#questionnaire_responses_table').attr('data-user-id', uids);
    	reloadWithSelectedParams();
    })

  }

}