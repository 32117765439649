import ResearchFiltering from 'src/javascripts/components/ResearchFiltering';

export default class ResearchCompaniesIndex {

  constructor() {}

  render() {

    // Filtering functions
    ResearchFiltering();

  }

}