// Show impact for a specific company or security across all system impact formulas
import * as c3 from 'c3';
import Chart from 'src/javascripts/components/Chart';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import ImpactDistChartTooltip from 'src/javascripts/components/charts/tooltips/ImpactDistChartTooltip';
import NoDataMessage from 'src/javascripts/components/alerts/NoDataMessage';

export default class ImpactDistByAllFormulasChart extends Chart {

  getData() {

    // Set a spinner to indicate chart loading
    Spinner(this.el.closest('chart-container'));

    // Set record type from element (used to get data for correct controller/model)
    const recordType = this.el.attr('data-api-record-type');

    // Get data
    let url = this.el.attr('data-api-url');

    // Adjust letter grades if needed
    let params = {};
    let letterGrades = this.el.attr('data-letter-grades');
    if (typeof letterGrades !== 'undefined') {
      params['letter_grades'] = letterGrades;
    }
    
    // Add formula ids if material causes present
    let formulaIds = this.el.closest('.card').find('.show-material-causes').attr('data-material-formula-ids');
    if (typeof formulaIds !== 'undefined') {
      params['formula_ids'] = formulaIds;
    }

    // Get data
    return $.getJSON(url + '?' + $.param(params));

  }

  render(data) {

    // Set chartId
    const chartId = this.chartId;

    // Show no data message if none available
    // data[1] is the chart data; data[0] is dates array
    if ((data === null) || (data[1].length <= 1) || (typeof data[1] === 'undefined')) { 
      return NoDataMessage(this.el); 
    }

    // Render chart
    const chart = c3.generate({
      bindto: '#' + chartId,
      padding: {left: 0, bottom: -30},
      data: {
        type: 'bar',
        json: data,
        labels: true,
        keys: {
          x: 'name',
          value: [ 'value' ]
        },
        onclick: function (d, element) { 

          // Focus/de-focus, depending on current status
          if ( $(element).hasClass('selected') ) {

            $(element).closest('.chart-container').find('.c3-bar').removeClass('lightest selected').addClass('opaque');
            let oTable = $('#' + chartId).closest('.card').find('.impact-by-formula').DataTable();
            oTable.search('').draw();

          } else {

            $(element).removeClass('lightest').addClass('opaque selected');
            $(element).closest('.chart-container').find('.c3-bar').not(element).removeClass('opaque selected').addClass('lightest');

            // On click of a bar, update table to show ratings for that letter (A, B, C, D or F)          
            let letterSearch;
            if (d.x === 0) {
              letterSearch = "A rating";
            } else if (d.x === 1) {
              letterSearch = "B rating";
            } else if (d.x === 2) {
              letterSearch = "C rating";
            } else if (d.x === 3) {
              letterSearch = "D rating";
            } else {
              letterSearch = "F rating";
            }

            // Search for the letter search (filtering handled in controller)
            let oTable = $('#' + chartId).closest('.card').find('.impact-by-formula').DataTable();
            oTable.search('"' + letterSearch + '"').draw();

          }

        }
      },
      axis: {
        x: {type: 'category'},
        y: {
          show: false,
          padding: {top: 20}
        }
      },
      bar: {width: {ratio: 0.8}},
      legend: {hide: true},
      tooltip: {
        format: {},
        contents(d, defaultTitleFormat, defaultValueFormat, color) {
          return ImpactDistChartTooltip(this, d, defaultTitleFormat, defaultValueFormat, color);
        }
      },

      // Add color to bars
      onrendered() {
        $('.impact-dist-by-all-formulas .c3-bar-0').addClass('bg-a-rating opaque');
        $('.impact-dist-by-all-formulas .c3-bar-1').addClass('bg-b-rating opaque');
        $('.impact-dist-by-all-formulas .c3-bar-2').addClass('bg-c-rating opaque');
        $('.impact-dist-by-all-formulas .c3-bar-3').addClass('bg-d-rating opaque');
        $('.impact-dist-by-all-formulas .c3-bar-4').addClass('bg-f-rating opaque');

        $('#' + chartId + ' .c3-axis-x g.tick text').off('click').on('click', function() {

          // Set index
          let text = $(this).text();

          // Set text
          if (text === '80-100') {
            text = 'A';
          } else if (text === '60-80') {
            text = 'B';
          } else if (text === '40-60') {
            text = 'C';
          } else if (text === '20-40') {
            text = 'D';
          } else if (text === '0-20') {
            text = 'F';
          }

          let c3bar = $('.c3-bar.bg-' + text.toLowerCase() + '-rating');

          // Focus/de-focus, depending on current status
          if ( c3bar.hasClass('selected') ) {

            c3bar.closest('.chart-container').find('.c3-bar').removeClass('lightest selected').addClass('opaque');
            let oTable = $('#' + chartId).closest('.card').find('.impact-by-formula').DataTable();
            oTable.search('').draw();

          } else {

            c3bar.removeClass('lightest').addClass('opaque selected');
            c3bar.closest('.chart-container').find('.c3-bar').not(c3bar).removeClass('opaque selected').addClass('lightest');

            // Search for the letter search (filtering handled in controller)
            let oTable = $('#' + chartId).closest('.card').find('.impact-by-formula').DataTable();
            oTable.search('"' + text + ' rating' + '"').draw();

          }

        });

        $('.impact-dist-by-all-formulas').removeClass('opaque');
        $('.impact-dist-by-all-formulas').closest('.chart-container').find('.spinner-container').remove();

      }

    });

    // Set chart data to allow for destroying chart before Turbolinks cache
    // https://stackoverflow.com/questions/25765596/how-to-get-object-reference-from-selector-for-c3-js-charts
    $('#' + chartId).data('c3-chart', chart);

    return chart;

  }
};
