import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import {noResultsTemplate, logoTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
import 'summernote/dist/summernote-bs4.min.js'

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class AssessmentsPreferences {

  constructor() {}

  render() {

    // Summernote text editor
    if ($('.summernote').length !== 0) {

      $('.summernote').summernote({
        minHeight: 150,
        dialogsInBody: true,
        toolbar: [
          ['font', ['bold', 'italic', 'underline', 'clear']],
          ['insert', ['link']],
        ]}
      );

    }

    // Boolean options show/hdie
    $('.assessment-opt-select').change(function(e) {
      let val = $(this).val();
      let optDetails = $(this).closest('.form-group').find('.form-details');
      if (val === 'false') {
        optDetails.addClass('d-none').removeClass('d-flex');        
      } else {
        optDetails.removeClass('d-none').addClass('d-flex');
      }
    });

    // Reset default text
    $('.reset-default-message').click(function(e) {
      e.preventDefault();
      let message = $(this).attr('data-html');
      let input = $(this).closest('.form-group').find('.summernote');
      input.summernote('code', '');
      input.summernote('pasteHTML', message);
    });

    // Sample ratings options
    $('.assessment-ratings-select').change(function(e) {
      let val = $(this).val();
      let optDetails = $(this).closest('.form-group').find('.form-details');
      let customDetails = $(this).closest('.form-group').find('.custom-details');
      if (val === 'none') {
        optDetails.addClass('d-none').removeClass('d-flex');
        customDetails.addClass('d-none').removeClass('d-flex');
      } else if (val === 'custom') {
        optDetails.addClass('d-none').removeClass('d-flex');
        customDetails.removeClass('d-none').addClass('d-flex');
      } else {
        optDetails.removeClass('d-none').addClass('d-flex');
        customDetails.addClass('d-none').removeClass('d-flex');
      }
    });

    // Contact info page
    $('#account_option_assessment_attributes_include_contact_info').change(function(e) {
      let val = $(this).val();
      let optDetails = $(this).closest('.app-section').find('.dependent-group');
      if (val === "false") {
        optDetails.addClass('d-none').removeClass('d-block');
      } else {
        optDetails.removeClass('d-none').addClass('d-block');
      }
    });


    // Next step options
    $('.next-step-select').change(function(e) {
      let val = $(this).val();
      let messageDetails = $(this).closest('.form-group').find('.form-details#nextStepMessageOpt');
      let linkDetails = $(this).closest('.form-group').find('.form-details#nextStepLinkOpt');
      if (val === 'link') {
        linkDetails.removeClass('d-none').addClass('d-flex');
        messageDetails.addClass('d-none').removeClass('d-flex');
      } else {
        messageDetails.removeClass('d-none').addClass('d-flex');
        linkDetails.addClass('d-none').removeClass('d-flex');
      }
    });

    // Lead assets table and functions to add/remove

    let table = new DefaultTable( $('#lead_assets_table') );
    $.when( table.render('lead_assets_table') ).then( status => table.setUp('lead_assets_table') );

    // Set companies and funds bloodhounds
    const companiesBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: '/companies/search?query=%QUERY',
        wildcard: "%QUERY",
        transform: function(d) { return d.companies; }
      }});
    companiesBloodhound.initialize();

    const fundsBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: '/funds/search?country=all&query=%QUERY',
        wildcard: "%QUERY"
      }});

    fundsBloodhound.initialize();

    $('#companies_search').typeahead({
      hint: false,
      highlight: true,
      minLength: 0,
    }, {
      name: 'companies',
      display: 'name',
      limit: 112,
      source: companiesBloodhound.ttAdapter(),
      templates: {
        suggestion(el) {
          if (el.name === null) {
            return noResultsTemplate();
          } else if ((el.logo_url !== null) && (el.logo_url.length > 0)) {
            return logoTemplate(el.name, el.symbol, el.logo_url);
          } else {
            return genericDisplayTemplate(el.name, el.symbol);
          }
        }
      },
    });

    $('#funds_search').typeahead({
      hint: false,
      highlight: true,
      minLength: 0,
    }, {
      name: 'funds',
      display: 'name',
      limit: 112,
      source: fundsBloodhound.ttAdapter(),
      templates: {
        suggestion(el) {
          if (el.name === null) {
            return noResultsTemplate();
          } else if ((el.logo_url !== null) && (el.logo_url.length > 0)) {
            return logoTemplate(el.name, el.symbol, el.logo_url);
          } else {
            return genericDisplayTemplate(el.name, el.symbol);
          }
        }
      },
    });

    // // Must set manually to "chrome-off", otherwise not getting set
    // // Must be "chrome-off": https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
    // $('.typeahead').attr('autocomplete', "chrome-off");

    $('.lead-assets-search').bind('typeahead:beforeselect', function(ev, suggestion) {
      let val = $(this).val();
      $(this).attr('data-search-term', val);
    })
    // On select of a typeahead suggestion
    $('.lead-assets-search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      let el = $(this);
      let accountUrl = el.attr('data-account-url');
      let params = {};
      if (suggestion.record_type === 'companies') {
        params['company_id'] = suggestion.value;
      } else {
        params['fund_id'] = suggestion.value;
      }
      params['asset_name'] = suggestion.name;
      params['asset_symbol'] = suggestion.symbol;
      params['asset_logo_url'] = suggestion.logo_url_short;
      $.ajax({
        type: "PATCH",
        dataType: "application/json",
        url: '/' + accountUrl + '/clients/add_lead_asset?' + $.param(params),
        complete(result) {
          $('#lead_assets_table').DataTable().ajax.reload();
          ToastCustom('Ethos', 'Added ' + suggestion.name);
        }
      });
      el.typeahead("val", "");

    });

  }

}