import Pickr from "@simonwep/pickr";
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import {noResultsTemplate, logoTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
import 'summernote/dist/summernote-bs4.min.js'

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class SettingsAccountsEdit {

  constructor() {}

  render() {

    // Allowed assets table and functions to add/remove
    if ($('#allowed_assets_table').length > 0) {

      let table = new DefaultTable( $('#allowed_assets_table') );
      $.when( table.render('allowed_assets_table') ).then( status => table.setUp('allowed_assets_table') );

	    // Set companies and funds bloodhounds
	    const companiesBloodhound = new Bloodhound({
	      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
	      queryTokenizer: Bloodhound.tokenizers.whitespace,
	      remote: {
	        url: '/companies/search?query=%QUERY',
	        wildcard: "%QUERY",
	        transform: function(d) { return d.companies; }
	      }});
	    companiesBloodhound.initialize();

	    const fundsBloodhound = new Bloodhound({
	      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
	      queryTokenizer: Bloodhound.tokenizers.whitespace,
	      remote: {
	        url: '/funds/search?country=all&query=%QUERY',
	        wildcard: "%QUERY"
	      }});

	    fundsBloodhound.initialize();

	    $('#companies_search').typeahead({
	      hint: false,
	      highlight: true,
	      minLength: 0,
	    }, {
	      name: 'companies',
	      display: 'name',
	      limit: 112,
	      source: companiesBloodhound.ttAdapter(),
	      templates: {
	        suggestion(el) {
	          if (el.name === null) {
	            return noResultsTemplate();
	          } else if ((el.logo_url !== null) && (el.logo_url.length > 0)) {
	            return logoTemplate(el.name, el.symbol, el.logo_url);
	          } else {
	            return genericDisplayTemplate(el.name, el.symbol);
	          }
	        }
	      },
	    });

	    $('#funds_search').typeahead({
	      hint: false,
	      highlight: true,
	      minLength: 0,
	    }, {
	      name: 'funds',
	      display: 'name',
	      limit: 112,
	      source: fundsBloodhound.ttAdapter(),
	      templates: {
	        suggestion(el) {
	          if (el.name === null) {
	            return noResultsTemplate();
	          } else if ((el.logo_url !== null) && (el.logo_url.length > 0)) {
	            return logoTemplate(el.name, el.symbol, el.logo_url);
	          } else {
	            return genericDisplayTemplate(el.name, el.symbol);
	          }
	        }
	      },
	    });

	    // // Must set manually to "chrome-off", otherwise not getting set
	    // // Must be "chrome-off": https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
	    // $('.typeahead').attr('autocomplete', "chrome-off");

	    $('.allowed-assets-search').bind('typeahead:beforeselect', function(ev, suggestion) {
	    	let val = $(this).val();
	    	$(this).attr('data-search-term', val);
	    })
	    // On select of a typeahead suggestion
	    $('.allowed-assets-search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
		    let el = $(this);
		    let accountUrl = el.attr('data-account-url');
		    let params = {};
		    if (suggestion.record_type === 'companies') {
		    	params['company_id'] = suggestion.value;
		    } else {
		    	params['fund_id'] = suggestion.value;
		    }
		    params['asset_name'] = suggestion.name;
		    params['asset_symbol'] = suggestion.symbol;
		    params['asset_logo_url'] = suggestion.logo_url_short;
		    $.ajax({
		      type: "PATCH",
		      dataType: "application/json",
		      url: '/' + accountUrl + '/add_allowed_asset?' + $.param(params),
			    complete(result) {
	          $('#allowed_assets_table').DataTable().ajax.reload();
	          ToastCustom('Ethos', 'Added ' + suggestion.name);
			    }
		    });
        el.typeahead("val", "");
	    });

    }

    // Change color preview on color input change
    const UpdateColorPrev = function(input) {
    	let color = input.val();
    	let prev = input.closest('.input-group').find('.input-group-append .input-group-text');
    	prev.css('background-color', color);
    }

    $('.color-group input').change(function() {
    	UpdateColorPrev($(this));
    });

    // Reset default colors on click
    $('.reset-default-colors').click(function() {
    	let color = $(this).attr('data-color');
    	let inverse = $(this).attr('data-inverse');
    	let g = $(this).closest('.color-group');
    	let cI = g.find('.color-input');
    	let iI = g.find('.inverse-input');
    	cI.val(color);
    	UpdateColorPrev(cI);
    	iI.val(inverse);
    	UpdateColorPrev(iI);
    });

    // Color pickr
    if ( $('.primary-picker').length > 0 ) {
      const createPicker = function(el) {
        let def = $(el).attr('data-default');
        let cType = $(el).attr('data-type');
        let pickr = Pickr.create({
          el: el,
          theme: 'classic', // or 'monolith', or 'nano'
          default: def,
          useAsButton: true,
          components: {

            // Main components
            preview: true,
            opacity: false,
            hue: true,

            // Input / output Options
            interaction: {
              hex: true,
              rgba: true,
              cmyk: true,
              input: true,
              save: true
            }
          }
        });

        // On save
        pickr.on('save', (color, instance) => {
          let hex = color.toHEXA().toString();
          if (hex.length < 7) {
            let len = hex.length;
            let i = 1;
            while (len < 7) {
              hex = hex + hex.charAt(i);
              i += 1;
              len += 1;
            }
          }        
          let cClass = '.' + cType + '-color-input';
          $(cClass).val(hex);
          $(el).css('background-color', hex);
          instance.hide();
        });

        // Reset to default
        let reset = $('.reset-' + cType + '-colors');
        reset.click(function() {
          let color = $(this).attr('data-color');
          let g = $(this).closest('.color-group');
          let cI = g.find('input');
          cI.val(color);
          pickr.setColor(color, false);
        });

        // Update on direct input
        let colInput = $(el).closest('.input-group').find('.color-input');
        colInput.on('blur', function() {
          let col = $(this).val();
          $(el).closest('.input-group').find('.picker').css('background-color', col);
          pickr.setColor(col, false);
        });

      }

      // Create pickers
      createPicker('.primary-picker');
      createPicker('.primary-inverse-picker');
      createPicker('.secondary-picker');
      createPicker('.secondary-inverse-picker');

    }

    // Summernote text editor (currently only used in admin)
    if ($('.summernote').length !== 0) {
      $('.summernote').summernote({
        minHeight: 300,
        toolbar: [
          ['font', ['bold', 'italic', 'underline', 'clear']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['insert', ['link']],
        ]}
      );
    }


  }

}