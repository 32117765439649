import PortfoliosForm from 'src/javascripts/components/forms/PortfoliosForm';

export default class PortfoliosNew {

  constructor() {}

  render() {

    // Portfolios form
    PortfoliosForm();

  }

}