// Toggle focus for typeahead on click of search button
// Used for small (not full-width) search inputs
export default function(el) {

  const typeahead = el.closest('form').find('.typeahead');
  const focused = typeahead.attr("data-focus");

  if ((focused === "false") || (typeof focused === 'undefined')) {
    typeahead.focus();
    return typeahead.attr("data-focus", "true");
  } else {
    typeahead.blur();
    return typeahead.attr("data-focus", "false");
  }

}