import {noResultsTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
import 'summernote/dist/summernote-bs4.min.js'

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default function() {

  const accountUrl = $('body').attr('data-account-url');
  let url = '/' + accountUrl + '/questionnaires/texts/search';
  
  let emptyText = function() {
    return '<div class="empty-message">Unable to find that. You can create new text below...</div>';      
  }

  const bloodhound = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: url + '?query=%QUERY',
      wildcard: "%QUERY",
      cache: false
    }});

  bloodhound.initialize();

  // Initiate apply models typeahead
  $('#texts_search').typeahead({
    hint: true,
    highlight: true,
    minLength: 0,
  }, {
    name: 'texts',
    display: 'name',
    limit: 54,
    remote: {cache: false},
    source: bloodhound.ttAdapter(),
    templates: {
      empty: emptyText,
      suggestion(el) {
        if (el.name === null) {
          return noResultsTemplate();
        } else {
          return genericDisplayTemplate(el.name);
        }
      }
    },
  });

  // Select a text
  $('#texts_search').bind('typeahead:beforeselect', function(ev, suggestion) {

    // Update hidden field
    $('#text_id').val(suggestion.value);      

    // Submit if 'new' action
    if ($('#add_included_text').length > 0) {
      Spinner($('.modal'));
      $('.modal-body').css('opacity', 0.25);
      $('#add_included_text').click();
    }

  });

  // On clear typeahead
  $('.clear-typeahead').click(function() {
    $('#text_id').val("");
    $('#texts_search').typeahead("val", "");
    $('#texts_search').focus();
  })

  // Summernote text editor
  if ($('.summernote').length !== 0) {

    // Send file to AWS
    // https://stackoverflow.com/questions/11240127/uploading-image-to-amazon-s3-with-html-javascript-jquery-with-ajax-request-n
    let sendFile = function(file) {
      let formData      = new FormData();
      let fileInput     = $('.summernote');
      let env           = fileInput.data("env");
      let accountUrl       = fileInput.data("account-url");
      formData.append("key", fileInput.data('form-data')["key"]);
      formData.append("success_action_status", fileInput.data('form-data')["success_action_status"]);
      formData.append("acl", "private");
      formData.append("policy", fileInput.data('form-data')["policy"]);
      formData.append("x-amz-algorithm", fileInput.data('form-data')["x-amz-algorithm"]);
      formData.append("x-amz-credential", fileInput.data('form-data')["x-amz-credential"]);
      formData.append("x-amz-date", fileInput.data('form-data')["x-amz-date"]);
      formData.append("x-amz-signature", fileInput.data('form-data')["x-amz-signature"]);
      formData.append("file", file);
      formData.append('Content-Type', file.type);
      var xhr = new XMLHttpRequest();
      xhr.open('POST', fileInput.data('url'), true); //MUST BE LAST LINE BEFORE YOU SEND 
      xhr.send(formData);
      return file.name;
    };

    $('.summernote').summernote({
      minHeight: 150,
      dialogsInBody: true,
      toolbar: [
        ['font', ['bold', 'italic', 'underline', 'clear']],
        ['para', ['ul']],
        ['insert', ['link', 'picture']],
      ],
      callbacks: {      
        onImageUpload: function(files, editor, welEditable) {
          $.when( sendFile(files[0]) ).then(function(name) {
            let key = $('.summernote').data('form-data')["key"];
            name = name.replace(/ /g, '+');
            key = key.replace("${filename}", name);
            let cloudFront = $('.summernote').data("cloudfront-url");
            let url = "https://" + cloudFront + '/' + key;
            let div = "<div class=\'mb-3 border-dark shadow\' style=\'width: 600px;\'>"; 
            div += "<a href=\'" + url + "\' target=\'_blank\'>";
            div += "<img width=600 filename=\'" + name + "\' src=\'" + url + "\'></img>";
            div += "</a></div>";
            return $('.summernote').summernote('pasteHTML', div);
          });
        }
      }
    });

  }



}
