export default function(range, floorMin) {
  let adjustedMin;

  // Adjust axis min to make sure it's less than value min
  // Also adjust depending on range from floorMin to ceilMax -- want to show some padding 
  // between bottom value on chart and axis min, scaled depending on value of min data
  if (range <= 10) {
    adjustedMin = (Math.round(floorMin/1)*1) - 1;

  } else if (range < 50) {
    adjustedMin = (Math.round(floorMin/1)*1) - 5;

  } else if (range < 100) {
    adjustedMin = (Math.round(floorMin/10)*10) - 10;

  } else if ((range >= 100) && (range < 1000)) {
    adjustedMin = (Math.round(floorMin/100)*100) - 50;

  } else if ((range >= 1000) && (range < 10000)) {
    adjustedMin = (Math.round(floorMin/1000)*1000) - 500;

  } else if ((range >= 10000) && (range < 100000)) {
    adjustedMin = (Math.round(floorMin/1000)*1000) - 1000;

  } else {
    adjustedMin = (Math.round(floorMin/10000)*10000) - 10000;
  }

  return adjustedMin;

}