export default function() {

  $('.minimize-sidebar').click(function() {
  	let userId = $(this).attr('data-user-id');
    let params = {};
    params['user'] = {}
  	params['user']['sidebar_minimized'] = 'true'
    $('.sidebar-special-button').addClass('d-none');
    $('.sidebar-app').addClass('minimized');
    $('.sidebar-offset').addClass('minimized');
    $('.expand-sidebar').removeClass('opaque');

    $.ajax({
      type: 'PATCH',
      url: '/users/' + userId + '?' + $.param(params),
      dataType: 'application/json',
    });

    // Re-draw and datatables
    $('.dataTable').DataTable().draw();

  });

  $('.expand-sidebar').click(function() {
    let userId = $(this).attr('data-user-id');
  	let params = {};
    params['user'] = {}
    params['user']['sidebar_minimized'] = 'false'
    $('.sidebar-special-button').removeClass('d-none');
    $('.sidebar-app').removeClass('minimized');
    $('.sidebar-offset').removeClass('minimized');
    $('.expand-sidebar').addClass('opaque');

    $.ajax({
      type: 'PATCH',
      url: '/users/' + userId + '?' + $.param(params),
      dataType: 'application/json',
    });

    // Re-draw and datatables
    $('.dataTable').DataTable().draw();

  });


}