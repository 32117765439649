// Initiate tooltips as long as not on touch device
import 'bootstrap/js/dist/tooltip.js';

export default function TooltipInit() {

  let isTouchDevice = function() {
    return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
  }

  if (isTouchDevice() === false) {

    $('[data-toggle="tooltip"]').tooltip({
      delay: {
        show: 100,
        hide: 100
      },
      template:
        '<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner small"></div></div>',
      // Avoid persistent tooltips on button click; define trigger as hover only
      trigger: 'hover'
    });

  }

}