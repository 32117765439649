import Spinner from 'src/javascripts/components/utilities/Spinner';
import Swal from 'sweetalert2';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {genericDisplayTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import 'summernote/dist/summernote-bs4.min.js'
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');


export default class ClientsEmailsNew {

  constructor() {}

  render() {

    const accountUrl = $('body').attr('data-account-url');
    const questionnaireId = $('#etemp').attr('data-questionnaire-id');

    $('#sendEmailInvite').click(function() {
      return Swal.fire({
        title: "Please confirm",
        text: "Just checking: ready to send this email now?",
        animation: false,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-light border',
          popup: 'animated fadeIn faster'
        }
      }).then((result) => {
        if (result.value) {
          $.ajaxSetup({
            headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')}
          });          
          $('#sendEmailInviteForm').submit();
        }
      });      
    })


    // Questionnaires and portfolio items search
    filterTypeahead('#questionnaires_search', 'questionnaires', '/' + accountUrl + '/questionnaires/search?', 82, 'generic');

    // On selecting a questionnaire
    $('#questionnaires_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      let qId = suggestion.value;
      $('#questionnaire_email_questionnaire_id').val(qId);
    })

    // On selecting a fund as benchmark
    $('.clear-typeahead').click(function() {
      let t = $(this).closest('.form-group').find('.typeahead');
      t.typeahead('val', '');
      t.focus();
    })

  }

}