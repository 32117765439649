// Change chart date for historical chart (and update data shown)
import SetChartTotal from     'src/javascripts/components/charts/actions/SetChartTotal';
import SetAxisMin from        'src/javascripts/components/charts/axes/SetAxisMin';
import ResetChartAxis from    'src/javascripts/components/charts/axes/ResetChartAxis';
import Spinner from           'src/javascripts/components/utilities/Spinner';

export default function(chart, fromDate) {

  // Set spinner
  Spinner($(chart.element));

  // Set from date based on selection
  $('.date-selection-group').attr('data-from-date', fromDate);

  // Set url based on chart data attrs
  const url = $(chart.element).attr('data-api-url') + '?from_date=' + fromDate;

  // Get data
  return $.getJSON(url, function(data) {

    // Show no data message if data not available
    if ((data === null) || (typeof data[1] === 'undefined') || (data[1].length <= 1)) { 

      /// Remove spinner
      return $(chart.element).find('.spinner-container').remove()

    }

    // Change active date selection button
    $('.update-chart-date.active').removeClass('active');
    $('.update-chart-date[data-from-date="' + fromDate + '"]').addClass('active');

    // Reload chart data
    return $.when( chart.load({ columns: data }) )
      .then( SetChartTotal(chart, chart.data.shown()[0].id) )
      .then( $(chart.element).find('.spinner-container').remove() );

  })

}