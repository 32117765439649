import MetricComparisonChart from 'src/javascripts/components/charts/MetricComparisonChart';

export default class ResearchCompaniesRealWorldMetricsShow {

  constructor() {}

  render() {

    // Render chart if present
    if ( ($('#metric_comparison_chart').length !== 0) && (typeof $('#metric_comparison_chart').data('c3-chart') === 'undefined') ) {
      const chart = new MetricComparisonChart( $('#metric_comparison_chart') );
      $.when( chart.render() );
    }

  }

}