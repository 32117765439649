// Get range of values from data for Y axis calc -- multiple arrays of data
// Used to set min/max values on y-axis
// Used with initial chart generation, when have multiple data arrays
import GetValueRangeSingleArr from 'src/javascripts/components/charts/helpers/GetValueRangeSingleArr';

export default function(data) {
  const numbers = [];
  let i = 0;

  while (i < data.length) {
    const singleArr = GetValueRangeSingleArr([data[i]]);
    $.merge(numbers, singleArr);
    i++;
  }

  return numbers;

};