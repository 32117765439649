import Spinner from 'src/javascripts/components/utilities/Spinner';
import ListCarousels from 'src/javascripts/components/lists/ListCarousels';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import QuestionnaireModulesTypeahead from 'src/javascripts/components/typeaheads/QuestionnaireModulesTypeahead';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class QuestionnairesIncludedModulesEdit {

  constructor() {}

  render() {

    // List carousels
    ListCarousels();

    // Draggable sections https://api.jqueryui.com/sortable
    $('.module-sortable').sortable({
      placeholder: "available-section-space",
      cursor: "move",
      handle: ".grip",
      items: "li.section-row:not(.unsortable)",
      update: function(event, ui) {
        let sortOrder = $(this).sortable('toArray');
				let accountUrl = $('body').attr('data-account-url');
				let questionnaireId = $('.module-sortable').attr('data-questionnaire-id');
        let includedModuleId = $('.module-sortable').attr('data-included-module-id');
        let url = '/' + accountUrl + '/questionnaires/' + questionnaireId + '/included/modules/' + includedModuleId + '.json?';
        let params = {};
	      params['questionnaire_included_module'] = {};
	      params['questionnaire_included_module']['included_sections_attributes'] = {};
        $.each(sortOrder, function(ind, sectionId) {
          let id = $('#' + sectionId).find('.hidden-included-section-id').val();
	        params['questionnaire_included_module']['included_sections_attributes'][ind] = {};
	        params['questionnaire_included_module']['included_sections_attributes'][ind]['id'] = id;
	        params['questionnaire_included_module']['included_sections_attributes'][ind]['sort_order'] = ind + 1;
        });
        $.ajax({
          type: 'PATCH',
          dataType: 'application/json',
          url: url + $.param(params),
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
          },
        });
      }
    });

    // Draggable sections https://api.jqueryui.com/sortable
    $('.questions-sortable').sortable({
      placeholder: "available-question-space",
      cursor: "move",
      handle: ".question-grip",
      items: "li.question-row:not(.unsortable)",
      update: function(event, ui) {
        let sortOrder = $(this).sortable('toArray');
        let accountUrl = $('body').attr('data-account-url');
        let questionnaireId = $('.module-sortable').attr('data-questionnaire-id');
        let includedSectionId = $(this).attr('data-included-section-id');
        let url = '/' + accountUrl + '/questionnaires/' + questionnaireId + '/included/sections/' + includedSectionId + '.json?';
        let params = {};
        params['questionnaire_included_section'] = {};
        params['questionnaire_included_section']['included_questions_attributes'] = {};
        params['questionnaire_included_section']['included_texts_attributes'] = {};
        params['questionnaire_included_section']['tables_attributes'] = {};
        $.each(sortOrder, function(ind, sectionId) {
          let qid = $('#' + sectionId).find('.hidden-included-question-id').val();
          let tid = $('#' + sectionId).find('.hidden-included-text-id').val();
          let taid = $('#' + sectionId).find('.hidden-included-table-id').val();
          if (typeof qid !== 'undefined') {
            params['questionnaire_included_section']['included_questions_attributes'][ind] = {};
            params['questionnaire_included_section']['included_questions_attributes'][ind]['id'] = qid;
            params['questionnaire_included_section']['included_questions_attributes'][ind]['sort_order'] = ind + 1;
          } else if (typeof tid !== 'undefined') {
            params['questionnaire_included_section']['included_texts_attributes'][ind] = {};
            params['questionnaire_included_section']['included_texts_attributes'][ind]['id'] = tid;
            params['questionnaire_included_section']['included_texts_attributes'][ind]['sort_order'] = ind + 1;
          } else {
            params['questionnaire_included_section']['tables_attributes'][ind] = {};
            params['questionnaire_included_section']['tables_attributes'][ind]['id'] = taid;
            params['questionnaire_included_section']['tables_attributes'][ind]['sort_order'] = ind + 1;            
          }
        });
        $.ajax({
          type: 'PATCH',
          dataType: 'application/json',
          url: url + $.param(params),
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
          },
        });
      }
    });

    // Draggable sections https://api.jqueryui.com/sortable
    $('.texts-sortable').sortable({
      placeholder: "available-text-space",
      cursor: "move",
      handle: ".text-grip",
      items: "li.text-row:not(.unsortable)",
      update: function(event, ui) {
        let sortOrder = $(this).sortable('toArray');
        let accountUrl = $('body').attr('data-account-url');
        let questionnaireId = $('.module-sortable').attr('data-questionnaire-id');
        let includedSectionId = $(this).attr('data-included-section-id');
        let url = '/' + accountUrl + '/questionnaires/' + questionnaireId + '/included/sections/' + includedSectionId + '.json?';
        let params = {};
        params['questionnaire_included_section'] = {};
        params['questionnaire_included_section']['included_texts_attributes'] = {};
        $.each(sortOrder, function(ind, sectionId) {
          let id = $('#' + sectionId).find('.hidden-included-text-id').val();
          params['questionnaire_included_section']['included_texts_attributes'][ind] = {};
          params['questionnaire_included_section']['included_texts_attributes'][ind]['id'] = id;
          params['questionnaire_included_section']['included_texts_attributes'][ind]['sort_order'] = ind + 1;
        });
        $.ajax({
          type: 'PATCH',
          dataType: 'application/json',
          url: url + $.param(params),
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
          },
        });
      }
    });


    // Set filter typeahead for included questions search
    let accountUrl = $('body').attr('data-account-url');
    let questionnaireId = $('#included_questions_search').attr('data-questionnaire-id');
    let eqUrl = "/" + accountUrl + "/questionnaires/" + questionnaireId + "/included/questions/search?";
    filterTypeahead('#included_questions_search', 'included_questions', eqUrl, 446, 'generic');

    // Create a filter on select
    $('#included_questions_search').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
      $('#determining_included_question_id').val(suggestion.value);
      let hiddenDisplay = $('#included_question_display');
      let hiddenDestroy = $('input.hidden-destroy');
      hiddenDestroy.val('false');
      hiddenDisplay.val('false');
    });

    $('.clear-included-question-typeahead').click(function() {
      $('#determining_included_question_id').val("");
      $('#included_questions_search').typeahead("val", "");
      $('#included_questions_search').focus();
      let hiddenDisplay = $('#included_question_display');
      let hiddenDestroy = $('input.hidden-destroy');
      hiddenDestroy.val('true');
      hiddenDisplay.val('true');
    })

    // Show questions typeahead on select
    $('#questionnaire_included_question_conditional').change(function() {
      let val = $(this).val();
      if (val === 'true') {
        $('.conditional-section').removeClass('d-none');
      } else {
        $('.conditional-section').addClass('d-none');
        $('#determining_included_question_id').val("");
        $('.field-value ').val("");
        $('#included_questions_search').typeahead("val", "");
        $('#included_questions_search').focus();
        let hiddenDisplay = $('#included_question_display');
        let hiddenDestroy = $('input.hidden-destroy');
        hiddenDestroy.val('true');
        hiddenDisplay.val('true');
      }
    });


    // Modules typeahead
    QuestionnaireModulesTypeahead();


  }

}