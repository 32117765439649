import {noResultsTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default function() {

  const accountUrl = $('body').attr('data-account-url');
  let url = '/' + accountUrl + '/questionnaires/modules/search';
  
  let emptyModule = function() {
    return '<div class="empty-message">Search for a module or enter new (min 3 letters)...</div>';      
  }

  let newModuleTemplate = function(name) { 
    let text = '<div class=\'d-flex align-items-center\'>';
    text += '<div class=\'mr-2\'>Click to create a new module named \'' + name + '\'</div>';
    text += '</div>';
    return text;
  }

  const bloodhound = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: url + '?query=%QUERY',
      wildcard: "%QUERY",
      cache: false
    }});

  bloodhound.initialize();

  // On clear typeahead
  $('.clear-typeahead').click(function() {
    $('#module_id').val("");
    $('#modules_search').typeahead("val", "");
    $('#modules_search').focus();
    $('.submit-enabled').addClass('d-none');
    $('.submit-disabled').removeClass('d-none');
  })

  // On blur
  $('#modules_search').blur(function() {
    let val = $(this).val();
    if (val.length === 0) {
      $('#module_id').val("");
      $('#modules_search').typeahead("val", "");
      $('.submit-enabled').addClass('d-none');
      $('.submit-disabled').removeClass('d-none');
    }
  })

  // Initiate apply models typeahead
  $('#modules_search').typeahead({
    hint: true,
    highlight: true,
    minLength: 0,
  }, {
    name: 'modules',
    display: 'name',
    limit: 54,
    remote: {cache: false},
    source: bloodhound.ttAdapter(),
    templates: {
      empty: emptyModule,
      suggestion(el) {
        if (el.is_new_module === "true") {
          return newModuleTemplate(el.name);
        } else if (el.name === null) {
          return noResultsTemplate();
        } else {
          return genericDisplayTemplate(el.name);
        }
      }
    },
  });

  // Select a household
  $('#modules_search').bind('typeahead:beforeselect', function(ev, suggestion) {

    if (suggestion.is_new_module == "true") {

      // Add a module
      let val = $('#modules_search.tt-input').val();
      if (val.length < 3) {
        ToastCustom('Name too short', 'Module name must be at least 3 characters', 5000);
        $('#modules_search').focus();
      } else {
        $('.modal-body').css('opacity', 0.2);
        Spinner($('.modal-content'));
        let url = '/' + accountUrl + '/questionnaires/modules?';
        let params = {};
        params['questionnaire_module'] = {};
        params['questionnaire_module']['title'] = val;
        $.ajax({
          type: 'POST',
          dataType: 'application/json',
          url: url + $.param(params),
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            $('.modal-content').find('.spinner-container').remove();
            $('.modal-body').css('opacity', 1);
            if (responseText.status === 400) {
              ToastCustom('There was a problem', responseText["error"], 5000);
            } else {
              let hId = responseText.id;
              $('#module_id').val(hId);
              ToastCustom('Module created', 'Module added successfully', 5000);
              $('#modules_search').blur();
            }
          },
        });
      }

    } else {

      // Update hidden field
      $('#module_id').val(suggestion.value);
      $('.submit-enabled').removeClass('d-none');
      $('.submit-disabled').addClass('d-none');

    }

  });


}
