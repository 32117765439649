import Spinner from 'src/javascripts/components/utilities/Spinner';
import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';
import StickySidebar from 'src/javascripts/components/nav/StickySidebar';
import ExpandContent from 'src/javascripts/components/utilities/ExpandContent';
import ExpandExcerpt from 'src/javascripts/components/utilities/ExpandExcerpt';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import CarbonFootprint from 'src/javascripts/components/charts/CarbonFootprint';
import QuestionnaireResponsesTable from 'src/javascripts/components/tables/QuestionnaireResponsesTable';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {genericDisplayTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

export default class PortfoliosCarbonFootprintsIndex {

  constructor() {}

  render() {

    // Set account url
    const accountUrl = $('body').attr('data-account-url');

    // Expand/minimize profile details
    ExpandContent();

    // Sticky sidebar scrolling
    StickySidebar();

    // Expand when click on table of contents item
    $(window).scroll(function(e) {
      let scroll = $(window).scrollTop();
      if (scroll > 130) {
        $('.sidebar-title-info').removeClass('min-height');
      } else {
        $('.sidebar-title-info').addClass('min-height');
      }
    });
    let scroll = $(window).scrollTop();
    if (scroll > 130) {
      $('.sidebar-title-info').removeClass('min-height');
    } else {
      $('.sidebar-title-info').addClass('min-height');
    }

    // Scroll to anchor if present
    let anchor = $('#profileTabContent').attr('data-anchor');
    if (typeof anchor !== 'undefined') {
      let toExpand = $('#' + anchor).find('.collapse');
      toExpand.collapse('show');
      $('.collapse').not(toExpand).collapse('hide');
      setTimeout(function() {
        ScrollToTag(anchor);
      }, 500);
    }

    // Start import status poll if importing
    let importPoll = () => {

      // Get generating message on page
      let isGenerating = $('.generating-message');

      // Only continue if status indicator present (if should poll for recs is true)
      if (isGenerating.length !== 0) {

        // Set Api url to get data
        let portfolioId = isGenerating.attr('data-portfolio-id');
        let modelId = isGenerating.attr('data-model-id');
        let params = {};
        params['portfolio_id'] = portfolioId;
        params['scope'] = 'portfolio';
        let url = '/' + accountUrl + '/portfolios/models/' + modelId + '/imports/check_import_status.js?' + $.param(params);

        // Execute ajax request (using js erb template to render content so can control profile styling more easily)
        // Must specify '.js' otherwise processes as JSON
        $.ajax({
          type: "POST",
          dataType: "script",
          timeout: 3000,
          url: url,
          complete() {
            setTimeout(function() { importPoll() }, 4000);
          }
        });
      }
    };

    // Poll server for status of mapped items, if generating message present
    if ($('.generating-message').length !== 0) { importPoll(); }

    // Set function to get carbon footprint data
    let getCarbonFootprintData = function(chart) {

      // Set chart and card body
      let cardBody = chart.closest('.card-body');
      let chartContainer = cardBody.find('.chart-container');
      let chartType = chart.attr('data-chart-type');

      // Set a spinner to indicate chart loading
      Spinner(cardBody);
      chart.css('opacity', 0.25);

      // Set url
      let dataUrl = chartContainer.attr('data-url');

      // Set empty hash to build params
      let params = {};

      // Set other filters from loadMore button
      let portfolioId = chart.attr("data-portfolio-id");
      let itemId = chart.attr("data-item-id");
      let year = chart.attr("data-year");
      let token = chartContainer.attr("data-token");
      let reportId = chartContainer.attr("data-report-id");
      let backPath = chartContainer.attr("data-back-path");
      params["chart_type"] = chartType;
      if (typeof year !== 'undefined') {params["year"] = year;}
      if (typeof portfolioId !== 'undefined') {params["portfolio_id"] = portfolioId;}
      if (typeof itemId !== 'undefined') {params["item_id"] = itemId;}
      if (typeof token !== 'undefined') {params["token"] = token;}
      if (typeof backPath !== 'undefined') {params["back_path"] = backPath;}
      if (typeof reportId !== 'undefined') {params["report_id"] = reportId;}

      // Execute ajax request (using js erb template to render content so can control profile styling more easily)
      // Must specify '.js' otherwise processes as JSON
      return $.ajax({
        type: "GET",
        dataType: "script",
        url: dataUrl + '.js?' + $.param(params)
      });

    }

    // Set function to load carbon footprint table
    let loadFootprintTable = function(card) {
      let table = card.find('.dataTable').DataTable();
      let tableId = card.find('.dataTable').attr('id');
      let dataUrl = BuildApiUrl(tableId);
      table.ajax.url(dataUrl).load();
    }

    // Set function to get carbon footprint data
    let loadGhgTable = function(card) {

      // Set chart and card body
      let cardBody = card.find('.card-body');
      let tableContainer = card.find('.table-container');

      // Set a spinner to indicate chart loading
      Spinner(cardBody);
      tableContainer.css('opacity', 0.25);

      // Set url
      let dataUrl = tableContainer.attr('data-api-url');

      // Set empty hash to build params
      let params = {};

      // Set other filters from loadMore button
      let portfolioId = tableContainer.attr("data-portfolio-id");
      let itemId = tableContainer.attr("data-item-id");
      let year = tableContainer.attr("data-year");
      if (typeof portfolioId !== 'undefined') {params["portfolio_id"] = portfolioId;}
      if (typeof itemId !== 'undefined') {params["item_id"] = itemId;}
      if (typeof year !== 'undefined') {params["year"] = year;}

      // Execute ajax request (using js erb template to render content so can control profile styling more easily)
      // Must specify '.js' otherwise processes as JSON
      return $.ajax({
        type: "GET",
        dataType: "script",
        url: dataUrl + '.js?' + $.param(params)
      });

    }

    // On selecting a portfolio_item
    filterTypeahead('#portfolio_items_search', 'portfolio_items', '/' + accountUrl + '/portfolios/items/search?', 382, 'generic');
    $('#portfolio_items_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      let itemId = suggestion.value;
      let card = $(this).closest('.card');
      let chart = card.find('.carbon-footprint-chart');
      chart.attr('data-item-id', itemId);
      getCarbonFootprintData(chart); 

      // Reload table
      card.find('.table-container').css('opacity', 0.25);
      card.find('.table').attr('data-item-id', itemId);
      loadFootprintTable(card);

      // Enable reset option
      $('.reset-portfolio-items').removeClass('d-none');
      $('#portfolio_items_search').blur();
      card.find('.analysis-scope').empty().append(suggestion.name);
    })

    // Reset portfolio item
    $('.reset-portfolio-items').click(function() {
      let t = $(this).closest('.card-header').find('.typeahead');
      t.typeahead('val', '');
      let card = $(this).closest('.card');
      let chart = card.find('.carbon-footprint-chart');
      chart.attr('data-item-id', null);
      getCarbonFootprintData(chart); 
      
      // Reload table
      card.find('.table-container').css('opacity', 0.25);
      card.find('.table').attr('data-item-id', null);
      loadFootprintTable(card);

      // Disable reset option
      $('.reset-portfolio-items').addClass('d-none');
      $('#portfolio_items_search').blur();
      card.find('.analysis-scope').empty().append("All portfolio");
    })


    // Show footprint by scope table on expand
    $('#footprintByHolding').on('shown.bs.collapse', function(e) {
      let chart = $(this).find('.carbon-footprint-chart');
      if ( $(this).find('.carbon-footprint-bar-container').length === 0) { 
        getCarbonFootprintData(chart); 
      }
      if (($('#footprint_holdings_table').length > 0) && ($('#footprint_holdings_table tbody td').length === 0)) {
        let tableId = 'footprint_holdings_table';
        let carbonFootprintTable = new DefaultTable($('#' + tableId));
        $.when( carbonFootprintTable.render(tableId) ).then( status => carbonFootprintTable.setUp(tableId) );      
      }
    })

    // Show calculation summary
    $('#calcBreakdown').on('shown.bs.collapse', function(e) {
      if (($('#footprint_calc_summary_table').length > 0) && ($('#footprint_calc_summary_table tbody td').length === 0)) {
        let tableId = 'footprint_calc_summary_table';
        let carbonFootprintTable = new DefaultTable($('#' + tableId));
        $.when( carbonFootprintTable.render(tableId) ).then( status => carbonFootprintTable.setUp(tableId) );      
      }
    })

    // Show footprint by scope table on expand
    $('#footprintByScope').on('shown.bs.collapse', function(e) {
      let chart = $(this).find('.carbon-footprint-chart');
      if ( $(this).find('.carbon-footprint-bar-container').length === 0) { 
        getCarbonFootprintData(chart); 
      }
      if (($('#footprint_holdings_scope_table').length > 0) && ($('#footprint_holdings_scope_table tbody td').length === 0)) {
        let tableId = 'footprint_holdings_scope_table';
        let carbonFootprintTable = new DefaultTable($('#' + tableId));
        $.when( carbonFootprintTable.render(tableId) ).then( status => carbonFootprintTable.setUp(tableId) );      
      }
    })

    // Show footprint by emissions table on expand
    $('#footprintByEmissionType').on('shown.bs.collapse', function(e) {
      let chart = $(this).find('.carbon-footprint-chart');
      if ( $(this).find('.carbon-footprint-bar-container').length === 0) { 
        getCarbonFootprintData(chart); 
      }
      if (($('#footprint_holdings_type_table').length > 0) && ($('#footprint_holdings_type_table tbody td').length === 0)) {
        let tableId = 'footprint_holdings_type_table';
        let carbonFootprintTable = new DefaultTable($('#' + tableId));
        $.when( carbonFootprintTable.render(tableId) ).then( status => carbonFootprintTable.setUp(tableId) );      
      }
    })

    // Show questionnaire responses table on expand
    $('#questionnaireResponsesList').on('shown.bs.collapse', function(e) {
      if (($('#questionnaire_responses_table').length > 0) && ($('#questionnaire_responses_table tbody td').length === 0)) {
        let table = new QuestionnaireResponsesTable($('#questionnaire_responses_table'));
        $.when( table.render() ).then( status => table.setUp('questionnaire_responses_table') )
      }
    })

    // Update chart and table on selecting a year
    $('.year-select').change(function(e) {
      let val = $(this).val();
      let accountUrl = $('body').attr('data-account-url');
      let portfolioId = $('#profileTabContent').attr('data-portfolio-id');
      let url = "/" + accountUrl + "/portfolios/carbon_footprint"
      let params = {};
      params['portfolio_id'] = portfolioId;
      params['year'] = val;
      Turbolinks.visit(url + '?' + $.param(params));
    })

    // Show holdings breakdown on expand GHG section
    $('#ghgBreakdown').on('shown.bs.collapse', function(e) {
      let card = $(this).find('.card');
      loadGhgTable(card);
    })

    // On selecting a portfolio_item - holdings breakdown
    filterTypeahead('#portfolio_items_search_holdings', 'portfolio_items', '/' + accountUrl + '/portfolios/items/search?', 382, 'generic');
    $('#portfolio_items_search_holdings').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      let itemId = suggestion.value;
      let card = $(this).closest('.card');
      card.find('.table-container').attr("data-item-id", itemId);
      loadGhgTable(card);
      $('.reset-portfolio-items-holdings').removeClass('d-none');
      card.find('.analysis-scope').empty().append(suggestion.name);
    })

    $('.reset-portfolio-items-holdings').click(function() {
      let t = $(this).closest('.card-header').find('.typeahead');
      t.typeahead('val', '');
      let card = $(this).closest('.card');
      card.find('.table-container').attr("data-item-id", null);
      loadGhgTable(card);
      $('.reset-portfolio-items-holdings').addClass('d-none');
      card.find('.analysis-scope').empty().append("All portfolio");
    })

    // Show public / outside-in carbon footprint on expand
    $('#carbonFootprintList').on('shown.bs.collapse', function (e) {
      CarbonFootprint(); 
    });


  }

}