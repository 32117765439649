import Spinner from 'src/javascripts/components/utilities/Spinner';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {genericDisplayTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';


export default function() {

  // Set account id
  const accountUrl = $('body').attr('data-account-url');
  const scheduleId = $('#edit_reportschedule_form').attr('data-schedule-id');

  filterTypeahead('#search_reports_for_inclusion', 'reports', '/' + accountUrl + '/reports/search?', 1802, 'generic');

  // Must set manually to "chrome-off", otherwise not getting set
  // Must be "chrome-off": https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
  $('.typeahead').attr('autocomplete', "off");

  // Create inclusion on select
  $('#search_reports_for_inclusion').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    $('.modal-body').css('opacity', 0.2);
    Spinner($('.modal-content'));
    let url = '/' + accountUrl + '/reports/schedules/' + scheduleId + '/schedule_inclusions?report_id=' + suggestion.value;
    $.ajax({
      type: 'POST',
      url: url,
      complete(result) { 
        let url = window.location.pathname;
        let params = {};
        params['name'] = $('#report_schedule_name').val();
        params['description'] = $('#report_schedule_description').val();
        params['frequency'] = $('#report_schedule_frequency').val();
        params['next_send_date'] = $('#report_schedule_next_send_date').val();
        Turbolinks.visit(url + '?' + $.param(params)); 
      },
    });
  });

  // Remove inclusion
  $('.remove-report-from-schedule').click(function() {
    $('.modal-body').css('opacity', 0.2);
    Spinner($('.modal-content'));
    let sid = $(this).attr('data-schedule-inclusion-id');
    let url = '/' + accountUrl + '/reports/schedules/' + scheduleId + '/schedule_inclusions/' + sid;
    $.ajax({
      type: 'DELETE',
      url: url,
      complete(result) {
        let url = window.location.pathname;
        let params = {};
        params['name'] = $('#report_schedule_name').val();
        params['description'] = $('#report_schedule_description').val();
        params['frequency'] = $('#report_schedule_frequency').val();
        params['next_send_date'] = $('#report_schedule_next_send_date').val();
        Turbolinks.visit(url + '?' + $.param(params)); 
      },
    });
  });

  // Create recipient on select
  $('.add-schedule-recipient').click(function() {
    $('.modal-body').css('opacity', 0.2);
    Spinner($('.modal-content'));
    let uid = $(this).attr('data-user-id');
    let email = $(this).attr('data-email');
    let url = '/' + accountUrl + '/reports/schedules/' + scheduleId + '/schedule_recipients?user_id=' + uid + '&email=' + email;
    $.ajax({
      type: 'POST',
      url: url,
      complete(result) { 
        let url = window.location.pathname;
        let params = {};
        params['name'] = $('#report_schedule_name').val();
        params['description'] = $('#report_schedule_description').val();
        params['frequency'] = $('#report_schedule_frequency').val();
        params['next_send_date'] = $('#report_schedule_next_send_date').val();
        Turbolinks.visit(url + '?' + $.param(params)); 
      },
    });
  });

  // Create recipient on email
  $('#add_recipient_by_email').focus(function() {
    $('input[type=\'submit\']').addClass('d-none');
    $('.disabled-button').removeClass('d-none');
  });

  $('#add_recipient_by_email').blur(function() {
    $('.modal-body').css('opacity', 0.2);
    Spinner($('.modal-content'));
    $('.disabled-button').addClass('d-none');
    $('input[type=\'submit\']').removeClass('d-none');
    let email = $(this).val();
    let url = '/' + accountUrl + '/reports/schedules/' + scheduleId + '/schedule_recipients?email=' + email;
    $.ajax({
      type: 'POST',
      url: url,
      complete(result) { 
        let url = window.location.pathname;
        let params = {};
        params['name'] = $('#report_schedule_name').val();
        params['description'] = $('#report_schedule_description').val();
        params['frequency'] = $('#report_schedule_frequency').val();
        params['next_send_date'] = $('#report_schedule_next_send_date').val();
        Turbolinks.visit(url + '?' + $.param(params)); 
      },
    });
  });

  // Remove inclusion
  $('.remove-schedule-recipient').click(function() {
    $('.modal-body').css('opacity', 0.2);
    Spinner($('.modal-content'));
    let sid = $(this).attr('data-schedule-recipient-id');
    let url = '/' + accountUrl + '/reports/schedules/' + scheduleId + '/schedule_recipients/' + sid;
    $.ajax({
      type: 'DELETE',
      url: url,
      complete(result) {
        let url = window.location.pathname;
        let params = {};
        params['name'] = $('#report_schedule_name').val();
        params['description'] = $('#report_schedule_description').val();
        params['frequency'] = $('#report_schedule_frequency').val();
        params['next_send_date'] = $('#report_schedule_next_send_date').val();
        Turbolinks.visit(url + '?' + $.param(params)); 
      },
    });
  });


}