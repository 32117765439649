export default class QuestionnairesResponsesShow {

  constructor() {}

  render() {

   $('.copy-questionnaire-invitation').click(function() {

      /* Get the text field */
      let copyText = document.querySelector('#questionnaire_invitation_token');
      
      /* Select the text field */  
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");

      // Update tooltiop
      $(this).attr('data-title', "Copied!");
      $(this).attr('data-original-title', "Copied!");
      $(this).tooltip('hide');
      $(this).tooltip('show');

    })


    // Create or delete respondent modules (access to module)
    $('.respondent-access-check').change(function() {
      let checked = $(this).is(':checked');
      let accountUrl = $('body').attr('data-account-url');
      let responseId = $(this).attr('data-response-id');
      let respondentId = $(this).attr('data-respondent-id');
      let id = $(this).attr('data-id');
      let includedModuleId = $(this).val();
      let url;
      let params = {};
      params['response_id'] = responseId;
      params['respondent_id'] = respondentId;
      params['included_module_id'] = includedModuleId;
      if (checked) {
        url = '/' + accountUrl + '/questionnaires/respondents/modules?' + $.param(params);
        $.ajax({
          type: "POST",
          dataType: "application/json",
          url: url,
        });
      } else if (typeof id !== 'undefined') {
        url = '/' + accountUrl + '/questionnaires/respondents/modules/' + id +'?' + $.param(params);
        $.ajax({
          type: "DELETE",
          dataType: "application/json",
          url: url,
        });        
      }
    })

    // Create all
    $('.respondent-access-create-all').click(function() {
      $(this).closest('tr').find('.respondent-access-check').prop('checked', true);
      let accountUrl = $('body').attr('data-account-url');
      let responseId = $(this).attr('data-response-id');
      let respondentId = $(this).attr('data-respondent-id');
      let url;
      let params = {};
      params['response_id'] = responseId;
      params['respondent_id'] = respondentId;
      url = '/' + accountUrl + '/questionnaires/respondents/modules/create_all?' + $.param(params);
      $.ajax({
        type: "POST",
        dataType: "application/json",
        url: url,
      });
    })

    // Delete all
    $('.respondent-access-delete-all').click(function() {
      $(this).closest('tr').find('.respondent-access-check').prop('checked', false);
      let accountUrl = $('body').attr('data-account-url');
      let responseId = $(this).attr('data-response-id');
      let respondentId = $(this).attr('data-respondent-id');
      let url;
      let params = {};
      params['response_id'] = responseId;
      params['respondent_id'] = respondentId;
      url = '/' + accountUrl + '/questionnaires/respondents/modules/delete_all?' + $.param(params);
      $.ajax({
        type: "DELETE",
        dataType: "application/json",
        url: url,
      });
    })


  }

}
