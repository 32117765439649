// ==============================================================
// Set colors for chart
// ==============================================================

export default function(data, chartId = null) {
  const colors = {};
  // Set portfolio name as current portfolio ('My portfolio') so this is always the same color (primary)
  const portfolioName = 'My portfolio';
  let i = 1;

  while (i < data.length) {
    if (data[i][0].toLowerCase() === 's&p 500 index') {
      colors[data[i][0]] = '#adb5bd';

    } else if (data[i][0].toLowerCase() === 'current portfolio') {
      colors[data[i][0]] = '#8c81d1'

    } else if (data[i][0].toLowerCase() === 'typical portfolio') {
      colors[data[i][0]] = '#8c81d1'

    } else if (data[i][0].toLowerCase() === 'proposed portfolio') {
      colors[data[i][0]] = '#009efb'

    } else if (data[i][0] === portfolioName) {
      colors[data[i][0]] = '#8c81d1';

    } else {

      var currentColors = [];

      // Add colors from chart table, if present
      if ($('.chart-table').length !== 0) {      
        $('table.chart-table tbody tr').each(function() {
          const color = $(this).attr('data-color');
          if (typeof color !== 'undefined') { return currentColors.push(color); }
        });
      }

      // Add colors from legend buttons, if present
      if (chartId !== null) {
        $('#' + chartId).closest('.chart-container').find('.legend button').each(function() {
          const color = $(this).attr('data-color');
          if (typeof color !== 'undefined') { return currentColors.push(color); }
        });
      } else if ($('.legend').length !== 0) {
        $('.legend button').each(function() {
          const color = $(this).attr('data-color');
          if (typeof color !== 'undefined') { return currentColors.push(color); }
        });
      }

      // Add other color not yet present
      if ($.inArray("#009efb", currentColors) === -1) { 
        colors[data[i][0]] = "#009efb"; 
      } else if ($.inArray("#f2d70c", currentColors) === -1) { 
        colors[data[i][0]] = "#f2d70c"; 
      } else if ($.inArray("#36bea6", currentColors) === -1) { 
        colors[data[i][0]] = "#36bea6"; 
      } else if ($.inArray("#2c949e", currentColors) === -1) { 
        colors[data[i][0]] = "#2c949e"; 
      } else if ($.inArray("#ffbc34", currentColors) === -1) { 
        colors[data[i][0]] = "#ffbc34"; 
      } else if ($.inArray("#e871ae", currentColors) === -1) { 
        colors[data[i][0]] = "#e871ae"; 
      } else if ($.inArray("#2c9e5d", currentColors) === -1) { 
        colors[data[i][0]] = "#2c9e5d"; 
      }
    }

    i++;
  }

  return colors;
};
