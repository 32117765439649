// Set tooltip format for impact distribution
// Distribution of ratings (A-F)
export default function(chart, d, defaultTitleFormat, defaultValueFormat, percentiles) {
  
  // Return nothing if d[i].x == 0 or 6
  if ((d[0].x === 0) || (d[0].x === 6)) {
    return;
  }

  let text;
  const chartId = $(chart.api.element).attr('id');

  let i = 0;
  while (i < d.length) {
    if (!(d[i] && (d[i].value || (d[i].value === 0)))) {
      i++;
      continue;
    }

    // Set percentile label
    let pLabel;
    if (d[i].x < 5) {
      pLabel = 'th';
    } else if (d[i].x == 5) {
      pLabel = 'nd';
    } else {
      pLabel = '';
    }

    if (!text) {
      const title = percentiles[d[i].x].toFixed(0);
      let label;
      text = '<table class=\'' + chart.CLASS.tooltip + '\' >';
      text += (title || (title === 0) ? '<tr><th class=\'bg-primary\'>' + title + pLabel + ' percentile</th></tr>' : '');
    }

    text += '<tr class=\'' + chart.CLASS.tooltipName + '-' + d[i].id + '\'>';
    text += '<td class=\'name\'><div class=\'d-inline-block\'>'; 
    text += 'A score above this level is better than ';
    text += percentiles[d[i].x] + '% of all portfolios and funds on Ethos ESG.';
    text += '</div></td></tr>';
    i++;
  }

  return text + '</table>';

};
