export default class AssessmentsAdvocacy {

  constructor() {}

  render() {

    // Submit form on click (default Rails submit button doesn't work with fa icons)
    $('#update_assessment_form_submit').click(function() {
      $('#assessment_lead_advocacy_form').submit();
    });

    // Update on click
    $('.advocacy-option').click(function() {
    	let opt = $(this).attr('data-advocacy-option');
    	$('#assessment_lead_advocacy_importance').val(opt);
    	$('.advocacy-option').addClass('opaque');
    	$(this).removeClass('opaque');
    })

  }

}