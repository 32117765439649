import SignUpForm from 'src/javascripts/components/forms/SignUpForm';
import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import ClientForm from 'src/javascripts/components/forms/ClientForm';

export default class ClientsEdit {

  constructor() {}

  render() {

  	// Client form
  	ClientForm();

    // Sign up form -- phone formatting
    SignUpForm();

	  // Format display numbers in form
	  NumberDisplay();

	  // Update initial investment hidden field on type of text field
	  $(".value-text").on("change paste keyup", function() {

	    // skip for arrow keys
	    if(event.which >= 37 && event.which <= 40) return;

	    // Update related numeric input (remove comma, return to value)
	    let numericInput = $(this).closest('.form-group').find('.numeric-hidden-field');
	    let numericVal = parseFloat( $(this).val() );
	    if (Number.isNaN(numericVal)) {
	      numericVal = 0;
	      numericInput.val(numericVal);
	    } else if (numericVal >= 1000000000000) {
	      numericVal = 100000;
	      numericInput.val(numericVal);
	      $(this).val(('100000'));
	      ToastCustom('Too large', 'Max value is $999,999,999,999', 3000);
	    } else {
	      numericInput.val(numericVal);
	    }

	  });

  }

}