import Spinner from 'src/javascripts/components/utilities/Spinner';
import ExpandExcerpt from 'src/javascripts/components/utilities/ExpandExcerpt';

export default class ResearchLocationsShow {

  constructor() {}

  render() {

    // Expand excerpted statements on click
    ExpandExcerpt();

    if ($('.business-address-map').length !== 0) {

      // Set id
      let mapId = $('.business-address-map').attr('id');
      
      // Add spinner to indicate loading map
      Spinner('#' + mapId);

      // Set Geocoder
      let geocoder = new google.maps.Geocoder();

      // Set address
      let address = $('#' + mapId).attr('data-address');

      // Geocode
      geocoder.geocode({ address: address }, function(results, status) {
        if (status === "OK") {

          // Create map
          let map = new google.maps.Map(document.getElementById(mapId), {
            zoom: 8,
            center: results[0].geometry.location,
            mapTypeControlOptions: { mapTypeIds: [] },
            streetViewControl: false,
          });

          // Create market
          var marker = new google.maps.Marker({
            map: map,
            position: results[0].geometry.location
          });

          // Remove spinner
          $('#' + mapId).find('.spinner-container').remove();

        } else {
          $('#' + mapId).empty().append("We weren't able to find the address for this company.");
        }

      });

    }

  }

}