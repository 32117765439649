import Spinner from 'src/javascripts/components/utilities/Spinner';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class MasterSearchTypeaheadVantage {

  constructor(el) { this.el = el; }

  render() {
    const accountUrl = $('body').attr('data-account-url');

    const companiesBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: '/research?query=%QUERY',
        wildcard: "%QUERY",
        transform: function(d) { return d.companies; }
      }});

    companiesBloodhound.initialize();

    const responsesBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: '/research?query=%QUERY',
        wildcard: "%QUERY",
        transform: function(d) { return d.responses; }
      }});


    responsesBloodhound.initialize();


    const noResultsTemplate = () => '<div class="empty-message">Unable to find anything</div>';

    let emptyTemplate = '<div class="empty-message">Unable to find anything</div>';

    const companyDisplayTemplate = (name, symbol, logo_url, id) => '<div class=\'company position-relative\'>' +
      '<a href=\'/companies/' + id + '\' class=\'gray-link stretched-link\'>' +
      '<div class="d-inline-block mr-3"><img alt="logo" width="25px" src="' + logo_url + '"></div>' +
      '<div class="d-inline-block">' + name + ' (' + symbol + ')</div>' +
      '</a>' +
      '</div>';

    const responseDisplayTemplate = (name, logo_url, id) => '<div class=\'company position-relative\'>' +
      '<a href=\'/' + accountUrl + '/data-collection/responses/' + id + '/modules\' target=\'_blank\' class=\'gray-link stretched-link\'>' +
      '<div class="d-inline-block mr-3"><img alt="logo" width="25px" src="' + logo_url + '"></div>' +
      '<div class="d-inline-block">' + name + '</div>' +
      '</a>' +
      '</div>';

    const masterTypeahead = () => $('.master-search-typeahead').typeahead({
      hint: true,
      highlight: true,
      minLength: 0,
      classNames: {
        menu: 'd-none' // add class name to menu so default dropdown does not show
      }
    }, {
      name: 'companies',
      display: 'name',
      limit: 202,
      source: companiesBloodhound.ttAdapter(),
      menu: '#companiesSearchList'
    }, {
      name: 'responses',
      display: 'name',
      limit: 205,
      source: responsesBloodhound.ttAdapter(),
      menu: '#responsesSearchList'
    });

    // Initiate typeaheads
    masterTypeahead();

    // Add 'expanded' class to menu, which will pin it open (at height = 400px)
    $('#navbarSearchSm').on('click', function() {
      $(this).addClass('show');
      $(this).find('input').focus();
      let dataLoaded = $('#companiesSearchList').find('.company');
      if (dataLoaded.length === 0) {
        let menuCont = $(this).find('.search-menu-expanded');
        Spinner(menuCont);
      }
    });

    // Close dropdown on click outside 
    $('body').on('click', function (e) {
      
      // Set dropdown item
      let d = $('#navbarSearchSm');

      // Close on click of 'collapse'
      if ($('.close').has(e.target).length !== 0) {
        d.removeClass('show');
      }

      // Remove open class if clicked outside the dropdown
      if ( !d.is(e.target) && d.has(e.target).length === 0 && $('.show').has(e.target).length === 0) {
        d.removeClass('show');
      }

    });

    $('.master-search-typeahead').on('typeahead:render', function (e, datum) {

      var suggestions = Array.prototype.slice.call(arguments, 1);

      if (suggestions.length) {

        // Set record type (companies, funds, or causes)
        let recordType = suggestions[0].record_type

        // Different rendering for each type
        if (recordType === 'companies') {

          //empty suggestion div to display results
          $('#companiesSearchList').empty();
          
          for (var c = 0; c < suggestions.length; c++) {

            // Set template to render
            let template;

            if ( (suggestions[c].name !== null) ) {

              template = companyDisplayTemplate(suggestions[c].name, suggestions[c].symbol, suggestions[c].logo_url, suggestions[c].value);

            } else {

              // Set template to render
              template = noResultsTemplate();
              
            }

            // Append to companies search list
            $('#companiesSearchList').append(template);

          }

        } else {

          //empty suggestion div to display results
          $('#responsesSearchList').empty();
          
          for (var i = 0; i < suggestions.length; i++) {

            // Set template
            let template;

            if (suggestions[i].name !== null) {

              // Set template to render
              template = responseDisplayTemplate(suggestions[i].name, suggestions[i].logo_url, suggestions[i].value);

            } else {

              // Set template to render
              template = noResultsTemplate();

            }

            // Append to funds search list
            $('#responsesSearchList').append(template);

          }

        }

        // Remove spinner
        $('#navbarSearchSm').find('.spinner-container').remove();

      }

    });
    
  }

}
