import Spinner from 'src/javascripts/components/utilities/Spinner';
import SecurityWeightsTable from 'src/javascripts/components/tables/SecurityWeightsTable';

export default class ResearchFundsTemperatureIndex {

  constructor() {}

  render() {

    if ($("#held_companies_table_wrapper").length === 0) {
      let tableId = 'held_companies_table';
      const table = new SecurityWeightsTable($('#' + tableId));
      $.when(table.render(tableId)).then( status => table.setUp(tableId) );
    }

    if ($("#held_funds_table_wrapper").length === 0) {
      let tableId = 'held_funds_table';
      const table = new SecurityWeightsTable($('#' + tableId));
      $.when(table.render(tableId)).then( status => table.setUp(tableId) );
    }

    if ($("#held_governments_table_wrapper").length === 0) {
      let tableId = 'held_governments_table';
      const table = new SecurityWeightsTable($('#' + tableId));
      $.when(table.render(tableId)).then( status => table.setUp(tableId) );
    }

  }

}