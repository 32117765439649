import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');


export default class PortfoliosModelsImportsMap {

  constructor() {}

  render() {

    // Submit form on click
    $('#submit_mapping').click(function() {
      Spinner($('#mapModelImport .modal-body'));
      $('#mapModelImport .modal-body').css('opacity', '0.2');
    });

    const companiesBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: '/research?query=%QUERY',
        wildcard: "%QUERY",
        transform: function(d) { return d.companies; }
      }});
    companiesBloodhound.initialize();
    const fundsBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: '/research?query=%QUERY',
        wildcard: "%QUERY",
        transform: function(d) { return d.funds; }
      }});
    fundsBloodhound.initialize();
    const currenciesBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: '/currencies/search?query=%QUERY',
        wildcard: "%QUERY",
      }});
    currenciesBloodhound.initialize();
    const countriesBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: '/countries/search?query=%QUERY',
        wildcard: "%QUERY",
      }});
    countriesBloodhound.initialize();

    // No results / empty templates
    const noResultsTemplate = (record_type) => '<div class="empty-message">No ' + record_type + ' found</div>';
    const emptyTemplate = '<div class="empty-message">Unable to find that</div>';
    
    const logoDisplayTemplate = (name, symbol, logo_url, record_type) => '<div class="d-flex align-items-center">' +
      '<div class="d-block mr-3"><img alt="logo" width="25px" src="' + logo_url + '"></div>' +
      '<div class="d-block">' + name + ' (' + symbol + ') </div>' +
      '<div class="ml-auto mr-2">' + record_type + '</div>' +
      '</div>';

    const genericDisplayTemplate = (name, symbol, record_type) => '<div class="d-flex align-items-center">' +
      '<div class="d-block">' + name + ' (' + symbol + ')' + '</div>' +
      '<div class="ml-auto mr-2">' + record_type + '</div>' +
      '</div>';

    const setUpImportTypeaheads = function(offset) {

      $('.symbol-typeahead[data-offset=\'' + offset + '\']').typeahead({
        hint: true,
        highlight: true,
        minLength: 1,
      }, {
        name: 'companies',
        display: 'symbol',
        limit: 20,
        source: companiesBloodhound.ttAdapter(),
        templates: {
          suggestion(el) {
            if (el.name === null) {
              return noResultsTemplate('companies');
            } else if ((el.logo_url !== null) && (el.logo_url.length > 0)) {
              return logoDisplayTemplate(el.name, el.symbol, el.logo_url, 'Company');
            } else {
              return genericDisplayTemplate(el.name, el.symbol, 'Company');
            }
          }
        },
      }, {
        name: 'funds',
        display: 'symbol',
        limit: 20,
        source: fundsBloodhound.ttAdapter(),
        templates: {
          suggestion(el) {
            if (el.name === null) {
              return noResultsTemplate('funds');
            } else if ((el.logo_url !== null) && (el.logo_url.length > 0)) {
              return logoDisplayTemplate(el.name, el.symbol, el.logo_url, 'Fund');
            } else {
              return genericDisplayTemplate(el.name, el.symbol, 'Fund');
            }
          }
        }
      }, {
        name: 'currencies',
        display: 'symbol',
        limit: 20,
        source: currenciesBloodhound.ttAdapter(),
        templates: {
          suggestion(el) {
            if (el.name === null) {
              return noResultsTemplate('currencies');
            } else {
              return logoDisplayTemplate(el.name, el.symbol, el.logo_url, 'Cash');
            }
          }
        }
      }, {
        name: 'countries',
        display: 'symbol',
        limit: 36,
        source: countriesBloodhound.ttAdapter(),
        templates: {
          suggestion(el) {
            if (el.name === null) {
              return noResultsTemplate('countries');
            } else {
              return logoDisplayTemplate(el.name, el.symbol, el.logo_url, 'Country');
            }
          }
        }
      });


      // Set value on select
      // Create/update a filter on typeahead select
      $('.symbol-typeahead[data-offset=\'' + offset + '\']').bind('typeahead:select', function(ev, suggestion) {

        // Build params for Ajax request
        let accountUrl = $('#modelImportItemsTable').attr('data-account-url');
        let modelId = $('#modelImportItemsTable').attr('data-model-id');
        let portfolioId = $('#modelImportItemsTable').attr('data-portfolio-id');
        let importId = $('#modelImportItemsTable').attr('data-import-id');
        let importItemId = $(this).closest('tr').attr('data-import-item-id');
        let originalSymbol = $(this).closest('tr').find('.original-symbol').text();
        let originalIsin = $(this).closest('tr').find('.original-isin').text();
        let originalCusip = $(this).closest('tr').find('.original-cusip').text();
        let params = {};
        params['import_item_id'] = importItemId;
        params['mapped_symbol'] = suggestion.symbol;
        if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId}
        if (typeof originalSymbol !== 'undefined') {params['original_symbol'] = originalSymbol}
        if (typeof originalIsin !== 'undefined') {params['original_isin'] = originalIsin}
        if (typeof originalCusip !== 'undefined') {params['original_cusip'] = originalCusip}

        // Set mapped type hidden field
        let rType = suggestion.record_type;
        if (rType === 'companies') {
          params['mapped_type'] = 'Company';
          params['company_id'] = suggestion.value;
        } else if (rType === 'funds') {
          params['mapped_type'] = 'Fund';
          params['fund_id'] = suggestion.value;
        } else if (rType === 'currencies') {
          params['mapped_type'] = 'Currency';        
          params['currency_id'] = suggestion.value;
        } else if (rType === 'countries') {
          params['mapped_type'] = 'Country';
          params['country_id'] = suggestion.value;
        }

        // Update name display
        let matchingInfo = $(this).closest('td').find('.matching-info');
        let text;
        if (suggestion.record_type === 'currencies') {
          text = "<div class=\'d-flex align-items-center font-weight-light logo-name-link\'>";
          text += "<div class=\'mr-2 overflow-hidden text-center logo-name-logo\'>";
          text += "<img class=\'xs-logo\' alt=\'" + suggestion.name + "\' src=\'" + suggestion.logo_url + "\'>";
          text += "</div>";
          text += "<div class=\'overflow-hidden logo-name-name\'>";
          text += "<div class=\'d-none d-md-block mr-1 pr-2\'>" + suggestion.name + "</div>";
          text += "</div></div>";
          matchingInfo.empty().append(text);

        } else {
          text = "<a href=\'/" + suggestion.record_type + "/" + suggestion.value + "\' target=\'_blank\' class=\'d-flex align-items-center font-weight-light gray-link logo-name-link\'>";
          text += "<div class=\'mr-2 overflow-hidden text-center logo-name-logo\'>";
          text += "<img class=\'xs-logo\' alt=\'" + suggestion.name + "\' src=\'" + suggestion.logo_url + "\'>";
          text += "</div>";
          text += "<div class=\'overflow-hidden logo-name-name\'>";
          text += "<div class=\'d-none d-md-block mr-1 pr-2\'>" + suggestion.name + "</div>";
          text += "</div></a>";
          matchingInfo.empty().append(text);
        }

        $(this).blur();

        let url = '/' + accountUrl + '/portfolios/models/' + modelId + '/imports/' + importId + '/update_mapped_item?';
        $.ajax({
          type: 'PATCH',
          dataType: 'application/json',
          url: url + $.param(params),
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            $('.modal-content').find('.spinner-container').remove();
            $('.modal-body').css('opacity', 1);
            if (responseText.status === 400) {
              ToastCustom('There was a problem', "There was a problem, please contact us for support", 5000);
            } else {
              ToastCustom('Asset updated', 'Asset mapping updated. Click import when you are ready to import all assets', 5000);
            }
          },
        })

      });

      // Clear selected matching security
      $('.clear-mapping').click(function() {

        // Update language in view
        $(this).closest('tr').find('.symbol-typeahead').typeahead('val', '');
        let text = "<div>No matching record found. Try searching for the security.";
        $(this).closest('tr').find('.matching-info').empty().append(text);

        // Build params for Ajax request
        let accountUrl = $('#modelImportItemsTable').attr('data-account-url');
        let modelId = $('#modelImportItemsTable').attr('data-model-id');
        let portfolioId = $('#modelImportItemsTable').attr('data-portfolio-id');
        let importId = $('#modelImportItemsTable').attr('data-import-id');
        let importItemId = $(this).closest('tr').attr('data-import-item-id');
        let params = {};
        params['import_item_id'] = importItemId;
        if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId}

        let url = '/' + accountUrl + '/portfolios/models/' + modelId + '/imports/' + importId + '/clear_mapped_item?';
        $.ajax({
          type: 'PATCH',
          dataType: 'application/json',
          url: url + $.param(params),
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            $('.modal-content').find('.spinner-container').remove();
            $('.modal-body').css('opacity', 1);
            if (responseText.status === 400) {
              ToastCustom('There was a problem', "There was a problem, please contact us for support", 5000);
            } else {
              ToastCustom('Mapping cleared', 'Asset mapping cleared. Click import when you are ready to import all assets', 5000);
            }
          },
        });


      });

    }

    setUpImportTypeaheads(0);

    // Keep loading while less than total
    const totalItems = $('#modelImportItemsTable').attr('data-total-items');
    const loadMoreItems = function(offset) {
      let loaded = parseInt($('#modelImportItemsTable').attr('data-offset'));
      let accountUrl = $('#modelImportItemsTable').attr('data-account-url');
      let modelId = $('#modelImportItemsTable').attr('data-model-id');
      let importId = $('#modelImportItemsTable').attr('data-import-id');
      let url = '/' + accountUrl + '/portfolios/models/' + modelId + '/imports/' + importId + '/map.js?';
      let params = {};
      params['offset'] = loaded;
      setTimeout(function() {
        $.ajax({
          type: 'GET',
          dataType: 'script',
          url: url + $.param(params),
          complete(result) { 
            setUpImportTypeaheads(loaded);
            loaded = loaded + 50;
            $('#modelImportItemsTable').attr('data-offset', loaded);
            if (loaded < totalItems) { loadMoreItems(loaded); }
          },
        });
      }, 1000)
    }
    loadMoreItems();


    // Set poll function to poll server for status of mapped import items
    let mappedPoll = () => {

      // Get generating message on page
      let isGenerating = $('.generating-message');

      // Only continue if status indicator present (if should poll for recs is true)
      if (isGenerating.length !== 0) {

        // Set Api url to get data
        let accountUrl = isGenerating.attr('data-account-url');
        let portfolioId = isGenerating.attr('data-portfolio-id');
        let modelId = isGenerating.attr('data-model-id');
        let importId = isGenerating.attr('data-import-id');
        let url = '/' + accountUrl + '/portfolios/models/' + modelId + '/imports/' + importId + '/check_mapping_status?portfolio_id=' + portfolioId;

        // Execute ajax request (using js erb template to render content so can control profile styling more easily)
        // Must specify '.js' otherwise processes as JSON
        $.ajax({
          type: "POST",
          dataType: "script",
          timeout: 3000,
          url: url + '.js?',
          complete() {
            setTimeout(function() { mappedPoll() }, 4000);
          }
        });
      }
    };

    // Poll server for status of mapped items
    return mappedPoll();

  }

}