import Spinner from 'src/javascripts/components/utilities/Spinner';
import QuestionnaireResponsesTable from 'src/javascripts/components/tables/QuestionnaireResponsesTable';

export default class QuestionnairesEdit {

  constructor() {}

  render() {

    // Set framework on select
    $('.framework-select').click(function() {
      let f = $(this).attr('data-framework');
      $('#questionnaire_framework').val(f);
      $('.framework-select.active').removeClass('active');
      $(this).addClass('active');
    })

    $('#updateQuestionnaireInfoModal').on('shown.bs.modal', function() {
      $('.portfolio-questionnaire-form').enableClientSideValidations();
    });

    // Draggable sections https://api.jqueryui.com/sortable
    $('.questionnaire-tiles-sortable').sortable({
      placeholder: "available-section-space",
      scrollSensitivity: 100,
      cursor: "move",
      handle: ".grip",
      items: "li:not(.unsortable)",
      start: function(event, ui) {
      	$('.module-action-buttons .btn').css('opacity', 0);
      	$('.module-title').addClass('show');
      },
      stop: function(event, ui) {
      	$('.module-action-buttons .btn').css('opacity', 1);
      	$('.module-title').removeClass('show');
      },
      update: function(event, ui) {
        let sortOrder = $(this).sortable('toArray');
				let accountUrl = $('body').attr('data-account-url');
				let questionnaireId = $('.questionnaire-tiles-sortable').attr('data-questionnaire-id');
        let url = '/' + accountUrl + '/questionnaires/' + questionnaireId + '.json?';
        let params = {};
	      params['questionnaire'] = {};
	      params['questionnaire']['included_modules_attributes'] = {};
        $.each(sortOrder, function(ind, sectionId) {
          let id = $('#' + sectionId).find('.hidden-included-module-id').val();
	        params['questionnaire']['included_modules_attributes'][ind] = {};
	        params['questionnaire']['included_modules_attributes'][ind]['id'] = id;
	        params['questionnaire']['included_modules_attributes'][ind]['sort_order'] = ind + 1;
        });
        $.ajax({
          type: 'PATCH',
          dataType: 'application/json',
          url: url + $.param(params),
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
          },
        });
      }
    });


    if ( $('#questionnaire_responses_table_wrapper').length === 0 ) {
      let table = new QuestionnaireResponsesTable($('#questionnaire_responses_table'));
      $.when( table.render() ).then( status => table.setUp('questionnaire_responses_table') )
    }

  }

}