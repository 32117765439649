import GetValueRangeSingleArr from 'src/javascripts/components/charts/helpers/GetValueRangeSingleArr'
import GetValueRangeMultArr from 'src/javascripts/components/charts/helpers/GetValueRangeMultArr'
import SetAdjustedMin from 'src/javascripts/components/charts/axes/SetAdjustedMin';

export default function(data, chartName) {

  // Set min based on chart data attribute
  let adjustedMin;
  let min = $(chartName).attr('data-chart-min');

  // Return rounded integer value of chart data attribute, if exists
  if (typeof min !== 'undefined') {

    min = Math.floor(min);

  // Otherwise calculate the min value based on returned data
  } else {

    let range = GetValueRangeMultArr(data);

    // Remove NaN from range array
    range = range.filter(value => !Number.isNaN(value));

    const minValue = Math.min.apply(Math,range);
    const maxValue = Math.max.apply(Math,range);
    const floorMin = Math.floor(minValue);
    const ceilMax = Math.ceil(maxValue);
    range = ceilMax - floorMin;

    // Set adjusted min
    adjustedMin = SetAdjustedMin(range, floorMin);

    // Make sure min axis point not below 0, if no data values are below 0
    if (minValue >= 0) { adjustedMin = Math.max.apply(Math, [adjustedMin, 0]); }
  }

  return adjustedMin;

};
