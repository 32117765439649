import {noResultsTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default function() {

  const accountUrl = $('body').attr('data-account-url');
  let url = '/' + accountUrl + '/questionnaires/sections/search';
  
  let emptySection = function() {
    return '<div class="empty-message">Unable to find that. You can create a new section below...</div>';      
  }

  const bloodhound = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: url + '?query=%QUERY',
      wildcard: "%QUERY",
      cache: false
    }});

  bloodhound.initialize();

  // Initiate apply models typeahead
  $('#sections_search').typeahead({
    hint: true,
    highlight: true,
    minLength: 0,
  }, {
    name: 'sections',
    display: 'name',
    limit: 54,
    remote: {cache: false},
    source: bloodhound.ttAdapter(),
    templates: {
      empty: emptySection,
      suggestion(el) {
        if (el.name === null) {
          return noResultsTemplate();
        } else {
          return genericDisplayTemplate(el.name);
        }
      }
    },
  });

  // Select a section
  $('#sections_search').bind('typeahead:beforeselect', function(ev, suggestion) {

    // Update hidden field
    $('#section_id').val(suggestion.value);      

    // Submit if 'new' action
    if ($('#add_included_section').length > 0) {
      Spinner($('.modal'));
      $('.modal-body').css('opacity', 0.25);
      $('#add_included_section').click();
    } else {
      $('.submit-enabled').removeClass('d-none');
      $('.submit-disabled').addClass('d-none');
    }

  });

  // On clear typeahead
  $('.clear-typeahead').click(function() {
    $('#section_id').val("");
    $('#sections_search').typeahead("val", "");
    $('#sections_search').focus();
    $('.submit-enabled').addClass('d-none');
    $('.submit-disabled').removeClass('d-none');
  })

  // On blur
  $('#sections_search').blur(function() {
    let val = $(this).val();
    if (val.length === 0) {
      $('#section_id').val("");
      $('#sections_search').typeahead("val", "");
      $('.submit-enabled').addClass('d-none');
      $('.submit-disabled').removeClass('d-none');
    }
  })


}
