import StickySidebar from 'src/javascripts/components/nav/StickySidebar';

export default class MarketplaceProductsShow {

  constructor() {}

  render() {

    // Sticky sidebar scrolling
    StickySidebar();

  }

}