import Spinner from 'src/javascripts/components/utilities/Spinner';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import QuestionnaireTablesTypeahead from 'src/javascripts/components/typeaheads/QuestionnaireTablesTypeahead';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');


export default class QuestionnairesIncludedTablesEdit {

  constructor() {}

  render() {

	  // Set vars
		let accountUrl = $('body').attr('data-account-url');
		let questionnaireId = $('#included_questions_search').attr('data-questionnaire-id');
		let eqUrl = "/" + accountUrl + "/questionnaires/" + questionnaireId + "/included/questions/search?";

  	// Tables typeahead
  	QuestionnaireTablesTypeahead();

    $('#editQuestionnaireIncludedTableModal').on('shown.bs.modal', function() {
      $('.portfolio-questionnaire-form').enableClientSideValidations();
    });

	  // Filter typeaheads
	  filterTypeahead('#included_questions_search', 'included_questions', eqUrl, 446, 'generic');

	  // Create a filter on select
	  $('#included_questions_search').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
	    $('#determining_included_question_id').val(suggestion.value);
	    let hiddenDisplay = $('#included_question_display');
	    let hiddenDestroy = $('input.hidden-destroy');
	    hiddenDestroy.val('false');
	    hiddenDisplay.val('false');
	  });

	  $('.clear-included-question-typeahead').click(function() {
	    $('#determining_included_question_id').val("");
	    $('#included_questions_search').typeahead("val", "");
	    $('#included_questions_search').focus();
	    let hiddenDisplay = $('#included_question_display');
	    let hiddenDestroy = $('input.hidden-destroy');
	    hiddenDestroy.val('true');
	    hiddenDisplay.val('true');
	  })

	  // Show questions typeahead on select
	  $('#questionnaire_included_table_conditional').change(function() {
	  	let val = $(this).val();
	  	if (val === 'true') {
	  		$('.conditional-section').removeClass('d-none');
	  	} else {
	  		$('.conditional-section').addClass('d-none');
		    $('#determining_included_question_id').val("");
		    $('.field-value ').val("");
		    $('#included_questions_search').typeahead("val", "");
		    $('#included_questions_search').focus();
	      let hiddenDisplay = $('#included_question_display');
	      let hiddenDestroy = $('input.hidden-destroy');
	      hiddenDestroy.val('true');
	    	hiddenDisplay.val('true');
	  	}
	  });

  }

}