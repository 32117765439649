import ResearchFiltering from 'src/javascripts/components/ResearchFiltering';

export default class AccountsResearchScreensIndex {

  constructor() {}

  render() {

    // Filtering functions
    ResearchFiltering();

  }

}