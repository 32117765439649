// Get data from Stripe on a payment method
import Spinner from 'src/javascripts/components/utilities/Spinner';

export default function() {

  let paymentMethodId = $('.default-payment-method').attr('data-payment-method-id');
  let accountUrl = $('.default-payment-method').attr('data-account-url');
  let url = '/marketplace/' + accountUrl + '/stripe/payment_methods/' + paymentMethodId

  // Only do ajax request if the ids are present
  if (typeof paymentMethodId !== 'undefined') {
    
    // Set a spinner to indicate data loading
    Spinner( $('.default-payment-method') );

    // Get data with ajax call
    // Must specify '.js' otherwise processes as JSON
    $.ajax({
      type: "GET",
      dataType: "script",
      url: url + '.js?'
    });

  }

}