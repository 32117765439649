import Collapsible from 'src/javascripts/components/utilities/Collapsible';
import TooltipInit from 'src/javascripts/components/utilities/TooltipInit';
import AdminHistoricalChart from 'src/javascripts/components/charts/AdminHistoricalChart';
import NavbarNotifications from 'src/javascripts/components/notifications/NavbarNotifications';
import ImagePickerAdmin from 'src/javascripts/components/forms/ImagePickerAdmin';
import ClientsTypeahead from 'src/javascripts/components/typeaheads/ClientsTypeahead';
import AccountsTypeahead from 'src/javascripts/components/typeaheads/AccountsTypeahead';
import UsersTypeahead from 'src/javascripts/components/typeaheads/UsersTypeahead';
import {companiesBloodhound, filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import 'summernote/dist/summernote-bs4.min.js'
import * as filestack from 'filestack-js';
import * as fileUpload from 'blueimp-file-upload/js/jquery.fileupload.js';

export default class Admin {

  constructor() {}

  render() {

    // Email format opt
    if ($('#addPlanOpt').length !== 0) {
      $('#addPlanOpt').click(function() {
        let section = $(this).closest('.section');
        let accountId = section.attr('data-account-id');
        let $options = section.attr('data-options');
        let count = section.find('input[type=\'hidden\']').length;
        let numbered = parseInt(count);
        let text = '<div class=\'mt-1 d-flex align-items-center\'><input class="d-none" type="hidden" name="account[allowed_custom_plans_attributes][';
        text += numbered + '][account_id]" id="allowed_custom_plans_attributes_' + numbered;
        text += '_account_id" value=' + accountId + '></input>';
        text += '<select data-validate="true" name="account[allowed_custom_plans_attributes][';
        text += numbered + '][plan_id]" id="allowed_custom_plans_attributes_' + numbered;
        text += '_plan_id">' + $options + '</select><span class=\'remove-plan-opt ml-2 clickable\'>x</span></div>';
        section.find('.form-group').append(text);
        $('.remove-plan-opt').off().click(function() {
          $(this).closest('div').remove();
        });
      });
    }

    // Subscription update for accounts
    if ($('#account_subscription_attributes__destroy').length !== 0) {
      $('#account_subscription_attributes_plan_id').change(function() {
        let val = $(this).val();
        if (val.length > 0) {
          $('#account_subscription_attributes__destroy').val(false);
        } else {
          $('#account_subscription_attributes__destroy').val(true);
        }
      });
    }

    // Image picker
    if ($('.admin-filepicker').length !== 0) {
      ImagePickerAdmin();

      // On show modal
      $('.show-admin-images-modal').click(function () {
        let fieldGroupId = $(this).attr('data-field-group-id');
        $('#adminImagesModal').find('.select-image').attr('data-field-group-id', fieldGroupId);
      });

      // Apply selected image on click
      $('.select-image').click(function() {
        // Update hidden field
        let url = $('.admin-image-container.active').attr('data-image-url');
        let fieldGroupId = $(this).attr('data-field-group-id');
        $('#' + fieldGroupId).find('input[type=\'hidden\']').val(url);

        // Close modal
        $('.modal').modal('hide');

        // Append image
        let src = $('.admin-image-container.active').find('img').attr('src');
        let image = "<img src=\'" + src + " \' class=\'img-fluid\'>";
        $('#' + fieldGroupId).find('.applied-image-container').empty().append(image);

      })

    }

  	// Initiate admin historical charts
  	$('.admin-historical-chart').each(function() {

  		// Return if already has c3-data (already initialized)
  		// Needed to prevent re-init when navigating away from page, before new body has loaded
  		if (typeof $(this).data('c3-chart') === 'undefined') { 

  			let chartId = $(this).attr('id');
  			let chart = new AdminHistoricalChart($('#' + chartId));

  			// Get data, render chart, set up chart, and create connected table, if exists
  			return $.when(chart.getData())
  			 .then( data => chart.render(data))
  			 .then( chartObj => chart.setUp(chartObj));

  		}

  	});

    // Filter by country, etc.
    if ($('.accounts-master-view-filter').length !== 0) {
      $('.accounts-master-view-filter').change(function() {
        let country = $('#countrySelect').val();
        let accountType = $('#accountTypeSelect').val();
        let paymentStatus = $('#paymentStatusSelect').val();
        let baseUrl = window.location.pathname;
        let params = {};
        if (country != 'All countries') { params['country'] = country; }
        if (accountType != 'All account types') { params['account_type'] = accountType; }
        if (paymentStatus != 'All statuses') { params['payment_status'] = paymentStatus; }
        let url;
        url = baseUrl + '?' + $.param(params);
        Turbolinks.visit(url);
      })
    }

    // Clients typeahead
    if ( $("#clients_typeahead").length > 0 )  {
      let clientsTypeahead = new ClientsTypeahead('#clients_typeahead');
      $.when(clientsTypeahead.initializeBloodhound() )
       .then( bloodhound => clientsTypeahead.render(bloodhound) )
       .then( clientsTypeahead.setUp('#clients_typeahead'));
    }

    // Accounts typeahead
    if ( $("#accounts_typeahead").length > 0 )  {
      let accountsTypeahead = new AccountsTypeahead('#accounts_typeahead');
      $.when(accountsTypeahead.initializeBloodhound() )
       .then( bloodhound => accountsTypeahead.render(bloodhound) )
       .then( accountsTypeahead.setUp('#accounts_typeahead'));
    }

    // Accounts typeahead
    if ( $("#account_summary_typeahead").length > 0 )  {
      let accountSummaryTypeahead = new AccountsTypeahead('#account_summary_typeahead');
      $.when(accountSummaryTypeahead.initializeBloodhound() )
       .then( bloodhound => accountSummaryTypeahead.render(bloodhound));

      $('#account_summary_typeahead').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) { 
        $('#account_summary_typeahead').blur();
        $('#account_summary_typeahead').typeahead("val","");
        let firmUrl = $(this).attr("data-firm-url");
        let updateSummaryId = $(this).attr("data-update-summary-id");
        let url = "/firm/" + firmUrl + "/update_summaries/" + updateSummaryId + "/account_summaries?";
        let params = {};
        params['account_summary'] = {}
        params['account_summary']['account_id'] = suggestion.value;
        $.ajax({
          type: 'POST',
          dataType: 'application/json',
          url: url + $.param(params),
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            if (responseText.status === 400) {
              ToastCustom('There was a problem', responseText["error"], 5000);
            } else {
              ToastCustom('Account added', 'Account added to the update summary. Click Save to save the update', 5000);
              $('#accounts_update_summaries_live_table').DataTable().ajax.reload();
            }
          },
        });
      });

      $('.update-all-account-summaries').click(function() {
        $('#accounts_update_summaries_live_table').closest('.card-body').css('opacity', 0.2);
        Spinner($('#accounts_update_summaries_live_table').closest('.card'));
      });
    }


    // Users typeahead
    if ( $("#users_typeahead").length > 0 )  {
      let usersTypeahead = new UsersTypeahead('#users_typeahead');
      $.when(usersTypeahead.initializeBloodhound() )
       .then( bloodhound => usersTypeahead.render(bloodhound) )
       .then( usersTypeahead.setUp('#users_typeahead'));
    }

    // Summernote text editor (currently only used in admin)
    if ($('.summernote').length !== 0) {

      // Send file to AWS
      // https://stackoverflow.com/questions/11240127/uploading-image-to-amazon-s3-with-html-javascript-jquery-with-ajax-request-n
      let sendFile = function(file) {
        let formData      = new FormData();
        let fileInput     = $('.summernote');
        let env           = fileInput.data("env");
        let accountUrl       = fileInput.data("account-url");
        formData.append("key", fileInput.data('form-data')["key"]);
        formData.append("success_action_status", fileInput.data('form-data')["success_action_status"]);
        formData.append("acl", "private");
        formData.append("policy", fileInput.data('form-data')["policy"]);
        formData.append("x-amz-algorithm", fileInput.data('form-data')["x-amz-algorithm"]);
        formData.append("x-amz-credential", fileInput.data('form-data')["x-amz-credential"]);
        formData.append("x-amz-date", fileInput.data('form-data')["x-amz-date"]);
        formData.append("x-amz-signature", fileInput.data('form-data')["x-amz-signature"]);
        formData.append("file", file);
        formData.append('Content-Type', file.type);
        var xhr = new XMLHttpRequest();
        xhr.open('POST', fileInput.data('url'), true); //MUST BE LAST LINE BEFORE YOU SEND 
        xhr.send(formData);
        return file.name;
      };

      $('.summernote').summernote({
        minHeight: 300,
        toolbar: [
          ['font', ['bold', 'italic', 'underline', 'clear']],
          ['para', ['ul']],
          ['insert', ['link', 'picture']],
          ['view', ['fullscreen', 'codeview']],
        ],
        callbacks: {      
          onImageUpload: function(files, editor, welEditable) {
            $.when( sendFile(files[0]) ).then(function(name) {
              let key = $('.summernote').data('form-data')["key"];
              name = name.replace(/ /g, '+');
              key = key.replace("${filename}", name);
              let cloudFront = $('.summernote').data("cloudfront-url");
              let url = "https://" + cloudFront + '/' + key;
              let div = "<div class=\'mb-3 border-dark shadow\' style=\'width: 600px;\'>"; 
              div += "<a href=\'" + url + "\' target=\'_blank\'>";
              div += "<img width=600 filename=\'" + name + "\' src=\'" + url + "\'></img>";
              div += "</a></div>";
              return $('.summernote').summernote('pasteHTML', div);
            });
          }
        }
      });

    }

    // Plan type
    if ($('.select-plan-type').length !== 0) {
      $('.select-plan-type').change(function() {
        if ($(this).val() === "base") {
          $('.base-fields').removeClass('d-none');
        } else {
          $('.base-fields').addClass('d-none');
        }
      })
    }

    // If funds search present
    if ($('#search_funds').length !== 0) {
      filterTypeahead('#search_funds', 'funds', '/funds/search?', 85, 'logo');
      $('#search_funds').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
        let fund_id = suggestion.value;
        let accountId = $('#allowedIndexes').attr('data-account-id');
        let firmUrl = $('body').attr('data-firm-url');
        let url = "/firm/" + firmUrl + '/accounts/' + accountId + '/add_allowed_index'
        let params = {};
        params['fund_id'] = fund_id;
        $('#search_funds').blur().typeahead('val', '');
        $.ajax({
          type: 'POST',
          dataType: 'script',
          url: url + '?' + $.param(params),
        });
      });
    }

    // If companies search present
    if ($('#search_companies').length !== 0) {

      // Init bloodhounds
      companiesBloodhound.initialize();

      // Initiate companies typeahead as one
      $('#search_companies').typeahead({
        hint: true,
        highlight: true,
        minLength: 0,
      }, {
        name: 'companies',
        display: 'name',
        limit: 44,
        source: companiesBloodhound.ttAdapter(),
        templates: {
          suggestion(el) {
            if (el.name === null) {
              return noResultsTemplate();
            } else {
              return logoTemplate(el.name, el.symbol, el.logo_url);
            }
          }
        },
      });

      $('#search_companies').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
        $('#search_companies').closest('.form-group').find('.hidden-field').val(suggestion.value);
      });
    }

    // If accounts search present
    if ($('#search_accounts').length !== 0) {

      // Init bloodhounds
      filterTypeahead('#search_accounts', 'accounts', '/admin/accounts/search?', 192, 'generic');

      $('#search_accounts').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
        $('#search_accounts').closest('.form-group').find('.hidden-field').val(suggestion.value);
      });
    }

    // If companies filter present
    if ($('#filter_companies').length !== 0) {

      // Init bloodhounds
      companiesBloodhound.initialize();

      // Initiate companies typeahead as one
      $('#filter_companies').typeahead({
        hint: true,
        highlight: true,
        minLength: 0,
      }, {
        name: 'companies',
        display: 'name',
        limit: 44,
        source: companiesBloodhound.ttAdapter(),
        templates: {
          suggestion(el) {
            if (el.name === null) {
              return noResultsTemplate();
            } else {
              return logoTemplate(el.name, el.symbol, el.logo_url);
            }
          }
        },
      });

      $('#filter_companies').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
        let url = $('#filter_companies').attr('data-url');
        let params = {};
        params["company_id"] = suggestion.value;
        Turbolinks.visit(url + '?' + $.param(params));
      });
    }

    // Accounts typeahead for adding to firm
    if ($('#add_accounts_typeahead').length !== 0) {
      let firmUrl = $('#add_accounts_typeahead').attr('data-firm-url');
      let firmId = $('#add_accounts_typeahead').attr('data-firm-id');
      filterTypeahead('#add_accounts_typeahead', 'accounts', '/firm/' + firmUrl + '/search_all_accounts?', 192, 'generic');

      // On selecting a fund 
      $('#add_accounts_typeahead').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
        $('.modal-body').css('opacity', 0.2);
        Spinner($('.modal-content'));
        let account_id = suggestion.value;
        let url = "/firm/" + firmUrl + '/firms/' + firmId + '/account_memberships?account_id=' + account_id
        $('#add_accounts_typeahead').blur().typeahead('val', '');
        $.ajax({
          type: 'POST',
          dataType: 'application/json',
          url: url,
          complete(result) { 
            let responseText = JSON.parse(result.responseText);
            $('.modal-content').find('.spinner-container').remove();
            $('.modal-body').css('opacity', 1);
            if (responseText.status === 400) {
              ToastCustom('There was a problem', responseText["error"], 5000);
            } else {
              ToastCustom('Account membership created', 'Account added to firm', 5000);
              $('#firm_accounts_table').DataTable().ajax.reload();
            }
          },
        });

      })
    }


    // If docs upload
    if ($('.product-document-filepicker').length !== 0) {
      $('.product-document-filepicker').each(function(i, elem) {
        let fileInput     = $(elem);
        let filePicker    = $(this);
        let env           = fileInput.data("env");
        let primaryColor  = fileInput.data("primary-color");
        var progressBar   = $("<div class='bar'></div>");
        var barContainer  = $("<div class='progress'></div>").append(progressBar);
        $('#fileUploadProgress').append(barContainer);
        fileInput.fileupload({
          fileInput:        fileInput,
          url:              fileInput.data('url'),
          type:             'POST',
          autoUpload:       true,
          formData:         fileInput.data('form-data'),
          paramName:        'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
          dataType:         'XML',  // S3 returns XML if success_action_status is set to 201
          replaceFileInput: false,
          progressall: function (e, data) {
            var progress = parseInt(data.loaded / data.total * 100, 10);
            progressBar.css('width', progress + '%')
          },
          start: function (e) {
            progressBar.
              css('background', primaryColor).
              css('display', 'block').
              css('width', '0%').
              css("height", "25px");
          },
          done: function(e, data) {
            progressBar.text("");

            // extract key from product
            let key = $(data.jqXHR.responseXML).find("Key").text();
            let docName = $('#document_name').val();
            let productId = filePicker.attr('data-product-id');

            // Set spinner so user knows in progress
            $(this).closest('.filepicker-container .filepicker-label').addClass('opaque');
            Spinner($(this).closest('.filepicker-container'));

            // Upload file
            let url = '/admin/products/' + productId + '/documents.json';
            let formData = {}
            formData['file'] = {}
            formData['file']['key'] = key;
            formData['file']['name'] = docName;

            // Update local attribute
            return $.ajax({
              type: 'POST',
              url: url,
              dataType: "application/json",
              data: formData,
              complete(result) { 
                let url;
                let productId = filePicker.attr('data-product-id');
                let params = {};

                // Set open elements
                let openEls = [];
                $('div[aria-expanded=\'true\']').each(function() {
                  let controls = $(this).attr('aria-controls');
                  openEls.push(controls);
                });
                params['openElements'] = openEls;
                $('#product_documents_table').DataTable().ajax.reload();
                $('#addDocModal').modal('hide');
              },
            });
          },
          fail: function(e, data) {
            progressBar.
              css("background", "red").
              text("Failed");
          }
        });
      });
    }


    // Remove subscription
    if ($('.remove-subscription').length !== 0) {
      $('.remove-subscription').click(function() {
        $(this).closest('.form-group').find('input[type=\'hidden\']').val('true');
        $(this).closest('.subscription-section').addClass('d-none');
      })
    }


  }
}