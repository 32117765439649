import Spinner from 'src/javascripts/components/utilities/Spinner';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {genericDisplayTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default function() {

  // Set accounturl
  let accountUrl = $('body').attr('data-account-url');

  // On update field values, don't destroy value
  $('.scoring-range-text-row input').blur(function() {
    $(this).closest('.scoring-range-text-row').find('.hidden-destroy-field').val('false');
  })

  // On add a new scoring range
  $('#addScoringRange').click(function(ev) {
    $('#add_scoring_range').val('true');
    $(this).closest('form').submit();
  })

  // On add a new formula weight
  $('#addNewWeight').click(function() {
    $('#redirect').val('reload_edit');
    $('.portfolio-questionnaire-form').submit();
  })

  // On update field values, don't destroy value
  $('.weight-text-row input').blur(function() {
    $(this).closest('.weight-text-row').find('.hidden-destroy-field').val('false');
  })

  // Set filter typeahead for general questions search
  filterTypeahead('#questions_search', 'questions', '/' + accountUrl + '/questionnaires/methodologies/questions/search?', 446, 'generic');

  // Create a filter on select
  $('#questions_search').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
    $('#dependent_question_id').val(suggestion.value);
  });

  // On clear typeahead
  $('.clear-typeahead').click(function() {
    $('#question_id').val("");
    $('#questions_search').typeahead("val", "");
    $('#questions_search').focus();
  })

  // Dependent or not
  $("#questionnaire_methodology_segment_dependent_weight").change(function() {
    let val = $(this).val();
    if (val === "true") {
      $('.weight-dependent').removeClass('d-none');
    } else {
      $('.weight-dependent').addClass('d-none');      
    }
  })


}