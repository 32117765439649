// Set y axis tick values, using min and max values of axis
import SetAdjustedMin from 'src/javascripts/components/charts/axes/SetAdjustedMin';

export default function(min, max, chartName) {

  // Set tick interval based on chart data attribute
  let yAxisValues;
  let tickInterval = $(chartName).attr('data-tick-interval');
  let tickReturnsDollar = $(chartName).attr('data-tick-returns-dollar');

  if (typeof tickInterval !== 'undefined') {

    // Return ticks based on chart data attribute, if exists
    tickInterval = parseInt(tickInterval);
    let i = min;
    yAxisValues = [];
    while (i <= max) {
      yAxisValues.push(i.toFixed(1));
      i = i + tickInterval;
    }

  } else if ((typeof tickReturnsDollar !== 'undefined') && (tickReturnsDollar === 'true')) {

    // Get range 
    let range = max - min;

    // Set interval based on range    
    if (range < 12000) {
      tickInterval = 1000
    } else if (range < 22000) {
      tickInterval = 2000
    } else if (range < 32000) {
      tickInterval = 3000
    } else if (range < 50001) {
      tickInterval = 5000
    } else {
      tickInterval = 10000
    }

    // Add ticks
    let i = min;
    yAxisValues = [];
    while (i <= max) {
      yAxisValues.push(i.toFixed(1));
      i = i + tickInterval;
    }

  } else {

    // Otherwise calculate the ticks based on returned data
    const split = ((min + max) / 2);
    yAxisValues = [min.toFixed(1), split.toFixed(1), max.toFixed(1)];
    if (((Math.abs(split) - 2) > 0) && ((Math.abs(min) - 2) > 0) && ((Math.abs(max) - 2) > 0) && (max < 100000)) { yAxisValues.push((0).toFixed(1)); }
  }

  return yAxisValues;

};