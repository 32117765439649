import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import 'summernote/dist/summernote-bs4.min.js'

export default class ComplianceApprovalTestRunsEdit {

  constructor() {}

  render() {
    const accountUrl = $('body').attr('data-account-url');

    // Search
    filterTypeahead('#search_approval_tests', 'approval_tests', '/' + accountUrl + '/compliance/approval_tests/search?', 132, 'generic');

    // On selecting a rule
    $('#search_approval_tests').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      $(this).blur();
      $(this).closest('.form-group').find(".hidden-field").val(suggestion.value);
      $('.modal-footer').find('input').attr('disabled', false);
    });

  }
}