import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

export default class AccountsImpactCalculatorsPublic {

  constructor() {}

  render() {

	  // Format display numbers in form
	  NumberDisplay();

    // Prev, next section
    $('.prev-section, .next-section').click(function() {
      let target = $(this).attr('data-target-key');
      $(this).closest('.calculator-card').addClass('d-none').removeClass('d-flex');
      $('.calculator-card[data-section-key=' + target + ']').removeClass('d-none').addClass('d-flex');
    })

    // Submit hypo investment
    $('#submitHypotheticalInvestment').click(function() {
      let target = $(this).attr('data-target-key');
      let value = $('#hypothetical_investment_amount').val();
      let url = $(this).attr('data-url');
    	url = url + "?key=" + target + "&value=" + value;
    	Turbolinks.visit(url);
    })

	  // Update initial investment hidden field on type of text field
	  $(".value-text").on("change paste keyup", function() {

	    // skip for arrow keys
	    if(event.which >= 37 && event.which <= 40) return;

	    // Update related numeric input (remove comma, return to value)
	    let numericInput = $(this).closest('.form-group').find('.numeric-hidden-field');
	    let numericVal = parseFloat( $(this).val() );
	    if (Number.isNaN(numericVal)) {
	      numericVal = 1;
	      numericInput.val(numericVal);
	    } else if (numericVal < 1) {
	      numericVal = 100000;
	      numericInput.val(numericVal);
	      $(this).val(('100000'));
	      ToastCustom('Too small', 'Value must be greater than 0', 3000);
	    } else if (numericVal >= 1000000000000) {
	      numericVal = 100000;
	      numericInput.val(numericVal);
	      $(this).val(('100000'));
	      ToastCustom('Too large', 'Max value is $999,999,999,999', 3000);
	    } else {
	      numericInput.val(numericVal);
	    }

	  });


  }

}