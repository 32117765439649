// ==============================================================
// Create a button for the legend
// ==============================================================
import SetChartTotal from 'src/javascripts/components/charts/actions/SetChartTotal';
import ResetChartAxis from 'src/javascripts/components/charts/axes/ResetChartAxis';

export default function(chart, legendRecord) {

  // Set params
  const recordType = legendRecord[0];
  const recordId = legendRecord[1];
  const recordName = legendRecord[2];

  // Create a legend button for selected legend item (e.g., user's portfolio)
  const chartLegend = $(chart.element).closest('.chart-container').find('.legend-container .legend');

  // Create a legend button for selected record
  let button = '<button class=\'btn btn-light mr-1 mt-2 border';
  button += '\' data-record-type="' + recordType;
  button += '" data-record-id="' + recordId + '" data-record-name="' + recordName;
  button += '" data-color="' + chart.color(recordName) + '">';
  button += '<div class=\'display-text text-truncate\'>' + recordName + '</div>';
  button += '<span style=\'background-color:' + chart.color(recordName) + '\'></span></button>';
  $(chartLegend).append(button);

  // Set actions for each legend button
  const legendButton = $(chartLegend).find('button[data-record-name="' + recordName + '"]');

  legendButton.off().on('mouseover', function() {

    // Focus the data on the chart
    chart.focus(recordName);

    // Update chart title and total indicator
    return SetChartTotal(chart, recordName);
  });

  legendButton.on('mouseout', function() {

    // Get the first shown data on the chart
    const shownId = chart.data.shown()[0].id;

    // Revert all items
    chart.revert();

    // Reset indicator to original chart data
    return SetChartTotal(chart, shownId);
  });

  return legendButton.on('click', function() {

    // Don't remove data if there's only one item selected
    if (!(chart.data.shown().length <= 1) || !!$(this).hasClass('c3-legend-item-hidden')) {

      // Hide the legend item
      $(this).toggleClass('c3-legend-item-hidden');

      // Toggle the data from the chart
      chart.toggle(recordName);

      // Reset y axis values and ticks
      return ResetChartAxis(chart);
    }
  });
};

