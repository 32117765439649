export default function() {

	if ($('.collapse-item').length !== 0) {

		// Collapse item on click
		$('.collapse-item').off('click').on('click', function() {
			
			// Toggle minimized class for closest collapsible item
			let $closed = $(this).closest('.collapsible-item');
			$closed.toggleClass('minimized');

			// Toggle chevron for THIS item
			let $toggleable = $(this).find('.toggleable');
			if ( ($toggleable.hasClass('fa-chevron-down')) || ($toggleable.hasClass('fa-chevron-up')) ) {
				$toggleable.toggleClass('fa-chevron-down').toggleClass('fa-chevron-up');
			} else {
				$toggleable.toggleClass('fa-plus-circle').toggleClass('fa-minus-circle');
			}

			// Collapse all sibling collapsible items, unless class 'allow-multiple-open'
			if ( !$closed.hasClass('allow-multiple-open') ) {
				let $open = $closed.siblings('.collapsible-item').not('.minimized');
				$open.addClass('minimized');
				let $toggleable = $open.find('.toggleable');
				if ( ($toggleable.hasClass('fa-chevron-down')) || ($toggleable.hasClass('fa-chevron-up')) ) {
					$toggleable.removeClass('fa-chevron-up').addClass('fa-chevron-down');
				} else {
					$toggleable.removeClass('fa-minus-circle').addClass('fa-plus-circle');
				}
				// Scroll to top of previously opened divs; required for recs modal
				$open.scrollTop(0)
			}

			// If research table present, add/remove collapsible item id to params
			if ($('#research_table').length !== 0) {
				let toClose = [];
				let current = JSON.parse($('#research_table').attr('data-to-close'));
				$.each(current, function(i, c) {toClose.push(c)});				
				let cid = $closed.attr('id');
				if ($closed.hasClass('minimized')) {
					toClose.push(cid);
				} else {
	        toClose = jQuery.grep(toClose, function(keep) {
	          return keep != cid;
	        });
				}
				$('#research_table').attr('data-to-close', JSON.stringify(toClose));
			}

		})

	}

}