/*!
 * Bug fix/hack to make work with twitter typeahead
 * https://github.com/DavyJonesLocker/client_side_validations/issues/629
 * Added wrapper: if (element.attr('name') !== undefined) to avoid issue with typeahead
 * Dist code under node_modules/@client-side-validations/client-side-validations/dist/client-side-validations.esm.js
 */

var isMarkedForDestroy = function isMarkedForDestroy(element) {
  if (element.attr('name') !== undefined) {
    if (element.attr('name').search(/\[([^\]]*?)\]$/) >= 0) {
      var destroyInputName = element.attr('name').replace(/\[([^\]]*?)\]$/, '[_destroy]');

      if ($("input[name='" + destroyInputName + "']").val() === '1') {
        return true;
      }
    }
  }

  return false;
};