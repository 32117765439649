import {noResultsTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
import 'summernote/dist/summernote-bs4.min.js'

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default function() {

  const accountUrl = $('body').attr('data-account-url');
  let url = '/' + accountUrl + '/questionnaires/tables/search';
  
  let emptyText = function() {
    return '<div class="empty-message">Unable to find that...</div>';      
  }

  const bloodhound = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: url + '?query=%QUERY',
      wildcard: "%QUERY",
      cache: false
    }});

  bloodhound.initialize();

  // Initiate apply models typeahead
  $('#tables_search').typeahead({
    hint: true,
    highlight: true,
    minLength: 0,
  }, {
    name: 'tables',
    display: 'name',
    limit: 127,
    remote: {cache: false},
    source: bloodhound.ttAdapter(),
    templates: {
      empty: emptyText,
      suggestion(el) {
        if (el.name === null) {
          return noResultsTemplate();
        } else {
          return genericDisplayTemplate(el.name);
        }
      }
    },
  });

  // Select a table
  $('#tables_search').bind('typeahead:beforeselect', function(ev, suggestion) {

    // Update hidden field
    $('#table_id').val(suggestion.value);

    // Submit if 'new' action
    if ($('#add_included_table').length > 0) {
      Spinner($('.modal'));
      $('.modal-body').css('opacity', 0.25);
      
      // Reload
      let questionnaireId = $('#tables_search').attr('data-questionnaire-id');
      let includedTableId = $('#tables_search').attr('data-included-table-id');
      let includedSectionId = $('#tables_search').attr('data-included-section-id');
      let backPath = $('#tables_search').attr('data-back-path');
      let url;
      let params = {};
      params['table_id'] = suggestion.value;
      params['included_section_id'] = includedSectionId;
      params['back_path'] = backPath;
      if (typeof includedTableId !== 'undefined') {
        url = '/' + accountUrl + '/questionnaires/' + questionnaireId + '/included/tables/' + includedTableId + '/edit?' + $.param(params);
      } else {
        url = '/' + accountUrl + '/questionnaires/' + questionnaireId + '/included/tables/new?' + $.param(params);
      }
      Turbolinks.visit(url);
    }

  });

  // On clear typeahead
  $('.clear-typeahead').click(function() {
    $('#table_id').val("");
    $('#tables_search').typeahead("val", "");
    $('#tables_search').focus();
  })


}
