// Set tooltip format for impact distribution
// Distribution of ratings (A-F)
export default function(chart, d, defaultTitleFormat, defaultValueFormat, color) {
  
  let bgClass, text;
  const chartId = $(chart.api.element).attr('id');

  if (d[0].index === 0) {
    bgClass = 'bg-a-rating opaque';
  } else if (d[0].index === 1) {
    bgClass = 'bg-b-rating opaque';
  } else if (d[0].index === 2) {
    bgClass = 'bg-c-rating opaque';
  } else if (d[0].index === 3) {
    bgClass = 'bg-d-rating opaque';
  } else {
    bgClass = 'bg-f-rating opaque';
  }

  let i = 0;
  while (i < d.length) {
    if (!(d[i] && (d[i].value || (d[i].value === 0)))) {
      i++;
      continue;
    }

    if (!text) {
      const title = defaultTitleFormat(d[i].x);
      let label;
      
      // Set label
      if (chartId === 'impact_dist_metric_scores') {
        label = 'metrics'
      } else {
        label = 'ratings';
      }

      text = '<table class=\'' + chart.CLASS.tooltip + '\' >';
      text += (title || (title === 0) ? '<tr><th class=\'' + bgClass + '\'>' + title + ' ' + label + '</th></tr>' : '');
    }

    text += '<tr class=\'' + chart.CLASS.tooltipName + '-' + d[i].id + '\'>';
    text += '<td class=\'name\'><div class=\'d-inline-block\'>'; 
    text += defaultValueFormat(d[i].value.toFixed(0), d[i].ratio, d[i].id, d[i].index); 

    if (chartId === 'impact_dist_by_one_formula') {
      text += ' companies '
    } else if (chartId === 'impact_dist_metric_scores') {
      text += ' metrics '
    } else if (chartId === 'impact_dist_portfolio') {
      text += ' assets '
    } else {
      text += ' causes ';
    }
    text += '<span class=\'font-weight-light\'>(click to view)</span></div></td></tr>';
    i++;
  }

  return text + '</table>';

};
