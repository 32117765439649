import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import AddRowDataAttrs from 'src/javascripts/components/tables/helpers/AddRowDataAttrs';
import AddRowHoverButtons from 'src/javascripts/components/tables/helpers/AddRowHoverButtons';
import AddRowClickLinks from 'src/javascripts/components/tables/actions/AddRowClickLinks';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';
import CheckPaging from 'src/javascripts/components/tables/helpers/CheckPaging';

export default class TopTable extends Table {

  render() {

    // Set a spinner to indicate chart loading
    Spinner(this.el);

    // Set table id
    const tableId = this.el.attr('id');

    // Build url, use to get filtered data with server-side processing
    let dataUrl = this.el.attr('data-api-url');

    // Set additional data vars (used below in table creation)
    let cols = GetCols(this.el);

    // Render table
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      retrieve: true,
      serverSide: true,
      ajax: dataUrl,
      paging: true,
      pageLength: 10,
      searching: true,
      ordering: true,
      order: [[1, 'asc']],
      dom: 'tp',
      columns: cols,
      responsive: { details: false },
      language: { 
        infoEmpty: "No data available. Please contact us with any questions.",
        emptyTable: "No data available. Please contact us with any questions.",
        zeroRecords: "No data available. Please contact us with any questions."
      },
      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        AddRowDataAttrs(row, data);

        // Add hover button links to rows, if passed in data
        if (typeof data.hover_links !== 'undefined') { AddRowHoverButtons(row, data.hover_links); }
      },

      drawCallback: function( settings ) {

        // Hide paging if total display is equal to or less than page length
        CheckPaging(tableId, settings);

        // Remove spinner after table has drawn
        $('#' + tableId).find('.spinner-container').remove();        

      }


    });

    return 'rendered';
  }

  setUp(tableId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Set searchable function for table
    $('#search_' + tableId).keyup(function(){
      oTable.search( $(this).val() ).draw();
    });

    // Ensure search input starts blank
    $('#search_' + tableId).val('');

    // Set on click action for table rows
    AddRowClickLinks(tableId);

    return 'setup';

  }


}
