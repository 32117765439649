import SetResearchParams from 'src/javascripts/components/research/SetResearchParams';
import UpdateResearchFilter from 'src/javascripts/components/research/UpdateResearchFilter'

// Reload page with updated filters
export default function(newFilter, value, method = 'add') {

  // Set url and filters to reload page
  let url;
  let filters;
  if ($('#loadMore').length > 0) {

    // Set url
    url = $('#loadMore').attr('data-reload-url');

    // Set current filter params
    filters = SetResearchParams('loadMore');

  } else {

    // Set urlfrom research table if present
    url = $('#research_table').attr('data-reload-url');

    // Set url if not present
    if (typeof url === 'undefined') {
      let accountUrl = $('#research_table').attr('data-account-url');
      let recordType = $('#research_table').attr('data-record-type');
      url = '/' + accountUrl + '/research/' + recordType;
    }

    // Set current filter params
    filters = SetResearchParams('research_table');

  }

  // Add or remove new filter
  filters = UpdateResearchFilter(filters, newFilter, value, method)

  // Reload page
  Turbolinks.visit(url + '?' + $.param(filters));

}
