import Spinner from 'src/javascripts/components/utilities/Spinner';

let ExecuteCardList = function() {

  // Set Api url to get data
  const dataUrl = $('.cards-list').attr('data-api-url');

  // Delay on search
  let keyupTimer;
  $(".card-list-search").keyup(function () {
    let q = $(this).val();
    clearTimeout(keyupTimer);
    keyupTimer = setTimeout(function () {
      let filters = {};
      filters['query'] = q;
      $.ajax({
        type: "GET",
        dataType: "script",
        url: dataUrl + '.js?' + $.param(filters)
      });
    }, 800);
  });

  // Set empty hash to build filters
  let filters = {};

  // Set record count to load
  filters['start'] = $('.cards-list').attr('data-api-offset-count');
  filters['length'] = $('.cards-list').attr('data-api-record-count');

  // Set updated formula id from formula_filter
  let formula_id = $('#formula_filter').attr('data-api-formula-id');

  // Set other filters from loadMore button
  let reportId = $('#loadMore').attr("data-report-id");
  let benchmarkType = $('#loadMore').attr("data-benchmark-type");
  let benchmarkId = $('#loadMore').attr("data-benchmark-id");
  let sector = $('#loadMore').attr("data-sector");
  let screens = $('#loadMore').attr("data-screens");
  let industry = $('#loadMore').attr("data-industry");
  let sub_industry = $('#loadMore').attr("data-sub-industry");
  let hq_country = $('#loadMore').attr("data-hq-country");
  let hq_state = $('#loadMore').attr("data-hq-state");
  let hq_city = $('#loadMore').attr("data-hq-city");
  let market_cap = $('#loadMore').attr("data-market-cap");
  let symbol = $('#loadMore').attr("data-symbol");
  let category_id = $('#loadMore').attr("data-category-id");
  let business_need_id = $('#loadMore').attr("data-business-need-id");
  let ai = $('#loadMore').attr("data-ai");
  let availability = $('#loadMore').attr("data-availability");
  let cause_id = $('#loadMore').attr("data-cause-id");
  let metric_id = $('#loadMore').attr("data-metric-id");
  let client_id = $('#loadMore').attr("data-client-id");
  let token = $('#loadMore').attr("data-token");
  let backPath = $('#loadMore').attr("data-back-path");
  let record_type = $('#loadMore').attr("data-record-type");
  if (typeof reportId !== 'undefined') {filters["report_id"] = reportId;}
  if (typeof benchmarkType !== 'undefined') {filters["benchmark_type"] = benchmarkType;}
  if (typeof benchmarkId !== 'undefined') {filters["benchmark_id"] = benchmarkId;}
  if (typeof sector !== 'undefined') {filters["sector"] = JSON.parse(sector);}
  if (typeof screens !== 'undefined') {filters["screens"] = JSON.parse(screens);}
  if (typeof industry !== 'undefined') {filters["industry"] = JSON.parse(industry);}
  if (typeof sub_industry !== 'undefined') {filters["sub_industry"] = JSON.parse(sub_industry);}
  if (typeof hq_country !== 'undefined') {filters["hq_country"] = JSON.parse(hq_country);}
  if (typeof hq_state !== 'undefined') {filters["hq_state"] = JSON.parse(hq_state);}
  if (typeof hq_city !== 'undefined') {filters["hq_city"] = JSON.parse(hq_city);}
  if (typeof category_id !== 'undefined') {filters["category_id"] = category_id;}
  if (typeof business_need_id !== 'undefined') {filters["business_need_id"] = business_need_id;}
  if (typeof availability !== 'undefined') {filters["availability"] = availability;}
  if (typeof ai !== 'undefined') {filters["ai"] = ai;}
  if (typeof market_cap !== 'undefined') {filters["market_cap"] = JSON.parse(market_cap);}
  if (typeof symbol !== 'undefined') {filters["symbol"] = JSON.parse(symbol);}
  if (typeof record_type !== 'undefined') {filters["record_type"] = record_type;}
  if (typeof token !== 'undefined') {filters["token"] = token;}
  if (typeof backPath !== 'undefined') {filters["back_path"] = backPath;}
  if (typeof client_id !== 'undefined') {
    filters["client_id"] = JSON.parse(client_id);
  } else if (typeof cause_id !== 'undefined') {
    filters["cause_id"] = JSON.parse(cause_id);
  } else if (typeof metric_id !== 'undefined') {
    filters["metric_id"] = JSON.parse(metric_id);
  } else if (typeof formula_id !== 'undefined') {
    filters["formula_id"] = JSON.parse(formula_id);
  }

  // Execute ajax request (using js erb template to render content so can control profile styling more easily)
  // Must specify '.js' otherwise processes as JSON
  return $.ajax({
    type: "GET",
    dataType: "script",
    url: dataUrl + '.js?' + $.param(filters)
  });


}

export default function() {

  // Listen for requests to load more data ('More' button at bottom of page)
  // On click, update the offset count
  $('.load-card-list').click( function() {

    // Update load offset to indicate where to start loading (offset) for more records
    let currentOffsetCount = parseInt( $(this).attr('data-api-offset-count') );
    $(this).attr('data-api-offset-count', currentOffsetCount + 10);

    // Set spinner
    $('.load-card-list h5').addClass('d-none');
    $('.load-card-list .text-container').append('<div class=\'small-spinner-container position-relative d-flex justify-content-center\'><div class=\'spinner-border text-primary\' role=\'status\'></div></div>');

    // Generate more impact profile cards
    ExecuteCardList();

  });

  // If cards not already loaded, load
  if (($(".list-card").length === 0)) { 

    // Set a spinner to indicate table loading
    Spinner( $('.card-list-container-wide') );

    // Get and load data
    ExecuteCardList();

  }


}