// Visibility toggle of password form inputs
export default function() {

  // Toggle visibility indicator on input of password
  $('.toggle-visibility').off('click').on('click', function() {

    let $input = $(this).closest('.form-group').find('input.password-input');
    
    if ($input.attr('type') === "password") {
      $input.attr('type', 'text');
      let $title = $(this).attr('data-title');
      $title = $title.replace("Show", "Hide");
      $(this).attr('data-original-title', $title);
      $(this).find('i, svg').removeClass('fa-eye').addClass('fa-eye-slash');

    } else {
      $input.attr('type', 'password');
      let $title = $(this).attr('data-title');
      $title = $title.replace("Hide", "Show");
      $(this).attr('data-original-title', $title);
      $(this).find('i, svg').removeClass('fa-eye-slash').addClass('fa-eye');

    }

  })

}