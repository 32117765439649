import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import Spinner from 'src/javascripts/components/utilities/Spinner';

export default class PortfoliosMetricScoresCarbonFootprintDetails {

  constructor() {}

  render() {


    // Portfolio items, if present on page
    if ($('#carbon_footprint_details_table').length > 0) {
      let tid = 'carbon_footprint_details_table';
      let fTable = new DefaultTable( $('#' + tid) );
      $.when( fTable.render(tid) ).then( status => fTable.setUp(tid) );

      // Reload on change scope
      $('#cfScopesSelect').change(function() {
      	let selected = $(this).val();
      	$('#carbon_footprint_details_table').attr('data-scopes', selected);
      	let table = $('#carbon_footprint_details_table').DataTable();
		    let dataUrl = $('#carbon_footprint_details_table').attr('data-api-url');
		    let params = {};
		    params['token'] = $('#carbon_footprint_details_table').attr('data-token');
		    if (selected === '1, 2 and 3') {
		      params['scopes'] = '1, 2 and 3';
		    } else {
		      params['scopes'] = '1 and 2';
		    }
		    dataUrl = dataUrl + '?' + $.param(params);
        table.ajax.url(dataUrl).load();
      });

    }

  }

}