import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';
import Swal from 'sweetalert2';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

export default class QuestionnaireResponsesTable extends Table {

  render() {

    // Set a spinner to indicate chart loading
    if (this.el.closest('.card').find('.spinner-container').length === 0) {
      Spinner(this.el);
    }

    // Set table id
    const tableId = this.el.attr('id');

    // Build url, use to get filtered data with server-side processing
    let dataUrl = this.el.attr('data-api-url');
    let params = {};
    let userId = this.el.attr('data-user-id');
    let questionnaireId = this.el.attr('data-questionnaire-id');
    let status = this.el.attr('data-status');
    let portfolioId = this.el.attr('data-portfolio-id');
    let itemId = this.el.attr('data-item-id');
    let companyId = this.el.attr('data-company-id');
    let deadline = this.el.attr('data-deadline');
    let updated = this.el.attr('data-updated');
    if (typeof userId !== 'undefined') {params['user_id'] = userId;}
    if (typeof questionnaireId !== 'undefined') {params['questionnaire_id'] = questionnaireId;}
    if (typeof status !== 'undefined') {params['status'] = status;}
    if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId;}
    if (typeof itemId !== 'undefined') {params['item_id'] = itemId;}
    if (typeof companyId !== 'undefined') {params['company_id'] = companyId;}
    if (typeof deadline !== 'undefined') {params['deadline'] = deadline;}
    if (typeof updated !== 'undefined') {params['updated'] = updated;}
    dataUrl = dataUrl + '?' + $.param(params);

    // Set additional data vars (used below in table creation)
    let cols = GetCols(this.el);

    // Set height if in full-window mode
    let $card = this.el.closest('.card');
    let $cardBody = this.el.closest('.card-body');
    let $height = $card.attr('data-scroll-height');
    if (typeof $height === 'undefined') {
      if ($card.hasClass('full-view')) {
        $height = $card.height() - 180;
      } else {
        $height = $cardBody.height() - 20;
      }
    }

    // Sort order
    let orderCol = this.el.attr("data-order-col");
    if (typeof orderCol === 'undefined') {orderCol = 0;}
    let orderDir = this.el.attr("data-order-dir");
    if (typeof orderDir === 'undefined') {orderDir = 'asc';}

    // Render table
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      retrieve: true,
      serverSide: true,
      ajax: dataUrl,
      scrollY: $height,
      scrollX: true,
      scrollCollapse: true,
      fixedColumns: {leftColumns: 1, rightColumns: 1},
      paging: true,
      pageLength: 50,
      searching: true,
      order: [[orderCol, orderDir]],
      dom: 'tlp',
      columns: cols,
      responsive: { details: false },
      language: { 
        lengthMenu: "Show _MENU_ records",
        infoEmpty: "Nothing found. Reach out to us at support@ethosesg.com if you would like any help.",
        emptyTable: "Nothing found. Reach out to us at support@ethosesg.com if you would like any help.",
        zeroRecords: "Nothing found. Reach out to us at support@ethosesg.com if you would like any help."
      },
      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        $(row).attr("data-questionnaire-id", data.questionnaire_id);
        $(row).attr("data-record-id", data.record_id);
        $(row).attr("data-token", data.token);
        $(row).attr("data-account-url", data.account_url);

      },

      drawCallback: function( settings ) {

        // Remove spinner after table has drawn
        $('#' + tableId).closest('.card').find('.spinner-container').remove();
        $('#' + tableId).closest('.card-body').css('opacity', 1);

        // Copy URL on click
        $('.copy-url').click(function() {

          let copyText = $(this).closest('tr').attr('data-url');
          
          // Create temp input and copy
          var $temp = $("<input>");
          $("body").append($temp);
          $temp.val(copyText).select();
          document.execCommand("copy");
          $temp.remove();

          // Append notice
          ToastCustom('Ethos', 'URL copied to clipboard')

        });

        // Confirm and delete an item on click
        $('.delete-response').click(function(e) {

          // Set model and item ids
          let accountUrl = $('body').attr('data-account-url');
          let questionnaireId = $(this).closest('tr').attr('data-questionnaire-id');
          let recordId = $(this).closest('tr').attr('data-record-id');
          let text = "Confirm that you would like to remove this response:"
          let url = '/' + accountUrl + '/questionnaires/responses/' + recordId + '?questionnaire_id=' + questionnaireId;

          // Validate
          return Swal.fire({
            title: "Are you sure?",
            text: text,
            animation: false,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-light border',
              popup: 'animated fadeIn faster'
            }
          }).then((result) => {
            
            if (result.value) {

              // Unlink the account
              $.ajax({
                type: 'DELETE',
                dataType: "application/json",
                url: url,
                beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
                complete(result) { 
                  let notice;
                  if (result.status === 200) {
                    notice = 'Response removed';
                  } else {
                    notice = 'There was a problem, please contact us for support';
                  }
                  let backPath = $('#questionnaire_responses_table').attr('data-back-path');
                  return Turbolinks.visit(backPath + '?notice=' + notice);
                },
              });

            }

          });

        });        

      }


    });

    return 'rendered';
  }

  setUp(tableId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Search table
    let timer;
    $('#search_' + tableId).keyup(function(){
      let val = $(this).val();
      clearTimeout(timer);
      timer = setTimeout(function() { oTable.search(val).draw() }, 300);
    });

    // Ensure search input starts blank
    $('#search_' + tableId).val('');

    // Set new window or not
    let target = $('#' + tableId).attr('data-target');
    if (typeof target === 'undefined') {target = ''};

    // Set click areas
    let firstTd; 
    let secondTd;
    firstTd = 'tbody > tr > td:first-child';
    secondTd = 'tbody > tr > td:nth-child(2)';
    $('#questionnaire_responses_table').on('click', firstTd, function() { 
      let r = $(this).closest('tr');
      let accountUrl = r.attr('data-account-url');
      let recordId = r.attr('data-record-id');
      let isCollection = $('#questionnaire_responses_table').attr('data-collection');
      let url;
      if ((typeof accountUrl === 'undefined') || (typeof recordId === 'undefined')) {
        return
      } else if (isCollection === 'true') {
        url = "/" + accountUrl + "/data-collection/responses/" + recordId + "/modules";
      } else {
        url = "/" + accountUrl + "/questionnaires/responses/" + recordId;
      }
      if (target === '_blank') {
        return window.open(url, '_blank');
      } else {
        return window.location.href = url;
      }
    });

    $('#questionnaire_responses_table').on('click', secondTd, function() { 
      let r = $(this).closest('tr');
      let accountUrl = r.attr('data-account-url');
      let recordId = r.attr('data-record-id');
      let isCollection = $('#questionnaire_responses_table').attr('data-collection');
      let url;
      if ((typeof accountUrl === 'undefined') || (typeof recordId === 'undefined')) {
        return
      } else if (isCollection === 'true') {
        url = "/" + accountUrl + "/data-collection/responses/" + recordId + "/modules";
      } else {
        url = "/" + accountUrl + "/questionnaires/responses/" + recordId;
      }
      if (target === '_blank') {
        return window.open(url, '_blank');
      } else {
        return window.location.href = url;
      }
    });

    return 'setup';

  }


}
