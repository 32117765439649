// Nav for public pages
// Add color and border to public top nav on scroll
export default function() {

  // Fade in public icons
  // They start with d-none; fade in once js is loaded
  $('.public-fadeIn').removeClass('opaque');

  $(window).scroll(function() {

    if ($(window).scrollTop() > 30) {

      return $('.navbar').addClass('nav-public-bordered');

    } else {

      return $('.navbar').removeClass('nav-public-bordered');
    }

  });

}