// Pick an image and update an associated attribute in DB
export default function() {

	// The validator variable is a JSON Object
	// The selector variable is a jQuery Object
	window.ClientSideValidations.validators.local['password_complexity'] = function(element, options) {
	  // Your validator code goes in here
	  if ( !element.closest('form').hasClass('sign-in-form') ) {
		  if (!/(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/.test(element.val())) {
		    // When the value fails to pass validation you need to return the error message.
		    // It can be derived from validator.message
		    return options.message;
		  }
		}
	}

}