import Spinner from 'src/javascripts/components/utilities/Spinner';
import ImpactDistByAllFormulasChart from 'src/javascripts/components/charts/ImpactDistByAllFormulasChart';
import ToggleReturnsChart from 'src/javascripts/components/charts/actions/ToggleReturnsChart';
import ImpactDistMetricScores from 'src/javascripts/components/charts/ImpactDistMetricScores';
import ImpactByFormulaTable from 'src/javascripts/components/tables/ImpactByFormulaTable';
import SecurityWeightsTable from 'src/javascripts/components/tables/SecurityWeightsTable';
import PeerComparisonTable from 'src/javascripts/components/tables/PeerComparisonTable';
import ExpandExcerpt from 'src/javascripts/components/utilities/ExpandExcerpt';
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import MetricScoresScatter from 'src/javascripts/components/charts/MetricScoresScatter';
import ReturnsChart from 'src/javascripts/components/charts/ReturnsChart';
import ReturnsDollarsChart from 'src/javascripts/components/charts/ReturnsDollarsChart';
import ImpactHistoricalRatingsChart from 'src/javascripts/components/charts/ImpactHistoricalRatingsChart';

export default class ResearchFundsShow {

  constructor() {}

  render() {

    // Expand problem statement on click
    ExpandExcerpt();

    // Render impact distribution chart, if chart does not already have c3-data (already initialized)
    // Needed to prevent re-init when navigating away from page, before new body has loaded    
    if (typeof $('#impact_dist_by_all_formulas').data('c3-chart') === 'undefined') {
      const chart = new ImpactDistByAllFormulasChart( $('#impact_dist_by_all_formulas') );
      $.when( chart.getData() )
       .then( data => chart.render(data) );
    }

    // Impact by formula table
    if ($(".dataTables_wrapper").length === 0) {
      let tableId = 'impact_by_formula';
      let table = new ImpactByFormulaTable($('#' + tableId));
      $.when( table.render(tableId) ).then( status => table.setUp(tableId) );
    }

    // Render peers chart, if chart does not already have c3-data (already initialized)
    if ($("#impact_details_peer_comparison_wrapper").length === 0) {
      let tableId = 'impact_details_peer_comparison';
      let table = new PeerComparisonTable( $('#' + tableId) );
      $.when( table.render(tableId) ).then( status => table.setUp(tableId) );
    }

    // Render impact historical ratings chart, if chart does not already have c3-data (already initialized)
    // Needed to prevent re-init when navigating away from page, before new body has loaded    
    if (typeof $('#impact_ratings_trend').data('c3-chart') === 'undefined') {
      let chart = new ImpactHistoricalRatingsChart( $('#impact_ratings_trend') );
      $.when( chart.getData() )
       .then( data => chart.render(data) );
    }

    // On show/hide of disclosure or overall impact modal; only if in display mod mode
    if ($('body').hasClass('modal-open')) {
      $('.detail-modal').on('show.bs.modal', function(e) {
        $('#fundProfileShow').css('opacity', 0.2);
        $('.banner-container').css('opacity', 0.05);
      }).on('hide.bs.modal', function(e) {
        $('#fundProfileShow').css('opacity', 1.0);
        $('.banner-container').css('opacity', 1.0);
      }).on('hidden.bs.modal', function(e) {
        $('body').addClass('modal-open');
      });
    }

    // Render child security table
    $('a[href="#dataTab"]').on('shown.bs.tab', function (e) {

      if (typeof $('#metric_scores_scatter').data('c3-chart') === 'undefined') {
        let chart = new MetricScoresScatter( $('#metric_scores_scatter') );
        $.when( chart.getData() )
         .then( data => chart.render(data) );
      }

      // Distribution of scores
      if (typeof $('#impact_dist_metric_scores').data('c3-chart') === 'undefined') {
        let chart = new ImpactDistMetricScores( $('#impact_dist_metric_scores') );
        $.when( chart.getData() )
         .then( data => chart.render(data) );
      }

      // Metric scores table, only once click on data tab for first time
      if ($('#fund_metric_scores_table_wrapper').length === 0) {
        let tableId = 'fund_metric_scores_table';
        let metricScoresTable = new DefaultTable($('#' + tableId));
        $.when( metricScoresTable.render(tableId) ).then( status => metricScoresTable.setUp(tableId) );
      }

    });

    // Render child security table
    $('a[href="#holdingsTab"]').on('shown.bs.tab', function (e) {

      if ($("#held_companies_table_wrapper").length === 0) {
        let tableId = 'held_companies_table';
        const table = new SecurityWeightsTable($('#' + tableId));
        $.when(table.render(tableId)).then( status => table.setUp(tableId) );
      }

      $('a[href="#heldFundsTab"]').on('shown.bs.tab', function (e) {
        if ($("#held_funds_table_wrapper").length === 0) {
          let tableId = 'held_funds_table';
          const table = new SecurityWeightsTable($('#' + tableId));
          $.when(table.render(tableId)).then( status => table.setUp(tableId) );
        }
      })

      $('a[href="#heldGovernmentsTab"]').on('shown.bs.tab', function (e) {
        if ($("#held_governments_table_wrapper").length === 0) {
          let tableId = 'held_governments_table';
          const table = new SecurityWeightsTable($('#' + tableId));
          $.when(table.render(tableId)).then( status => table.setUp(tableId) );
        }
      })

      // Search tables by impact screen keyword - must be here to look across three tables
      $('.filter-security-weights').off().click(function() {
        $('.filter-security-weights.active').not($(this)).removeClass('active');
        let active = $(this).hasClass('active');
        let searchQuery = $(this).attr('data-search');
        $(this).toggleClass('active');
        if ( active ) {
          $('#held_companies_table').DataTable().search('').draw();
          $('#held_funds_table').DataTable().search('').draw();
          $('#held_countries_table').DataTable().search('').draw();
        } else {
          $('#held_companies_table').DataTable().search(searchQuery).draw();
          $('#held_funds_table').DataTable().search(searchQuery).draw();
          $('#held_countries_table').DataTable().search(searchQuery).draw();
        }
      });


    });

    // Re-flow dist chart on click of 'Scorecard' tab
    $('a[href="#scorecardTab"]').on('shown.bs.tab', function (e) {
      setTimeout(function() {
        let distChart = $('#impact_dist_by_all_formulas').data('c3-chart');
        if (typeof distChart !== 'undefined') { 
          distChart.flush(); 
        }
      }, 500);
    });

    // Financial tab
    $('a[href="#financialTab"]').on('shown.bs.tab', function (e) {

      // Initialize a new chart instance for each chart on the page
      if (($('#returns_chart').attr('data-return-option') === 'percentage') && (typeof $('#returns_chart').data('c3-chart') === 'undefined')) {

        // Set chartId and chart object
        // Get data, render chart, set up chart, and create connected table, if exists
        let chart = new ReturnsChart($('#returns_chart'));
        $.when(chart.getData())
         .then( data => chart.render(data))
         .then( chartObj => chart.setUp(chartObj));

      } else if (($('#returns_chart').attr('data-return-option') === 'dollar') && (typeof $('#returns_chart').data('c3-chart') === 'undefined')) {

        // Set chartId and chart object
        // Get data, render chart, set up chart, and create connected table, if exists
        let chart = new ReturnsDollarsChart($('#returns_chart'));
        $.when(chart.getData())
         .then( data => chart.render(data))
         .then( chartObj => chart.setUp(chartObj));

      }

      // Toggle returns chart between hypothetical growth and percentage returns
      ToggleReturnsChart();

    });

  }

}