import Spinner from 'src/javascripts/components/utilities/Spinner';

export default class QuestionnairesNew {

  constructor() {}

  render() {

  	// Set framework on select
  	$('.framework-select').click(function() {
  		let f = $(this).attr('data-framework');
  		$('#questionnaire_framework').val(f);
  		$('.framework-select.active').removeClass('active');
  		$(this).addClass('active');
  	})

  }

}