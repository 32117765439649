// No data available message
// Alert user that no data available
// Used with charts and tables
export default function(el) {

  // Set no data div -- must be present on page
  let noDataDiv = $('#noDataContainer');

  // Set no data message, by element attr if present, otherwise default
  let defaultNoDataMessage = 'No data available. Please contact us if you have any questions.';
  let noDataMessage = $(el).attr('data-no-data-message') || defaultNoDataMessage;

  // Remove chart and table containers, if exist
  let chartContainer = $(el).closest('.chart-section').find('.chart-container');
  let tableContainer = $(el).closest('.chart-section').find('.table-container');
  chartContainer.remove();
  tableContainer.remove();

  // Append message
  noDataDiv.empty().append(noDataMessage);

  // Remove any spinner containers in el
  $(el).find('.spinner-container').remove();

  // Return
  return;

}