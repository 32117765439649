export default function() {

  // Update user's settings preferences on select
  let $envInput = $('#toggleDevMode');
  if (typeof $envInput !== 'undefined') {
	  $envInput.change(function() {
	    
	    // Set params as true or false
	    let params = {};
      let env = $(this).find(":selected").val();
      console.log(env);
      params['user_env'] = env;

      $.ajax({
        type: 'PATCH',
        dataType: "json",
        url: '/users/update_user_env.json?' + $.param(params),
        complete(result) { 
        	let accountUrl = $('body').attr('data-account-url');
          let url = '/' + accountUrl;
      		if (params['user_env'] === 'staging') {
      			url = url + '?notice=Switched to Staging mode';
          } else if (params['user_env'] === 'development') {
            url = url + '?notice=Switched to Development mode';
      		} else {
      			url = url + '?notice=Switched to Production mode';
      		}
          Turbolinks.visit(url);
        }
      })

	  });
	}


}