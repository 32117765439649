import {genericDisplayTemplate, noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';

export default class PortfoliosItemsRecommendationSummaryModulesEdit {

  constructor() {}

  render() {

	 	// Get households for account
		let accountUrl = $('body').attr('data-account-url');

	  // On selecting a questionnaire response
	  filterTypeahead('#questionnaire_responses_search', 'questionnaire_responses', '/' + accountUrl + '/questionnaires/responses/search?', 382, 'generic');
	  $('#questionnaire_responses_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
	    let itemId = suggestion.value;
	    $('#hidden_questionnaire_response_id').val(itemId);
	  })

    // Associate with a questionnaire module
  	filterTypeahead('#questionnaire_modules_search', 'questionnaire_modules', '/' + accountUrl + '/questionnaires/modules/search?', 82, 'generic');
  	filterTypeahead('#items_search', 'portfolio_items', '/' + accountUrl + '/portfolios/items/search?', 52, 'generic');

	  // On selecting a company
	  $('#items_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
	    let itemId = suggestion.value;
	    $('#hidden_portfolio_item_id').val(itemId);
	  })

	  // On selecting a company
	  $('#questionnaire_modules_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
	    let mId = suggestion.value;
	    $('#hidden_questionnaire_module_id').val(mId);
	  })


  }

}