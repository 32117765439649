export default class AssessmentsFaithBased {

  constructor() {}

  render() {

    // Submit form on click (default Rails submit button doesn't work with fa icons)
    $('#update_assessment_form_submit').click(function() {
      $('#assessment_lead_faith_based_form').submit();
    });

    // Update on click
    $('.faith-based-option').click(function() {
    	let opt = $(this).attr('data-faith-based-option');
    	$('#assessment_lead_faith_based_interest').val(opt);
    	$('.faith-based-option').addClass('opaque');
    	$(this).removeClass('opaque');
    })

  }

}