import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';

export default function() {

  // Expand when click on table of contents item
  $('.sticky-sidebar a.scroll-to-section').click(function(e) {
    e.preventDefault();

    // Set section to scroll to and expand
    let sectionId = $(this).attr('data-tag');
    let toExpand = $('#' + sectionId).find('.collapse');

    // Show the selected (clicked on) section
    toExpand.collapse('show');

    // Hide other sections
    $('.collapse').not(toExpand).collapse('hide');

    // Scroll to the selectd section
    setTimeout(function() {
      ScrollToTag(sectionId);  
    }, 500);
    
  });



}