import FormatString from 'src/javascripts/components/utilities/FormatString';
import * as moment from 'moment';

// ==============================================================
// Returns chart tooltip format
// ==============================================================

export default function(chart, tooltip, d, defaultTitleFormat, defaultValueFormat, color) {
  let text; let i = 0;

  // Sort data by value
  function SortByValue(a, b){
    let aVal = a.value.toFixed(0);
    let bVal = b.value.toFixed(0); 
    return ((aVal < bVal) ? -1 : ((aVal > bVal) ? 1 : 0));
  }
  d = d.sort(SortByValue).reverse();

  // Create tooltip item for every value on the date
  while (i < d.length) {

    // Move to next item if value not present
    if (typeof d[i].value === 'undefined') {i++; continue;}

    // Create initial text
    if (!text) {
      const title = d[i].x;
      text = '<table class=\'' + tooltip.CLASS.tooltip + '\'>' + (title || (title === 0) ? '<tr><th colspan=\'2\' class=\'bg-primary-light\'>' + title + '</th></tr>' : '');
    }

    // Set name from data
    let name = (d[i].name.substr(0, 1).toUpperCase() + d[i].name.substr(1)).replace(/_/g,' ');
    
    // Set return value from data
    let value = FormatString(parseFloat(d[i].value.toFixed(0)), 'currency', 0, false, false, false);

    // Add text, including change string unless this is for returns
    const bgcolor = tooltip.levelColor ? tooltip.levelColor(d[i].value) : color(d[i].id);
    text += '<tr class=\'' + tooltip.CLASS.tooltipName + '-' + d[i].id + '\'>';
    text += '<td class=\'name\'><span style=\'background-color:' + bgcolor + '\'></span>' + name + '</td>';

    // Add price from prices array attached to chart data, if exists and 
    // if not for current portfolio, which is often used for comparison. Don't want to 
    // show artificial prices for portfolio or accounts
    text += '<td class=\'value\'>' + value + '</td>';
    text += '</tr>';
    i++;

  }
  return text + '</table>';
};

