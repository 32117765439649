import Spinner from 'src/javascripts/components/utilities/Spinner';
import ReportForm from 'src/javascripts/components/reports/ReportForm';
import FeaturedMetricsForm from 'src/javascripts/components/reports/FeaturedMetricsForm';

export default class ReportsEdit {

  constructor() {}

  render() {

    // Set report form
    ReportForm();

    // Featured metrics form to include
    FeaturedMetricsForm();

  }

}