// Set tooltip format for metric scores scatter chart
export default function(chart, d, defaultTitleFormat, defaultValueFormat, color, data) {
  
  let bgClass, text, ratingLetter;
  const chartId = $(chart.api.element).attr('id');

  if (d[0].value >= 80) {
    bgClass = 'bg-a-rating opaque';
    ratingLetter = 'A';
  } else if (d[0].value >= 60) {
    bgClass = 'bg-b-rating opaque';
    ratingLetter = 'B';
  } else if (d[0].value >= 40) {
    bgClass = 'bg-c-rating opaque';
    ratingLetter = 'C';
  } else if (d[0].value >= 20) {
    bgClass = 'bg-d-rating opaque';
    ratingLetter = 'D';
  } else {
    bgClass = 'bg-f-rating opaque';
    ratingLetter = 'F';
  }

  let i = 0;
  while (i < d.length) {
    if (!(d[i] && (d[i].value || (d[i].value === 0)))) {
      i++;
      continue;
    }

    let ind = -1;
    let len = data.length;
    let x = parseFloat(d[i].x).toFixed(3);
    let val = parseFloat(d[i].value).toFixed(3);

    let z = 0;
    while (z < len) {
      let w = parseFloat(data[z].weight).toFixed(3);
      let s = parseFloat(data[z].score).toFixed(3);
      if ( w === x && (s === val) ) {ind = z; break;}
      z++;
    }

    // Make sure ind has been assigned
    if (ind === -1) {
      let len2 = data.length - 1;
      ind = len2 - d[i].index;
    }

    let weight = data[ind].weight_formatted;
    let metric = data[ind].metric;
    let desc = data[ind].description;
    let source = data[ind].source;
    let score_base = data[ind].score_base;
    let rating = data[ind].score_formatted;
    let date = data[ind].date;

    if (!text) {
      text = '<table class=\'' + chart.CLASS.tooltip + '\' >';
      text += '<tr><th class=\'' + bgClass + '\'>' + metric + '</th></tr>';
    }

    text += '<tr class=\'' + chart.CLASS.tooltipName + '-' + d[i].id + '\'>';
    text += '<td class=\'score\'><div class=\'d-flex\'><div class=\'mr-2\'>Data:</div>'; 
    text += '<div class=\'ml-auto\'>' + score_base + '</div></div>'; 
    text += '</td></tr>';
    text += '<tr class=\'' + chart.CLASS.tooltipName + '-' + d[i].id + '\'>';
    text += '<td class=\'rating\'><div class=\'d-flex\'><div class=\'mr-2\'>Rating:</div>'; 
    text += '<div class=\'ml-auto\'>' + rating + '</div></div>'; 
    text += '</td></tr>';
    text += '<tr class=\'' + chart.CLASS.tooltipName + '-' + d[i].id + '\'>';
    text += '<td class=\'weight\'><div class=\'d-flex\'><div class=\'mr-2\'>Weight:</div>'; 
    text += '<div class=\'ml-auto\'>' + weight + '</div></div>'; 
    text += '</td></tr>';
    text += '<tr class=\'' + chart.CLASS.tooltipName + '-' + d[i].id + '\'>';
    text += '<td class=\'source\'><div class=\'d-flex\'><div class=\'mr-2\'>Source:</div>'; 
    text += '<div class=\'ml-auto\'>' + source + '</div></div>'; 
    text += '</td></tr>';
    text += '<tr class=\'' + chart.CLASS.tooltipName + '-' + d[i].id + '\'>';
    text += '<td class=\'date\'><div class=\'d-flex\'><div class=\'mr-2\'>Date:</div>'; 
    text += '<div class=\'ml-auto\'>' + date + '</div></div>'; 
    text += '</td></tr>';
    text += '<tr class=\'c3-tooltip-name-description\'>';
    text += '<td class=\'description\'><div class=\'py-2\'>' + desc + '</div></div></div>'; 
    text += '</td></tr>';
    i++;
  }

  return text + '</table>';

};
