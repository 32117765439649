// Update a research filter value
export default function(filters, newFilter, value, method) {

  if (method === 'add') {
    if (newFilter === 'cause_id') {
      filters[newFilter] = value;
      filters["formula_id"] = {}
      filters["client_id"] = {}
      filters["metric_id"] = {}

    } else if (newFilter === 'client_id') {
      filters[newFilter] = value;
      filters["formula_id"] = {}
      filters["cause_id"] = {}
      filters["metric_id"] = {}

    } else if (newFilter === 'metric_id') {
      filters[newFilter] = value;
      filters["formula_id"] = {}
      filters["client_id"] = {}
      filters["cause_id"] = {}

    } else if (['total_assets', 'expense_ratio'].indexOf(newFilter) > -1) {
      filters[newFilter] = value;

    } else if (typeof filters[newFilter] === 'undefined') {
      filters[newFilter] = value;

    } else {
      let updated = filters[newFilter];
      if (updated.indexOf(value) === -1) {
        updated.push(value);
        filters[newFilter] = updated;
      }
    }
  } else {

    if (['cause_id', 'client_id', 'metric_id'].indexOf(newFilter) !== -1) {
      filters[newFilter] = {};

    } else {
      let updated = filters[newFilter];
      if (updated.indexOf(value) !== -1) {
        updated = jQuery.grep(updated, function(keep) {
          return keep != value;
        });
        filters[newFilter] = updated;
      }
    }
  }
  return filters;

}