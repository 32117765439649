export default function() {

  if ($('.generating-stocks-message').length > 0) {

    // Start stocks status poll if importing
    let stocksStatusPoll = () => {

      // Get generating message on page
      let isGenerating = $('.generating-stocks-message');

      // Only continue if status indicator present (if should poll for recs is true)
      if (isGenerating.length !== 0) {

        // Set Api url to get data
        let accountUrl = isGenerating.attr('data-account-url');
        let portfolioId = isGenerating.attr('data-portfolio-id');
        let token = isGenerating.attr('data-token');
        let params = {};
        params['portfolio_id'] = portfolioId;
        let url = '/' + accountUrl + '/portfolios/' + portfolioId + '/items/check_underlying_status.js?' + $.param(params);

        // Execute ajax request (using js erb template to render content so can control profile styling more easily)
        // Must specify '.js' otherwise processes as JSON
        $.ajax({
          type: "POST",
          dataType: "script",
          timeout: 3000,
          url: url,
          complete() {
            setTimeout(function() { stocksStatusPoll() }, 4000);
          }
        });
      }
    };

    // Poll server for status of mapped items, if generating message present
    if ($('.generating-stocks-message').length !== 0) { stocksStatusPoll(); }

  }

}