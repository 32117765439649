// Sign up form used on multiple pages
export default function() {

  // On select account type
  $('#account_account_type').change(function() {
    let val = $(this).val();
    if (val.length !== 0) {
      $(this).removeClass('text-gray500');
    } else {
      $(this).addClass('text-gray500');
    }
  });

  // Intl tel input 
  // https://github.com/jackocnr/intl-tel-input
  var input = document.querySelector(".phone-input");

  if (input !== null) {

    // Initialize
    var iti = window.intlTelInput(input, {
      initialCountry: "auto",
      dropdownContainer: document.body,
      geoIpLookup: function(success, failure) {
        $.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
          var countryCode = (resp && resp.country) ? resp.country : "";          
          success(countryCode);
        });
      },
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.11/js/utils.js"
    }); 

    // Update width of of phone number input as type country code
    $('#country_code').on('keyup', function() {
      let len = $(this).val().length;
      if (len <= 1) {
        $('#phone_number').removeClass('pl-xl').removeClass('pl-l');
      } else if (len === 2) {
        $('#phone_number').removeClass('pl-xl').addClass('pl-l');
      } else {
        $('#phone_number').removeClass('pl-l').addClass('pl-xl');
      }
    })

    // Change country code input on selection of flag
    input.addEventListener("countrychange", function() {
      let countryData = iti.getSelectedCountryData();
      // Adjust padding of phone number field
      let len = countryData.dialCode.length;      
      if (len === 1) {
        $('#phone_number').removeClass('pl-xl').removeClass('pl-l');
      } else if (len === 2) {
        $('#phone_number').removeClass('pl-xl').addClass('pl-l');
      } else {
        $('#phone_number').removeClass('pl-l').addClass('pl-xl');
      }
      $('#country_code').val(countryData.dialCode);
    });

    // Validate input
    // https://intl-tel-input.com/node_modules/intl-tel-input/examples/gen/is-valid-number.html
    // Index maps to the error code returned from getValidationError - see readme
    var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];
    var resetPhoneNumberErrors = function() { $('.phone-error-message').remove(); };

    // on blur: validate
    input.addEventListener('blur', function() {
      resetPhoneNumberErrors();
      if (input.value.trim()) {
        if (!iti.isValidNumber()) {

          // Get error code and update phone error message
          var errorCode = iti.getValidationError();
          $(input).after("<label class=\'phone-error-message\'>" + errorMap[errorCode] + "</label>");

        }
      }
    });

    // on keyup / change flag: resetPhoneNumberErrors
    input.addEventListener('change', resetPhoneNumberErrors);
    input.addEventListener('keyup', resetPhoneNumberErrors);

    // Format number as user types
    var formatAsType = function() {       
      let n = iti.getNumber(intlTelInputUtils.numberFormat.NATIONAL);
      $(input).val(n);
    };
    input.addEventListener('keyup', formatAsType);

  }

  // Option to input CSRIC designation for financial advisors
  $('#account_account_type').change(function() {
    let val = $(this).val();
    if (val === 'advisor') {
      $('.csric-field').removeClass('d-none');
    }  else {
      $('.csric-field').addClass('d-none');
    }
  })

}

