export default function() {

  // Expand problem statement on click
  return $('.expand-excerpt a').on('click', function() {

  	// Set excerptable el
  	let $excerptable = $(this).closest('.excerptable');

    // Toggle expanded class for card
    $excerptable.toggleClass('expanded');

    // Toggle display class for read more link
    return $excerptable.find('.expand-excerpt').toggleClass('d-none');

  });

}