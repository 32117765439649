import Spinner from 'src/javascripts/components/utilities/Spinner';
import ReportSchedulesForm from 'src/javascripts/components/reports/ReportSchedulesForm';

export default class ReportsSchedulesEdit {

  constructor() {}

  render() {

    // Set report scheduling form
    ReportSchedulesForm();

  }

}