import * as d3 from 'd3';
import * as c3 from 'c3';
import Chart from 'src/javascripts/components/Chart';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import SetColors from 'src/javascripts/components/charts/helpers/SetColors';
import RatingsChartTooltip from 'src/javascripts/components/charts/tooltips/RatingsChartTooltip';
import FormatString from 'src/javascripts/components/utilities/FormatString';

export default class ImpactHistoricalRatingsChart extends Chart {

  getData() {

    // Set chart element id
    const chartId = this.el.attr('id');

    // Set a spinner to indicate chart loading
    Spinner(this.el);

    // Build url
    const url = this.el.attr('data-api-url');

    // Get data
    return $.getJSON(url);

  }

  render(data) {

    // Set ratings
    let ratings = data.ratings;

    // Set whether to include letter ratings
    let letterGrades = this.el.attr('data-letter-grades');

    // Set id
    let chartId = this.el.attr('id');

    // Render chart
    var chart = c3.generate({
      bindto: '#' + chartId,
      padding: {top: -10, bottom: -10, left: 15, right: 15},
      data: {
        x: 'date',
        columns: ratings,
        type: 'line',
        color: function (color, d) { return '#8c81d1'; }
      },
      point: {show: false},
      axis: {
        x: {
          show: true,
          type: 'timeseries'
        },
        y: {
          min: 0,
          max: 100,
          inner: true,
          padding: {bottom: 2},
          tick: {
            outer: false,
            values: [0, 20, 40, 60, 80, 100],
            format(v, id, i, j) { return v; }
          }
        }
      },
      regions: [
        {axis: 'y', start: 0, end: 20, class: 'bg-f-rating'},
        {axis: 'y', start: 20, end: 40, class: 'bg-d-rating'},
        {axis: 'y', start: 40, end: 60, class: 'bg-c-rating'},
        {axis: 'y', start: 60, end: 80, class: 'bg-b-rating'},
        {axis: 'y', start: 80, end: 100, class: 'bg-a-rating'}
      ],
      legend: {show: false},
      tooltip: {
        format: {},
        contents(d, defaultTitleFormat, defaultValueFormat, color) {
          return RatingsChartTooltip(chart, this, d, defaultTitleFormat, defaultValueFormat, 'rating', 2, color, letterGrades);
        }
      },
      grid: {
        y: {show: false},
        x: {show: false}
      }
    });

    // Set ratings metrics
    let high = FormatString(parseFloat(data.high), 'rating', 1, false, true, false, letterGrades);
    let low = FormatString(parseFloat(data.low), 'rating', 1, false, true, false, letterGrades);
    let avg = FormatString(parseFloat(data.avg), 'rating', 1, false, true, false, letterGrades);
    let change = FormatString(parseFloat(data.change), 'decimal', 2, true, true, false, letterGrades);
    $('#historicalImpactRatings .annual-high').empty().append(high);
    $('#historicalImpactRatings .annual-low').empty().append(low);
    $('#historicalImpactRatings .annual-avg').empty().append(avg);
    $('#historicalImpactRatings .annual-change').empty().append(change);

    // Append change to chart indicator
    if (parseFloat(data.change) !== 0) {
      $('#ratingTrend .chart-total-indicator').empty().append(change);
    }

    return chart;
  }

};
