import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';

export default class SettingsAccountsMethodologiesCausesNew {

  constructor() {}

  render() {
    let accountUrl = $('body').attr('data-account-url');
    let methodologyid = $('#sub_industries_search').attr('data-methodology-id');

    // Init bloodhounds
    filterTypeahead('#companies_search', 'companies', '/' + accountUrl + '/companies/search_share_classes?', 85, 'logo');
    filterTypeahead('#sub_industries_search', 'sub_industries', '/' + accountUrl + '/methodologies/' + methodologyid + '/sub_industries/search?', 274, 'generic');

    $('#companies_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      $('#hidden_company_id').val(suggestion.company_id);
    });
    $('#sub_industries_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      $('#hidden_sub_industry_id').val(suggestion.value);
    });
    
    // On selecting a fund as benchmark
    $('.clear-typeahead').click(function() {
      let t = $(this).closest('.form-group').find('.typeahead');
      t.typeahead('val', '');
      $(this).closest('.form-group').find('input[type=\'hidden\']').val('');
      t.focus();
    })


  }

}