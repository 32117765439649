// ==============================================================
// Change chart date (and update data shown)
// ==============================================================
import SetChartTotal from     'src/javascripts/components/charts/actions/SetChartTotal';
import SetAxisMin from        'src/javascripts/components/charts/axes/SetAxisMin';
import ResetChartAxis from    'src/javascripts/components/charts/axes/ResetChartAxis';
import GetReturnsAll from     'src/javascripts/components/charts/helpers/GetReturnsAll';
import BuildChartUrl from     'src/javascripts/components/charts/url/BuildChartUrl';
import Spinner from           'src/javascripts/components/utilities/Spinner';

export default function(chart) {

  // Get chart id
  const chartId = $(chart.element).attr('id');

  // On click of an 'update-chart-date' button (change date of data on chart)
  return $(chart.element).closest('.chart-section').find('.update-chart-date').off().click(function() {

    // Set selected date key
    const $selectedDate = $(this);

    // Set human name of selection
    const $selectedHuman = $(this).attr('data-human');

    // Set spinner
    Spinner($(chart.element));

    // Set rebalancing opt for updating URL below
    const $rebalancingOpt = $('#portfolioRebalanceOption').val();

    // Set the element used to control chart's from date
    const dateSelectionKey = $selectedDate.closest('.date-selection-group');

    // Set from date based on selection
    // Must set from date here; below actions use updated from date
    const fromDate = $selectedDate.attr('data-from-date');
    dateSelectionKey.attr('data-from-date', fromDate);
    $(chart.element).attr('data-from-date', fromDate);

    // Set records to get updated data for (with updated dates)
    // Add record displayed on chart
    const recordsToUpdate = [];
    let legendRecords = $(chart.element).attr('data-legend-records');
    if (typeof legendRecords !== 'undefined') {
      legendRecords = JSON.parse(legendRecords);
      let i = 0
      while (i < legendRecords.length) {
        let recordType = legendRecords[i][0];
        let recordId = legendRecords[i][1];
        let recordName = legendRecords[i][2];
        let record = [recordType, recordId, recordName]
        recordsToUpdate.push(record);
        i++;
      }
    }

    // Add display record if not already added from legend records
    // Sometimes legend present and sometimes not, so need to check here
    if (recordsToUpdate.length === 0) {
      let displayRecord = $(chart.element).attr('data-display-record');
      if (typeof displayRecord !== 'undefined') {
        displayRecord = JSON.parse(displayRecord);
        let recordType = displayRecord[0];
        let recordId = displayRecord[1];
        let recordName = displayRecord[2];
        let record = [recordType, recordId, recordName]
        recordsToUpdate.push(record);
      }
    }
    
    // Add other records from legend
    // Sometimes displayed record not in legend, so need to separate
    // Empty selectedRecords
    let selectedRecords = $(chart.element).closest('.chart-section').find('.legend-container').attr('data-selected-records');
    if (typeof selectedRecords !== 'undefined') {
      selectedRecords = JSON.parse(selectedRecords);
      let i = 0
      while (i < selectedRecords.length) {
        let recordType = selectedRecords[i]['recordType'];
        let recordId = selectedRecords[i]['recordId'];
        let recordName = selectedRecords[i]['recordName'];
        let record = [recordType, recordId, recordName]
        // Push unless already in array
        if (recordId !== parseInt(recordsToUpdate[1])) { recordsToUpdate.push(record); }
        i++;
      }
    }

    // Set record count, remove spinner when finished updating records
    let recordCount = recordsToUpdate.length;
    let i = 0;

    // Set data array to load on chart
    // Only load chart with new data once (rather than each time in loop below)
    let dataToLoad = [];

    // Update table returns attrs, if this is a returns chart and chart_table is present
    if ( $(chart.element).hasClass('returns-chart') && (typeof $('#returns_table') !== 'undefined') ) {

      // Set table
      let table = $('#returns_table').DataTable();

      // Set all columns
      let colIndex = $(this).attr('data-col');
      let allCols = $(this).closest('.date-selection-group').attr('data-all-cols');

      // Set visibility of all columns
      table.columns([allCols]).visible(false);
      table.column(colIndex).visible(true);

      // Set data-visible attr; required to maintain col vis when updating sidebar filters
      // https://datatables.net/reference/api/columns().header()
      table.columns(allCols).header().to$().attr('data-visible', 'false');
      $(table.column(colIndex).header()).attr('data-visible', 'true');

      // Update title
      $('.returns-title .timeframe').empty().append($selectedHuman);

      // Move hover container to end of row
      // Required because DataTables inserts the newly-visible columns after existing hover container
      $('.dt-hover-outer').each(function() { $(this).appendTo($(this).closest('tr')); });

    }

    // Update download link if present
    if ( $('.download-prices').length > 0 ) {
      let baseUrl = $('.download-prices').attr('href').split('?')[0];
      let newUrl = baseUrl + '?from_date=' + fromDate + '&rebalancing=' + $rebalancingOpt;
      $('.download-prices').attr('href', newUrl);
    }

    // Update data (with new date range) for each item in the legend and for each selected row
    return $.each(recordsToUpdate, function() {

      // Set url based on chart data attrs
      const url = BuildChartUrl(chartId, $(this));

      // Set selected record name (used below to update chart title and axis)
      const selectedRecordName = $(this)[2];

      // Remove prices data from chart (add for each record with updated date range below)
      if ($(chart.element).attr('data-include-prices') === 'true') {
        $(chart.element).data('prices', []);
      }

      // Get data
      return $.getJSON(url, function(data) {

        // Set dates as first array in returned data (used below)
        const dates = data[0];        

        // Change prices data to daily returns, if this is for returns
        if (url.indexOf('returns') >= 0) { 

          // Add prices to data array attached to chart element, if exists
          if ($(chart.element).attr('data-include-prices') === 'true') {
            $(chart.element).data('prices').push(data.slice(1)[0]);
          }

          if (!$(chart.element).hasClass('returns-dollar-chart')) {
            data = GetReturnsAll(dates, data); 
          }

        }

        // Add dates to array of data to be loaded (above before loop) if not already there
        let c1 = typeof dataToLoad[0] === 'undefined';
        let c2 = (typeof dataToLoad[0] !== 'undefined') && (dataToLoad[0][0] !== 'date');
        if ( (c1) || (c2) ) { dataToLoad.unshift(dates); }

        // Add actual data (second array from returned data, not dates) to array to be loaded
        // Only load data once (below, if this is the last record) for performance        
        dataToLoad.push(data[1])

        // Load new data, reset axis, set chart total and remove spinner if this is the last record
        if ((i + 1) === recordCount) { 

          // Show no data message if data not available
          // data[1] is the chart data; data[0] is dates array
          if ((data === null) || (typeof data[1] === 'undefined') || (data[1].length <= 1)) { 
            /// Remove spinner
            $(chart.element).find('.spinner-container').remove()
          }

          // Change active date selection button
          // Wait until after above check for no data, in case there's no data
          dateSelectionKey.find('.update-chart-date.active').removeClass('active');
          $selectedDate.addClass('active');

          // Set chart total indicator to match shown data item on chart
          $.when(chart.load({ columns: dataToLoad }))
            .then(ResetChartAxis(chart))
            .then( SetChartTotal(chart, chart.data.shown()[0].id) )
            .then( $(chart.element).find('.spinner-container').remove() );

        }

        return i++;

      })

    })

  })
};
