export default class AssessmentsScreens {

  constructor() {}

  render() {

    // Submit form on click (default Rails submit button doesn't work with fa icons)
    $('#update_assessment_form_submit').click(function() {
      $('#assessment_screen_joins_form').submit();
    });

    // Check for screen set active
    const checkActiveScreenSet = function(el) {
      let screen_ids = JSON.parse(el.attr('data-screen-ids'));
      let active = 0;
      $.each(screen_ids, function(index, sid) {
        let icon = $('.impact-assessment-icon[data-screen-id=\'' + sid + '\']');
        if ( (icon.hasClass('active')) && (active === index)) {
          el.addClass('active');
          el.css('opacity', 1.0);
          active += 1;
        } else {
          el.removeClass('active');
          el.css('opacity', 0.3);
        }
      });
    }
    $('.screen-set').each(function() { checkActiveScreenSet($(this)); });

    // Action when clicking on a card
    // Check the box when click a row or a checkbox in a row
    $('.impact-assessment-icon').on('click', function() {
      let hiddenCheckbox = $(this).find('input.hidden-ccid');
      let hiddenDestroy = $(this).find('input.hidden-destroy');

      // Check/uncheck the box and update val, depending on current state
      // Also update active class of card
      if (hiddenCheckbox[0].checked === false) {
        hiddenCheckbox.prop('checked', true);
        hiddenCheckbox.val(hiddenCheckbox.attr("data-impact-screen-id"));
        hiddenDestroy.val('false');
        $(this).addClass('active');        
        $(this).find('span.fa-layers').css('opacity', 1.0);

      } else if (hiddenCheckbox[0].checked === true) {
        hiddenCheckbox.prop('checked', false);
        hiddenCheckbox.val("");
        hiddenDestroy.val('true');
        $(this).removeClass('active');
        $(this).find('span.fa-layers').css('opacity', 0.3);
      }

      // Check/uncheck screen sets
      let screen_set_ids = JSON.parse($(this).attr('data-screen-set-ids'));
      $.each(screen_set_ids, function(index, sid) {
        let setEl = $('.screen-set[data-screen-set-id=\'' + sid + '\']');
        checkActiveScreenSet(setEl);
      })

      // Blur focus; required in touch to update active class
      $(this).blur();

    });

    // Select/deselect all related screens on select of set
    $('.screen-set').click(function() {
      let screen_ids = JSON.parse($(this).attr('data-screen-ids'));

      if ($(this).hasClass('active')) {
        $.each(screen_ids, function(index, sid) {
          let icon = $('.impact-assessment-icon[data-screen-id=\'' + sid + '\']');
          let hiddenCheckbox = icon.find('input.hidden-ccid');
          let hiddenDestroy = icon.find('input.hidden-destroy');
          hiddenCheckbox.prop('checked', false);
          hiddenCheckbox.val("");
          hiddenDestroy.val('true');
          icon.removeClass('active');
          icon.find('span.fa-layers').css('opacity', 0.3);
        });
        $(this).removeClass('active');
        $(this).css('opacity', 0.3);

      } else {
        $.each(screen_ids, function(index, sid) {
          let icon = $('.impact-assessment-icon[data-screen-id=\'' + sid + '\']');
          let hiddenCheckbox = icon.find('input.hidden-ccid');
          let hiddenDestroy = icon.find('input.hidden-destroy');
          hiddenCheckbox.prop('checked', true);
          hiddenCheckbox.val(hiddenCheckbox.attr("data-impact-screen-id"));
          hiddenDestroy.val('false');
          icon.addClass('active');
          icon.find('span.fa-layers').css('opacity', 1.0);
        });
        $(this).addClass('active');
        $(this).css('opacity', 1.0);
      }

      // Blur focus; required in touch to update active class
      $(this).blur();

    });

  }

}