export default function(tableId, settings) {

	// Set total records and page length
  let totalRecords = settings._iRecordsDisplay;
  let pageLength = settings._iDisplayLength;

  // Update tr class if no data available
  if (totalRecords === 0) {$('#' + tableId + ' tbody tr').addClass('no-click');}

	// Check record count, hide paging if records less than paging count
  if (totalRecords <= pageLength) {
    $('#' + tableId + '_paginate').addClass('d-none');
    $('#' + tableId + '_length').addClass('d-none');
    return $('#' + tableId + '_info').addClass('d-none');
  } else {
    $('#' + tableId + '_paginate').removeClass('d-none');
    $('#' + tableId + '_length').removeClass('d-none');
    return $('#' + tableId + '_info').removeClass('d-none');
  }

}