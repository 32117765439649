import Spinner from 'src/javascripts/components/utilities/Spinner';
import ImpactDistByAllFormulasChart from 'src/javascripts/components/charts/ImpactDistByAllFormulasChart';
import ToggleReturnsChart from 'src/javascripts/components/charts/actions/ToggleReturnsChart';
import StickySidebar from 'src/javascripts/components/nav/StickySidebar';
import ImpactDistMetricScores from 'src/javascripts/components/charts/ImpactDistMetricScores';
import ImpactDistControversies from 'src/javascripts/components/charts/ImpactDistControversies';
import ImpactByFormulaTable from 'src/javascripts/components/tables/ImpactByFormulaTable';
import PeerComparisonTable from 'src/javascripts/components/tables/PeerComparisonTable';
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import MetricScoresScatter from 'src/javascripts/components/charts/MetricScoresScatter';
import ExpandExcerpt from 'src/javascripts/components/utilities/ExpandExcerpt';
import ExpandContent from 'src/javascripts/components/utilities/ExpandContent';
import ReturnsChart from 'src/javascripts/components/charts/ReturnsChart';
import ReturnsDollarsChart from 'src/javascripts/components/charts/ReturnsDollarsChart';
import ImpactHistoricalRatingsChart from 'src/javascripts/components/charts/ImpactHistoricalRatingsChart';
import QuestionnaireResponseAnswersTable from 'src/javascripts/components/tables/QuestionnaireResponseAnswersTable';
import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import QuestionnaireResponsesTable from 'src/javascripts/components/tables/QuestionnaireResponsesTable';

export default class ResearchCompaniesShow {

  constructor() {}

  render() {
    const accountUrl = $('body').attr('data-account-url');

    // Expand excerpted statements on click
    ExpandExcerpt();

    // Expand/minimize profile details
    ExpandContent();

    // On show/hide of disclosure or overall impact modal; only if in display mod mode
    if ($('body').hasClass('modal-open')) {
      $('.detail-modal').on('show.bs.modal', function(e) {
        $('#companyProfileShow').css('opacity', 0.2);
        $('.banner-container').css('opacity', 0.05);
      }).on('hide.bs.modal', function(e) {
        $('#companyProfileShow').css('opacity', 1.0);
        $('.banner-container').css('opacity', 1.0);
      }).on('hidden.bs.modal', function(e) {
        $('body').addClass('modal-open');
      });
    }

    // On click on a screen
    $('.impact-screens-list tr').click(function() {
      let url = $(this).attr('data-url');
      if (typeof url !== 'undefined') {
        return $.ajax({
          type: "GET",
          dataType: "script",
          url: url + '.js'
        });
      }
    })

    // Metric scores distribution and table, only once click on data tab for first time
    $('a[href="#scorecardTab"]').on('shown.bs.tab', function (e) {

      // Render impact chart, if chart does not already have c3-data (already initialized)
      // Needed to prevent re-init when navigating away from page, before new body has loaded    
      if (typeof $('#impact_dist_by_all_formulas').data('c3-chart') === 'undefined') {
        let chart = new ImpactDistByAllFormulasChart( $('#impact_dist_by_all_formulas') );
        $.when( chart.getData() )
         .then( data => chart.render(data) );
      } else {
        let chart = $('#impact_dist_by_all_formulas').data('c3-chart');
        chart.flush();
      }

      // Impact by formula table
      if ($("#impact_by_formula_wrapper").length === 0) {
        let tableId = 'impact_by_formula';
        let table = new ImpactByFormulaTable($('#' + tableId));
        $.when( table.render(tableId) ).then( status => table.setUp(tableId) );
      }

      // Render peers chart, if chart does not already have c3-data (already initialized)
      if ($("#impact_details_peer_comparison_wrapper").length === 0) {
        let tableId = 'impact_details_peer_comparison';
        let table = new PeerComparisonTable( $('#' + tableId) );
        $.when( table.render(tableId) ).then( status => table.setUp(tableId) );
      }

      // Render impact historical ratings chart, if chart does not already have c3-data (already initialized)
      // Needed to prevent re-init when navigating away from page, before new body has loaded    
      if (typeof $('#impact_ratings_trend').data('c3-chart') === 'undefined') {
        let chart = new ImpactHistoricalRatingsChart( $('#impact_ratings_trend') );
        $.when( chart.getData() )
         .then( data => chart.render(data) );
      }

    });

    // Metric scores distribution and table, only once click on data tab for first time
    $('a[href="#riskScorecardTab"]').on('shown.bs.tab', function (e) {

      // Render risk topics chart if not already loaded
      if ( ($('#risk_topics').length !== 0) && (typeof $('#risk_topics').data('c3-chart') === 'undefined')) {
        let companyId = $('#risk_topics').attr('data-company-id');
        let url = "/" + accountUrl + "/companies/" + companyId + "/ratings/risk_topics?";
        let backPath = $('#risk_topics').attr('data-back-path');
        let params = {};
        params['back_path'] = backPath;
        Spinner($('#risk_topics').closest('.card'));
        $('#risk_topics').closest('.card-body').css('opacity', 0.25);
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url + $.param(params)
        });

        // Show material topics only
        $('.show-material-topics').click(function() {
          let companyId = $('#risk_topics').attr('data-company-id');
          let formulaId = $('#risk_topics').attr('data-formula-id'); 
          let url = "/" + accountUrl + "/companies/" + companyId + "/ratings/risk_topics?";
          let backPath = $('#risk_topics').attr('data-back-path');
          backPath = backPath + '?tab=riskScorecardTab';
          let params = {};
          params['back_path'] = backPath;
          params['material'] = "true";
          $('#risk_topics').empty();
          Spinner($('#risk_topics').closest('.card'));
          $('#risk_topics').closest('.card-body').css('opacity', 0.25);
          $('.show-material-topics').removeClass('btn-white').addClass('btn-primary-light');
          $('.show-all-topics').addClass('btn-white').removeClass('btn-primary-light');
          $.ajax({
            type: "GET",
            dataType: "script",
            url: url + $.param(params)
          });
        })

        // Show all topics
        $('.show-all-topics').click(function() {
          let companyId = $('#risk_topics').attr('data-company-id');
          let formulaId = $('#risk_topics').attr('data-formula-id'); 
          let url = "/" + accountUrl + "/companies/" + companyId + "/ratings/risk_topics?";
          let backPath = $('#risk_topics').attr('data-back-path');
          backPath = backPath + '?tab=riskScorecardTab';
          let params = {};
          params['back_path'] = backPath;
          params['material'] = "false";
          $('#risk_topics').empty();
          Spinner($('#risk_topics').closest('.card'));
          $('#risk_topics').closest('.card-body').css('opacity', 0.25);
          $('.show-all-topics').removeClass('btn-white').addClass('btn-primary-light');
          $('.show-material-topics').addClass('btn-white').removeClass('btn-primary-light');
          $.ajax({
            type: "GET",
            dataType: "script",
            url: url + $.param(params)
          });
        })
        
      };

      // Policies assessment, if present on page but not already loaded
      if (($("#policiesAssessment").length !== 0) && ($('#policiesAssessment .table').length === 0)) {
        let recordId = $("#policiesAssessment").attr('data-record-id');
        let url = "/" + accountUrl + "/companies/" + recordId + "/metric_scores/policies_assessment";
        Spinner($('#policiesAssessment'));
        $('#policiesAssessment').find('.card-body').css('opacity', 0.25);
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url + '.js'
        });

        // Remove ajax-loaded resources before Turbolinks cache; otherwise loads twice on back-button
        $(document).on("turbolinks:before-cache", function() {
          $('#policiesAssessment').find('.card-body').empty();
        })

      }

      // Risk categories visual, if present on page but not already loaded
      if (($('#riskCategoriesContainer').length > 0) && ($('.risk-category-card').length === 0)) {
        let companyId = $('#riskCategoriesContainer').attr('data-company-id');
        let url = "/" + accountUrl + "/companies/" + companyId + "/metric_scores/risk_categories";
        Spinner($('#riskCategoriesContainer'));

        // Load visual
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url + '.js'
        });

        // Remove ajax-loaded resources before Turbolinks cache; otherwise loads twice on back-button
        $(document).on("turbolinks:before-cache", function() {
          $('#riskCategoriesContainer').empty();
        })

      } 

    });

    // Metric scores distribution and table, only once click on data tab for first time
    $('a[href="#customMethodologyTab"]').on('shown.bs.tab', function (e) {

      // Custom methodology ratings
      $('#customMethodologyRatingsList').collapse('show');
      if ( typeof $('.custom-ratings-explorer') !== 'undefined' ) {
        let companyId = $('#customMethodologyRatingsList').attr('data-company-id');
        let url = "/" + accountUrl + "/companies/" + companyId + "/ratings/custom_methodology_scorecard";
        let params = {};
        Spinner($('#customMethodologyRatingsList'));
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url + $.param(params)
        });
      }

      // Show table
      $('#customMethodologyTableList').on('shown.bs.collapse', function (e) {    
        let companyId = $('#customMethodologyTableList').attr('data-company-id');
        let url = "/" + accountUrl + "/companies/" + companyId + "/ratings/custom_methodology_table";
        Spinner($('#customMethodologyTableList'));
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url
        });
      });

      // Show peers chart
      $('#customMethodologyPeersChart').on('shown.bs.collapse', function (e) {    
        let companyId = $('#customMethodologyPeersChart').attr('data-company-id');
        let url = "/" + accountUrl + "/companies/" + companyId + "/ratings/custom_methodology_peers_chart";
        Spinner($('#customMethodologyPeersChart'));
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url
        });
      });

      // Show peers 
      $('#customMethodologyPeersList').on('shown.bs.collapse', function (e) {    
        let companyId = $('#customMethodologyPeersList').attr('data-company-id');
        let url = "/" + accountUrl + "/companies/" + companyId + "/ratings/custom_methodology_peers";
        Spinner($('#customMethodologyPeersList'));
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url
        });
      });

    })

    // Metric scores distribution and table, only once click on data tab for first time
    $('a[href="#questionnairesTab"]').on('shown.bs.tab', function (e) {

      // Response answers
      $('#responseDataList').on('shown.bs.collapse', function (e) {    
        if ( $('#questionnaire_response_answer_trends_table_wrapper').length === 0 ) {
          let table = new QuestionnaireResponseAnswersTable($('#questionnaire_response_answer_trends_table'));
          $.when( table.render() ).then( status => table.setUp('questionnaire_response_answer_trends_table') )
        }
      });

      // Questionnaire responses table
      $('#companyResponsesList').collapse('show');
      if ( $('#questionnaire_responses_table_wrapper').length === 0 ) {
        let table = new QuestionnaireResponsesTable($('#questionnaire_responses_table'));
        $.when( table.render() ).then( status => table.setUp('questionnaire_responses_table') )
      }

    });

    // Metric scores distribution and table, only once click on data tab for first time
    $('a[href="#dataTab"]').on('shown.bs.tab', function (e) {

      // Search formulas 
      let formulasUrl = '/formulas/search?risk=all&include_non_system=true&';
      let formulasTypeaheadSize = $('.formulas-name-short-filter').length;
      if (formulasTypeaheadSize === 1) {
        filterTypeahead('.formulas-name-short-filter', 'formulas', formulasUrl, 279, 'cause');
      }

      // Search methodologies
      let methodologiesUrl = '/' + accountUrl + '/methodologies/search?';
      let methodologiesTypeaheadSize = $('.methodologies-filter').length;
      if (methodologiesTypeaheadSize === 1) {
        filterTypeahead('.methodologies-filter', 'methodologies', methodologiesUrl, 279, 'generic');
      }

      // On typeahead select, create a filter
      $('.formulas-name-short-filter').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {

        // Prevent default
        ev.preventDefault();

        $(this).blur();

        // Hide tooltip
        $('.tooltip').tooltip('hide');

        // Set value to symbol if a fund; otherwise value
        let val = suggestion.value;

        // Set params and reload
        let backPath = $('#search_formulas').attr('data-back-path');
        let url = window.location.href.split('?')[0];
        let params = {};
        params['formula_id'] = val;
        params['tab'] = 'dataTab';
        params['back_path'] = backPath;

        // Reload with params
        return Turbolinks.visit(url + '?' + $.param(params));

      });
      $('.clear-formulas-typeahead').click(function() {
        let t = $(this).closest('.input-group').find('.typeahead');
        t.typeahead('val', '');
        t.focus();
      });


      if (typeof $('#metric_scores_scatter').data('c3-chart') === 'undefined') {
        let chart = new MetricScoresScatter( $('#metric_scores_scatter') );
        $.when( chart.getData() )
         .then( data => chart.render(data) );
      }

      if (typeof $('#impact_dist_metric_scores').data('c3-chart') === 'undefined') {
        let chart = new ImpactDistMetricScores( $('#impact_dist_metric_scores') );
        $.when( chart.getData() )
         .then( data => chart.render(data) );
      }

      if ($('#metric_scores_table_wrapper').length === 0) {
        let tableId = 'metric_scores_table';
        let metricScoresTable = new DefaultTable($('#' + tableId));
        $.when( metricScoresTable.render(tableId) ).then( status => metricScoresTable.setUp(tableId) );
      }

      if (typeof $('#impact_dist_controversies').data('c3-chart') === 'undefined') {
        let chart = new ImpactDistControversies( $('#impact_dist_controversies') );
        $.when( chart.getData() )
         .then( data => chart.render(data) );
      }

      if ($('#controversies_table_wrapper').length === 0) {
        let tableId = 'controversies_table';
        let controversiesTable = new DefaultTable($('#' + tableId));
        $.when( controversiesTable.render(tableId) ).then( status => controversiesTable.setUp(tableId) );
      }

    });


    // Financial tab
    $('a[href="#financialTab"]').on('shown.bs.tab', function (e) {

      // Initialize a new chart instance for each chart on the page
      if (($('#returns_chart').attr('data-return-option') === 'percentage') && (typeof $('#returns_chart').data('c3-chart') === 'undefined')) {

        // Set chartId and chart object
        // Get data, render chart, set up chart, and create connected table, if exists
        let chart = new ReturnsChart($('#returns_chart'));
        $.when(chart.getData())
         .then( data => chart.render(data))
         .then( chartObj => chart.setUp(chartObj));

      } else if (($('#returns_chart').attr('data-return-option') === 'dollar') && (typeof $('#returns_chart').data('c3-chart') === 'undefined')) {

        // Set chartId and chart object
        // Get data, render chart, set up chart, and create connected table, if exists
        let chart = new ReturnsDollarsChart($('#returns_chart'));
        $.when(chart.getData())
         .then( data => chart.render(data))
         .then( chartObj => chart.setUp(chartObj));

      }

      // Toggle returns chart between hypothetical growth and percentage returns
      ToggleReturnsChart();

    });


    // Google map
    $('a[href="#aboutTab"]').on('show.bs.tab', function (e) {

      if ($('.business-address-map').length !== 0) {

        // Set id
        let mapId = $('.business-address-map').attr('id');
        
        // Add spinner to indicate loading map
        Spinner('#' + mapId);

        // Set Geocoder
        let geocoder = new google.maps.Geocoder();

        // Set address
        let address = $('#' + mapId).attr('data-address');

        // Geocode
        geocoder.geocode({ address: address }, function(results, status) {
          if (status === "OK") {

            // Create map
            let map = new google.maps.Map(document.getElementById(mapId), {
              zoom: 8,
              center: results[0].geometry.location,
              mapTypeControlOptions: { mapTypeIds: [] },
              streetViewControl: false,
            });

            // Create market
            var marker = new google.maps.Marker({
              map: map,
              position: results[0].geometry.location
            });

            // Remove spinner
            $('#' + mapId).find('.spinner-container').remove();

          } else {
            $('#' + mapId).empty().append("We weren't able to find the address for this company.");
          }

        });

      }
    })

    $('a[href="#responsesTab"]').on('show.bs.tab', function (e) {

      // Render table
      if ( $('#questionnaire_response_answers_table_wrapper').length === 0 ) {
        let table = new QuestionnaireResponseAnswersTable($('#questionnaire_response_answers_table'));
        $.when( table.render() ).then( status => table.setUp('questionnaire_response_answers_table') )
      }

    })

    // Show active tab
    let activeTab = $('#profileNavTabs').attr('data-active-tab');
    $('a[href="#' + activeTab + '"]').tab('show');


  }

}