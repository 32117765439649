import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
import {noResultsTemplate, emptyTemplate, genericDisplayTemplate, causeTemplate, logoTemplate, screenTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');
const accountUrl = $('body').attr('data-account-url');

// Screens typeahead
var screens = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: '/screens/search.json?query=%QUERY',
    wildcard: "%QUERY",
    cache: false
  }
});
screens.initialize();
export const screensTypeahead = function(exclude = null) {
  $('#screens_typeahead').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    source: screens,
    name: "name",
    display: "name",
    limit: 332,
    templates: {
      empty: emptyTemplate,
      suggestion(el) {
        if (el.name === null) {
          return noResultsTemplate();
        } else {
          return screenTemplate(el.name, el.fa_key);
        }
      }
    }
  });
}

// Screens typeahead - optimizer
var screensOptimizer = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: '/' + accountUrl +'/companies/screens/search.json?query=%QUERY&exclude=no_sbti',
    wildcard: "%QUERY"
  }
});
screensOptimizer.initialize();
export const screensTypeaheadOptimizer = function(exclude = null) {
  $('#screens_typeahead').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    source: screensOptimizer,
    name: "name",
    display: "name",
    limit: 332,
    templates: {
      empty: emptyTemplate,
      suggestion(el) {
        if (el.name === null) {
          return noResultsTemplate();
        } else {
          return screenTemplate(el.name, el.fa_key);
        }
      }
    }
  });
}

// Screens typeahead
var screensLite = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: '/' + accountUrl +'/companies/screens/search.json?query=%QUERY',
    wildcard: "%QUERY",
    cache: false
  }
});
screens.initialize();
export const screensLiteTypeahead = function() {
  $('#screens_lite_typeahead').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    source: screensLite,
    name: "name",
    display: "name",
    limit: 332,
    templates: {
      empty: emptyTemplate,
      suggestion(el) {
        if (el.name === null) {
          return noResultsTemplate();
        } else {
          return screenTemplate(el.name, el.fa_key);
        }
      }
    }
  });
}

// Screens lite typeahead - optimizer
var screensLiteOptimizer = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: '/' + accountUrl +'/companies/screens/search.json?query=%QUERY&exclude=no_sbti',
    wildcard: "%QUERY"
  }
});
screensLiteOptimizer.initialize();
export const screensLiteTypeaheadOptimizer = function() {
  $('#screens_lite_typeahead').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    source: screensLiteOptimizer,
    name: "name",
    display: "name",
    limit: 332,
    templates: {
      empty: emptyTemplate,
      suggestion(el) {
        if (el.name === null) {
          return noResultsTemplate();
        } else {
          return screenTemplate(el.name, el.fa_key);
        }
      }
    }
  });
}

var screenSets = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: '/' + accountUrl +'/companies/screen_sets/search.json?query=%QUERY',
    wildcard: "%QUERY"
  }
});
screenSets.initialize();

// Screens typeahead
export const screenSetsTypeahead = function() {
  $('#screen_sets_typeahead').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    source: screenSets,
    name: "name",
    display: "name",
    limit: 332,
    templates: {
      empty: emptyTemplate,
      suggestion(el) {
        if (el.name === null) {
          return noResultsTemplate();
        } else {
          return screenTemplate(el.name, el.fa_key);
        }
      }
    }
  });
}


// Company bloodhounds
export const companiesBloodhound = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: '/research?query=%QUERY',
    wildcard: "%QUERY",
    transform: function(d) { return d.companies; }
  }
});

// Fund bloodhounds
export const fundsBloodhound = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: '/research?query=%QUERY',
    wildcard: "%QUERY",
    transform: function(d) { return d.funds; }
  }
});

// Initiate filter typeaheads
export const filterTypeahead = function(klass, name, url, limit, template) {
  let urlQuery;
  if (url[url.length - 1] !== '?') {
    urlQuery = url + '&query='
  } else {
    urlQuery = url + 'query='    
  }
  let bloodhound = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: urlQuery + '%QUERY',
      wildcard: "%QUERY"
    }});
  bloodhound.initialize();
  $(klass).typeahead({
    hint: true,
    highlight: true,
    minLength: 0,
  }, {
    name: name,
    display: 'name',
    source: bloodhound,
    limit: limit,
    templates: {
      empty: emptyTemplate,
      suggestion(el) {
        if (el.value === null) {
          return noResultsTemplate();
        } else {
          if (template === 'generic') {
            if (typeof el.name !== 'undefined') {
              return genericDisplayTemplate(el.name);
            } else {
              return genericDisplayTemplate(el.value);
            }
          } else if (template === 'cause') {
            return causeTemplate(el.name, el.name_short, el.fa_key, el.value);
          } else if (template === 'logo') {
            return logoTemplate(el.name, el.symbol, el.logo_url, el.value);              
          }
        }
      },
    }
  });
} 


// Initiate filter typeaheads with no cache
export const filterTypeaheadNoCache = function(klass, name, url, limit, template) {
  let urlQuery;
  if (url[url.length - 1] !== '?') {
    urlQuery = url + '&query='
  } else {
    urlQuery = url + 'query='    
  }
  let bloodhound = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: urlQuery + '%QUERY',
      wildcard: "%QUERY",
      cache: false
    }});
  bloodhound.initialize();
  $(klass).typeahead({
    hint: true,
    highlight: true,
    minLength: 0,
  }, {
    name: name,
    display: 'name',
    source: bloodhound,
    limit: limit,
    templates: {
      empty: emptyTemplate,
      suggestion(el) {
        if (el.value === null) {
          return noResultsTemplate();
        } else {
          if (template === 'generic') {
            if (typeof el.name !== 'undefined') {
              return genericDisplayTemplate(el.name);
            } else {
              return genericDisplayTemplate(el.value);
            }
          } else if (template === 'cause') {
            return causeTemplate(el.name, el.name_short, el.fa_key, el.value);
          } else if (template === 'logo') {
            return logoTemplate(el.name, el.symbol, el.logo_url, el.value);              
          }
        }
      },
    }
  });
} 

