export default function() {

  $('.card-list .collapse').on('show.bs.collapse', function(e) {
    if (e.target == this){
      // Set section
      let $section = $(this).closest('.card-list');
      // Toggle collapse item on 
      $section.find('.toggleable').toggleClass('d-none');
      // Toggle section background
      $section.addClass('active');
      // Reflow chart if present
      let $chart = $(this).find('.chart').data('c3-chart');
      if ( typeof $chart !== 'undefined' ) {
        setTimeout(function() {
          $chart.flush();
        }, 100);
      }
    }
  });


  $('.card-list .collapse').on('hide.bs.collapse', function(e) {
    if (e.target == this){
      // Set section
      let $section = $(this).closest('.card-list');
      // Toggle collapse item on 
      $section.find('.toggleable').toggleClass('d-none')
      // Toggle section background
      $section.removeClass('active');
    }
  });
}
