// Get upcoming invoice data
import Spinner from 'src/javascripts/components/utilities/Spinner';

export default function() {

  // Set ethos account id
  let accountUrl = $('.get-upcoming-invoice').attr('data-account-url');
  let url = '/marketplace/' + accountUrl + '/stripe/invoices/next';

  // Set a spinner to indicate data loading
  Spinner( $('.get-upcoming-invoice') );

  // Get data with ajax call
  // Must specify '.js' otherwise processes as JSON
  $.ajax({
    type: "GET",
    dataType: "script",
    url: url + '.js?'
  });

}