import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';

export default class PortfoliosItemsRecommendationsIndex {

  constructor() {}

  render() {

    // If tag present
    let tag = $('#profileTabContent').attr('data-tag');
    if ((typeof tag !== 'undefined') && (tag.length > 0)) {
      let toExpand = $('#' + tag).find('.collapse');
      toExpand.collapse('show');
      $('.collapse').not(toExpand).collapse('hide');
      ScrollToTag(tag);  
    }

    // Render top table
    $('#recommendationsList').on('shown.bs.collapse', function (e) {
      if ( $('#recommendations_table_wrapper').length === 0 ) {
        let table = new DefaultTable($('#recommendations_table'));
        $.when( table.render() ).then( status => table.setUp('recommendations_table') )
      }
    })

    // Render top table
    $('#recommendationSummaries').on('shown.bs.collapse', function (e) {
      if ( $('#recommendation_summaries_table_wrapper').length === 0 ) {
        let table = new DefaultTable($('#recommendation_summaries_table'));
        $.when( table.render() ).then( status => table.setUp('recommendation_summaries_table') )
      }
    })

    // Render top table
    $('#recommendationSummaryModules').on('shown.bs.collapse', function (e) {
      if ( $('#recommendation_summary_modules_table_wrapper').length === 0 ) {
        let table = new DefaultTable($('#recommendation_summary_modules_table'));
        $.when( table.render() ).then( status => table.setUp('recommendation_summary_modules_table') )
      }
    })

    // Render top table
    $('#recommendationActionPlans').on('shown.bs.collapse', function (e) {
      if ( $('#recommendation_action_plans_table_wrapper').length === 0 ) {
        let table = new DefaultTable($('#recommendation_action_plans_table'));
        $.when( table.render() ).then( status => table.setUp('recommendation_action_plans_table') )
      }
    })

    // Vantage ACA company dashboards
    if ($('#portcoDashboards').length > 0) { 

      // Set a spinner to indicate chart loading
      Spinner('#portcoDashboards').closest(".card-list");
      $('#portcoDashboards').css('opacity', 0.25);

      // Set url
      let dataUrl = $('#portcoDashboards').attr('data-url');

      // Set empty hash to build params
      let params = {};
      let token = $('#portcoDashboards').attr('data-report-token');
      if (typeof token !== "undefined") {params['token'] = token}

      // Execute ajax request (using js erb template to render content so can control profile styling more easily)
      // Must specify '.js' otherwise processes as JSON
      return $.ajax({
        type: "GET",
        dataType: "script",
        url: dataUrl + '.js?' + $.param(params)
      });

    }


  }

}