import FilePicker from 'src/javascripts/components/forms/FilePicker';

export default class ClientsImportsNew {

  constructor() {}

  render() {

    // Upload file
    FilePicker();

    // Update on click
    $('.submit-button').click(function(e) {
      e.preventDefault();
      let accountUrl = $(this).attr('data-account-url');
      let url = '/' + accountUrl + '/clients/imports';
      let formData = {}
      formData['file'] = {}
      formData['file']['key'] = $(this).attr('data-key');

      // Update local attribute
      return $.ajax({
        type: 'POST',
        url: url,
        dataType: "application/json",
        data: formData,
        complete(result) { 
          let url = window.location.href.split('?')[0];
          if (result.status === 200) {
            url = '/' + accountUrl + '/clients?notice=Your import has started. You will receive a notice by email when it has finished (usually within 1 minute)';
          } else {
            url = '/' + accountUrl + '/clients?notice=There was a problem, please contact us for support';
          }
          return Turbolinks.visit(url);
        },
      });


    })


  }

}