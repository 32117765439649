import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import AddRowDataAttrs from 'src/javascripts/components/tables/helpers/AddRowDataAttrs';
import AddRowHoverButtons from 'src/javascripts/components/tables/helpers/AddRowHoverButtons';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';
import PortfolioAdverseIndicatorsTrend from 'src/javascripts/components/charts/PortfolioAdverseIndicatorsTrend';

export default class PortfolioAdverseIndicatorsTable extends Table {

  render() {

    // Set additional data vars (used below in table creation)
    let cols = GetCols(this.el);

    // Build url, use to get filtered data with server-side processing
    let dataUrl = this.el.attr('data-api-url');
    let tableId = this.el.attr('id');

    // Render table
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      retrieve: true,
      serverSide: true,
      ajax: dataUrl,
      paging: true,
      pageLength: 10,
      searching: true,
      order: [[0, 'asc']],
      dom: 'tp',
      columns: cols,
      responsive: { details: false },
      language: { 
        search: "_INPUT_",
        searchPlaceholder: "Search...",
        lengthMenu: "Show _MENU_",
        infoEmpty: "No indicators found. Reach out to us with any questions.",
        emptyTable: "No indicators found. Reach out to us with any questions.",
        zeroRecords: "No indicators found. Reach out to us with any questions."
      },
      createdRow(row, data, dataIndex) {
        
        if (typeof data.hover_links !== 'undefined') { 

          // Add data attrs to row
          AddRowDataAttrs(row, data);

          // Add hover button links to rows, if passed in data
          AddRowHoverButtons(row, data.hover_links); 

          // No click on row
          $(row).addClass('no-click');

        } else {
          $(row).addClass('no-click');
        }

      },

      drawCallback: function( settings ) {
        // Set chart id
        let chartId = $('#' + tableId).closest('.card-body').find('.chart').attr('id');

        // Update chart view on click
        $('.set-indicator-trend').off().click(function() {
          let selectedSfdrId = $(this).closest('tr').attr('data-record-id');
          $('#' + chartId).attr('data-sfdr-indicator-id', selectedSfdrId);
          let chart = $('#' + chartId).data('c3-chart');
          let chartUrl = $('#' + chartId).attr('data-api-url');
          let params = {};
          params['sfdr_indicator_id'] = selectedSfdrId;
          chartUrl = chartUrl + '?' + $.param(params);

          // Update chart
          $('#' + chartId).closest('.chart-container').css('opacity', 0);
          $('#' + chartId).closest('.card-body').addClass('opaque');
          Spinner($('#' + chartId).closest('.card'));
          chart = chart.destroy();
          chart = new PortfolioAdverseIndicatorsTrend( $('#' + chartId) );
          $.when( chart.getData() )
           .then( data => chart.render(data) );

        });

      },

      initComplete: function( settings ) {

        // initComplete runs once table has been fully drawn, with ajax async
        // https://datatables.net/reference/option/initComplete
        // Remove spinner after table has drawn, IF chart is already present
        let chartId = $('#' + tableId).closest('.card-body').find('.chart').attr('id');
        let chart = $('#' + chartId).data('c3-chart');
        if (typeof chart !== 'undefined') {
          let card = $('#' + tableId).closest('.card');
          $(card).find('.spinner-container').remove();
          card.find('.card-body').removeClass('opaque');
        }

      }
    });

    return 'rendered';
  }

  setUp(tableId) {

    // Set on click action for table rows, if request url present
    if ( typeof $('#' + tableId).attr('data-request-url') != 'undefined' ) {

      // Set table
      let table = $('#' + tableId)

      // Set click areas
      $(table).on('click', 'tbody > tr > td', function() { 

        // Get request url from table data attribute
        let url = $(this).closest('.table').attr('data-request-url');

        // Replace url record id with data from row
        let row = $(this).closest('tr');
        if (url.includes(":record_id")) {
          let recordId = row.attr('data-record-id');
          url = url.replace(':record_id', recordId);
        }

        // Replace url record type with data from row
        if (url.includes(":record_type")) {
          let recordType = row.attr('data-record-type');
          url = url.replace(':record_type', recordType);
        }

        return Turbolinks.visit(url);

      });

    }

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Set searchable function for table
    $('#search_' + tableId).keyup(function(){
      oTable.search( $(this).val() ).draw();
    });

    // Ensure search input starts blank
    $('#search_' + tableId).val('');

    // // Filter to ESG causes on click
    // $('.esg-button').click(function() {

    //   // Set ESG categories
    //   let selectedEsgCategory = $(this).attr('data-esg-category');
    //   let currentEsgCategory = $('#' + tableId).attr('data-esg-category');

    //   // Set chart vars
    //   let chartUrl = $('#impact_dist_metric_scores').attr('data-api-url');
    //   let params = {};
    //   params['formula_id'] = $('#impact_dist_metric_scores').attr('data-formula-id');
    //   let letterGrades = $('#impact_dist_metric_scores').attr('data-letter-grades');
    //   if (typeof letterGrades !== 'undefined') {
    //     params['letter_grades'] = letterGrades;
    //   }
    //   if (selectedEsgCategory === currentEsgCategory) {
    //     params['esg_category'] = 'all';
    //   } else {
    //     params['esg_category'] = selectedEsgCategory;
    //   }
    //   chartUrl = chartUrl + '?' + $.param(params);
    //   let chart = $('#impact_dist_metric_scores').data('c3-chart');

    //   // Set table vars
    //   params = {};
    //   params['scope_id'] = $('#' + tableId).attr('data-api-scope-id');
    //   params['scope_type'] = $('#' + tableId).attr('data-api-scope-type');
    //   params['formula_id'] = $('#' + tableId).attr('data-api-formula-id');
    //   if (selectedEsgCategory === currentEsgCategory) {
    //     params['esg_category'] = 'all';
      
    //     // Switch button colors and reset table esg category
    //     $(this).removeClass('btn-primary-light border font-weight-normal active').addClass('btn-white');
    //     $('#' + tableId).attr('data-esg-category', 'all');

    //   } else {
    //     params['esg_category'] = selectedEsgCategory;
      
    //     // Switch button colors and set ESG category
    //     $('.esg-button.active').removeClass('btn-primary-light border font-weight-normal active').addClass('btn-white');
    //     $(this).removeClass('btn-white').addClass('btn-primary-light border font-weight-normal active');
    //     $('#' + tableId).attr('data-esg-category', selectedEsgCategory);

    //   }

    //   // Set table Api url
    //   let tableUrl = $('#' + tableId).attr('data-api-url') + '?' + $.param(params);

    //   // Hide tooltip
    //   $(this).tooltip('hide');

    //   // Reset table 
    //   oTable.ajax.url( tableUrl ).load()      
    //   // oTable.search( '' ).columns().search( '' ).draw();

    //   // Update chart
    //   $('#impact_dist_metric_scores').closest('.chart-container').css('opacity', 0);
    //   $('#impact_dist_metric_scores').closest('.card-body').addClass('opaque');
    //   Spinner($('#impact_dist_metric_scores').closest('.card'));
    //   $.when( $.getJSON(chartUrl) ).then(data =>         
    //     chart.unload({
    //       done: function() {
    //         chart.load({
    //           json: data, 
    //           keys: {x: 'name', value: [ 'value' ]}
    //         });

    //         // Remove spinner after table has drawn
    //         let card = $('#' + tableId).closest('.card');
    //         $(card).find('.spinner-container').remove();
    //         card.find('.card-body').removeClass('opaque');
    //         setTimeout(function() {
    //           $('#impact_dist_metric_scores').closest('.chart-container').css('opacity', 1);
    //         }, 500);

    //       }
    //     })
    //   );

    // });

    return 'setup';
  }


}
