// Toggle returns chart
import ReturnsChart from 'src/javascripts/components/charts/ReturnsChart';
import ReturnsDollarsChart from 'src/javascripts/components/charts/ReturnsDollarsChart';

export default function() {

  // Switch on click
  $('.toggle-returns-chart').click(function() {

    // Set option
    let returnOption = $(this).attr('data-return-option');
    if ( (returnOption === "dollar") && ($('#returns_chart').attr('data-return-option') !== 'dollar') ) {

      // Set current c3 returns chart
      let currentChart = $('#returns_chart').data('c3-chart');
      currentChart = currentChart.destroy();
      $('#returns_chart').closest('.chart-container').find('.legend').empty();
      $('.returns-title-growth').removeClass('d-none');
      $('.returns-title-performance').addClass('d-none');

      // Update element attrs
      $('#returns_chart').attr('data-tick-returns-dollar', 'true');
      $('#returns_chart').attr('data-return-option', 'dollar');
      $('#returns_chart').addClass('returns-dollar-chart');
      $('.toggle-returns-chart[data-return-option=\'dollar\']').removeClass('btn-light').addClass('btn-primary-light');
      $('.toggle-returns-chart[data-return-option=\'percentage\']').removeClass('btn-primary-light').addClass('btn-light');

      // Set chartId and chart object
      // Get data, render chart, set up chart, and create connected table, if exists
      let chart = new ReturnsDollarsChart($('#returns_chart'));
      $.when(chart.getData())
       .then( data => chart.render(data))
       .then( chartObj => chart.setUp(chartObj));

    } else if ( (returnOption === "percentage") && ($('#returns_chart').attr('data-return-option') !== 'percentage') ) {

      // Set current c3 returns chart
      let currentChart = $('#returns_chart').data('c3-chart');
      currentChart = currentChart.destroy();
      $('#returns_chart').closest('.chart-container').find('.legend').empty();
      $('#returns_chart').removeClass('returns-dollar-chart');
      $('.returns-title-growth').addClass('d-none');
      $('.returns-title-performance').removeClass('d-none');
      $('.toggle-returns-chart[data-return-option=\'percentage\']').removeClass('btn-light').addClass('btn-primary-light');
      $('.toggle-returns-chart[data-return-option=\'dollar\']').removeClass('btn-primary-light').addClass('btn-light');

      // Update element attrs
      $('#returns_chart').attr('data-tick-returns-dollar', 'false');
      $('#returns_chart').attr('data-return-option', 'percentage');

      // Set chartId and chart object
      // Get data, render chart, set up chart, and create connected table, if exists
      let chart = new ReturnsChart($('#returns_chart'));
      $.when(chart.getData())
       .then( data => chart.render(data))
       .then( chartObj => chart.setUp(chartObj));

    }

  });

}