import Spinner from 'src/javascripts/components/utilities/Spinner';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');


export default function() {

  // Set vars
	let accountUrl = $('body').attr('data-account-url');
	let questionnaireId = $('#included_questions_search').attr('data-questionnaire-id');
	let eqUrl = "/" + accountUrl + "/questionnaires/" + questionnaireId + "/included/questions/search?";

	// Collapse/expand sections
  $('.card-list-items.collapse').on('show.bs.collapse', function () {
    // Set section
    let $section = $(this).closest('.card-list');
    // Toggle collapse item on 
    $section.find('.toggleable').toggleClass('d-none');
    // Toggle section background
    $section.addClass('active');
  });
  $('.card-list-items.collapse').on('hide.bs.collapse', function () {
    // Set section
    let $section = $(this).closest('.card-list');
    // Toggle collapse item on 
    $section.find('.toggleable').toggleClass('d-none')
    // Toggle section background
    $section.removeClass('active');
  });

  // Display question results
  const questionDisplayAllTemplate = (question_text, metric, category, id) => '<div class=\'card m-2 clickable bg-gray100 question-search-option\' data-question-text=\'' + question_text + '\' data-question-id=\'' + id + '\'>' +
    '<div class=\'card-body\'><div class="mb-2"><span class=\'font-weight-bold\'>Question</span>: ' + question_text + '</div>' +
    '<div class="mb-2"><span class=\'font-weight-bold\'>Metric</span>: ' + metric + '</div>' +
    '<div><span class=\'font-weight-bold\'>Category</span>: ' + category + '</div>' +
    '</div></div>';

  // Questions search
  let questionsBloodhound = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: '/' + accountUrl + '/questionnaires/questions/search?query=%QUERY',
      wildcard: "%QUERY",
      cache: false
    }});
  questionsBloodhound.initialize();
  $('#questions_search').typeahead({
    hint: true,
    highlight: true,
    minLength: 0,
    classNames: {
      menu: 'd-none' // add class name to menu so default dropdown does not show
    }
  }, {
    name: 'questions',
    display: 'name',
    limit: 351,
    source: questionsBloodhound.ttAdapter(),
    menu: '#questionsSearchResults'
  });

  $('#questions_search').on('typeahead:render', function (e, datum) {
    $('#questionsSearchResults').empty();
    $('#questionsSearchResults').css('height', '450px');
  	Spinner($('#questionsSearchResults'));
    var suggestions = Array.prototype.slice.call(arguments, 1);
    if (suggestions.length) {

      //empty suggestion div to display results
      for (var c = 0; c < suggestions.length; c++) {

        // Set template to render
        let template;
      	template = questionDisplayAllTemplate(suggestions[c].name, suggestions[c].metric, suggestions[c].category, suggestions[c].value);

        // Append to companies search list
        $('#questionsSearchResults').append(template);

      }

	    // Remove spinner
	    $('#questionsSearchResults').find('.spinner-container').remove();

    } else {

      // Remove spinner
      $('#questionsSearchResults').find('.spinner-container').remove();

      // Append to companies search list
      $('#questionsSearchResults').append("<div class=\'h5 mb-0 font-weight-light mt-2\'>No questions found</div>");

    }

	  // Update selected on click 
	  $('.question-search-option').click(function(ev) {
	    let qid = $(this).attr('data-question-id');
	    let qText = $(this).attr('data-question-text');
	    $('#question_id').val(qid);
	    $('#questions_search').val(qText);
	    $('#questionsSearchResults').empty();
	    $('#questionsSearchResults').css('height', '0px');
	    $('.submit-enabled').removeClass('d-none');
	    $('.submit-disabled').addClass('d-none');
	  });

  });
  
  // On clear typeahead
  $('.clear-typeahead').click(function() {
    $('#question_id').val("");
    $('#questions_search').typeahead("val", "");
    $('#questions_search').focus();
    $('.submit-enabled').addClass('d-none');
    $('.submit-disabled').removeClass('d-none');
  })

  // Submit form on click
  $('.submit-enabled').click(function() {
  	$('#included_question_form').submit();
  })

  // Focus questions
  $('#selectExistingList').on('shown.bs.collapse', function() {
  	$('#questions_search').focus();
  })

  // Create a filter on select
  filterTypeahead('#metrics_search', 'metrics', '/metrics/search?', 576, 'cause');
  $('#metrics_search').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
    $('#metric_id').val(suggestion.value);
  });

  // On clear typeahead
  $('.clear-metric-typeahead').click(function() {
    $('#metric_id').val("");
    $('#metrics_search').typeahead("val", "");
    $('#metrics_search').focus();
  })

	$('.question-type-card').click(function() {
		let qTypeId = $(this).attr('data-question-type-id');
		let qTypeName = $(this).attr('data-question-type-name');
		$(this).closest('.form-group').find('input[type=\'hidden\']').val(qTypeId);
		$('.question-type-card .card-body.bg-primary-light').removeClass('bg-primary-light');
		$(this).find('.card-body').addClass('bg-primary-light');

		// Opacity and spinner of modal
		let modal = $(this).closest('.modal');
		modal.find('.modal-body').css('opacity', 0.25);
		Spinner(modal);

		// If dropdown, unhide dropdown area
		if (qTypeName == "dropdown") {
			$('.dropdown-options-section').removeClass('d-none');
		} else {
			$('.dropdown-options-section').addClass('d-none');
		}

		// If numeric, unhide high-low value area
		let numericArray = ['dropdown', 'percent', 'integer', 'decimal'];
		if (jQuery.inArray(qTypeName, numericArray) !== -1) {
			$('.high-low-value').removeClass('d-none');
		} else {
			$('.high-low-value').addClass('d-none');
		}

		setTimeout(function() {
			modal.find('.modal-body').css('opacity', 1);
			modal.find('.spinner-container').remove();
		}, 1000)

	})

	$('.question-options-source-card').click(function() {
		let optionsSource = $(this).attr('data-options-source');
		$(this).closest('.form-group').find('input[type=\'hidden\']').val(optionsSource);
		$('.question-options-source-card .card-body.bg-primary-light').removeClass('bg-primary-light');
		$(this).find('.card-body').addClass('bg-primary-light');

		// Opacity and spinner of modal
		let modal = $(this).closest('.modal');
		modal.find('.modal-body').css('opacity', 0.25);
		Spinner(modal);

		// If custom, unhide
		if (optionsSource == "custom") {
			$('.custom-options').removeClass('d-none');
		} else {
			$('.custom-options').addClass('d-none');
		}

		setTimeout(function() {
			modal.find('.modal-body').css('opacity', 1);
			modal.find('.spinner-container').remove();
		}, 1000)

	})

  // Filter typeaheads
  filterTypeahead('#included_questions_search', 'included_questions', eqUrl, 446, 'generic');
  filterTypeahead('#calc_included_questions_search', 'included_questions', eqUrl, 446, 'generic');
  filterTypeahead('.validating-questions-search', 'included_questions', eqUrl, 446, 'generic');

  // Create a filter on select
  $('#included_questions_search').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
    $('#determining_included_question_id').val(suggestion.value);
    let hiddenDisplay = $('#included_question_display');
    let hiddenDestroy = $('input.hidden-destroy');
    hiddenDestroy.val('false');
    hiddenDisplay.val('false');
  });

  // Create a filter on select
  $('#calc_included_questions_search').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
    $('#calc_determining_included_question_id').val(suggestion.value);
    let hiddenDestroy = $('input.calc-hidden-destroy');
    hiddenDestroy.val('false');
  });

  $('.clear-included-question-typeahead').click(function() {
    $('#determining_included_question_id').val("");
    $('#included_questions_search').typeahead("val", "");
    $('#included_questions_search').focus();
    let hiddenDisplay = $('#included_question_display');
    let hiddenDestroy = $('input.hidden-destroy');
    hiddenDestroy.val('true');
    hiddenDisplay.val('true');
  })

  // Show questions typeahead on select
  $('#questionnaire_included_question_conditional').change(function() {
  	let val = $(this).val();
  	if (val === 'true') {
  		$('.conditional-section').removeClass('d-none');
  	} else {
  		$('.conditional-section').addClass('d-none');
	    $('#determining_included_question_id').val("");
	    $('.field-value ').val("");
	    $('#included_questions_search').typeahead("val", "");
	    $('#included_questions_search').focus();
      let hiddenDisplay = $('#included_question_display');
      let hiddenDestroy = $('input.hidden-destroy');
      hiddenDestroy.val('true');
    	hiddenDisplay.val('true');
  	}
  });

  // Show included questions typeahead on select
  $('#questionnaire_included_question_calculated').change(function() {
    let val = $(this).val();
    if (val === 'true') {
      $('.calculated-section').removeClass('d-none');
    } else {
      $('.calculated-section').addClass('d-none');
      $('#calc_determining_included_question_id').val("");
      $('.question-calc-field ').val("");
      $('#calc_included_questions_search').typeahead("val", "");
      $('#calc_included_questions_search').focus();
      let hiddenDestroy = $('input.calc-hidden-destroy');
      hiddenDestroy.val('true');
    }
  });

  $('.clear-calc-included-question-typeahead').click(function() {
    $('#calc_determining_included_question_id').val("");
    $('#calc_included_questions_search').typeahead("val", "");
    $('#calc_included_questions_search').focus();
    let hiddenDestroy = $('input.calc-hidden-destroy');
    hiddenDestroy.val('true');
  })

  // Remove a calculated value
  $('.remove-calculated-value').click(function() {
    let len = $('.calculated-value-row').length;
    if (len > 1) {
      $(this).closest('.calculated-value-row').remove();
    }
  })

  // Add a calculated value
  $('.add-calculated-value').click(function() {
    let len = $('.calculated-value-row').length;
    let firstV = $('.calculated-value-row')[0];
    let newV = $(firstV).clone().appendTo('.calculated-values-container');
    newV.find('.calc-hidden-value-id').remove();
    newV.find('.question-calc-key').val('');
    newV.find('.question-calc-key').attr('name', 'questionnaire_included_question[dependent_calculations_attributes][0][values_attributes][' + len + '][key]');
    newV.find('.question-calc-key').attr('id', 'questionnaire_included_question_dependent_calculations_attributes_0_values_attributes_' + len + '_key');
    newV.find('.question-calc-value').val('');
    newV.find('.question-calc-value').attr('name', 'questionnaire_included_question[dependent_calculations_attributes][0][values_attributes][' + len + '][value]');
    newV.find('.question-calc-valueey').attr('id', 'questionnaire_included_question_dependent_calculations_attributes_0_values_attributes_' + len + '_value');
  })


  // Validations
  // Create a filter on select
  $('.validating-questions-search').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
    let col = $(this).closest('.col')
    col.find('input[type=\'hidden\']').val(suggestion.value);
    let hiddenDestroy = $(this).closest('.validation-row').find('input.hidden-destroy-validation');
    hiddenDestroy.val('false');
  });

  $('.validation-field').change(function() {
    let hiddenDestroy = $(this).closest('.validation-row').find('input.hidden-destroy-validation');
    hiddenDestroy.val('false');
  });

  // Show questions typeahead on select
  $('#has_validations').change(function() {
  	let val = $(this).val();
  	if (val === 'Yes') {
  		$('.validations-section').removeClass('d-none');
  	} else {
  		$('.validations-section').addClass('d-none');
	    $('.validating-included-question-id').val("");
	    $('.validating-questions-search').typeahead("val", "");
	    $('.validating-questions-search').focus();
      let hiddenDestroy = $('input.hidden-destroy');
      hiddenDestroy.val('true');
  	}
  });

  // Destroy on click
  $('.remove-validation-x').click(function() {
  	$(this).closest('.validation-row').find('input[type=\'hidden\']').val('true');
  	$(this).closest('.validation-row').addClass('d-none');
  })

}