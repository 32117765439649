import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

export default class AssessmentsIndex {

  constructor() {}

  render() {

    $('.copy-branded-assessment-link').click(function() {

      /* Get the text field */
      let selector = $(this).attr('data-selector');
      let copyText = document.querySelector(selector);
      
      /* Select the text field */  
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");

      // Update tooltip
      $(this).attr('data-title', "Copied!");
      $(this).attr('data-original-title', "Copied!");
      $(this).tooltip('hide');
      $(this).tooltip('show');

      // Hide after 1 second
      setTimeout(function() {
        $('[data-toggle="tooltip"]').tooltip('hide');
      }, 1000);

    });

  }

}