// Set chart total indicator, including:
// -- Update title at top left of chart
// -- Update indicator at top right of chart showing total for selected data
// Shown on load of chart and hover over legend items

import FormatString from 'src/javascripts/components/utilities/FormatString';

export default function(chart, targetId) {

  // Passed data should be in c3 columns format [name, n1, n2, n3, ...]
  // Set start, endValue and change variables, based on data array passed as param
  let changeString, totalString;
  let values = chart.data.values(targetId);
  let start = values[0];
  let endValue = values[values.length - 1];
  let change = endValue - start;

  // Change endValue to 0 if NaN
  if (typeof endValue === 'undefined') {endValue = 0;}

  // Set format variables based on chart class
  const chartClass = $(chart.element).attr('class');

  // Set format and total strings based on chart type
  if ( chartClass.includes('sum-total') ) {

    let sum = 0
    for (let i = 0; i < values.length; i++) {
      sum += values[i] << 0;
    }
    totalString = FormatString(sum, 'decimals', 0, false, false, false);

  } else if ( chartClass.includes('returns-dollar-chart') ) {
    totalString = FormatString(endValue, 'currency', 0, false, false, false);

  } else if ( chartClass.includes('returns-chart') ) {
    totalString = FormatString(endValue, 'percentage', 1, true, true, false);

  } else {

    totalString = FormatString(endValue, 'decimals', 1, false, false, false);
    changeString = FormatString(change, 'decimals', 1, true, true, true);
  }

  // Get page elements to update
  const chartTitleSection = $(chart.element).closest('.chart-section').find('.chart-title-section');
  const indicator = chartTitleSection.find('.chart-total-indicator');

  // Append name to chart title, unless 'data-maintain-original-title' set to true
  // and this is for user's portfolio
  // Need to specify portfolio to maintain original title initially (because portfolio often 
  // auto-loaded) but then update when user selects new data to add to chart
  const chartTitle = chartTitleSection.find('.chart-title');
  const maintainOriginalTitle = chartTitle.attr('data-maintain-original-title');
  const firstTitle = chartTitle.attr('data-first-title');
  const portfolioName = $('.page-wrapper').attr('data-portfolio-name');
  if (typeof maintainOriginalTitle !== 'undefined') {
    if (maintainOriginalTitle === 'true') {
      chartTitle.empty().append(firstTitle);
    } else {
      chartTitle.empty().append(targetId);
    }
  } else if ((targetId === portfolioName) && (typeof firstTitle !== 'undefined')) {
    chartTitle.empty().append(firstTitle);
  } else {
    chartTitle.empty().append(targetId);
  }

  // Append append total and change string (unless this is for returns)
  indicator.empty().append(totalString);
  if (typeof changeString !== 'undefined') { 
    let changeDiv = ' <div class=\'change-div d-inline-block\'>' + changeString + '</div>';
    return indicator.append(changeDiv); 
  }
    // return indicator.append(' <div class=\'change-string d-inline-block\'' + changeString); }
};
