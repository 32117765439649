// Set research params (shared)
export default function(elId) {

  // Set filters from research_table button
  let filters = {};
  let assetsScope = $('#' + elId).attr("data-assets-scope");
  let screens = $('#' + elId).attr("data-screens");
  let index = $('#' + elId).attr("data-index");
  let sector = $('#' + elId).attr("data-sector");
  let industry = $('#' + elId).attr("data-industry");
  let sub_industry = $('#' + elId).attr("data-sub-industry");
  let region = $('#' + elId).attr("data-region");
  let hq_country = $('#' + elId).attr("data-hq-country");
  let hq_state = $('#' + elId).attr("data-hq-state");
  let hq_city = $('#' + elId).attr("data-hq-city");
  let market_cap = $('#' + elId).attr("data-market-cap");
  let fund_type = $('#' + elId).attr("data-fund-type");
  let cap = $('#' + elId).attr("data-cap");
  let fundCountry = $('#' + elId).attr("data-country");
  let assetClass = $('#' + elId).attr("data-asset-class");
  let geography = $('#' + elId).attr("data-geography");
  let fundFamily = $('#' + elId).attr("data-fund-family");
  let category = $('#' + elId).attr("data-category");
  let symbol = $('#' + elId).attr("data-symbol");
  let cause_id = $('#' + elId).attr("data-cause-id");
  let metric_id = $('#' + elId).attr("data-metric-id");
  let client_id = $('#' + elId).attr("data-client-id");
  let formula_id = $('#' + elId).attr("data-formula-id");
  let include_holding = $('#' + elId).attr("data-include-holding");
  let exclude_holding = $('#' + elId).attr("data-exclude-holding");

  // Set expense ratio
  let expenseRatio = $('#' + elId).attr("data-fund-share-classes-expense-ratio");
  if (typeof expenseRatio !== 'undefined') {
    let expenseRatioArr = [];
    expenseRatioArr.push(expenseRatio.split("..")[0] * 100);
    expenseRatioArr.push(expenseRatio.split("..")[1] * 100);
    filters["expense_ratio"] = expenseRatioArr;
  }

  // Set total assets
  let totalAssets = $('#' + elId).attr("data-total-assets");
  if (typeof totalAssets !== 'undefined') {
    let totalAssetsArr = [];
    totalAssetsArr.push(totalAssets.split("..")[0] / 1000000000);
    totalAssetsArr.push(totalAssets.split("..")[1] / 1000000000);
    filters["total_assets"] = totalAssetsArr;
  }

  let back_path = $('#' + elId).attr('data-back-path');
  let back_params = $('#' + elId).attr('data-back-params');
  if (typeof assetsScope !== 'undefined') {filters["assets_scope"] = assetsScope;}
  if (typeof screens !== 'undefined') {filters["screens"] = JSON.parse(screens);}
  if (typeof index !== 'undefined') {filters["index"] = JSON.parse(index);}
  if (typeof sector !== 'undefined') {filters["sector"] = JSON.parse(sector);}
  if (typeof industry !== 'undefined') {filters["industry"] = JSON.parse(industry);}
  if (typeof sub_industry !== 'undefined') {filters["sub_industry"] = JSON.parse(sub_industry);}
  if (typeof region !== 'undefined') {filters["region"] = JSON.parse(region);}
  if (typeof hq_country !== 'undefined') {filters["hq_country"] = JSON.parse(hq_country);}
  if (typeof hq_state !== 'undefined') {filters["hq_state"] = JSON.parse(hq_state);}
  if (typeof hq_city !== 'undefined') {filters["hq_city"] = JSON.parse(hq_city);}
  if (typeof fund_type !== 'undefined') {filters["fund_type"] = JSON.parse(fund_type);}
  if (typeof cap !== 'undefined') {filters["cap"] = JSON.parse(cap);}
  if (typeof assetClass !== 'undefined') {filters["asset_class"] = JSON.parse(assetClass);}
  if (typeof geography !== 'undefined') {filters["geography"] = JSON.parse(geography);}
  if (typeof fundCountry !== 'undefined') {filters["country"] = JSON.parse(fundCountry);}
  if (typeof fundFamily !== 'undefined') {filters["fund_family"] = JSON.parse(fundFamily);}
  if (typeof category !== 'undefined') {filters["category"] = JSON.parse(category);}
  if (typeof market_cap !== 'undefined') {filters["market_cap"] = JSON.parse(market_cap);}
  if (typeof symbol !== 'undefined') {filters["symbol"] = JSON.parse(symbol);}
  if (typeof include_holding !== 'undefined') {filters["include_holding"] = JSON.parse(include_holding);}
  if (typeof exclude_holding !== 'undefined') {filters["exclude_holding"] = JSON.parse(exclude_holding);}
  if (typeof back_path !== 'undefined') {filters["back_path"] = back_path;}
  if (typeof back_params !== 'undefined') {filters["back_params"] = back_params;}
  if (typeof client_id !== 'undefined') {
    filters["client_id"] = JSON.parse(client_id);
  } else if (typeof cause_id !== 'undefined') {
    filters["cause_id"] = JSON.parse(cause_id);
  } else if (typeof metric_id !== 'undefined') {
    filters["metric_id"] = JSON.parse(metric_id);
  } else if (typeof formula_id !== 'undefined') {
    filters["formula_id"] = JSON.parse(formula_id);
  }

  // Set closed/collapsed cards, if present
  let toClose = $('#' + elId).attr("data-to-close");
  if (typeof toClose !== 'undefined') {
    filters["to_close"] = toClose;
  }

  return filters

}