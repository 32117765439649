import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import AddRowDataAttrs from 'src/javascripts/components/tables/helpers/AddRowDataAttrs';
import AddRowHoverButtons from 'src/javascripts/components/tables/helpers/AddRowHoverButtons';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';
import CheckPaging from 'src/javascripts/components/tables/helpers/CheckPaging';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import Swal from 'sweetalert2';

export default class PortfolioItemsTable extends Table {

  render() {

    // Set a spinner to indicate chart loading
    Spinner(this.el);

    // Set table id
    const tableId = this.el.attr('id');

    // Build url, use to get filtered data with server-side processing
    let dataUrl = BuildApiUrl(tableId);

    // Set additional data vars (used below in table creation)
    let cols = GetCols(this.el);
    let colLen = cols.length;

    // Set scroll Y option
    let scrollY = $('#' + tableId).attr('data-scroll-y');
    if (typeof scrollY === 'undefined') {scrollY = false};

    // Render table
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      retrieve: true,
      serverSide: true,
      ajax: dataUrl,
      paging: true,
      scrollY: scrollY,
      pageLength: 10,
      lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
      lengthChange: true,
      searching: true,
      order: [[1, 'desc']],
      columns: cols,
      dom: 'tlp',
      responsive: { details: false },
      language: { 
        search: "_INPUT_",
        searchPlaceholder: "Search...",
        lengthMenu: "Show _MENU_",
        infoEmpty: "No assets found. Add one or reach out to us for support.",
        emptyTable: "No assets found. Add one or reach out to us for support.",
        zeroRecords: "No assets found. Add one or reach out to us for support."
      },
      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        AddRowDataAttrs(row, data);

      },

      drawCallback: function( settings ) {

        // Remove spinner after table has drawn
        $('#' + tableId).find('.spinner-container').remove();        

      }


    });

    return 'rendered';
  }

  setUp(tableId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Set searchable function for table
    $('#search_' + tableId).keyup(function(){
      oTable.search( $(this).val() ).draw();
    });

    let causesFilter = $('#change_portfolio_items_cause');
    if (causesFilter.length > 0) {

      // Determine whether to include risk
      let risk = $('#' + tableId).attr('data-risk');
      let url = '/causes/search?';
      if (typeof risk !== "undefined") { url = url + 'risk=' + risk + '&'; }
      filterTypeahead('#change_portfolio_items_cause', 'causes', url, 223, 'cause');

      // Focus change causes
      $('.focus-change-cause').click(function() {
        $('#change_portfolio_items_cause').focus();
      });

      // Reset asset causes
      let resetAssetCauses = function(url, causeUrl, chart) {
        oTable.ajax.url(url).load();
        Spinner($('#impact_dist_portfolio').closest('.chart-container'));
        $('#impact_dist_portfolio').addClass('opaque');
        $('.cause-indicator').empty().append("client causes");
        $('.focus-change-cause').removeClass('d-none');
        $.when( $.getJSON(causeUrl) ).then(data => 
          chart.unload({
            done: function() {
              chart.load({
                json: data, 
                keys: {x: 'name', value: [ 'value' ]}
              });
            }
          })
        );
      }

      // Reset on click
      $('.reset-asset-causes').click(function() {
        let url = $('#' + tableId).attr('data-api-url');
        let causeUrl = $('#impact_dist_portfolio').attr('data-api-url');
        let chart = $('#impact_dist_portfolio').data('c3-chart');
        resetAssetCauses(url, causeUrl, chart);
      });

      // On typeahead select
      $('#change_portfolio_items_cause').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {

        // Prevent default
        ev.preventDefault();

        // Indicate cause
        let text = "<i class=\'fad mr-1 fa-" + suggestion.fa_key + "\'></i>" + suggestion.name;
        text += " <span class=\'reset-asset-causes font-weight-light clickable\'>(reset)</span>"
        $('.cause-indicator').empty().append(text);
        $('.focus-change-cause').addClass('d-none');

        // Reload table
        let url = $('#' + tableId).attr('data-api-url');
        let params = {};
        params['cause_id'] = suggestion.value;
        if (url.indexOf('?') > -1) {
          url = url + '&' + $.param(params);
        } else {
          url = url + '?' + $.param(params);          
        }
        oTable.ajax.url(url).load();

        // Reload chart
        Spinner($('#impact_dist_portfolio').closest('.chart-container'));
        $('#impact_dist_portfolio').addClass('opaque');
        let causeUrl = $('#impact_dist_portfolio').attr('data-api-url');
        let causeParams = {};
        causeParams['cause_id'] = suggestion.value;
        if (causeUrl.indexOf('?') > -1) {
          causeUrl = causeUrl + '&' + $.param(causeParams);
        } else {
          causeUrl = causeUrl + '?' + $.param(causeParams);
        }

        let chart = $('#impact_dist_portfolio').data('c3-chart');
        $.when( $.getJSON(causeUrl) ).then(data => 
          chart.unload({
            done: function() {
              chart.load({
                json: data, 
                keys: {x: 'name', value: [ 'value' ]}
              });
            }
          })
        );

        // Reset on click
        $('.reset-asset-causes').click(function() {
          let url = $('#' + tableId).attr('data-api-url');
          let causeUrl = $('#impact_dist_portfolio').attr('data-api-url');
          let chart = $('#impact_dist_portfolio').data('c3-chart');
          resetAssetCauses(url, causeUrl, chart);
        });

        // Blur
        $('#change_portfolio_items_cause').blur();

      });

    }

    // Ensure search input starts blank
    $('#search_' + tableId).val('');

    return 'setup';
  }


}
