import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class UsersTypeahead {

  constructor(el) {this.el = el;}

  initializeBloodhound() {

    let apiUrl = $(this.el).attr('data-api-url');
    const bloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: apiUrl + '?query=%QUERY',
        wildcard: "%QUERY"
      }});

    bloodhound.initialize();
    return bloodhound;

  }

  render(bloodhound, autocomplete = 'off') {

    let emptyTemplate = '<div class="empty-message">Unable to find that</div>';

    let userTemplate = (name) => 
      '<div>' + name + '</div>';

    $(this.el).typeahead({
      hint: true,
      highlight: true,
      minLength: 0
    }, {
      name: 'typeahead',
      display: 'name',
      limit: 104,
      source: bloodhound.ttAdapter(),
      templates: {
        empty: emptyTemplate,
        suggestion(el) {
          return userTemplate(el.name);
        }
      }
    });
  }

  setUp() {

    // Auto-focus typeahead
    $('#usersFilterDropdown').on('shown.bs.dropdown', function () {
      $('#users_typeahead').focus();
    })    

    $('#users_typeahead').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) { 
      let url = $(this).attr('data-url');
      let params = {}
      let portfolioType = $(this).attr('data-portfolio-type');
      let reportType = $(this).attr('data-report-type');
      let clientId = $(this).attr('data-client-id');
      let accountId = $(this).attr('data-account-id');
      let filt = suggestion.value;
      if (typeof portfolioType !== 'undefined') {params['portfolio_type'] = portfolioType};
      if (typeof reportType !== 'undefined') {params['report_type'] = reportType};
      if (typeof clientId !== 'undefined') {params['client_id'] = clientId};
      if (typeof accountId !== 'undefined') {params['account_id'] = accountId};
      params['user_id'] = filt;
      Turbolinks.visit(url + '?' + $.param(params));
    })
  }

}
