import 'bootstrap/js/dist/toast.js';

export default function() {

  $('.flash').each(function() {
    let message = $(this).attr('data-message');

    // Autohide
    let autohide = $(this).attr('data-autohide');
    if (typeof autohide === 'undefined') {
      autohide = true;
    } else if (autohide === "false") {
      autohide = false;
    } else {
      autohide = true;
    }

    // Delay
    let delay = $(this).attr('data-delay');
    if (typeof delay !== 'undefined') {
      delay = parseFloat(delay);
    } else {
      delay = 15000;
    }

    // Title
    let title = $(this).attr('data-title');
    if (message === "You are already signed in.") {
      title = "Welcome back!";
    } else if (typeof title === 'undefined') {
      title = 'Ethos';
    }

    // Build toast
    let toast = "<div class=\"toast mb-1\" role=\"alert\">";
    toast += "<div class=\"toast-header bg-primary-light\">";
    toast += "<span class=\"mr-auto font-weight-bold\">" + title + "</span>";
    toast += "<button type=\"button\" class=\"ml-2 mb-1 close\" data-dismiss=\"toast\" aria-label=\"Close\">";
    toast += "<span aria-hidden=\"true\">&times;</span>";
    toast += "</button></div>";
    toast += "<div class=\"toast-body\">";
    toast += message;
    toast += "</div></div>";  

    // Replace existing toast if one present, otherwise prepend to body
    if ($('.toast').length !== 0) {
      $('.toast').replaceWith(toast);
    } else {
      $('body').prepend(toast);
    }

    // Show toast  
    $('.toast').toast({autohide: autohide, delay: delay});
    return $('.toast').toast('show');
  
  });

}