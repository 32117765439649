import Spinner from 'src/javascripts/components/utilities/Spinner';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import QuestionnaireSectionsTypeahead from 'src/javascripts/components/typeaheads/QuestionnaireSectionsTypeahead';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');


export default class QuestionnairesIncludedSectionsEdit {

  constructor() {}

  render() {

  	// Sections typeahead
  	QuestionnaireSectionsTypeahead();


  }

}