import Spinner from 'src/javascripts/components/utilities/Spinner';
import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';
import ExpandContent from 'src/javascripts/components/utilities/ExpandContent';
import ExpandExcerpt from 'src/javascripts/components/utilities/ExpandExcerpt';
import StickySidebar from 'src/javascripts/components/nav/StickySidebar';
import ImpactDistPortfolio from 'src/javascripts/components/charts/ImpactDistPortfolio';
import PortfolioItemsTable from 'src/javascripts/components/tables/PortfolioItemsTable';
import PortfolioItemsUnderlyingTable from 'src/javascripts/components/tables/PortfolioItemsUnderlyingTable';
import ToggleReturnsChart from 'src/javascripts/components/charts/actions/ToggleReturnsChart';
import ReturnsDollarsChart from 'src/javascripts/components/charts/ReturnsDollarsChart';
import ReturnsChart from 'src/javascripts/components/charts/ReturnsChart';
import ReturnsTable from 'src/javascripts/components/tables/ReturnsTable';
import PortfolioStocksPoll from 'src/javascripts/components/polling/PortfolioStocksPoll';
import PortfolioPerformancePoll from 'src/javascripts/components/polling/PortfolioPerformancePoll';

export default class PortfoliosAssets {

  constructor() {}

  render() {

    // Set account url
    const accountUrl = $('body').attr('data-account-url');

    // Expand/minimize profile details
    ExpandContent();

    // Sticky sidebar scrolling
    StickySidebar();

    // Expand when click on table of contents item
    $(window).scroll(function(e) {
      let scroll = $(window).scrollTop();
      if (scroll > 130) {
        $('.sidebar-title-info').removeClass('min-height');
      } else {
        $('.sidebar-title-info').addClass('min-height');
      }
    });
    let scroll = $(window).scrollTop();
    if (scroll > 130) {
      $('.sidebar-title-info').removeClass('min-height');
    } else {
      $('.sidebar-title-info').addClass('min-height');
    }

    // Start import status poll if importing
    let importPoll = () => {

      // Get generating message on page
      let isGenerating = $('.generating-message.importing-message');

      // Only continue if status indicator present (if should poll for recs is true)
      if (isGenerating.length !== 0) {

        // Set Api url to get data
        let portfolioId = isGenerating.attr('data-portfolio-id');
        let modelId = isGenerating.attr('data-model-id');
        let params = {};
        params['portfolio_id'] = portfolioId;
        params['scope'] = 'portfolio';
        let url = '/' + accountUrl + '/portfolios/models/' + modelId + '/imports/check_import_status.js?' + $.param(params);

        // Execute ajax request (using js erb template to render content so can control profile styling more easily)
        // Must specify '.js' otherwise processes as JSON
        $.ajax({
          type: "POST",
          dataType: "script",
          timeout: 3000,
          url: url,
          complete() {
            setTimeout(function() { importPoll() }, 4000);
          }
        });
      }
    };

    // Poll server for status of mapped items, if generating message present
    if ($('.generating-message.importing-message').length !== 0) { importPoll(); }

    // Portfolio sectors
    let portfolioId = $('#sectorsTab').attr('data-portfolio-id');
    if ((typeof portfolioId !== 'undefined') && ($('.characteristic-row-comparison').length === 0) ) {
      $.ajax({
        type: "GET",
        dataType: "script",
        url: '/' + accountUrl + '/portfolios/' + portfolioId + '/characteristics.js?scope=sectors'
      });
    }

    // Characteristics on tab shown
    $('#portfolioCharacteristicsCharts a[data-toggle=\'tab\']').off().on('shown.bs.tab', function (e) {
      Spinner($('#charTabContent'));
      let scope = $(this).attr('data-scope');
      let portfolioId = $('#sectorsTab').attr('data-portfolio-id');
      $.ajax({
        type: "GET",
        dataType: "script",
        url: '/' + accountUrl + '/portfolios/' + portfolioId + '/characteristics.js?scope=' + scope
      });      
    });

    // Impact dist chart, if chart does not already have c3-data (already initialized)
    if (typeof $('#impact_dist_portfolio').data('c3-chart') === 'undefined') {
      if ($('#impact_dist_portfolio').length > 0) {
        let chart = new ImpactDistPortfolio( $('#impact_dist_portfolio') );
        $.when( chart.getData() )
         .then( data => chart.render(data) );
      }
    } else {
      if ($('#impact_dist_portfolio').length > 0) {
        let chart = $('#impact_dist_portfolio').data('c3-chart');
        chart.flush;
      }        
    }

    // Portfolio items, if present on page
    if (($('.portfolio-items-table').length > 0) && ($('.portfolio-items-table tbody td').length === 0)) {
      $('.portfolio-items-table').each(function() {
        let tableId = $(this).attr('id');
        let portfolioItemsTable = new PortfolioItemsTable($('#' + tableId));
        $.when( portfolioItemsTable.render(tableId) ).then( status => portfolioItemsTable.setUp(tableId) );
      })
    }

    // Portfolio items underlying stocks, if present on page
    if (($('#portfolio_items_underlying_table').length > 0) && ($('#portfolio_items_underlying_table tbody td').length === 0)) {
      let tableId = 'portfolio_items_underlying_table';
      let portfolioItemsUnderlyingTable = new PortfolioItemsUnderlyingTable($('#' + tableId));
      $.when( portfolioItemsUnderlyingTable.render(tableId) ).then( status => portfolioItemsUnderlyingTable.setUp(tableId) );
    } 

    // Portfolio stocks and screens polling
    PortfolioStocksPoll();

    // Characteristics on tab shown
    $('#assetFinancialList').off().on('shown.bs.collapse', function (e) {

      // Poll for performance if needed
      PortfolioPerformancePoll();

      // Load data if no generating message
      if ($('.generating-performance-message').length === 0) {

        // Initialize a new chart instance for each chart on the page
        if (($('#returns_chart').attr('data-return-option') === 'dollar') && (typeof $('#returns_chart').data('c3-chart') === 'undefined')) {

          // Set chartId and chart object
          // Get data, render chart, set up chart, and create connected table, if exists
          let chart = new ReturnsDollarsChart($('#returns_chart'));
          $.when(chart.getData())
           .then( data => chart.render(data))
           .then( chartObj => chart.setUp(chartObj));

        } else if (($('#returns_chart').attr('data-return-option') === 'percentage') && (typeof $('#returns_chart').data('c3-chart') === 'undefined')) {

          // Set chartId and chart object
          // Get data, render chart, set up chart, and create connected table, if exists
          let chart = new ReturnsChart($('#returns_chart'));
          $.when(chart.getData())
           .then( data => chart.render(data))
           .then( chartObj => chart.setUp(chartObj));

        }

        // Toggle returns chart between hypothetical growth and percentage returns
        ToggleReturnsChart();

        // Portfolio items return attrs, if present on page
        if ($('#returns_table').length > 0) {
          let returnsTable = new ReturnsTable($('#returns_table'));
          $.when( returnsTable.render('returns_table') ).then( status => returnsTable.setUp('returns_table') );
        }

      }

    })


  }

}