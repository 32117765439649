import ClientsTypeahead from 'src/javascripts/components/typeaheads/ClientsTypeahead';
import Spinner from 'src/javascripts/components/utilities/Spinner';

export default class ReportsIndex {

  constructor() {}

  render() {

    // Select all reports
    $('.select-all-reports').click(function() {
      $('#reports_table tr .checkbox').prop('checked', this.checked);
    });

    // Export bulk report PDFs
    $('#exportBulkReportPdfs').click(function() {

      // Set spinner and opacity to indicate working
      $('#reports_table').closest('.card-body').css('opacity', 0.2);
      Spinner($('#reports_table').closest('.card'));

      // Set params
      let accountUrl = $('body').attr('data-account-url');
      let url = '/' + accountUrl + '/reports/pdfs/create_bulk?';
      let params = {};
      let reportIds = []
      $('#reports_table input.checkbox:checked').each(function() {
        let r = $(this).closest('tr').attr('data-record-id');
        if (typeof r !== 'undefined') {reportIds.push(r);}
      })
      params['report_ids'] = reportIds;

      // Send ajax call
      $.ajax({
        type: 'POST',
        dataType: 'script',
        url: url + $.param(params),
      });

    })

    // Clients typeahead
    let clientsTypeahead = new ClientsTypeahead('#clients_typeahead');
    $.when(clientsTypeahead.initializeBloodhound() )
     .then( bloodhound => clientsTypeahead.render(bloodhound) )
     .then( clientsTypeahead.setUp('#clients_typeahead'));

  }

}