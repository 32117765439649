import Questions from 'src/javascripts/components/questionnaires/Questions'

export default class QuestionnairesQuestionsEdit {

  constructor() {}

  render() {

  	Questions();

  }

}