export default function() {

  // Set up poll to track status of PDF being created and uploaded to Amazon
  let pdfPoll = () => {

    // Get rec request on page
    let reportPdfId = $('.modal-body').attr('data-report-pdf-id');

    // Only continue if status indicator present (if should poll for recs is true)
    if (reportPdfId.length !== 0) {

      // Set Api url to get data
      let pollUrl = '/reports/pdfs/poll/' + reportPdfId;

      // Execute ajax request (using js erb template to render content so can control profile styling more easily)
      // Must specify '.js' otherwise processes as JSON
      $.ajax({
        type: "GET",
        dataType: "script",
        timeout: 3000,
        url: pollUrl + '.js?',
        complete() {
          setTimeout(function() { pdfPoll() }, 4000);
        }
      });
    }
  };

  // Poll server for status of recs
  let reportPdfId = $('.modal-body').attr('data-report-pdf-id');
  if ((typeof reportPdfId !== 'undefined') && (reportPdfId.length > 0)) {
    pdfPoll();
  }

}