import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';

export default function(el) {

  // Funds and benchmarks
  filterTypeahead('#fund_search', 'funds', '/funds/search?country=all&', 42, 'logo');
  filterTypeahead('#fund_benchmark_search', 'funds', '/funds/search?country=all&', 42, 'logo');

  // On selecting a fund 
  $('#fund_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {

    // Set hidden field value
    let fundId = suggestion.value;
    $('#fund_id').val(fundId);

    // Blur focus
    $('#fund_search').blur();

    // Enable save if both fund and benchmark selected
    if ( ($('#fund_id').val().length > 0) && ($('#benchmark_fund_id').val().length > 0) ) {
      $('#createCalculatorDisabled').removeClass('d-block').addClass('d-none');
      $('#createCalculatorSubmit').removeClass('d-none').addClass('d-block');
    } else {
      $('#createCalculatorSubmit').removeClass('d-block').addClass('d-none');
      $('#createCalculatorDisabled').removeClass('d-none').addClass('d-block');        
    }

  })

  // On selecting a fund as benchmark
  $('#fund_benchmark_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {

    // Set hidden field value
    let benchmarkId = suggestion.value;
    $('#benchmark_fund_id').val(benchmarkId);

    // Blur focus
    $('#fund_benchmark_search').blur();

    // Enable save if both fund and benchmark selected
    if ( ($('#fund_id').val().length > 0) && ($('#benchmark_fund_id').val().length > 0) ) {
      $('#createCalculatorDisabled').removeClass('d-block').addClass('d-none');
      $('#createCalculatorSubmit').removeClass('d-none').addClass('d-block');
    } else {
      $('#createCalculatorSubmit').removeClass('d-block').addClass('d-none');
      $('#createCalculatorDisabled').removeClass('d-none').addClass('d-block');        
    }

  })

  // On selecting a fund as benchmark
  $('.clear-typeahead').click(function() {
    let t = $(this).closest('.form-group').find('.typeahead');
    t.typeahead('val', '');
    t.focus();
  })

}