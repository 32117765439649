import SetChartTotal from 'src/javascripts/components/charts/actions/SetChartTotal';
import ResetChartAxis from 'src/javascripts/components/charts/axes/ResetChartAxis';

export default function(chart, legendRecord) {

  // Set params
  const recordType = legendRecord[0];
  const recordId = legendRecord[1];
  const recordName = legendRecord[2];
  const legendRow = $('#returns_table').find('tr[data-chart-record-id="' + recordName + '"]');

  legendRow.off().on('mouseover', function() {

    // Focus the data on the chart
    chart.focus(recordName);

    // Update chart title and total indicator
    return SetChartTotal(chart, recordName);
  });

  legendRow.on('mouseout', function() {

    // Get the first shown data on the chart
    const shownId = chart.data.shown()[0].id;

    // Revert all items
    chart.revert();

    // Reset indicator to original chart data
    return SetChartTotal(chart, shownId);
  });

  return legendRow.on('click', function() {

    // Don't remove data if there's only one item selected
    if (!(chart.data.shown().length <= 1) || !!$(this).hasClass('c3-legend-item-hidden')) {

      // Hide the legend item
      $(this).toggleClass('c3-legend-item-hidden');

      // Toggle the data from the chart
      chart.toggle(recordName);

      // Reset y axis values and ticks
      return ResetChartAxis(chart);
    }
  });
};

