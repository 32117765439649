import * as d3 from 'd3';
import * as c3 from 'c3';
import Chart from 'src/javascripts/components/Chart';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import ClimateRiskTimeseriesTooltip from 'src/javascripts/components/charts/tooltips/ClimateRiskTimeseriesTooltip';

export default class ClimateRiskTimeseriesChart extends Chart {

  getData() {

    // Set a spinner to indicate chart loading
    Spinner(this.el);

    // Build url
    let url = this.el.attr('data-api-url');

    // Get data
    return $.getJSON(url);
  }

  render(data) {

    let chartId = this.el.attr('id')

    // Render chart
    var chart = c3.generate({
      bindto: '#' + chartId,
      padding: {left: 60, bottom: 0, top: 0},
      data: {
        x: 'year',
        columns: data,
        type: 'line'
      },
      point: {show: false},
      axis: {
        y: {
          padding: {bottom: 20},
          tick: {
            outer: false,
            format(v, id, i, j) { return "$-" + (v / 1000000).toFixed(2).toLocaleString("en", {minimumFracctionDigits: 2, maximumFractionDigits: 2}) + "M"; }
          }
        }
      },
      tooltip: {
        format: {},
        contents(d, defaultTitleFormat, defaultValueFormat, color) {
          return ClimateRiskTimeseriesTooltip(chart, this, d, defaultTitleFormat, defaultValueFormat, color);
        }
      },
      grid: {
        y: {show: false},
        x: {show: false}
      }
    });

    return chart;
  }

  setUp(chart) {

    // Return if no chart available (don't attempt to set up below)
    if (typeof chart === 'undefined') {return;}

    // Set chart data to allow for accessing chart programmatically (used to allow tables to add/remove data)
    // Also allows for destroying chart before Turbolinks cache
    // https://stackoverflow.com/questions/25765596/how-to-get-object-reference-from-selector-for-c3-js-charts
    let chartId = '#' + $(chart.element).attr('id');
    $(chartId).data('c3-chart', chart);

    // Remove spinner
    $(chart.element).find('.spinner-container').remove();

    return chart;
  }

};
