export default function() {

  // Category selection (ion range sliders)
  // Change 1-7 input (on slider) to 0-100 scale
  const updateAssessmentCategoryInput = function(selection) {
    let score;
    if (selection === 7) {
      score = 100;
    } else if (selection === 6) {
      score = 83;
    } else if (selection === 5) {
      score = 66;
    } else if (selection === 4) {
      score = 49;
    } else if (selection === 3) {
      score = 31;
    } else if (selection === 2) {
      score = 14;
    } else {
      score = 0;
    }
    return score;
  }

  // Set sliders
  $('.impact-assessment-filter').each(function() {

    // Set range
    let $range = $(this).find('.impact-assessment-input');

    // Create slider
    $range.ionRangeSlider({
      // skin: "sharp",
      min: 1,
      max: 7,
      from: 1,
      to: 7,
      step: 1,
      grid: true,
      grid_num: 6,
      hide_min_max: true,

      onFinish(data) {

        // Set value of input on 0-100 scale
        let score = updateAssessmentCategoryInput(data.from);

        // update value of input
        return data.input.closest('.impact-assessment-filter').find('input.assessment-input').val(score);

      },

      onChange(data) {

        // Update indicators
        return data.input.closest('.impact-assessment-filter').find('.filter-indicator').empty().html(data.from);

      },
      
      onUpdate: function (data) {

        // Set value of input on 0-100 scale
        let score = updateAssessmentCategoryInput(data.from);

        // Set filter
        let $filter = data.input.closest('.impact-assessment-filter')

        // update value of input
        $filter.find('input.assessment-input').val(score);

        // Update indicators
        $filter.find('.filter-indicator').empty().html(data.from);

        // Re-set listener for click of text after update
        return $('.irs-grid-text').off().on('click', function() {
          let $slider = $(this).closest('.impact-assessment-filter').find('.impact-assessment-input').data('ionRangeSlider');
          let $selection = parseInt($(this).html());
          $slider.update({from: $selection, to: 7});
			  	let primaryColor = $('.assessment-content').attr('data-background-color');
			  	$('.irs-bar').css('background-color', primaryColor);
			  	$('.irs-handle.state_hover > i:first-child, .irs-handle:hover > i:first-child, .irs-handle > i:first-child').css('background-color', primaryColor);
        })

      }

    });

  	// Update background colors
  	let primaryColor = $('.assessment-content').attr('data-background-color');
  	$('.irs-bar').css('background-color', primaryColor);
  	$('.irs-handle.state_hover > i:first-child, .irs-handle:hover > i:first-child, .irs-handle > i:first-child').css('background-color', primaryColor);

    // Remove opaque class to show slider (starts as opaque to hide occasional jumping while JS initiating above)
    $(this).removeClass('opaque');

  })

  // Submit form on click (default Rails submit button doesn't work with fa icons)
  $('#update_assessment_form_submit').click(function() {
    $('#update_assessment_form').submit();
  });

  // Update slider on click of assessment
  $('.irs-grid-text').on('click', function() {
    let $slider = $(this).closest('.impact-assessment-filter').find('.impact-assessment-input').data('ionRangeSlider');
    let $selection = parseInt($(this).html());
    $slider.update({from: $selection, to: 7});
  	let primaryColor = $('.assessment-content').attr('data-background-color');
  	$('.irs-bar').css('background-color', primaryColor);
  	$('.irs-handle.state_hover > i:first-child, .irs-handle:hover > i:first-child, .irs-handle > i:first-child').css('background-color', primaryColor);

  })

}