// Reposition an image
export default function() {

	// Set vars
	const img = $('.cover-container img.repositionable');
	let y1 = $('.cover-container').height();
	let y2 = img.height();
	let x1 = $('.cover-container').width();
	let x2 = img.width();
	let desktop_start_x=0;
	let desktop_start_y=0;
	let mobile_start_x= -200;
	let mobile_start_y= -200;

	// Set opacities of image; will be different for account cover image and report image
	let originalOpacity;
	let positioningOpacity;
  if ($('body[data-controller-module="Reports"]').length !== 0) {
    originalOpacity = 0.25;
    positioningOpacity = 0.2;
  } else {
    originalOpacity = 0.8;
    positioningOpacity = 0.6;
  }

	// On click of save
	$('.save').click(function(event){
    event.preventDefault();
    var t = img.position().top, l = img.position().left;
    let accountUrl = $('.banner-container').attr('data-account-url');
    let data = {};
    data['account'] = {};

    // Set cover top or report cover top depending on whether we're in a report or not
    if ($('body[data-controller-module="Reports"]').length !== 0) {
	    data['account']['report_cover_top'] = t;
	    data['account']['report_cover_left'] = l;
	    $('img.repositionable').css('z-index', 0);
	    $('.controls, .controls-save').css('z-index', 1);
    } else {
	    data['account']['cover_top'] = t;
	    data['account']['cover_left'] = l;
	  }

    // Save new positions
    $.ajax({
      type: 'PATCH',
      dataType: "application/json",
      url: '/' + accountUrl,
      data: data,
      complete(result) { 
        img.css('opacity', originalOpacity);
        $('.save').addClass('d-none');
      } 
    });

    // Disable draggable
    img.draggable({ disabled: true });

	})

	$('.pos').click(function(event){
		event.preventDefault();

	  if ($('.save').hasClass('d-none')) {

	  	// Set image opacity to positioning opacity
		  img.css('opacity', positioningOpacity);

	  	// Unhide save button
		  $('.save').removeClass('d-none');

	    // Adjust z-indexes if for reports
	    if ($('body[data-controller-module="Reports"]').length !== 0) {
		    $('img.repositionable').css('z-index', 2);
		    $('.controls, .controls-save').css('z-index', 3);
		  }

		  // Set image as draggable
		  img.draggable({ 
		  	disabled: false,
		  	scroll: false,
		  	axis: 'y, x',
		  	cursor : 'move',
		  	drag: function(event, ui) {
	       	if(ui.position.top >= 0) {ui.position.top = 0;}
	       	if(ui.position.top <= y1 - y2) {ui.position.top = y1 - y2;}
	       	if (ui.position.left >= 0) {ui.position.left = 0;}
	      	if(ui.position.left <= x1 - x2) {ui.position.left = x1 - x2;}
			 	}
	  	});

	  } else {

	  	// Set image opacity back to original
		  img.css('opacity', originalOpacity);

		  // Hide save button
		  $('.save').addClass('d-none');

	    // Adjust z-indexes if for reports
	    if ($('body[data-controller-module="Reports"]').length !== 0) {
		    $('img.repositionable').css('z-index', 0);
		    $('.controls, .controls-save').css('z-index', 1);
		  }

	    // Disable draggable
	    img.draggable({ disabled: true });


	  }

	});

}