import * as d3 from 'd3';
import * as c3 from 'c3';
import Chart from 'src/javascripts/components/Chart';
import SetColors from 'src/javascripts/components/charts/helpers/SetColors';
import ImpactPercentileTooltip from 'src/javascripts/components/charts/tooltips/ImpactPercentileTooltip';

export default class RatingBellCurveChart extends Chart {

  render() {

    // Set id
    let chartId = this.el.attr('id');

    // Set data (percentiles)
    let data = ['n', 0.1, 2.3, 15.9, 50, 15.9, 2.3, 0.1];
    let percentiles = [99.9, 97.7, 84.1, 50, 15.9, 2.3, 0.1];
    let colors = {};
    colors['n'] = '#8c81d1';

    // Render chart
    var chart = c3.generate({
      bindto: '#' + chartId,
      padding: {top: 0, bottom: -4, left: 15, right: 15},
      data: {
        columns: [data],
        types: {n: 'area-spline'},
        colors: colors
      },
      point: {show: false},
      axis: {
        x: {
          padding: {left: 0, right: 0},
          tick: {
            outer: false,
            fit: true,
            format(v, id, i, j) { 
              return percentiles[v] + '%'; 
            }
          }
        },
        y: {
          show: false,
        }
      },
      legend: {show: false},
      tooltip: {
        format: {},
        contents(d, defaultTitleFormat, defaultValueFormat, color) {
          return ImpactPercentileTooltip(this, d, defaultTitleFormat, defaultValueFormat, percentiles);
        }
      },
      grid: {
        y: {show: false},
        x: {show: false}
      }
    });

    return chart;
  }

};
