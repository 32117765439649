import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import AddRowDataAttrs from 'src/javascripts/components/tables/helpers/AddRowDataAttrs';
import AddRowClickLinks from 'src/javascripts/components/tables/actions/AddRowClickLinks';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';

export default class PeerComparisonTable extends Table {

  render(tableId = null) {

    // Set tableId if not passed
    if (tableId === null) {tableId = 'impact_details_peer_comparison';}

    // Build url, use to get filtered data with server-side processing
    let dataUrl = this.el.attr('data-url');

    // Set additional data vars (used below in table creation)
    let cols = GetCols(this.el);

    // Set height if in full-window mode
    let $card = this.el.closest('.card');
    let $height = $card.attr('data-scroll-height');
    if (typeof $height === 'undefined') {
      if ($card.hasClass('full-view')) {
        $height = $card.height() - 180;
      } else {
        $height = 230;
      }
    }

    // Render peers datatable
    let peersTable = $('#' + tableId).DataTable({
      retrieve: true,
      ajax: dataUrl,
      paging: true,
      pageLength: 5,
      lengthMenu: [[5, 10, 25, 50], [5, 10, 25, 50]],
      scrollY: $height,
      scrollX: true,
      scrollCollapse: true,
      searching: true,
      ordering: false,
      order: [[1, 'asc']],
      dom: 'tlp',
      columns: cols,
      responsive: { details: false },
      language: { 
        lengthMenu: "Show _MENU_ peers",
        infoEmpty: "No peer data available. Please contact us with any questions.",
        emptyTable: "No peer data available. Please contact us with any questions.",
        zeroRecords: "No peer data available. Please contact us with any questions."
      },
      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        AddRowDataAttrs(row, data);

        // Highlight record
        if (data.featured === true) {
          $(row).addClass('bg-primary-light');
        }

      },
      drawCallback: function( settings ) {},
      initComplete: function( settings ) {

        // Set table
        let oTable = $('#' + tableId).DataTable();

        // Set searchable function for table
        $('#search_' + tableId).keyup(function(){
          oTable.search( $(this).val() ).draw();
        });

        // Set on click action for table rows
        // AddRowClickLinks(tableId);

        // Set peer rank
        let peerRank = settings.json.peer_rank;
        let peerSize = settings.json.peer_size;
        let peerAttributes = settings.json.peer_attributes;
        let formulaName = settings.json.formula_name;
        
        // Remove card if peerSize < 3
        if (peerSize < 3) { $('#' + tableId).closest('.card').addClass('d-none'); }

        // Set peer rank if present
        if ((typeof peerRank !== 'undefined') && (peerRank !== null)) {
          $('.peer-rank-subtitle').empty().append("#" + peerRank + " of " + peerSize + " for " + formulaName);
        } else {
          $('.peer-rank-subtitle').empty().append("Not rated for " + formulaName);
        }

        // Set attributes used to create sub-industry if present
        if ((typeof peerAttributes !== 'undefined') && (peerAttributes.length > 0)) {
          let peerAttrText = "<div class=\'card-header peer-attributes-header small d-flex align-items-center\'>";
          $.each(peerAttributes, function(i, o) {            
            if (o.value !== null) {
              peerAttrText += "<span class=\'mr-2 border px-2 bg-light\' style=\'border-radius: 5px; padding-top: 3px; padding-bottom: 3px;\' data-toggle=\'tooltip\' data-title=\'" + o.key + "\'>" + o.value + "</span>";
            }
          });
          peerAttrText += "</div>";
          $('.peer-attributes-header').remove();
          $('#impactDetailsPeerCardHeader').after(peerAttrText);
        }

        // Re-init tooltip
        $('[data-toggle=tooltip]').tooltip();

        // Remove spinner and opacity
        $('#' + tableId).closest('.card').find('.spinner-container').remove();
        $('#' + tableId).removeClass('opaque');
      }

    });

    return 'rendered';
  }

  setup(tableId) {

    return 'setup';

  }

}