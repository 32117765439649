import ExpandContent from 'src/javascripts/components/utilities/ExpandContent';
import ExpandExcerpt from 'src/javascripts/components/utilities/ExpandExcerpt';
import AssessmentCausesChart from 'src/javascripts/components/charts/AssessmentCausesChart';

export default class ClientsAssessmentsShow {

  constructor() {}

  render() {

    // Expand/minimize profile details
    ExpandContent();

    // Expand excerpted statements on click
    ExpandExcerpt();

    // Render chart if present
    if ( ($('#assessment_causes_chart').length !== 0) && (typeof $('#assessment_causes_chart').data('c3-chart') === 'undefined') ) {
      const chart = new AssessmentCausesChart( $('#assessment_causes_chart') );
      $.when( chart.render() );
    }

  }

}