export default class AssessmentsEditCategories {

  constructor() {}

  render() {

    // Listen for submit form
    const checkEnabledStatusOfSubmit = function() {

      // Get selected category IDs
      if ($('input:checkbox:checked').length > 0) {
        $('.disabled-button').addClass('d-none');
        $('.enabled-button').removeClass('d-none');
      } else {
        $('.disabled-button').removeClass('d-none');
        $('.enabled-button').addClass('d-none');
      }
    }

    // Toggle SDGs
    $('#toggleSdgs').click(function() {
      $('.associated-sdgs').toggleClass('d-none d-flex');
      $(this).toggleClass('opaque');
    });

    // Action when clicking on a card
    // Check the box when click a row or a checkbox in a row
    $('.impact-assessment-icon span.fa-layers').on('click', function() {
      let icon = $(this).closest('.impact-assessment-icon');
      let hiddenCheckbox = icon.find('input.hidden-ccid');
      let hiddenDestroy = icon.find('input.hidden-destroy');

      // Check/uncheck the box and update val, depending on current state
      // Also update active class of card
      // Also update hidden id field of assessment category
      if (hiddenCheckbox[0].checked === false) {
        hiddenCheckbox.prop('checked', true);
        hiddenCheckbox.val(hiddenCheckbox.attr("data-cause-category-id"));
        hiddenDestroy.val('false');
        $(this).addClass('active');        
        $(this).css('opacity', 1.0);

      } else if (hiddenCheckbox[0].checked === true) {
        hiddenCheckbox.prop('checked', false);
        hiddenCheckbox.val("");
        hiddenDestroy.val('true');
        $(this).removeClass('active');
        $(this).css('opacity', 0.3);
      }

      // Blur focus; required in touch to update active class
      $(this).blur();

      // Enable/disable submit buton
      checkEnabledStatusOfSubmit();

    });

    // Prevent submitting form on disabled button click
    $('.disabled-button').click(function(e) {
      e.preventDefault();
    });
    // Submit form (custom button text doesn't work with default Rails f.submit)
    $('.submit-impact-categories').click(function() {
      $('#assessment_categories_form').submit();
    })

  }

}