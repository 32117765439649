import SignUpForm from 'src/javascripts/components/forms/SignUpForm';
import PasswordVisibility from 'src/javascripts/components/forms/PasswordVisibility';

export default class SettingsUsersSecurity {

  constructor() {}

  render() {

    // Sign up form and password visibility
    SignUpForm();
    PasswordVisibility();

    // Enable delete account button on type of 'CONFIRM'
    $('#confirm_deletion').on('input', function() {
      let val = $(this).val();
      if (val === 'CONFIRM') {
        $('.delete-account-button').removeClass('disabled');
      } else {
        $('.delete-account-button').addClass('disabled');
      }
    });

  }

}