import {companiesBloodhound, filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import 'summernote/dist/summernote-bs4.min.js'

export default class ComplianceApprovalsEdit {

  constructor() {}

  render() {
    const accountUrl = $('body').attr('data-account-url');

    // Search portfolios
    filterTypeahead('#search_portfolios', 'portfolios', '/' + accountUrl + '/portfolios/search?', 182, 'generic');
    $('#search_portfolios').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      $(this).blur();
      $(this).closest('.form-group').find(".hidden-field").val(suggestion.value);
      $('.modal-footer').find('input').attr('disabled', false);
    });

    // Search funds
    filterTypeahead('#search_funds', 'funds', '/funds/search?', 182, 'logo');
    $('#search_funds').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      $(this).blur();
      $(this).closest('.form-group').find(".hidden-field").val(suggestion.value);
      $('.modal-footer').find('input').attr('disabled', false);
    });

    // Search users
    filterTypeahead('#search_users', 'users', '/' + accountUrl + '/users/search?', 95, 'generic');
    $('#search_users').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      $(this).blur();
      $(this).closest('.form-group').find(".hidden-field").val(suggestion.value);
      $('.modal-footer').find('input').attr('disabled', false);
    });

    // If companies search present
    if ($('#search_companies').length !== 0) {

      // Init bloodhounds
      companiesBloodhound.initialize();

      // Initiate companies typeahead as one
      $('#search_companies').typeahead({
        hint: true,
        highlight: true,
        minLength: 0,
      }, {
        name: 'companies',
        display: 'name',
        limit: 44,
        source: companiesBloodhound.ttAdapter(),
        templates: {
          suggestion(el) {
            if (el.name === null) {
              return noResultsTemplate();
            } else {
              return logoTemplate(el.name, el.symbol, el.logo_url);
            }
          }
        },
      });

      $('#search_companies').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
        $(this).blur();
        $(this).closest('.form-group').find(".hidden-field").val(suggestion.value);
        $('.modal-footer').find('input').attr('disabled', false);
      });
    }

  }
}