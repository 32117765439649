// Set y axis max value, using value range from data
import GetValueRangeMultArr from 'src/javascripts/components/charts/helpers/GetValueRangeMultArr'

export default function(data, chartName) {

  // Set max based on chart data attribute
  let adjustedMax;
  let max = $(chartName).attr('data-chart-max');

  // Return rounded integer value of chart data attribute, if exists
  if (typeof max !== 'undefined') {

    max = Math.ceil(max);

  // Otherwise calculate the min value based on returned data
  } else {

    let range = GetValueRangeMultArr(data);

    // Remove NaN from range array
    range = range.filter(value => !Number.isNaN(value));

    const minValue = Math.min.apply(Math,range);
    const maxValue = Math.max.apply(Math,range);
    const floorMin = Math.floor(minValue);
    const ceilMax = Math.ceil(maxValue);
    range = ceilMax - floorMin;

    // Adjust max to make sure it's greater than value max
    // Also adjust max depending on range from floorMin to ceilMax -- want to show some padding 
    // between top value on chart and axis max, scaled depending on value of max data
    if (range <= 10) {
      adjustedMax = (Math.round(ceilMax/1)*1) + 1;

    } else if (range < 50) {
      adjustedMax = (Math.round(ceilMax/1)*1) + 5;

    } else if (range < 100) {
      adjustedMax = (Math.round(ceilMax/10)*10) + 10;

    } else if ((range >= 100) && (range < 1000)) {
      adjustedMax = (Math.round(ceilMax/100)*100) + 50;

    } else if ((range >= 1000) && (range < 10000)) {
      adjustedMax = (Math.round(ceilMax/1000)*1000) + 500;

    } else if ((range >= 10000) && (range < 100000)) {
      adjustedMax = (Math.round(ceilMax/1000)*1000) + 1000;

    } else {
      adjustedMax = (Math.round(ceilMax/10000)*10000) + 10000;
    }
  }

  return adjustedMax;

};
