import MethodologiesFormulasForm from 'src/javascripts/components/forms/MethodologiesFormulasForm';

export default class SettingsAccountsMethodologiesFormulasEdit {

  constructor() {}

  render() {

  	MethodologiesFormulasForm();

  }

}