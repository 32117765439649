// No results / empty templates
export const noSectionsTemplate = function() {
  return '<div class="empty-message">No sections found or no additional sections available</div>';
}

export const noResultsTemplate = function() {
  return '<div class="empty-message">Nothing found</div>';
}

export const emptyTemplate = function() {
	return '<div class="empty-message">Nothing found</div>';
}

export const genericDisplayTemplate = function(name) { 
  let text = '<div class=\'d-flex align-items-center\'>';
  text += '<div class=\'mr-2\'>' + name + '</div>';
  text += '</div>';
  return text;
}

export const causeTemplate = function(name, name_short, fa_key, id) {
  let text = '<div class=\'d-flex align-items-center\'>';
  text += '<div class="mr-2 d-inline-block" width="25"><i class="fad fa-fw fa-' + fa_key + '"></i></div>';
  text += '<div class="d-inline-block mr-2">' + name_short + '</div>';
  text += '</div>';
  return text;
}

export const screenTemplate = function(name, fa_key) {
  let text = '<div class=\'d-flex align-items-center\'>';
  text += '<div class="mr-2 d-inline-block" width="25"><i class="fad fa-fw fa-' + fa_key + '"></i></div>';
  text += '<div class="d-inline-block mr-2">' + name + '</div>';
  text += '</div>';
  return text;  
}

export const logoTemplate = function(name, symbol, logo_url, id) {
  let text = '<div class=\'d-flex align-items-center\'>';
  text += '<div class="mr-2 d-inline-block"><img alt="logo" width="25px" src="' + logo_url + '"></div>';
  text += '<div class="d-inline-block mr-2">' + name + ' (' + symbol + ')</div>';
  text += '</div>';
  return text;
}

