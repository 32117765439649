import QuestionnaireResponsesTable from 'src/javascripts/components/tables/QuestionnaireResponsesTable';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {genericDisplayTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');


export default class QuestionnairesResponsesIndex {

  constructor() {}

  render() {

	  // Set account id
	  const accountUrl = $('body').attr('data-account-url');

	  // Questionnaires and companies search
	  filterTypeahead('.questionnaires-search', 'questionnaires', '/' + accountUrl + '/questionnaires/search?', 82, 'generic');
	  filterTypeahead('.companies-search', 'companies', '/' + accountUrl + '/companies/search_share_classes?', 382, 'generic');
	  filterTypeahead('#questions_search', 'questions', '/' + accountUrl + '/questionnaires/questions/search?', 282, 'generic');
	  filterTypeahead('#responses_search', 'responses', '/' + accountUrl + '/questionnaires/responses/search?', 403, 'generic');
    filterTypeahead('#users_search', 'users', '/' + accountUrl + '/users/search?', 282, 'generic');

    // Questionnaire responses table
    if ( $('#questionnaire_responses_table_wrapper').length === 0 ) {
      let table = new QuestionnaireResponsesTable($('#questionnaire_responses_table'));
      $.when( table.render() ).then( status => table.setUp('questionnaire_responses_table') )
    }

    // Expand when click on table of contents item
    $('.sticky-sidebar a.scroll-to-section').click(function(e) {
      e.preventDefault();
      let sectionId = $(this).attr('data-tag');
      setTimeout(function() {
        ScrollToTag(sectionId);  
      }, 500);
    });

    // Auto-focus on expanding dropdown
    $('.dropdown-toggle').on('click', function() {
      let tp = $(this).closest('.btn-group').find('.typeahead');
      setTimeout(function() { tp.focus() }, 300)
    });

    // Reload with attrs
    let reloadWithSelectedParams = function() {
	    let url = '/' + accountUrl + '/questionnaires/responses';
      let params = {};
      params['company_id'] = $('#questionnaire_responses_table').attr('data-company-id');
      params['status'] = $('#questionnaire_responses_table').attr('data-status');
      params['questionnaire_id'] = $('#questionnaire_responses_table').attr('data-questionnaire-id');
	    params['question_id'] = $('#questionnaire_response_answer_trends_table').attr('data-question-id');
      let userId = $('#questionnaire_responses_table').attr('data-user-id');
      if (typeof userId === 'undefined') {
        params['questionnaire_user_id'] = 'all';
      } else {
        params['questionnaire_user_id'] = userId;
      }
      Turbolinks.visit(url + '?' + $.param(params));
    }


    // On selecting a user
    $('#users_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      let existingUserIds = $('#questionnaire_responses_table').attr('data-user-id');
      let uids = suggestion.value;
      if (typeof existingUserIds !== 'undefined') {uids = uids + ',' + existingUserIds;}
    	$('#questionnaire_responses_table').attr('data-user-id', uids);
    	reloadWithSelectedParams();
    })

    // On selecting a response
    $('.companies-search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      let existingCompanyIds = $('#questionnaire_responses_table').attr('data-company-id');
      let iids = suggestion.value;
      if (typeof existingCompanyIds !== 'undefined') {iids = iids + ',' + existingCompanyIds;}
    	$('#questionnaire_responses_table').attr('data-company-id', iids);
    	reloadWithSelectedParams();
    })

    // On selecting a questionnaire
    $('.questionnaires-search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      let existingQuestionnaireIds = $('#questionnaire_responses_table').attr('data-questionnaire-id');
      let iids = suggestion.value;
      if (typeof existingQuestionnaireIds !== 'undefined') {iids = iids + ',' + existingQuestionnaireIds;}
    	$('#questionnaire_responses_table').attr('data-questionnaire-id', iids);
    	reloadWithSelectedParams();
    })

	  // On selecting a question
	  $('#questions_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
	    $('#questionnaire_response_answer_trends_table').attr('data-question-id', suggestion.value);
    	reloadWithSelectedParams();
	  })

  }

}