import Spinner from 'src/javascripts/components/utilities/Spinner';
import ListCarousels from 'src/javascripts/components/lists/ListCarousels';
import RecRequest from 'src/javascripts/components/RecRequest';
import RatingBellCurveChart from 'src/javascripts/components/charts/RatingBellCurveChart';
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';

export default class ClientsShow {

  constructor() {}

  render() {

    // List carousels
    ListCarousels();

    // Get recommendation requests
    RecRequest();

    // Render ratings chart
    if (typeof $('.rating-bell-curve-chart').data('c3-chart') === 'undefined') {
      let chart = new RatingBellCurveChart( $('.rating-bell-curve-chart') );
      chart.render();
    }

    // Show table on tab
    $('a[href="#portfoliosTableTab"]').on('shown.bs.tab', function (e) {
      if ($("#portfolios_table_wrapper").length === 0) {
        let table = new DefaultTable($('#portfolios_table'));
        $.when( table.render() ).then( status => table.setUp('portfolios_table') )
      }
    });


    // Update client closed_ratings attr on close or open
    $('#recItems.collapse').on('show.bs.collapse', function () {
      let accountUrl = $('body').attr('data-account-url');
      let clientId = $('#recItems').attr('data-client-id');
      let params = {};
      params['client'] = {};
      params['client']['closed_ratings'] = 'false';
      let url = '/' + accountUrl + '/clients/' + clientId + '?' + $.param(params);
      $.ajax({
        type: 'PATCH',
        url: url,
        dataType: 'json',
      })
    });

    $('#recItems.collapse').on('hide.bs.collapse', function () {
      let accountUrl = $('body').attr('data-account-url');
      let clientId = $('#recItems').attr('data-client-id');
      let params = {};
      params['client'] = {};
      params['client']['closed_ratings'] = 'true';
      let url = '/' + accountUrl + '/clients/' + clientId + '?' + $.param(params);
      $.ajax({
        type: 'PATCH',
        url: url,
        dataType: 'json',
      })
    });

    // Toggle option to show carousel
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      $(this).addClass('d-none');
      $('a[data-toggle="tab"]').not(this).removeClass('d-none');
    });

  }

}