export default function() {

	$('.guide-minimize').click(function() {
		$('.toast-guide').toggleClass('minimized');
	})

	$('.guide-maximize').click(function() {
		$('.toast-guide').toggleClass('maximized');
	})

}