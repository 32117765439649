import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';

export default class SdgsTable extends Table {

  render(tableId) {

    // Build url, use to get filtered data with server-side processing
    let dataUrl = $('#' + tableId).attr('data-url');

    // Set additional data vars (used below in table creation)
    let cols = GetCols(this.el);

    // Render table
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      retrieve: true,
      ajax: dataUrl,
      paging: true,
      pageLength: 5,
      searching: true,
      ordering: true,
      order: [[2, 'desc']],
      dom: 'tp',
      columns: cols,
      responsive: { details: false },
      language: { 
        infoEmpty: "No data available. Please contact us with any questions.",
        emptyTable: "No data available. Please contact us with any questions.",
        zeroRecords: "No data available. Please contact us with any questions."
      },
      createdRow(row, data, dataIndex) {},
      initComplete: function( settings ) {

        // Remove spinner after table has drawn
        $('#' + tableId).closest('.table-container').find('.spinner-container').remove();

      }

    });

    return 'rendered';
  }

  setUp(tableId, chartId) {
    return 'setup';
  }

};
