// Get column headers for table

export default function(table) {

  // Set array to add columns to 
  const cols = [];

  // Get col name from each header in the table
  $(table).find('thead th').each(function() {

    // Build hash
    let colOpt = {};

    let orderable = $(this).attr('data-orderable');
    if (orderable === 'false') {
      orderable = false
    } else {
      orderable = true
    }
    colOpt['orderable'] = orderable;

    let dbColName = $(this).attr('data-db-col');
    let dataName = $(this).attr('data-name');
    colOpt['data'] = dataName;
    colOpt['name'] = dbColName;

    // Set visibility boolean based on true/false string, with default of 'true'
    let visible = $(this).attr('data-visible');
    if (typeof visible === 'undefined') {visible = 'true'};
    visible = (visible == 'true');
    colOpt['visible'] = visible;

    // Set col type
    let colType = $(this).attr('data-col-type');
    if (typeof colType !== 'undefined') {
      colOpt['type'] = colType;
    };

    return cols.push(colOpt);

  });

  return cols;

};