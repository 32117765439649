import ClientsTypeahead from 'src/javascripts/components/typeaheads/ClientsTypeahead';
import Spinner from 'src/javascripts/components/utilities/Spinner';

export default class ClientsIndex {

  constructor() {}

  render() {

    // Merge info
    const mergInfo = function() {

      // Merge info
      let clientToDelete = $('#clientToDelete').val();
      let clientToKeep = $('#clientToKeep').val();

      if ((clientToDelete.length > 0) && (clientToKeep.length > 0)) {
      	let text = "<p>This will merge <b>" + clientToDelete;
      	text += "</b> into <b>" + clientToKeep;
      	text += "</b>.</p><p>All portfolios and reports from " + clientToDelete;
      	text += " will be retained in the new merged client. If both clients have completed an Impact Assessment, the ";
      	text += " assessment for " + clientToKeep + " will be used.</p>";
      	$('#mergeInfo').empty().append(text);
      } else {
      	$('#mergeInfo').empty();
      }

    }

    // Clients typeahead
    let clientsTypeahead = new ClientsTypeahead('.clients-typeahead');
    $.when(clientsTypeahead.initializeBloodhound() )
     .then( bloodhound => clientsTypeahead.render(bloodhound) )

    $('#clientToDelete').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) { 
      let id = suggestion.value;
      $('#delete_id').val(id);
      mergInfo();
    });

    $('#clientToKeep').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) { 
      let id = suggestion.value;
      $('#client_id').val(id);
      mergInfo();
    });


  }

}