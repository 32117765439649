import GetPaymentMethodData from 'src/javascripts/components/stripe/GetPaymentMethodData';
import Subscribe from 'src/javascripts/components/stripe/Subscribe';
import UpdateCard from 'src/javascripts/components/stripe/UpdateCard';
import CancelSubscription from 'src/javascripts/components/stripe/CancelSubscription';

export default class MarketplaceProductsPreview {

  constructor() {}

  render() {

    // Get current payment method data
    GetPaymentMethodData();

    // Initiate main Stripe subscribe JS
    Subscribe();

    // Cancel subscription on click
    CancelSubscription();

    // Initiate update card JS if element present on page
    if ($('#updateCardElement').length !== 0) {UpdateCard();}

  }

}