// Show success toast if params indicate success
import 'bootstrap/js/dist/toast.js';

export default function() {

  // Build toast
  let toast = "<div class=\"toast\" role=\"alert\">";
  toast += "<div class=\"toast-header\">";
  toast += "<span class=\"mr-auto font-weight-bold\">Update successful</span>";
  toast += "<button type=\"button\" class=\"ml-2 mb-1 close\" data-dismiss=\"toast\" aria-label=\"Close\">";
  toast += "<span aria-hidden=\"true\">&times;</span>";
  toast += "</button></div>";
  toast += "<div class=\"toast-body\">";
  toast += "Thank you for your purchase. You can check all invoices and billing data under Firm settings > Billing."
  toast += "</div></div>";  

  // Replace existing toast if one present, otherwise prepend to body
  if ($('.toast').length !== 0) {
    $('.toast').replaceWith(toast);
  } else {
    $('body').prepend(toast);
  }

  // Show toast  
  $('.toast').toast({autohide: false});
  return $('.toast').toast('show');

}