import MethodologiesFormulasForm from 'src/javascripts/components/forms/MethodologiesFormulasForm';

export default class SettingsAccountsMethodologiesFormulasNew {

  constructor() {}

  render() {

  	MethodologiesFormulasForm();

  }

}