import QuestionnaireResponseApprovalRequestsTable from 'src/javascripts/components/tables/QuestionnaireResponseApprovalRequestsTable';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import NavQuestionnaire from 'src/javascripts/components/nav/NavQuestionnaire';
import * as filestack from 'filestack-js';
import * as fileUpload from 'blueimp-file-upload/js/jquery.fileupload.js';

export default class QuestionnairesCollectionApprovalRequestsIndex {

  constructor() {}

  render() {

    // Add border to navbar on scroll
    NavQuestionnaire();

    // Render table
    if ( $('#questionnaire_response_approval_requests_table_wrapper').length === 0 ) {
      let table = new QuestionnaireResponseApprovalRequestsTable($('#questionnaire_response_approval_requests_table'));
      $.when( table.render() ).then( status => table.setUp('questionnaire_response_approval_requests_table') )
    }

    // Create new approval action if option passed
    let approvalRequestId = $('#questionnaire_response_approval_requests_table').attr('data-approval-request-id');
    if (typeof approvalRequestId !== 'undefined') {
      let accountUrl = $('body').attr('data-account-url');
      let responseId = $('#questionnaire_response_approval_requests_table').attr('data-response-id');
      let approvalAction = $('#questionnaire_response_approval_requests_table').attr('data-approval-action');
      let url = "/" + accountUrl + '/data-collection/responses/' + responseId + '/approval_requests/' + approvalRequestId + '/approval_actions/new';
      let params = {};
      if (typeof approvalAction !== 'undefined') {params['approval_action'] = approvalAction;}
      Turbolinks.visit(url + '?' + $.param(params));
    }

  }

}