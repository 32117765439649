import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';

export default class PortfolioScreensTableFund extends Table {

  render(tableId) {

    // Set cols
    const cols = GetCols(this.el);

    // Build url, use to get filtered data with server-side processing
    let dataUrl = this.el.attr('data-api-url');

    // Render table
    const table = $('#' + tableId).DataTable({
      ajax: dataUrl,
      pageLength: 10,
      scrollx: true,
      dom: 'tp',
      columns: cols,      
      ordering: true,
      order: [[0, 'asc']],
      language: { 
        emptyTable: "No screens information available for this fund. Please contact us with any questions.",
      },
      drawCallback: function( settings ) {

        // Hide paging if total display is equal to or less than page length
        let json = settings.json;
        if (typeof json !== 'undefined') {
          let len = json.recordsTotal;
          if (len < 11) {
            $('#' + tableId + '_paginate').addClass('d-none');
          }
        }

        // Re-init tooltips for lock icons
        $('[data-toggle=tooltip]').tooltip();

      },

    });

    return 'rendered';

  }
};