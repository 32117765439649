import Spinner from 'src/javascripts/components/utilities/Spinner';
import ImpactDistByAllFormulasChart from 'src/javascripts/components/charts/ImpactDistByAllFormulasChart';
import ImpactDistMetricScores from 'src/javascripts/components/charts/ImpactDistMetricScores';
import ImpactByFormulaTable from 'src/javascripts/components/tables/ImpactByFormulaTable';
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import MetricScoresScatter from 'src/javascripts/components/charts/MetricScoresScatter';

export default class ResearchCountriesShow {

  constructor() {}

  render() {

    // Render impact chart, if chart does not already have c3-data (already initialized)
    // Needed to prevent re-init when navigating away from page, before new body has loaded    
    if (typeof $('#impact_dist_by_all_formulas').data('c3-chart') === 'undefined') {
      let chart = new ImpactDistByAllFormulasChart( $('#impact_dist_by_all_formulas') );
      $.when( chart.getData() )
       .then( data => chart.render(data) );
    }

    // Impact by formula table
    if ($("#impact_by_formula_wrapper").length === 0) {
      let tableId = 'impact_by_formula';
      let table = new ImpactByFormulaTable($('#' + tableId));
      $.when( table.render(tableId) ).then( status => table.setUp(tableId) );
    }

    // On show/hide of disclosure or overall impact modal; only if in display mod mode
    if ($('body').hasClass('modal-open')) {
      $('.detail-modal').on('show.bs.modal', function(e) {
        $('#countryProfileShow').css('opacity', 0.2);
        $('.banner-container').css('opacity', 0.05);
      }).on('hide.bs.modal', function(e) {
        $('#countryProfileShow').css('opacity', 1.0);
        $('.banner-container').css('opacity', 1.0);
      }).on('hidden.bs.modal', function(e) {
        $('body').addClass('modal-open');
      });
    }

    // Metric scores distribution and table, only once click on data tab for first time
    $('a[href="#dataTab"]').on('shown.bs.tab', function (e) {

      if (typeof $('#metric_scores_scatter').data('c3-chart') === 'undefined') {
        let chart = new MetricScoresScatter( $('#metric_scores_scatter') );
        $.when( chart.getData() )
         .then( data => chart.render(data) );
      }

      if (typeof $('#impact_dist_metric_scores').data('c3-chart') === 'undefined') {
        let chart = new ImpactDistMetricScores( $('#impact_dist_metric_scores') );
        $.when( chart.getData() )
         .then( data => chart.render(data) );
      }

      if ($('#metric_scores_table_wrapper').length === 0) {
        let tableId = 'metric_scores_table';
        let metricScoresTable = new DefaultTable($('#' + tableId));
        $.when( metricScoresTable.render(tableId) ).then( status => metricScoresTable.setUp(tableId) );
      }

    });

    // Re-flow dist chart on click of 'Scorecard' tab
    $('a[href="#scorecardTab"]').on('shown.bs.tab', function (e) {
      setTimeout(function() {
        let distChart = $('#impact_dist_by_all_formulas').data('c3-chart');
        if (typeof distChart !== 'undefined') { 
          distChart.flush(); 
        }
      }, 500);
    });

  }

}