import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import CheckPaging from 'src/javascripts/components/tables/helpers/CheckPaging';

export default class ReturnsTableStatic extends Table {

  render(tableId) {

    // Set a spinner to indicate chart loading
    Spinner(this.el);

    // Set order from table attr or default
    let orderCol = $('#' + tableId).attr('data-order-col') || 6;

    // Render table
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      paging: false,
      pageLength: 10,
      searching: true,
      ordering: true,
      order: [[orderCol, 'desc']],
      dom: 'tp',
      drawCallback: function( settings ) {

        // Remove spinner and opacity
        $('#' + tableId).closest('.card').find('.spinner-container').remove();
        $('#' + tableId).removeClass('opaque');

      }

    });

    return 'rendered';
  }

  setUp(tableId, chartId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Set searchable function for table
    $('#search_returns_table').keyup(function(){
      oTable.search( $(this).val() ).draw();
    });

    // Ensure search input starts blank
    $('#search_returns_table').val('');

    return 'setup';

  }

};
