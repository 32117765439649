import ImpactCalculatorForm from 'src/javascripts/components/forms/ImpactCalculatorForm';

export default class AccountsImpactCalculatorsEdit {

  constructor() {}

  render() {

    ImpactCalculatorForm();

  }

}