import * as filestack from 'filestack-js';
import * as fileUpload from 'blueimp-file-upload/js/jquery.fileupload.js';

// Pick an image and update an associated attribute in DB
export default function() {

  // Set environment and account id for AWS path vars
  $('.filepicker').each(function(i, elem) {
    let fileInput    	= $(elem);
	  let env 					= fileInput.data("env");
	  let accountUrl 			= fileInput.data("account-url");
    var progressBar  	= $("<div class='bar'></div>");
    var barContainer 	= $("<div class='progress'></div>").append(progressBar);
    $('#fileUploadProgress').append(barContainer);
    fileInput.fileupload({
      fileInput:       	fileInput,
      url:             	fileInput.data('url'),
      type:            	'POST',
      autoUpload:       true,
      formData:         fileInput.data('form-data'),
      paramName:        'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
      dataType:         'XML',  // S3 returns XML if success_action_status is set to 201
      replaceFileInput: false,
      progressall: function (e, data) {
        var progress = parseInt(data.loaded / data.total * 100, 10);
        progressBar.css('width', progress + '%')
      },
      start: function (e) {
        progressBar.
          css('background', '#8c81d1').
          css('display', 'block').
          css('width', '0%').
          text("Loading...");
      },
      done: function(e, data) {
        progressBar.text("Uploading done");

        // Set submit button text
        let submitText = $('.submit-button').text();

        // Change upload div
        let nextText = '<div class=\'h5 mb-0\'>File successfully uploaded. Click '
        nextText += '<span class=\'font-weight-bold\'>' + submitText + '</span> to begin importing</div>';
        $('.filepicker-container').empty().append(nextText);

        // extract key from response
        let key = $(data.jqXHR.responseXML).find("Key").text();

        // Append key to submit button, enable
        $('.submit-button').attr('data-key', key);
        $('.submit-button').prop('disabled', false);

      },
      fail: function(e, data) {
        progressBar.
          css("background", "red").
          text("Failed");
      }
    });
  });

}