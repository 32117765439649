export default function() {

  // Set up poll to track status of PPT being created and uploaded to Amazon
  let pptPoll = () => {

    // Get rec request on page
    let reportPptId = $('.modal-body').attr('data-report-ppt-id');

    // Only continue if status indicator present (if should poll for recs is true)
    if (reportPptId.length !== 0) {

      // Set Api url to get data
      let pollUrl = '/reports/ppts/poll/' + reportPptId;

      // Execute ajax request
      $.ajax({
        type: "GET",
        dataType: "script",
        timeout: 3000,
        url: pollUrl + '.js?',
        complete() {
          setTimeout(function() { pptPoll() }, 4000);
        }
      });
    }
  };

  // Poll server for status
  let reportPptId = $('.modal-body').attr('data-report-ppt-id');
  if ((typeof reportPptId !== 'undefined') && (reportPptId.length > 0)) {
    pptPoll();
  }

}