// Nav for questionnaire pages
export default function() {

  $(window).scroll(function() {

    if ($(window).scrollTop() > 30) {

      return $('.navbar').addClass('nav-questionnaire-bordered');

    } else {

      return $('.navbar').removeClass('nav-questionnaire-bordered');
    }

  });

}