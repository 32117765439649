import Spinner from 'src/javascripts/components/utilities/Spinner';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {genericDisplayTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class QuestionnairesResponsesApprovalRequestsNew {

  constructor() {}

  render() {

    // Set account id
    const accountUrl = $('body').attr('data-account-url');

    // Responses search
    filterTypeahead('#responses_search', 'responses', '/' + accountUrl + '/questionnaires/responses/search?', 403, 'generic');


    // On selecting a response
    $('#responses_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      $('#hidden_response_id').val(suggestion.value)
    })

    // Dup approver
    $('#addApprover').click(function() {
      let approverRows = $('.approver-row');
      let last = approverRows[approverRows.length - 1];
      let newRow = $(last).clone();
      let newId = approverRows.length;
      newRow.find('.user-id-select').attr('name', 'questionnaire_response_approval_request[approvers_attributes][' + newId + '][user_id]');
      newRow.find('.user-id-select').attr('id', 'questionnaire_response_approval_request_approvers_attributes_' + newId + '_user_id');
      newRow.find('.primary-select').attr('name', 'questionnaire_response_approval_request[approvers_attributes][' + newId + '][primary]');
      newRow.find('.primary-select').attr('id', 'questionnaire_response_approval_request_approvers_attributes_' + newId + '_primary');
      $('.approver-rows-container').append(newRow);

      $('.remove-approver-row').off().click(function() {
        let len = $('.approver-row').length;
        if (len > 1) {
          $(this).closest('.approver-row').remove();
        }
      })

    });

    $('.remove-approver-row').click(function() {
      let len = $('.approver-row').length;
      if (len > 1) {
        $(this).closest('.approver-row').remove();
      }
    })

  }

}