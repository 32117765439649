import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import AddRowDataAttrs from 'src/javascripts/components/tables/helpers/AddRowDataAttrs';
import AddRowHoverButtons from 'src/javascripts/components/tables/helpers/AddRowHoverButtons';
import AddRowClickLinks from 'src/javascripts/components/tables/actions/AddRowClickLinks';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';

export default class ImpactByFormulaTable extends Table {

  render(tableId) {

    // Set a spinner to indicate chart loading
    Spinner(this.el);

    // Build url, use to get filtered data with server-side processing
    let dataUrl = BuildApiUrl(tableId);

    // Set additional data vars (used below in table creation)
    let cols = GetCols(this.el);

    // Set height if in full-window mode
    let $card = this.el.closest('.card');
    let $height = $card.attr('data-scroll-height');
    if (typeof $height === 'undefined') {
      if ($card.hasClass('full-view')) {
        $height = $card.height() - 180;
      } else {
        $height = 230;
      }
    }

    // Render table
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      retrieve: true,
      // serverSide: true,
      ajax: dataUrl,
      paging: true,
      pageLength: 5,
      lengthMenu: [[5, 10, 25, 50], [5, 10, 25, 50]],
      scrollY: $height,
      scrollX: false,
      scrollCollapse: true,
      search: true,
      ordering: true,
      order: [[0, 'asc']],
      dom: 'tlp',
      columns: cols,
      responsive: { details: false },
      language: { 
        lengthMenu: "Show _MENU_ causes",
        infoEmpty: "No impact data available. Please contact us with any questions.",
        emptyTable: "No impact data available. Please contact us with any questions.",
        zeroRecords: "No impact data available. Please contact us with any questions."
      },
      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        AddRowDataAttrs(row, data);

        // Add hover button links to rows, if passed in data
        if (typeof data.hover_links !== 'undefined') { AddRowHoverButtons(row, data.hover_links); }
      },

      drawCallback: function( settings ) {},
      initComplete: function( settings ) {

        // Remove spinner after table has fully drawn
        $('#' + tableId).find('.spinner-container').remove();

        if (settings.json.env_score !== 'undefined') {
          let env_score = settings.json.env_score;
          let soc_score = settings.json.soc_score;
          let gov_score = settings.json.gov_score;
          let env_class = settings.json.env_class;
          let soc_class = settings.json.soc_class;
          let gov_class = settings.json.gov_class;
          $('.env-indicator').empty().append(env_score);
          $('.env-indicator').addClass(env_class).removeClass('opaque');
          $('.soc-indicator').empty().append(soc_score);
          $('.soc-indicator').addClass(soc_class).removeClass('opaque');
          $('.gov-indicator').empty().append(gov_score);
          $('.gov-indicator').addClass(gov_class).removeClass('opaque');
        }

        // Filter to material causes, if present on page
        let slugs = $('#' + tableId).closest('.card').find('.show-material-causes').attr('data-material-slugs');
        if (typeof slugs !== 'undefined') {
          let s = JSON.parse(slugs).join("|");
          $('#' + tableId).DataTable().column(1).search(s, true, false).draw();
        }

      }


    });

    return 'rendered';
  }

  setUp(tableId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Set searchable function for table
    $('#search_' + tableId + '_table').keyup(function(){
      oTable.search( $(this).val() ).draw();
    });

    // Ensure search input starts blank
    $('#search_' + tableId).val('');

    // Filter to material causes on click
    $('#' + tableId).closest('.card').find('.show-all-causes').click(function() {

      // Set vars
      let chartEl = $('#' + tableId).closest('.card').find('.impact-dist-by-all-formulas');
      let url = chartEl.attr('data-api-url');
      let params = {};
      let letterGrades = chartEl.attr('data-letter-grades');
      if (typeof letterGrades !== 'undefined') {
        params['letter_grades'] = letterGrades;
      }
      url = url + '?' + $.param(params);
     

      let chart = chartEl.data('c3-chart');

      // Switch button colors
      $('#' + tableId).closest('.card').find('.show-material-causes').removeClass('btn-primary-light border font-weight-normal').addClass('btn-white');
      $(this).removeClass('btn-white').addClass('btn-primary-light border font-weight-normal');

      // Hide tooltip
      $(this).tooltip('hide');

      // Reset table
      oTable.search( '' ).columns().search( '' ).draw();

      // Update chart
      Spinner(chartEl.closest('.chart-container'));
      chartEl.addClass('opaque');
      $.when( $.getJSON(url) ).then(data => 
        chart.unload({
          done: function() {
            chart.load({
              json: data, 
              keys: {x: 'name', value: [ 'value' ]}
            });
          }
        })
      );

    });

    // Filter to material causes on click
    $('#' + tableId).closest('.card').find('.show-material-causes').click(function() {

      // Set vars
      let chartEl = $('#' + tableId).closest('.card').find('.impact-dist-by-all-formulas');
      let formulaIds = $(this).attr('data-material-formula-ids');
      let slugs = $(this).attr('data-material-slugs');
      let url = chartEl.attr('data-api-url');
      let params = {};
      params['formula_ids'] = formulaIds;
      let letterGrades = chartEl.attr('data-letter-grades');
      if (typeof letterGrades !== 'undefined') {
        params['letter_grades'] = letterGrades;
      }
      url = url + '?' + $.param(params);
     

      let chart = chartEl.data('c3-chart');

      // Switch button colors
      $('#' + tableId).closest('.card').find('.show-all-causes').removeClass('btn-primary-light border font-weight-normal').addClass('btn-white');
      $(this).removeClass('btn-white').addClass('btn-primary-light border font-weight-normal');

      // Hide tooltip
      $(this).tooltip('hide');

      // Filter table
      let s = JSON.parse(slugs).join("|");
      oTable.column(1).search(s, true, false).draw();

      // Update chart
      Spinner(chartEl.closest('.chart-container'));
      chartEl.addClass('opaque');
      $.when( $.getJSON(url) ).then(data => 
        chart.unload({
          done: function() {
            chart.load({
              json: data, 
              keys: {x: 'name', value: [ 'value' ]}
            });
          }
        })
      );

    });

    // Set on click action for table rows
    AddRowClickLinks(tableId);

    return 'setup';

  }

};
