import Spinner from 'src/javascripts/components/utilities/Spinner';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {genericDisplayTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import 'summernote/dist/summernote-bs4.min.js'

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class QuestionnairesResponsesApprovalActionsNew {

  constructor() {}

  render() {

    // Set account id
    const accountUrl = $('body').attr('data-account-url');

    // Summernote text editor
    $('.summernote').summernote({
      minHeight: 150,
      dialogsInBody: true,
      toolbar: [
        ['font', ['bold', 'italic', 'underline', 'clear']],
        ['insert', ['link']],
      ]}
    );

    // Prevent change in body modal class on close of link dialogue
    $('.link-dialog').on('hidden.bs.modal', function (e) {
      $('body').addClass('modal-open');
    });

    // Indicator of approve/reject
    $('.module-approval-action').change(function() {
      let val = $(this).val();
      if (val === 'true') {
        $(this).removeClass('bg-f-rating').addClass('bg-a-rating');
      } else if (val === 'false') {
        $(this).removeClass('bg-a-rating').addClass('bg-f-rating');
      } else {
        $(this).removeClass('bg-a-rating').removeClass('bg-f-rating');
      }
    })

    $('.approve-all').click(function() {
      $(".module-approval-action").each(function(){
        $(this).val('true').trigger('change');
      });      
    })

    $('.reject-all').click(function() {
      $(".module-approval-action").each(function(){
        $(this).val('false').trigger('change');
      });      
    })

    // On select of overall approval
    $('#approval_action_action').change(function() {
      let sel = $(this).val();
      if (sel === 'approve') {
        $(this).removeClass('bg-f-rating').addClass('bg-a-rating');
      } else if (sel === 'reject') {
        $(this).removeClass('bg-a-rating').addClass('bg-f-rating');
      } else {
        $(this).removeClass('bg-a-rating').removeClass('bg-f-rating');
      }
      let rejectedCount = 0;
      $(".module-approval-action").each(function(){
        let value = $(this).val();
        if ((value == "false") || (value === '')) {rejectedCount += 1;}
      });
      if ((rejectedCount > 0) && (sel === "approve")) {

        $(this).val("");
        $(this).removeClass('bg-a-rating').removeClass('bg-f-rating');

        let toast;
        toast = "<div class=\"toast\" role=\"alert\">";
        toast += "<div class=\"toast-header\">";
        toast += "<span class=\"mr-auto font-weight-bold\">Cannot approve</span>";
        toast += "<button type=\"button\" class=\"ml-2 mb-1 close\" data-dismiss=\"toast\" aria-label=\"Close\">";
        toast += "<span aria-hidden=\"true\">&times;</span>";
        toast += "</button></div>";
        toast += "<div class=\"toast-body\">";
        toast += "Cannot approve the request until all modules are approved. Please select 'Approve' for all modules or take a different overall action.";
        toast += "</div></div>";  

        // Replace existing toast if one present, otherwise prepend to body
        if ($('.toast').length !== 0) {
          $('.toast').replaceWith(toast);
        } else {
          $('body').prepend(toast);
        }

        // Show toast  
        $('.toast').toast({delay: 20000});
        $('.toast').toast('show');

      }
    })

  }

}