import PasswordVisibility from 'src/javascripts/components/forms/PasswordVisibility';

export default class SettingsAccountsIntegrationsIndex {

  constructor() {}

  render() {

    // Password visibility
    PasswordVisibility();

    $('.show-key').click(function() {
      let keyId = $(this).attr('data-key-id');
      let $input = $('#' + keyId);      
      if ($input.attr('type') === "password") {
        $input.attr('type', 'text');
        let $title = $(this).attr('data-title');
        $title = $title.replace("Show", "Hide");
        $(this).attr('data-original-title', $title);
        $(this).find('i, svg').removeClass('fa-eye').addClass('fa-eye-slash');
      } else {
        $input.attr('type', 'password');
        let $title = $(this).attr('data-title');
        $title = $title.replace("Hide", "Show");
        $(this).attr('data-original-title', $title);
        $(this).find('i, svg').removeClass('fa-eye-slash').addClass('fa-eye');
      }
    })

  }

}
