import 'summernote/dist/summernote-bs4.min.js'
export default function() {

  // Summernote text editor
  if ($('.summernote-default').length !== 0) {

    $('.summernote-default').summernote({
      minHeight: 150,
      dialogsInBody: true,
      toolbar: [
        ['font', ['bold', 'italic', 'underline', 'clear']],
        ['para', ['ul', 'ol']],
        ['insert', ['link']],
      ],
      inheritPlaceholder: true
    });

  }

  // Summernote text editor
  if ($('.summernote-default-sm').length !== 0) {

    $('.summernote-default-sm').summernote({
      minHeight: 50,
      dialogsInBody: true,
      toolbar: [
        ['font', ['bold', 'italic', 'underline', 'clear']],
      ],
      inheritPlaceholder: true
    });

  }

  // Summernote text editor
  if ($('.summernote-spelling').length !== 0) {

    $('.summernote-spelling').summernote({
      dialogsInBody: true,
      toolbar: [],
      inheritPlaceholder: true
    });

  }

}