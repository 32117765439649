import Spinner from 'src/javascripts/components/utilities/Spinner';

export default class SettingsAccountsRolesNew {

  constructor() {}

  render() {

    // Update user's settings preferences on select
    let $settingInput = $('input.dashboard-inclusion-input');
    if (typeof $settingInput !== 'undefined') {
      $settingInput.change(function() {
        $('#dashboardModulesModal .modal-body').css('opacity', 0.25)
        Spinner($('#dashboardModulesModal .modal-content'));

        // Set attrs
        let roleId = $(this).attr('data-role-id');
        let accountUrl = $('body').attr('data-account-url');
        let itemId = $(this).attr('data-item-id');
        let inclusionId = $(this).attr('data-inclusion-id');

        // Set params as true or false
        let params = {};
        params['role_dashboard_inclusions'] = {}
        params['role_dashboard_inclusions']['dashboard_item_id'] = itemId;

        if ( $(this)[0].checked ) {
          $.ajax({
            type: 'POST',
            dataType: "json",
            url: '/' + accountUrl + '/roles/' + roleId + '/role_dashboard_inclusions.js?' + $.param(params),
            complete(result) { 
              $('#dashboardModulesModal').attr('data-changed', 'true');
              $('#dashboardModulesModal .modal-content').find('.spinner-container').remove();
              $('#dashboardModulesModal .modal-body').css('opacity', 1)
            }
          })

        } else {
          $.ajax({
            type: 'DELETE',
            dataType: "json",
            url: '/' + accountUrl + '/roles/' + roleId + '/role_dashboard_inclusions/' + inclusionId + '.js?',
            complete(result) { 
              $('#dashboardModulesModal').attr('data-changed', 'true');
              $('#dashboardModulesModal .modal-content').find('.spinner-container').remove();
              $('#dashboardModulesModal .modal-body').css('opacity', 1)
            }
          })

        }

      });

    }

  }

}
