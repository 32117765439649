import ToggleReturnsChart from 'src/javascripts/components/charts/actions/ToggleReturnsChart';
import ReturnsDollarsChart from 'src/javascripts/components/charts/ReturnsDollarsChart';
import ReturnsChart from 'src/javascripts/components/charts/ReturnsChart';
import ReturnsTable from 'src/javascripts/components/tables/ReturnsTable';

export default function() {

  if ($('.generating-performance-message').length > 0) {

    // Start status poll if importing
    let performanceStatusPoll = () => {

      // Get generating message on page
      let isGenerating = $('.generating-performance-message');

      // Only continue if status indicator present (if should poll for recs is true)
      if (isGenerating.length !== 0) {

        // Set Api url to get data
        let accountUrl = isGenerating.attr('data-account-url');
        let portfolioId = isGenerating.attr('data-portfolio-id');
        let token = isGenerating.attr('data-token');
        let params = {};
        params['token'] = token;
        let url = '/' + accountUrl + '/portfolios/' + portfolioId + '/returns/check_performance_status?' + $.param(params);

        // Execute ajax request (using js erb template to render content so can control profile styling more easily)
        // Must specify '.js' otherwise processes as JSON
        $.ajax({
          type: "POST",
          dataType: "application/json",
          timeout: 3000,
          url: url,
          complete(result) { 
            let resultStatus = JSON.parse(result.responseText);
            if ((typeof resultStatus !== 'undefined') && (resultStatus.statusText === "complete")) {

              // Remove generating message, unhide others
              $('.generating-performance-message').closest('.chart-container').remove()
              $('#assetFinancialList').find('.chart-container').removeClass('d-none');
              $('#assetFinancialList').find('.table-responsive').removeClass('d-none');

              // Initialize a new chart instance for each chart on the page
              if (($('#returns_chart').attr('data-return-option') === 'dollar') && (typeof $('#returns_chart').data('c3-chart') === 'undefined')) {

                // Set chartId and chart object
                // Get data, render chart, set up chart, and create connected table, if exists
                let chart = new ReturnsDollarsChart($('#returns_chart'));
                $.when(chart.getData())
                 .then( data => chart.render(data))
                 .then( chartObj => chart.setUp(chartObj));

              } else if (($('#returns_chart').attr('data-return-option') === 'percentage') && (typeof $('#returns_chart').data('c3-chart') === 'undefined')) {

                // Set chartId and chart object
                // Get data, render chart, set up chart, and create connected table, if exists
                let chart = new ReturnsChart($('#returns_chart'));
                $.when(chart.getData())
                 .then( data => chart.render(data))
                 .then( chartObj => chart.setUp(chartObj));

              }

              // Toggle returns chart between hypothetical growth and percentage returns
              ToggleReturnsChart();

              // Portfolio items return attrs, if present on page
              if ($('#returns_table').length > 0) {
                let returnsTable = new ReturnsTable($('#returns_table'));
                $.when( returnsTable.render('returns_table') ).then( status => returnsTable.setUp('returns_table') );
              }

            } else {

              // Continue poll if not complete
              setTimeout(function() { performanceStatusPoll() }, 4000);

            }

          }
        });
      }
    };

    // Poll server for status of mapped items, if generating message present
    if ($('.generating-performance-message').length !== 0) { performanceStatusPoll(); }

  }

}