export default function() {

  $('#copyUrl').click(function() {

    /* Get the text field */
    let copyText = document.querySelector('#copyText');
    
    /* Select the text field */  
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");

    // Update tooltiop
    $(this).attr('data-title', "Copied!");
    $(this).attr('data-original-title', "Copied!");
    $(this).tooltip('hide');
    $(this).tooltip('show');

  })

}