export default function() {

  $('.expand-content').click(function() {
    let $parent = $(this).closest('.expand-parent');
    let $expandable = $parent.find('.expandable-content');
    
    // Expand content
    $expandable.toggleClass('expanded');

    // Adjust tooltip      
    if ( $expandable.hasClass('expanded') ) {
      $(this).attr('data-title', 'Click to hide additional information');
      $(this).attr('data-original-title', 'Click to hide additional information');
    } else {
      $(this).attr('data-title', 'Click to view more information');
      $(this).attr('data-original-title', 'Click to view more information');
    }

    // Hide tooltip
    $(this).tooltip('hide');

  });

}