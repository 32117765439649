import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import {companiesBloodhound, fundsBloodhound, filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {genericDisplayTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

export default function() {

  // Format display numbers in form
  NumberDisplay();

  // Funds
  filterTypeahead('#fund_benchmark_search', 'funds', '/funds/search?', 42, 'logo');

  // On selecting a fund as benchmark
  $('#fund_benchmark_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {

    // Set hidden field value
    let benchmarkId = suggestion.value;
    $('#benchmark_fund_id').val(benchmarkId);

  })

  // On selecting a fund as benchmark
  $('.clear-typeahead').click(function() {
    let t = $(this).closest('.form-group').find('.typeahead');
    t.typeahead('val', '');
    t.focus();
  })

  // Update initial investment hidden field on type of text field
  $(".value-text").on("change paste keyup", function() {

    // skip for arrow keys
    if(event.which >= 37 && event.which <= 40) return;

    // Update related numeric input (remove comma, return to value)
    let numericInput = $(this).closest('.form-group').find('.numeric-hidden-field');
    let numericVal = parseFloat( $(this).val() );
    if (Number.isNaN(numericVal)) {
      numericVal = 1;
      numericInput.val(numericVal);
    } else if (numericVal < 1) {
      numericVal = 100000;
      numericInput.val(numericVal);
      $(this).val(('100000'));
      ToastCustom('Too small', 'Value must be greater than 0', 3000);
    } else if (numericVal >= 1000000000000) {
      numericVal = 100000;
      numericInput.val(numericVal);
      $(this).val(('100000'));
      ToastCustom('Too large', 'Max value is $999,999,999,999', 3000);
    } else {
      numericInput.val(numericVal);
    }

  });

}