// Generate recommendations from a rec request on a page
import Spinner from 'src/javascripts/components/utilities/Spinner';

export default function() {

  // Set poll function to poll server for status of recommendations
  let recsPoll = () => {

    // Get rec request on page
    let recRequest = $('.rec-request[data-should-poll-for-recs]');

    // Only continue if status indicator present (if should poll for recs is true)
    if (recRequest.length !== 0) {

      // Set Api url to get data
      let dataUrl = '/recommended/lists/poll';

      // Set empty hash to build offset/length params
      let params = {};

      // Set formula id to get recs for
      params['formula_id'] = recRequest.attr('data-formula-id');
      params['record_type'] = recRequest.attr('data-record-type');

      // Set account id if present
      let accountId = recRequest.attr('data-account-id');
      if (typeof accountId !== 'undefined') {params['account_id'] = accountId;}

      // Set record count to load
      params['offset'] = recRequest.attr('data-offset');
      params['length'] = recRequest.attr('data-length');

      // Execute ajax request (using js erb template to render content so can control profile styling more easily)
      // Must specify '.js' otherwise processes as JSON
      $.ajax({
        type: "GET",
        dataType: "script",
        timeout: 3000,
        url: dataUrl + '.js?' + $.param(params),
        complete() {
          setTimeout(function() { recsPoll() }, 4000);
        }
      });
    }
  };

  // Poll server for status of recs
  return recsPoll();

}
