import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import Swal from 'sweetalert2';
import CheckPaging from 'src/javascripts/components/tables/helpers/CheckPaging';

export default class ManualTable extends Table {

  render() {

    // Set a spinner to indicate chart loading
    Spinner(this.el);

    // Set table id
    const tableId = this.el.attr('id');

    // Set height if in full-window mode
    let $card = this.el.closest('.card');
    let $cardBody = $card.find('.card-body');
    let $height = $card.attr('data-scroll-height');
    if (typeof $height === 'undefined') {
      $height = $cardBody.height();
    }

    // Render table
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      paging: false,
      dom: 't',
      ordering: true,
      order: [[1, 'asc']],
      searching: true,
      scrollY: $height,
      scrollCollapse: true,
      drawCallback: function( settings ) {

        // Hide paging if total display is equal to or less than page length
        CheckPaging(tableId, settings);

        // Remove spinner after table has drawn
        $('#' + tableId).find('.spinner-container').remove();        

      }


    });

    return 'rendered';
  }

  setUp(tableId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Set searchable function for table
    $('#search_' + tableId).keyup(function(){
      oTable.search( $(this).val() ).draw();
    });

    // Ensure search input starts blank
    $('#search_' + tableId).val('');

    return 'setup';

  }

}
