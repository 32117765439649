import GetPaymentMethodData from 'src/javascripts/components/stripe/GetPaymentMethodData';
import GetUpcomingInvoice from 'src/javascripts/components/stripe/GetUpcomingInvoice';
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import UpdateCard from 'src/javascripts/components/stripe/UpdateCard';

export default class MarketplaceStripeInvoicesBilling {

  constructor() {}

  render() {

    // Get current payment method data
    GetPaymentMethodData();

    // Get upcoming invoice data, if user has a subscription
    if ($('.get-upcoming-invoice').length !== 0) {GetUpcomingInvoice();}

    // Set invoices table
    if ($("#past_invoices_wrapper").length === 0) {
      let table = new DefaultTable($('#past_invoices'));
      $.when( table.render() ).then( status => table.setUp('past_invoices') );
    }

    // Initiate update card JS if element present on page
    if ($('#updateCardElement').length !== 0) {UpdateCard();}

  }

}