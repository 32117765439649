import ResearchFiltering from 'src/javascripts/components/ResearchFiltering';

export default class ResearchFundsIndex {

  constructor() {}

  render() {

    // Filtering functions
    ResearchFiltering();


  }

}