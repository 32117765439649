import QuestionnaireResponsesForm from 'src/javascripts/components/questionnaires/QuestionnaireResponsesForm'

export default class QuestionnairesResponsesNew {

  constructor() {}

  render() {

  	QuestionnaireResponsesForm();

  }

}