import QuestionnaireResponsesForm from 'src/javascripts/components/questionnaires/QuestionnaireResponsesForm'

export default class QuestionnairesResponsesEdit {

  constructor() {}

  render() {

  	QuestionnaireResponsesForm();

  }

}