export default class AccountsResearchApiDocs {

  constructor() {}

  render() {

  	// Switch preview
  	$('.change-language .dropdown-item').click(function() {
  		let lang = $(this).attr('data-language');
  		$('.request-preview').addClass('d-none');
  		$('.request-preview[data-language="' + lang + '"').removeClass('d-none');
  		$('.language-dropdown .dropdown-toggle').empty().append(lang);
  	});

  }

}