import * as d3 from 'd3';
import * as c3 from 'c3';
import Chart from 'src/javascripts/components/Chart';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import SetColors from 'src/javascripts/components/charts/helpers/SetColors';
import AdverseIndicatorChartTooltip from 'src/javascripts/components/charts/tooltips/AdverseIndicatorChartTooltip';
import FormatString from 'src/javascripts/components/utilities/FormatString';

export default class PortfolioAdverseIndicatorsTrend extends Chart {

  getData() {

    // Set chart element id
    const chartId = this.el.attr('id');

    // Build url
    let url = this.el.attr('data-api-url');
    let params = {};
    let sfdrIndicatorId = this.el.attr('data-sfdr-indicator-id');
    let backPath = this.el.attr('data-back-path');
    if (typeof sfdrIndicatorId !== 'undefined') {params['sfdr_indicator_id'] = sfdrIndicatorId;}
    if (typeof backPath !== 'undefined') {params['back_path'] = backPath;}
    url = url + '?' + $.param(params);

    // Get data
    return $.getJSON(url);

  }

  render(data) {

    // Set id
    let chartId = this.el.attr('id');

    // Set indicators data
    let indicators_data = data.data.adverse_indicators;
    let ticks = data.data.ticks;

    // Set title
    let chartTitle = data.data.sfdr_indicator;
    let chartSubtitle = data.data.metric_uom;
    let currentScore = data.data.current_score;
    $('.adverse-indicators-chart-title .title-text').empty().append(chartTitle);
    $('.adverse-indicators-chart-title .title-data').empty().append(currentScore);

    // Render chart
    var chart = c3.generate({
      bindto: '#' + chartId,
      data: {
        x: 'date',
        columns: indicators_data,
        type: 'line',
        color: function (color, d) { return '#8c81d1'; }
      },
      axis: {
        x: {
          show: true,
          type: 'timeseries',
          tick: {
            values: ticks
          },
        },
        y: {
          show: false,
          padding: {left: 90},
          // inner: false,
          // padding: {bottom: 2},
        }
      },
      point: {r: 10},
      legend: {show: false},
      tooltip: {
        format: {},
        contents(d, defaultTitleFormat, defaultValueFormat, color) {
          return AdverseIndicatorChartTooltip(this, d, defaultTitleFormat, defaultValueFormat, color, data.data);
        }
      },
      grid: {
        y: {show: true},
        x: {show: false}
      }
    });

    // Add chart data to el
    $('#' + chartId).data('c3-chart', chart);

    // Remove spinner and opacity if any
    let card = $('#' + chartId).closest('.card');
    $(card).find('.spinner-container').remove();
    card.find('.card-body').removeClass('opaque');
    setTimeout(function() {
      $('#' + chartId).closest('.chart-container').css('opacity', 1);
    }, 500);

    // // Set ratings metrics
    // let high = FormatString(parseFloat(data.high), 'rating', 1, false, true, false, letterGrades);
    // let low = FormatString(parseFloat(data.low), 'rating', 1, false, true, false, letterGrades);
    // let avg = FormatString(parseFloat(data.avg), 'rating', 1, false, true, false, letterGrades);
    // let change = FormatString(parseFloat(data.change), 'decimal', 2, true, true, false, letterGrades);
    // $('#historicalImpactRatings .annual-high').empty().append(high);
    // $('#historicalImpactRatings .annual-low').empty().append(low);
    // $('#historicalImpactRatings .annual-avg').empty().append(avg);
    // $('#historicalImpactRatings .annual-change').empty().append(change);

    // Append change to chart indicator
    // if (parseFloat(data.change) !== 0) {
    //   $('#ratingTrend .chart-total-indicator').empty().append(change);
    // }

    return chart;
  }

};
