export default class AssessmentsQuestions {

  constructor() {}

  render() {

    // Don't submit form on click of disabled buttong
    $('.disabled-button').click(function(e) {
    	e.preventDefault();
    })
    
    // Submit form on click (default Rails submit button doesn't work with fa icons)
    $('#update_assessment_form_submit').click(function() {
      $('#update_assessment_form').submit();
    });

    // Enable submit button on select of radio
    $('.assessment-question-check').click(function() {

      // Get current value of primary interest field
      let interests = []
      $('.assessment-question-check:checked').each(function() {
        interests.push($(this).val());
      });

      // Update hidden field
      $('#assessment_primary_interest').val(interests);

      // Check length of checked items; if greater than 0, show next button
      let checkedLength = $('.assessment-question-check:checked').length;
      if (checkedLength > 0) {
        $('.disabled-button').addClass('d-none');
        $('.enabled-button').removeClass('d-none');
      } else {
        $('.enabled-button').addClass('d-none');        
        $('.disabled-button').removeClass('d-none');
      }
    });

  }

}