// Set tooltip format for metric scores scatter chart
export default function(chart, d, defaultTitleFormat, defaultValueFormat, color, data) {
  
  let text;
  const chartId = $(chart.api.element).attr('id');

  let indicators = data.adverse_indicators;
  let desc = data.description;
  let indicatorNumber = data.indicator_number;
  let sfdrIndicator = data.sfdr_indicator;
  let formatted_indicators = data.formatted_indicators;

  let i = 0;
  while (i < d.length) {
    if (!(d[i] && (d[i].value || (d[i].value === 0)))) {
      i++;
      continue;
    }

    let ind = d[i].index;
    let date = indicators[0][ind + 1];
    let score_base = formatted_indicators[ind];

    if (!text) {
      text = '<table class=\'' + chart.CLASS.tooltip + '\' >';
      text += '<tr><th class=\'bg-primary-light\'>' + sfdrIndicator + '</th></tr>';
    }

    text += '<tr class=\'' + chart.CLASS.tooltipName + '-' + d[i].id + '\'>';
    text += '<td class=\'date\'><div class=\'d-flex\'><div class=\'mr-2\'>Indicator:</div>'; 
    text += '<div class=\'ml-auto\'>' + indicatorNumber + '</div></div>'; 
    text += '</td></tr>';
    text += '<tr class=\'' + chart.CLASS.tooltipName + '-' + d[i].id + '\'>';
    text += '<td class=\'score\'><div class=\'d-flex\'><div class=\'mr-2\'>Data:</div>'; 
    text += '<div class=\'ml-auto\'>' + score_base + '</div></div>'; 
    text += '</td></tr>';
    text += '<tr class=\'' + chart.CLASS.tooltipName + '-' + d[i].id + '\'>';
    text += '<td class=\'date\'><div class=\'d-flex\'><div class=\'mr-2\'>Date:</div>'; 
    text += '<div class=\'ml-auto\'>' + date + '</div></div>'; 
    text += '</td></tr>';
    text += '<tr class=\'c3-tooltip-name-description\'>';
    text += '<td class=\'description\'><div class=\'py-2\'>' + desc + '</div></div></div>'; 
    text += '</td></tr>';
    i++;
  }

  return text + '</table>';

};
