import 'bootstrap/js/dist/toast.js';

// Show a custom toast message and title 
export default function(title, message, delay = 5000, autohide = true) {

  // Build toast
  let toast = "<div class=\"toast\" role=\"alert\">";
  toast += "<div class=\"toast-header\">";
  toast += "<span class=\"mr-auto font-weight-bold\">" + title + "</span>";
  toast += "<button type=\"button\" class=\"ml-2 mb-1 close\" data-dismiss=\"toast\" aria-label=\"Close\">";
  toast += "<span aria-hidden=\"true\">&times;</span>";
  toast += "</button></div>";
  toast += "<div class=\"toast-body\">";
  toast += message;
  toast += "</div></div>";  

  // Replace existing toast if one present, otherwise prepend to body
  if ($('.toast').length !== 0) {
    $('.toast').replaceWith(toast);
  } else {
    $('body').prepend(toast);
  }

  // Show toast  
  $('.toast').toast({delay: delay, autohide: autohide});
  return $('.toast').toast('show');

}