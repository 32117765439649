// Create colored/formatted string
export default function(number, format, decimals, includeValSign, includeColorClass, includeParens, letterGrades = "true") {

  // Add pre and post signs, depending on format passed
  let colorClass, leftParen, letterRating, postSign, preSign, rightParen, valueSign;
  if (format === 'currency') { preSign = '$'; } else { preSign = ''; }
  if (format === 'percentage') { postSign = '%'; } else { postSign = ''; }

  // Add value sign if option passed is true
  if (includeValSign === true) {
    if (number === 0) { valueSign = ''; } else if (number > 0) { valueSign = '+'; } else { valueSign = '-'; }
  } else {
    if (number < 0) { valueSign = '-'; } else { valueSign = ''; }
  }

  // Add color class (and letter rating if net impact chart) if option passed is true
  if (includeColorClass === true) {

    if (format === 'rating') {
      if (number >= 80) {
        colorClass = 'text-a-rating';
        letterRating = 'A';
      } else if ((number >= 60) && (number < 80)) {
        colorClass = 'text-b-rating';
        letterRating = 'B';
      } else if ((number >= 40) && (number < 60)) {
        colorClass = 'text-c-rating';
        letterRating = 'C';
      } else if ((number >= 20) && (number < 40)) {
        colorClass = 'text-d-rating';
        letterRating = 'D';
      } else {
        colorClass = 'text-f-rating';
        letterRating = 'F';
      }
    } else {
      if (number === 0) { colorClass = ''; } else if (number > 0) { colorClass = 'text-success'; } else { colorClass = 'text-danger'; }
    }
  } else {
    colorClass = '';
  }

  // Add parentheses if option passed is true
  if (includeParens === true) {
    leftParen = '(';
    rightParen = ')';
  } else {
    leftParen = '';
    rightParen = '';
  }

  // Build and return string
  let string = '<div class=\'d-inline-block ' + colorClass + '\'>' + leftParen + valueSign + preSign;
  if ((format === 'rating') && (leftParen === '') && (letterGrades === "true")) {
    string += letterRating + " ("; 
  }
  string += Math.abs(number).toLocaleString("en", {minimumFracctionDigits: decimals, maximumFractionDigits: decimals});
  string += postSign + rightParen; 
  if ((format === 'rating') && (rightParen === '') && (letterGrades === "true")) { string += ")"; }
  string += '</div>';
  return string;

}
