import Spinner from 'src/javascripts/components/utilities/Spinner';
import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';
import ExpandContent from 'src/javascripts/components/utilities/ExpandContent';
import ExpandExcerpt from 'src/javascripts/components/utilities/ExpandExcerpt';
import StickySidebar from 'src/javascripts/components/nav/StickySidebar';
import PortfolioCausesTable from 'src/javascripts/components/tables/PortfolioCausesTable';
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import PortfolioScreensPoll from 'src/javascripts/components/polling/PortfolioScreensPoll';
import ImpactDistPortfolio from 'src/javascripts/components/charts/ImpactDistPortfolio';
import PortfolioItemsTable from 'src/javascripts/components/tables/PortfolioItemsTable';
import ResearchFiltering from 'src/javascripts/components/ResearchFiltering';
import ImpactDistMetricScores from 'src/javascripts/components/charts/ImpactDistMetricScores';
import CarbonComparison from 'src/javascripts/components/charts/CarbonComparison';
import PortfolioAdverseIndicatorsPoll from 'src/javascripts/components/polling/PortfolioAdverseIndicatorsPoll';
import PortfolioAdverseIndicatorsTrend from 'src/javascripts/components/charts/PortfolioAdverseIndicatorsTrend';
import PortfolioAdverseIndicatorsTable from 'src/javascripts/components/tables/PortfolioAdverseIndicatorsTable';
import SdgsTable from 'src/javascripts/components/tables/SdgsTable';
import SdgContributionPieChart from 'src/javascripts/components/charts/SdgContributionPieChart';

export default class PortfoliosVantageLevelOne {

  constructor() {}

  render() {

    // Set account url
    const accountUrl = $('body').attr('data-account-url');

    // Expand/minimize profile details
    ExpandContent();

    // Sticky sidebar scrolling
    StickySidebar();

    // Expand when click on table of contents item
    $(window).scroll(function(e) {
      let scroll = $(window).scrollTop();
      if (scroll > 130) {
        $('.sidebar-title-info').removeClass('min-height');
      } else {
        $('.sidebar-title-info').addClass('min-height');
      }
    });
    let scroll = $(window).scrollTop();
    if (scroll > 130) {
      $('.sidebar-title-info').removeClass('min-height');
    } else {
      $('.sidebar-title-info').addClass('min-height');
    }

    // Scroll to anchor if present
    let anchor = $('#profileTabContent').attr('data-anchor');
    if (typeof anchor !== 'undefined') {
      let toExpand = $('#' + anchor).find('.collapse');
      toExpand.collapse('show');
      $('.collapse').not(toExpand).collapse('hide');
      setTimeout(function() {
        ScrollToTag(anchor);
      }, 500);
    }

    // Start import status poll if importing
    let importPoll = () => {

      // Get generating message on page
      let isGenerating = $('.generating-message');

      // Only continue if status indicator present (if should poll for recs is true)
      if (isGenerating.length !== 0) {

        // Set Api url to get data
        let portfolioId = isGenerating.attr('data-portfolio-id');
        let modelId = isGenerating.attr('data-model-id');
        let params = {};
        params['portfolio_id'] = portfolioId;
        params['scope'] = 'portfolio';
        let url = '/' + accountUrl + '/portfolios/models/' + modelId + '/imports/check_import_status.js?' + $.param(params);

        // Execute ajax request (using js erb template to render content so can control profile styling more easily)
        // Must specify '.js' otherwise processes as JSON
        $.ajax({
          type: "POST",
          dataType: "script",
          timeout: 3000,
          url: url,
          complete() {
            setTimeout(function() { importPoll() }, 4000);
          }
        });
      }
    };

    // Poll server for status of mapped items, if generating message present
    if ($('.generating-message').length !== 0) { importPoll(); }

    // Controversy tab on shown
    $('#controversiesList').on('shown.bs.collapse', function (e) {
      if (($('#cont_table').length > 0) && ($('#cont_table tbody td').length === 0)) {
        ResearchFiltering();
      }

      // Filter by date
      $('#controversiesDate').change(function() {
        let selected = $(this).val();
        $('#researchResultsCard').attr('data-date', selected);
        let $table = $('#cont_table').DataTable();
        $table.clear().destroy();
        ResearchFiltering();
      })

    });


    // Portfolio items, if present on page
    $('#assetRatingsList').on('shown.bs.collapse', function (e) {

      if (($('.portfolio-items-table').length > 0) && ($('.portfolio-items-table tbody td').length === 0)) {
        $('.portfolio-items-table').each(function() {
          let tableId = $(this).attr('id');
          let portfolioItemsTable = new PortfolioItemsTable($('#' + tableId));
          $.when( portfolioItemsTable.render(tableId) ).then( status => portfolioItemsTable.setUp(tableId) );
        })
      };

      // Impact dist chart, if chart does not already have c3-data (already initialized)
      if (typeof $('#impact_dist_portfolio').data('c3-chart') === 'undefined') {
        if ($('#impact_dist_portfolio').length > 0) {
          let chart = new ImpactDistPortfolio( $('#impact_dist_portfolio') );
          $.when( chart.getData() )
           .then( data => chart.render(data) );
        }
      } else {
        if ($('#impact_dist_portfolio').length > 0) {
          let chart = $('#impact_dist_portfolio').data('c3-chart');
          chart.flush;
        }        
      }

    });

    // Render risk topics chart if not already loaded
    if ( ($('#risk_topics').length !== 0) && (typeof $('#risk_topics').data('c3-chart') === 'undefined')) {
      let portfolioId = $('#risk_topics').attr('data-portfolio-id');
      let url = "/" + accountUrl + "/portfolios/" + portfolioId + "/ratings/risk_topics?";
      let backPath = $('#risk_topics').attr('data-back-path');
      backPath = backPath + '?tab=riskTab';
      let params = {};
      params['back_path'] = backPath;
      Spinner($('#riskTopics'));
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url + $.param(params)
      });

    };


    // Risk categories visual, if present on page but not already loaded
    if (($('#riskCategoriesContainer').length > 0) && ($('.risk-category-card').length === 0)) {
      let portfolioId = $('#riskCategoriesContainer').attr('data-portfolio-id');
      let url = "/" + accountUrl + "/portfolios/" + portfolioId + "/metric_scores/risk_categories";
      Spinner($('#riskCategoriesContainer'));

      // Load visual
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url + '.js'
      });

      // Remove ajax-loaded resources before Turbolinks cache; otherwise loads twice on back-button
      $(document).on("turbolinks:before-cache", function() {
        $('#riskCategoriesContainer').empty();
      })

    } 

    // Maturity assessment, if present on page but not already loaded
    if (($("#policiesAssessment").length !== 0) && ($('#policiesAssessment .table').length === 0)) {
      let portfolioId = $("#policiesAssessment").attr('data-portfolio-id');
      let url = "/" + accountUrl + "/portfolios/" + portfolioId + "/metric_scores/policies_assessment";
      Spinner($('#policiesAssessment'));
      $('#policiesAssessment').find('.card-body').css('opacity', 0.25);
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url + '.js'
      });

      // Remove ajax-loaded resources before Turbolinks cache; otherwise loads twice on back-button
      $(document).on("turbolinks:before-cache", function() {
        $('#policiesAssessment').find('.card-body').empty();
      })

    }

    // Portfolio assessment visuals, if present on page
    if ($('#riskDriversGraphic').length > 0) {
      let portfolioId = $('#riskDriversGraphic').attr('data-portfolio-id');
      let url = "/" + accountUrl + "/portfolios/" + portfolioId + "/metric_scores/risk_drivers";
      Spinner($('#riskDriversGraphic'));

      // Load visual
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url + '.js'
      });

      // Remove ajax-loaded resources before Turbolinks cache; otherwise loads twice on back-button
      $(document).on("turbolinks:before-cache", function() {
        $('#riskDriversGraphic').empty();
      })

    } 

    // Portfolio assessment visuals, if present on page
    if ( ($('.assessment-vis').length > 0) && ($('.assessment-results-visual').length === 0) ) {

      $('#clientCausesList').on('shown.bs.collapse', function (e) {

        $('.assessment-vis').each(function() {
          let vid = $(this).attr('id');

          Spinner($('#' + vid));

          // Set params for loading metrics
          let portfolioId = $('#' + vid).attr('data-portfolio-id');
          let impactApproach = $('#' + vid).attr('data-impact-approach');
          let advocacy = $('#' + vid).attr('data-advocacy');
          let backPath = $('#' + vid).attr('data-back-path');
          let url = '/' + accountUrl + '/portfolios/causes/assessment_vis';
          let params = {};
          params['portfolio_id'] = portfolioId;
          params['back_path'] = backPath;
          params['features'] = 'false';

          // Load assessment visuals
          $.ajax({
            type: "GET",
            dataType: "script",
            url: url + '.js?' + $.param(params)
          });

          // Remove ajax-loaded resources before Turbolinks cache; otherwise loads twice on back-button
          $(document).on("turbolinks:before-cache", function() {
            $('#' + vid).empty();
          })

        })

      });

    } 

    // Portfolio causes
    if (($('#portfolio_causes_table').length > 0) && ($('#portfolio_causes_table tbody td').length === 0)) {
      let portfolioCausesTable = new PortfolioCausesTable($('#portfolio_causes_table'));
      $.when( portfolioCausesTable.render('portfolio_causes_table') ).then( status => portfolioCausesTable.setUp('portfolio_causes_table') );
    }

    // Portfolio metric scores distribution
    $('#allMetricsList').on('shown.bs.collapse', function (e) {
      if (typeof $('#impact_dist_metric_scores').data('c3-chart') === 'undefined') {
        if ($('#impact_dist_metric_scores').length > 0) {
          let chart = new ImpactDistMetricScores( $('#impact_dist_metric_scores') );
          $.when( chart.getData() )
           .then( data => chart.render(data) );
        }
      }

      // Portfolio metric scores, if not already present on page
      if (($('.metric-scores-table').length > 0) && ($('.metric-scores-table tbody td').length === 0)) {
        $('.metric-scores-table').each(function() {
          let tableId = $(this).attr('id');
          let portfolioMetricScoresTable = new DefaultTable($('#' + tableId));
          $.when( portfolioMetricScoresTable.render(tableId) ).then( status => portfolioMetricScoresTable.setUp(tableId) );
        })
      }

    });

    // Portfolio carbon metrics
    $('#carbonComparisonList').on('shown.bs.collapse', function (e) {
      if (($('#portfolioCarbonComparisonMetrics').length > 0) && ($('.bar-container').length === 0)) { 
        CarbonComparison(); 
      }
    });


    PortfolioAdverseIndicatorsPoll();
    PortfolioScreensPoll();

    // Portfolio SDGs visuals, if present on page
    $('#exposureSdgsList').on('shown.bs.collapse', function (e) {
      if ( ($('.assessment-sdgs').length > 0) && ($('.assessment-sdgs .card').length === 0) ) {

        $('.assessment-sdgs').each(function() {
          let vid = $(this).attr('id');

          Spinner($('#' + vid));

          // Set params for loading metrics
          let portfolioId = $('#' + vid).attr('data-portfolio-id');
          let impactApproach = $('#' + vid).attr('data-impact-approach');
          let advocacy = $('#' + vid).attr('data-advocacy');
          let backPath = $('#' + vid).attr('data-back-path');
          let url = '/' + accountUrl + '/portfolios/causes/assessment_sdgs';
          let params = {};
          params['portfolio_id'] = portfolioId;
          params['back_path'] = backPath;

          // Load assessment visuals
          $.ajax({
            type: "GET",
            dataType: "script",
            url: url + '.js?' + $.param(params)
          });

          // Remove ajax-loaded resources before Turbolinks cache; otherwise loads twice on back-button
          $(document).on("turbolinks:before-cache", function() {
            $('#' + vid).empty();
          })

        })        

      }

      // Portfolio SDGs
      if (($('#sdgs_table').length > 0) && ($('#sdgs_table tbody td').length === 0)) {
        let sdgsTable = new SdgsTable($('#sdgs_table'));
        $.when( sdgsTable.render('sdgs_table') ).then( status => sdgsTable.setUp('sdgs_table') );
      }

    });

    $('[href="#sdgContBarTab"]').on('shown.bs.tab', function (e) {

      // Hide other indicators
      $('.sdg-contributors-legend .adjustable').removeClass('lightest').addClass('opaque');
      $('.sdg-contributors-legend .adjustable-text').removeClass('text-gray400');

    });


    // Update SDGs chart on show of tab
    $('[href="#sdgContPieTab"]').on('shown.bs.tab', function (e) {

      // Hide other indicators
      $('.sdg-contributors-legend .adjustable').removeClass('opaque').addClass('lightest');
      $('.sdg-contributors-legend .adjustable-text').addClass('text-gray400');

      // Render chart if present
      if ( ($('#sdg_contribution_pie_chart').length !== 0) && (typeof $('#sdg_contribution_pie_chart').data('c3-chart') === 'undefined') ) {

        // Set a spinner to indicate chart loading
        Spinner($('#sdgContPieTab'));

        let chart = new SdgContributionPieChart( $('#sdg_contribution_pie_chart') );
        $.when( chart.getData() )
         .then( data => chart.render(data) );
      }

    });

    $('#contributionSdgsList').on('shown.bs.collapse', function (e) {

      // Portfolio SDGs visuals, if present on page
      if ( ($('.sdgs-contribution-chart').length > 0) && ($('.sdgs-contribution-chart .card').length === 0) ) {

        $('.sdgs-contribution-chart').each(function() {
          let vid = $(this).attr('id');

          Spinner($('#' + vid));

          // Set params for loading metrics
          let accountUrl = $('#' + vid).attr('data-account-url');
          let portfolioId = $('#' + vid).attr('data-portfolio-id');
          let backPath = $('#' + vid).attr('data-back-path');
          let url = '/' + accountUrl + '/portfolios/causes/sdg_contribution';
          let params = {};
          params['portfolio_id'] = portfolioId;
          params['back_path'] = backPath;

          // Load assessment visuals
          $.ajax({
            type: "GET",
            dataType: "script",
            url: url + '.js?' + $.param(params)
          });

          // Remove ajax-loaded resources before Turbolinks cache; otherwise loads twice on back-button
          $(document).on("turbolinks:before-cache", function() {
            $('#' + vid).empty();
          })

        })        

      } 

    });


    // Portfolio adverse indicators trend
    $('.sfdr-adverse-indicators-list').on('shown.bs.collapse', function (e) {
      let el = $(this);
      let chart = $(this).find('.chart');
      let chartId = chart.attr('id');
      let table = $(this).find('.table');

      if (typeof chart.data('c3-chart') === 'undefined') {
        if (chart.length > 0) {
          let chart = new PortfolioAdverseIndicatorsTrend( $('#' + chartId) );
          $.when( chart.getData() )
           .then( data => chart.render(data) );
        }
      }

      // Portfolio adverse indicators table if present on page
      if ((table.length > 0) && (table.find('td').length === 0)) {
        let tableId = table.attr('id');
        let portfolioAdverseIndicatorsTable = new PortfolioAdverseIndicatorsTable($('#' + tableId));
        $.when( portfolioAdverseIndicatorsTable.render(tableId) ).then( status => portfolioAdverseIndicatorsTable.setUp(tableId) );
      }

      // Create adverse indicator snapshot
      if ($('.create-adverse-indicators-snapshot').length > 0) {
        $('.create-adverse-indicators-snapshot').off().on('click', function() {

          // Indicate that creating a snapshot
          el.attr('data-creating-indicator-snapshot', "true");
          
          // Empty card and start spinner
          let card = $(this).closest('.card');
          card.find('.chart-container').css('opacity', 0);
          card.find('.card-body').addClass('opaque');
          Spinner(card);

        })
      }

    });

    // Carbon underground chart if not already loaded
    if ($('#carbon_underground_chart').length !== 0) {
      let url = $('#carbon_underground_chart').attr('data-url');
      let backPath = $('#carbon_underground_chart').attr('data-back-path');
      backPath = backPath + '?tab=riskTab';
      let params = {};
      params['back_path'] = backPath;
      Spinner($('#cu200List'));
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url + '?' + $.param(params)
      });
    };


  }

}