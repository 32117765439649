import PasswordVisibility from 'src/javascripts/components/forms/PasswordVisibility';

export default class QuestionnairesCollectionResponsesWelcome {

  constructor() {}

  render() {

    // List carousels
    PasswordVisibility();

  }

}