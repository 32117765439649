export default class AssessmentsRankCategories {

  constructor() {}

  render() {

    // Sortable cards
    $('#sortableCategories').sortable({
      placeholder: "available-category-space",
      scroll: false,
      update: function(event, ui) {
        let sortOrder = $(this).sortable('toArray');
        $.each(sortOrder, function(ind, categoryId) {
          $('#' + categoryId).find('.hidden-sort-order').val(ind + 1);
        });
      }
    });

    // Submit form (custom button text doesn't work with default Rails f.submit)
    $('.submit-impact-categories').click(function() {
      $('#assessment_lead_rank_categories_form').submit();
    })

  }

}