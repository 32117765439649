import Spinner from 'src/javascripts/components/utilities/Spinner';

export default function() {

  // Set a spinner to indicate chart loading
  Spinner('#portfolioCarbonComparisonMetrics');
  $('#portfolioCarbonComparisonMetricsList').css('opacity', 0.25);

  // Set url
  let dataUrl = $('#portfolioCarbonComparisonMetrics').attr('data-url');

  // Set empty hash to build params
  let params = {};

  // Set other filters from loadMore button
  let benchmarkType = $('#portfolioCarbonComparisonMetrics').attr("data-benchmark-type");
  let benchmarkId = $('#portfolioCarbonComparisonMetrics').attr("data-benchmark-id");
  let token = $('#portfolioCarbonComparisonMetrics').attr("data-token");
  let reportId = $('#portfolioCarbonComparisonMetrics').attr("data-report-id");
  let backPath = $('#portfolioCarbonComparisonMetrics').attr("data-back-path");
  if (typeof benchmarkType !== 'undefined') {params["benchmark_type"] = benchmarkType;}
  if (typeof benchmarkId !== 'undefined') {params["benchmark_id"] = benchmarkId;}
  if (typeof token !== 'undefined') {params["token"] = token;}
  if (typeof backPath !== 'undefined') {params["back_path"] = backPath;}
  if (typeof reportId !== 'undefined') {params["report_id"] = reportId;}

  // Execute ajax request (using js erb template to render content so can control profile styling more easily)
  // Must specify '.js' otherwise processes as JSON
  return $.ajax({
    type: "GET",
    dataType: "script",
    url: dataUrl + '.js?' + $.param(params)
  });

}
