import Spinner from 'src/javascripts/components/utilities/Spinner';

export default function() {

  // Set url
  let dataUrl = $('#portfolioCarbonFootprintBody').attr('data-url');

  // Set empty hash to build params
  let params = {};

  // Function to get data
  let getCfData = function() {

    // Set a spinner to indicate chart loading
    Spinner('#portfolioCarbonFootprintBody');
    $('#portfolioCarbonFootprintChart').css('opacity', 0.25);

    // Set other filters from loadMore button
    let scopes = $('#portfolioCarbonFootprintBody').attr("data-scopes");
    let token = $('#portfolioCarbonFootprintBody').attr("data-token");
    let reportId = $('#portfolioCarbonFootprintBody').attr("data-report-id");
    let backPath = $('#portfolioCarbonFootprintBody').attr("data-back-path");
    if (typeof scopes !== 'undefined') {params["scopes"] = scopes;}
    if (typeof token !== 'undefined') {params["token"] = token;}
    if (typeof backPath !== 'undefined') {params["back_path"] = backPath;}
    if (typeof reportId !== 'undefined') {params["report_id"] = reportId;}

    // Execute ajax request (using js erb template to render content so can control profile styling more easily)
    // Must specify '.js' otherwise processes as JSON
    $.ajax({
      type: "GET",
      dataType: "script",
      url: dataUrl + '.js?' + $.param(params)
    });

  }

  // On update scopes select
  $('#cfScopesSelect').change(function() {
    let selected = $(this).val();
    $('#portfolioCarbonFootprintBody').attr('data-scopes', selected);
    getCfData();
  });

  // Get initial data
  getCfData();

}
