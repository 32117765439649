import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import MetricComparisonChart from 'src/javascripts/components/charts/MetricComparisonChart';

export default class PortfoliosMetricsShow {

  constructor() {}

  render() {

    // Render table
    if ( $('#portfolio_metric_scores_table_wrapper').length === 0 ) {
      let table = new DefaultTable($('#portfolio_metric_scores_table'));
      $.when( table.render() ).then( status => table.setUp('portfolio_metric_scores_table') )
    }

    // Render chart if present
    if ( ($('#metric_comparison_chart').length !== 0) && (typeof $('#metric_comparison_chart').data('c3-chart') === 'undefined') ) {
      const chart = new MetricComparisonChart( $('#metric_comparison_chart') );
      $.when( chart.render() );
    }

  }

}