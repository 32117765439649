// ==============================================================
// Create legend
// ==============================================================
// Get data for pre-selected items for legend below chart
import AddChartLegendButton from 'src/javascripts/components/charts/legend/AddChartLegendButton';
import AddChartLegendRow from 'src/javascripts/components/charts/legend/AddChartLegendRow';
import BuildChartUrl from 'src/javascripts/components/charts/url/BuildChartUrl';
import SetColors from 'src/javascripts/components/charts/helpers/SetColors';
import GetReturnsSingleArr from 'src/javascripts/components/charts/helpers/GetReturnsSingleArr';
import ResetChartAxis from 'src/javascripts/components/charts/axes/ResetChartAxis';

export default function(chart) {

  // Set legend records to get data for
  let legendRecords = $(chart.element).attr('data-legend-records');

  // Return null if no legend records exist
  if (typeof legendRecords === 'undefined') { return null; }

  // Parse records
  legendRecords = JSON.parse(legendRecords);

  // Create legend items for selected records
  let i = 0

  while (i < legendRecords.length) {

    let record = legendRecords[i];

    // Set params based on chart data attributes
    let recordType = record[0];
    let recordId = record[1];
    let recordName = record[2];

    // Use existing data if already loaded
    if (chart.data(recordName).length !== 0) {      

      // Return chart legend button or row
      if ( typeof $(chart.element).attr('data-legend-table') !== 'undefined' ) {
        AddChartLegendRow(chart, record);
      } else {
        AddChartLegendButton(chart, record);
      }

    // Otherwise get new data for chart and then create legend button
    } else {

      // Build url
      let chartId = $(chart.element).attr('id');
      let url = BuildChartUrl(chartId, record);

      // Get data
      $.getJSON(url, function(data) {

        // Return if no data
        if (data === null) {return;} 

        // Set data as index 1 (index 0 is dates, which is not needed for this)
        let recordData;
        let dates;
        if (data.length === 1) {
          recordData = data[0];
        } else {
          dates = data[0];
          recordData = data[1];
        }

        // Add prices to data array attached to chart element, if exists
        if ($(chart.element).attr('data-include-prices') === 'true') {
          $(chart.element).data('prices').push(recordData);
        }

        // Change legend record data to returns if this is for returns chart
        // Change prices data to daily returns (compared to first price in the array)
        if ($(chart.element).attr('data-return-option') === 'percentage') {
          recordData = GetReturnsSingleArr(recordData);
        }

        // Set colors -- must have a first placeholder array
        let colors = SetColors([['dates'], recordData]);          

        // Reload chart with updated data
        chart.load({
          columns: [recordData],
          colors: colors
        });

        // Reset y axis with new ticks and values
        ResetChartAxis(chart);

        // Focus chart if first record
        if (i === 0) { 

          chart.focus(recordName); 

        }

        // Return chart legend button or row
        if ( typeof $(chart.element).attr('data-legend-table') !== 'undefined' ) {
          return AddChartLegendRow(chart, record);
        } else {
          return AddChartLegendButton(chart, record);
        }

      });
    }

    // Iterate to next record
    i++;

  }

  return;

};

