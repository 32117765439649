import Spinner from 'src/javascripts/components/utilities/Spinner';
import AssessmentSliders from 'src/javascripts/components/forms/AssessmentSliders';
import 'ion-rangeslider/js/ion.rangeSlider';

// Edit impact formula category (importance rating)
export default class AssessmentsEditCategory {

  constructor() {}

  render() {

  	AssessmentSliders();

  }

}