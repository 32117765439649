import 'summernote/dist/summernote-bs4.min.js'
import Swal from 'sweetalert2';
import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import SetResearchParams from 'src/javascripts/components/research/SetResearchParams';
import ReportFundStoriesTable from 'src/javascripts/components/tables/ReportFundStoriesTable';
import UpdateResearchFilter from 'src/javascripts/components/research/UpdateResearchFilter';
import {companiesBloodhound, fundsBloodhound, filterTypeahead, screensTypeahead, filterTypeaheadNoCache} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {genericDisplayTemplate, logoTemplate, noResultsTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ImagePicker from 'src/javascripts/components/forms/ImagePicker';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default function() {

  // Set account id
  const accountUrl = $('#submitReport').attr('data-account-url');
  const reportId = $('#submitReport').attr('data-report-id');
  const reportTemplateId = $('#submitReport').attr('data-report-template-id');
  const reportType = $('#submitReport').attr('data-report-type');
  const reportScope = $('#submitReport').attr('data-report-scope');

  // Summernote text editor
  if ($('.summernote').length !== 0) {
    $('.summernote').summernote({
      dialogsInBody: true,
      minHeight: 150,
      toolbar: [
        ['font', ['bold', 'italic', 'underline', 'clear']],
      ]}
    );
  }

  // Show dragging on click
  $('.sort-modules-toggle').click(function() {
    $('.module-card').toggleClass('minimized');
    $('.module-sorting-col').toggleClass('d-none');
    $('.sorting-info').toggleClass('d-none');
    $('#sortableModulesContainer').toggleClass('border px-3 pt-3 mb-3 shadow');
    let offset = $('#sortableModulesContainer').position().top - 50;
    $('#newReportModalBody').animate({ scrollTop: offset}, 'slow');
    if ($('.module-card').hasClass('minimized')) {
      ToastCustom('Sort modules', 'Drag and drop modules to sort their order in the report', 6000);
    }
  });

  // Focus add module
  $('.add-module-focus').click(function() {
    $(this).closest('.input-group').find('input').focus();
  })

  // Draggable modules
  $('#sortableModules').off().sortable({
    placeholder: "available-module-space",
    handle: 'h4.drag-module',
    update: function(event, ui) {
      let sortOrder = $(this).sortable('toArray');

      // Set url and params for updating in database
      let url = '/' + accountUrl + '/reports/' + reportId + '?';
      let params = {};
      params["report"] = {};
      params["report"]["modules_attributes"] = {};

      // Iterate through sections
      $.each(sortOrder, function(ind, moduleId) {

        // Update sort order fields
        $('#' + moduleId).find('.hidden-sort-order').val(ind + 1);

        // Set li and params
        let li = $('#' + moduleId);
        params["report"]["modules_attributes"][ind] = {};
        params["report"]["modules_attributes"][ind]["id"] = li.find('input[type=\'hidden\']').val();
        params["report"]["modules_attributes"][ind]["sort_order"] = li.find('.hidden-sort-order').val();
        params["report"]["modules_attributes"][ind]["title"] = li.find('input[type=\'text\']').val();

      });

      // Update in database
      $.ajax({
        type: 'PATCH',
        dataType: 'application/json',
        url: url + $.param(params)
      });

    }
  });

  // Draggable sections
  $('.sortable-sections').sortable({
    placeholder: "available-section-space",
    handle: 'h5.drag-section',
    connectWith: $('.sortable-sections'),
    update: function(event, ui) {
      let sortOrder = $(this).sortable('toArray');

      // Set url and params for updating in database
      let url = '/' + accountUrl + '/reports/' + reportId + '?';
      let params = {};
      params["report"] = {};
      params["report"]["sections_attributes"] = {};

      // Iterate through sections
      $.each(sortOrder, function(ind, sectionId) {

        // Update sort order fields
        $('#' + sectionId).find('.hidden-sort-order').val(ind + 1);

        // Set li and params
        let li = $('#' + sectionId);
        params["report"]["sections_attributes"][ind] = {};
        params["report"]["sections_attributes"][ind]["id"] = li.find('input[type=\'hidden\']').val();
        params["report"]["sections_attributes"][ind]["sort_order"] = li.find('.hidden-sort-order').val();
        params["report"]["sections_attributes"][ind]["title"] = li.find('input[type=\'text\']').val();
        params["report"]["sections_attributes"][ind]["module_id"] = li.closest('li.module-option').attr("data-module-id");

      });

      // Update in database
      $.ajax({
        type: 'PATCH',
        dataType: 'application/json',
        url: url + $.param(params)
      });

    }
  });

  // Update classes of excluded/included sections
  $('.section-option select:not(.no-excluded)').change(function() {
    let val = $(this).val();
    let section = $(this).closest('.section-option');
    if (val === 'false') {
      section.addClass('excluded');
    } else {
      section.removeClass('excluded');
    }
  });

  // Show net fees option
  $('#showNetFees').change(function() {
    let val = $(this).val();
    $('#showNetOfFeesOpt').val(val);
  });

  // Definitions title option
  $('#report_include_definitions').change(function() {
    let checked = $(this).is(':checked');
    if (checked) {
      $('.definitions-title').removeClass('d-none');
    } else {
      $('.definitions-title').addClass('d-none');
    }
  });


  // Expand to choose options
  $('.choose-report-options').click(function() {
    let card = $(this).closest('.card');
    let cb = card.find('.card-body:not(.report-options)');
    let cbo = card.find('.card-body.report-options');
    let toggleInfo = card.find('.show-report-info i, .show-report-info svg');
    let section = $(this).closest('.section-option');
    if (card.hasClass('choose-options')) {
      card.removeClass('choose-options');
    } else {
      card.addClass('choose-options');
      card.addClass('minimized');
      section.removeClass('excluded');
      card.find('select:not(.no-excluded)').val('true');
    }
    cb.addClass('d-none').removeClass('d-block');
    cbo.addClass('d-block').removeClass('d-none');
    toggleInfo.removeClass('fa-chevron-up').addClass('fa-chevron-down');
    $(this).find('i, svg').toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
  });

  // Expand to show report information
  $('.show-report-info').click(function() {
    let card = $(this).closest('.card');
    let cb = card.find('.card-body:not(.report-options)');
    let cbo = card.find('.card-body.report-options');
    let toggleOpts = card.find('.choose-report-options i, .choose-report-options svg');
    card.removeClass('choose-options');
    cb.addClass('d-block').removeClass('d-none');
    cbo.addClass('d-none').removeClass('d-block');
    toggleOpts.removeClass('fa-chevron-up').addClass('fa-chevron-down');
  })

  // Section add
  if ($('.report-sections-search').length > 0) { 
    filterTypeaheadNoCache('.report-sections-search', 'sections', '/' + accountUrl + '/reports/' + reportId + '/sections/search?', 87, 'generic'); 
  }

  // SASB causes add
  if ($('.sasb-causes-search').length > 0) { 
    filterTypeahead('.sasb-causes-search', 'sections', '/causes/search?is_risk=true', 87, 'cause'); 
  }

  // Questionnaire response modules
  if ($('.included-questionnaire-module').length > 0) { 
    filterTypeahead('.included-questionnaire-module', 'questionnaire_modules', '/' + accountUrl + '/questionnaires/responses/modules/search?', 108, 'generic'); 
  }

  // Portfolio items
  if ($('.portfolio-items').length > 0) { 
    filterTypeahead('.portfolio-items', 'portfolio_items', '/' + accountUrl + '/portfolios/items/search?', 117, 'generic'); 
  }

  // Init imported bloodhounds //
  if ($('#clients_search').length > 0) { filterTypeahead('#clients_search', 'clients', '/' + accountUrl + '/clients/search?', 45, 'generic'); }
  if ($('#formulas_search').length > 0) { 
    let isRisk = $('#formulas_search').attr('data-risk');
    let params = {};
    params['include_non_system'] = "true"
    if (typeof isRisk !== 'undefined') {params['risk'] = isRisk}
    filterTypeahead('#formulas_search', 'formulas', '/formulas/search?' + $.param(params), 187, 'cause'); 
  }

  // Included formulas search - for adding multiple
  if ($('#included_formulas_search').length > 0) { 
    let isRisk = $('#included_formulas_search').attr('data-risk');
    let params = {};
    params['include_non_system'] = "true"
    if (typeof isRisk !== 'undefined') {params['risk'] = isRisk}
    filterTypeahead('#included_formulas_search', 'formulas', '/formulas/search?' + $.param(params), 187, 'cause'); 
  }

  // Screen option search
  if ($('#screens_typeahead').length > 0) { 
    screensTypeahead();
  }

  // Benchmark metrics search
  if ($('#benchmark_metrics_search').length > 0) { 
    filterTypeahead('#benchmark_metrics_search', 'metrics', '/metrics/search?', 544, 'cause'); 
  }


  if ($('#featured_portfolio_search').length > 0) {
    let clientId = $('#featured_portfolio_search').attr('data-client-id');
    let params = {};
    if (typeof clientId !== 'undefined') {params['client_id'] = clientId;}
    filterTypeahead('#featured_portfolio_search', 'portfolios', '/' + accountUrl + '/portfolios/search?' + $.param(params), 256, 'generic');
    // Show message if portfolio selected that has no metrics
    if ($('#featured_portfolio_search').attr('data-selected-portfolio-insufficient-data-message') === "true") {
      ToastCustom('Not enough data', 'The portfolio you selected does not have enough data to be used. Please select a different portfolio or contact us for support.', 6000);
    } else if ($('#featured_portfolio_search').attr('data-selected-portfolio-no-indicators-message') === "true") {
      ToastCustom('No Adverse Indicators created', 'The portfolio you selected does not have adverse indicators. Create an SFDR snapshot on the portfolio profile page or contact us for support.', 6000);
    }
  }

  if ($('#portfolio_benchmark_search').length > 0) {
    let clientId = $('#featured_portfolio_search').attr('data-client-id');
    let params = {};
    params['has_score_only'] = 'true';
    if (typeof clientId !== 'undefined') {params['client_id'] = clientId;}
    filterTypeahead('#portfolio_benchmark_search', 'portfolios', '/' + accountUrl + '/portfolios/search?' + $.param(params), 183, 'generic');
    // Show message if fund selected that has no metrics
    if ($('#portfolio_benchmark_search').attr('data-selected-portfolio-insufficient-data-message') === "true") {
      ToastCustom('Not enough data', 'The portfolio you selected for a benchmark does not have enough data to be used. Please select a different portfolio or contact us for support.', 6000);
    }
  }
  if ($('#featured_fund_search').length > 0) {
    let params = {};
    params['has_score_only'] = 'true';
    filterTypeahead('#featured_fund_search', 'funds', '/funds/search?' + $.param(params), 42, 'logo');
    // Show message if fund selected that has no metrics
    if ($('#featured_fund_search').attr('data-fund-no-metrics-message') === "true") {
      ToastCustom('Not enough data', 'The fund you selected does not have enough data to be used. Please select a different fund or contact us for support.', 6000);
    }
  }
  if ($('#fund_benchmark_search').length > 0) {
    let params = {};
    params['has_score_only'] = 'true';
    filterTypeahead('#fund_benchmark_search', 'funds', '/funds/search?' + $.param(params), 42, 'logo');
    // Show message if fund selected that has no metrics
    if ($('#fund_benchmark_search').attr('data-fund-no-metrics-message') === "true") {
      ToastCustom('Not enough data', 'The fund you selected for a benchmark does not have enough data to be used. Please select a different fund or contact us for support.', 6000);
    }
  }


  // Init companies bloodhound -- must do separately to avoid including brands
  const companiesBloodhound = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: '/companies/search?has_score_only=true&query=%QUERY',
      wildcard: "%QUERY",
      transform: function(d) { return d.companies; }
    }
  });
  companiesBloodhound.initialize();

  if ($('#featured_company_search').length > 0) {
    $('#featured_company_search').typeahead({
      hint: true,
      highlight: true,
      minLength: 0,
    }, {
      name: 'companies',
      display: 'name',
      limit: 44,
      source: companiesBloodhound.ttAdapter(),
      templates: {
        suggestion(el) {
          if (el.name === null) {
            return noResultsTemplate();
          } else {
            return logoTemplate(el.name, el.symbol, el.logo_url);
          }
        }
      },
    });
    if ($('#featured_company_search').attr('data-company-no-metrics-message') === "true") {
      ToastCustom('Not enough data', 'The company you selected does not have enough data to be used. Please select a different company or contact us for support.', 6000);
    }
  }
  if ($('#company_benchmark_search').length > 0) {
    $('#company_benchmark_search').typeahead({
      hint: true,
      highlight: true,
      minLength: 0,
    }, {
      name: 'companies',
      display: 'name',
      limit: 44,
      source: companiesBloodhound.ttAdapter(),
      templates: {
        suggestion(el) {
          if (el.name === null) {
            return noResultsTemplate();
          } else {
            return logoTemplate(el.name, el.symbol, el.logo_url);
          }
        }
      },
    });
    // Show message if company selected that has no metrics
    if ($('#company_benchmark_search').attr('data-company-no-metrics-message') === "true") {
      ToastCustom('Not enough data', 'The company you selected for a benchmark does not have enough data to be used. Please select a different company or contact us for support.', 6000);
    }
  }


  $('#include_custom_confidentiality').change(function() {
    if ($(this).is(':checked')) {
      $('.custom-confidentiality-container').removeClass('minimized'); 
    } else {
      $('.custom-confidentiality-container').addClass('minimized'); 
    }
  });

  $('#include_custom_disclosure').change(function() {
    if ($(this).is(':checked')) {
      $('.custom-disclosure-container').removeClass('minimized'); 
    } else {
      $('.custom-disclosure-container').addClass('minimized'); 
      $('.custom-disclosure-container .summernote').summernote('reset');
    }
  });

  $('#include_custom_benchmark_disclosure').change(function() {
    if ($(this).is(':checked')) {
      $('.custom-benchmark-disclosure-container').removeClass('minimized'); 
    } else {
      $('.custom-benchmark-disclosure-container').addClass('minimized'); 
      $('#report_custom_benchmark_disclosure').html('');
    }
  });

  // Reloading with attrs //
  const reloadWithAttrs = function() {

    // Set spinner and opacity
    $('.modal-body').css('opacity', 0.2);
    if ( $('.modal-content').find('.spinner-container').length === 0 ) {
      Spinner($('.modal-content'));
    }

    // Set params
    let baseUrl = window.location.pathname;
    let params = {};
    params['record_type'] = $('#submitReport').attr('data-record-type');
    params['report_type'] = $('#submitReport').attr('data-report-type');
    params['report_template_id'] = reportTemplateId;
    params['report_id'] = reportId;
    params['report_scope'] = reportScope;
    params['formula_id'] = $('#report_formula_id').val();
    params['back_path'] = $('#submitReport').attr('data-back-link');
    params['client_id'] = $('#report_client_id').val();
    if ($('#report_client_id').attr('data-clear-client-id') === 'true') {params['clear_client_id'] = 'true';}
    if ($('#featured_portfolio_search').attr('data-clear-portfolios') === 'true') {params['clear_portfolios'] = 'true';}

    // Add title and subtitle to maintain across reload
    params['report_title'] = $('.title-input-field').val();
    params['report_subtitle'] = $('.subtitle-input-field').val();

    // Set div scroll position
    params["s"] = $('#newReportModal .modal-body').scrollTop();

    let url = baseUrl + '?' + $.param(params);

    // Clear Turbolinks cache to avoid showing a screen without modal loaded
    Turbolinks.clearCache(); 

    // Reload page with Turbolinks
    Turbolinks.visit(url);

  }

  // Reload with record_type on select
  $('.select-record-type').on('change', function() {
    $('#submitReport').attr('data-record-type', this.value);
    reloadWithAttrs();
  });

  // Reload with record_type on select
  $('.select-formula').on('change', function() {
    $('#report_formula_id').val(this.value);
    reloadWithAttrs();
  });

  // Disable create when remove the title
  $('#report_title').on('keyup', function() {
    let val = $(this).val();
    if (val === '') {
      $('#submitReport').attr('disabled', true);
    } else {
      $('#submitReport').attr('disabled', false);
    }
  });

  // On selecting a section
  $('.report-sections-search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    $('.modal-body').css('opacity', 0.2);
    Spinner($('.modal-content'));
    let url = '/' + accountUrl + '/reports/' + reportId + '/sections?';
    let moduleId = $(this).attr('data-module-id');
    let params = {};
    params["key"] = suggestion.key;
    params["module_id"] = moduleId;
    $.ajax({
      type: 'POST',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        if (responseText.status === 400) {
          ToastCustom('Not available', responseText["message"], 8000);
          $('.modal-body').css('opacity', 1);
          $('.modal-content').find('.spinner-container').remove();
        } else {
          filterTypeaheadNoCache('.report-sections-search', 'sections', '/' + accountUrl + '/reports/' + reportId + '/sections/search?', 87, 'generic'); 
          reloadWithAttrs();
        }
      },
    });
  });

  // On adding a module
  $('.add-module').click(function() {
    let title = $(this).closest('.input-group').find('input').val();
    if (title.length === 0) {return}
    $('.modal-body').css('opacity', 0.2);
    Spinner($('.modal-content'));
    let url = '/' + accountUrl + '/reports/' + reportId + '/modules?';
    let params = {};
    params["report_module"] = {}
    params["report_module"]["title"] = title;
    $.ajax({
      type: 'POST',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        if (responseText.status === 400) {
          ToastCustom('There was a problem, please contact us for support', responseText["message"], 8000);
          $('.modal-body').css('opacity', 1);
          $('.modal-content').find('.spinner-container').remove();
        } else {
          reloadWithAttrs();
        }
      },
    });
  });

  // On selecting a cause
  $('.sasb-causes-search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    $('.modal-body').css('opacity', 0.2);
    Spinner($('.modal-content'));
    let url = '/' + accountUrl + '/reports/' + reportId + '/included_sasb_causes?';
    let params = {};
    params["cause_id"] = suggestion.value;
    $.ajax({
      type: 'POST',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        if (responseText.status === 400) {
          ToastCustom('Already added', responseText["message"], 8000);
          $('.modal-body').css('opacity', 1);
          $('.modal-content').find('.spinner-container').remove();
        } else {
          reloadWithAttrs();
        }
      },
    });
  });


  // On selecting a screen
  $('#screens_typeahead').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    $('.modal-body').css('opacity', 0.2);
    Spinner($('.modal-content'));
    let url = '/' + accountUrl + '/reports/' + reportId + '/screen_options?';
    let params = {};
    params["screen_id"] = suggestion.id;
    $.ajax({
      type: 'POST',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        if (responseText.status === 400) {
          ToastCustom('There was a problem, please contact us for support', responseText["message"], 8000);
          $('.modal-body').css('opacity', 1);
          $('.modal-content').find('.spinner-container').remove();
        } else {
          reloadWithAttrs();
        }
      },
    });
  });

  // Remove a screen
  $('.report-screen-option').change(function() {
    if(this.checked) { $(this).prop("checked", false); }
    let url = '/' + accountUrl + '/reports/' + reportId + '/screen_options?';
    let params = {};
    params['screen_id'] = this.value;
    $.ajax({
      type: 'DELETE',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        if (responseText.status === 400) {
          ToastCustom('There was a problem', responseText["message"], 8000);
        } else {
          reloadWithAttrs();
        }
      },
    });
  });



  // On selecting a formula
  $('#included_formulas_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    $('.modal-body').css('opacity', 0.2);
    Spinner($('.modal-content'));
    let url = '/' + accountUrl + '/reports/' + reportId + '/included_formulas?';
    let params = {};
    params["formula_id"] = suggestion.value;
    $.ajax({
      type: 'POST',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        if (responseText.status === 400) {
          ToastCustom('There was a problem, please contact us for support', responseText["message"], 8000);
          $('.modal-body').css('opacity', 1);
          $('.modal-content').find('.spinner-container').remove();
        } else {
          reloadWithAttrs();
        }
      },
    });
  });

  // Remove a formula
  $('.report-included-formula-option').change(function() {
    if(this.checked) { $(this).prop("checked", false); }
    let url = '/' + accountUrl + '/reports/' + reportId + '/included_formulas?';
    let params = {};
    params['formula_id'] = this.value;
    $.ajax({
      type: 'DELETE',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        if (responseText.status === 400) {
          ToastCustom('There was a problem', responseText["message"], 8000);
        } else {
          reloadWithAttrs();
        }
      },
    });
  });



  // On selecting a metric
  $('#benchmark_metrics_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    $('.modal-body').css('opacity', 0.2);
    Spinner($('.modal-content'));
    let url = '/' + accountUrl + '/reports/' + reportId + '/included_benchmark_metrics?';
    let params = {};
    params["metric_id"] = suggestion.value;
    $.ajax({
      type: 'POST',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        if (responseText.status === 400) {
          ToastCustom('There was a problem, please contact us for support', responseText["message"], 8000);
          $('.modal-body').css('opacity', 1);
          $('.modal-content').find('.spinner-container').remove();
        } else {
          reloadWithAttrs();
        }
      },
    });
  });

  // Remove a screen
  $('.report-benchmark-metric').change(function() {
    if(this.checked) { $(this).prop("checked", false); }
    let url = '/' + accountUrl + '/reports/' + reportId + '/included_benchmark_metrics?';
    let params = {};
    params['metric_id'] = this.value;
    $.ajax({
      type: 'DELETE',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        if (responseText.status === 400) {
          ToastCustom('There was a problem', responseText["message"], 8000);
        } else {
          reloadWithAttrs();
        }
      },
    });
  });



  // On selecting a questionnaire - get all responses
  $('.included-questionnaire-module').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    $('.modal-body').css('opacity', 0.2);
    Spinner($('.modal-content'));
    let sectionId = $(this).attr('data-section-id');
    let url = '/' + accountUrl + '/reports/' + reportId + '/included_questionnaire_modules?';
    let params = {};
    params["section_id"] = sectionId;
    params["questionnaire_module_id"] = suggestion.value;
    $.ajax({
      type: 'POST',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        reloadWithAttrs();
      },
    });
  });


  // Remove a module on click
  $('.delete-report-module').click(function(e) {
    e.preventDefault();
    let moduleId = $(this).attr('data-module-id');
    let moduleLi = $(this).closest('li.module-option');
    let url = '/' + accountUrl + '/reports/' + reportId + '/modules/' + moduleId;

    // Confirm and then continue with request
    return Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to remove this module? Any sections in the module will also be removed from the report.",
      animation: false,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light border',
        popup: 'animated fadeIn faster'
      }
    }).then((result) => {
      if (result.value) {
        $('.modal-body').css('opacity', 0.2);
        Spinner($('.modal-content'));
        $.ajax({
          type: 'DELETE',
          url: url,
          dataType: 'application/json',
          complete(result) { 
            moduleLi.remove();
            reloadWithAttrs();
          },
        });
      }
    });    
  })

  // Remove a section on click
  $('.delete-report-section').click(function(e) {
    e.preventDefault();
    let sectionId = $(this).attr('data-section-id');
    let sectionLi = $(this).closest('li.section-option');
    let url = '/' + accountUrl + '/reports/' + reportId + '/sections/' + sectionId;

    // Confirm and then continue with request
    return Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to remove this section?",
      animation: false,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light border',
        popup: 'animated fadeIn faster'
      }
    }).then((result) => {
      if (result.value) {
        $('.modal-body').css('opacity', 0.2);
        Spinner($('.modal-content'));
        $.ajax({
          type: 'DELETE',
          url: url,
          dataType: 'application/json',
          complete(result) { 
            sectionLi.remove();
            reloadWithAttrs();
          },
        });
      }
    });    
  })

  // Remove a cause on click
  $('.delete-included-sasb-cause').click(function(e) {
    e.preventDefault();
    let causeId = $(this).attr('data-cause-id');
    let includedSasbCauseId = $(this).attr('data-id');
    let url = '/' + accountUrl + '/reports/' + reportId + '/included_sasb_causes/' + includedSasbCauseId;

    // Confirm and then continue with request
    return Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to remove this? This will delete any data you've entered for this topic.",
      animation: false,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light border',
        popup: 'animated fadeIn faster'
      }
    }).then((result) => {
      if (result.value) {
        $('.modal-body').css('opacity', 0.2);
        Spinner($('.modal-content'));
        $.ajax({
          type: 'DELETE',
          url: url,
          dataType: 'application/json',
          complete(result) { 
            $('tr.included-sasb-row[data-cause-id=\'' + causeId + '\']').remove();
            $('.modal-body').css('opacity', 1);
            $('.modal-content').find('.spinner-container').remove();
          },
        });
      }
    });    
  })

  // Remove a questionnaire module on click
  $('.delete-included-questionnaire-module').click(function(e) {
    e.preventDefault();
    let responseId = $(this).attr('data-id');
    let url = '/' + accountUrl + '/reports/' + reportId + '/included_questionnaire_modules/' + responseId;

    // Confirm and then continue with request
    return Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to remove this?",
      animation: false,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light border',
        popup: 'animated fadeIn faster'
      }
    }).then((result) => {
      if (result.value) {
        $('.modal-body').css('opacity', 0.2);
        Spinner($('.modal-content'));
        $.ajax({
          type: 'DELETE',
          url: url,
          dataType: 'application/json',
          complete(result) { 
            reloadWithAttrs();
          },
        });
      }
    });    
  })


  // On selecting a formula
  $('#clients_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    $('#report_client_id').val(suggestion.value);
    $('#report_formula_id').val('');
    if ($('#report_client_id').val() === '') { $('#report_client_id').attr('data-clear-client-id', 'true'); }
    reloadWithAttrs();
  });

  $('#clients_search').blur(function(ev) {
    let val = $(this).val();
    if (val === '') {
      $('#report_client_id').val(val);
      $('#report_formula_id').val('');
      $('#report_client_id').attr('data-clear-client-id', 'true');
      reloadWithAttrs();
    }
  });
  // Clear client and reload on click of clear button
  $('.clear-selected-report-client').click(function(ev, suggestion) {
    $('#report_client_id').val('');
    $('#report_formula_id').val('');
    $('#report_client_id').attr('data-clear-client-id', 'true');
    reloadWithAttrs();
  });

  // On selecting a formula
  $('#formulas_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    $('#report_formula_id').val(suggestion.record_id);
    $('#report_client_id').val('');
    reloadWithAttrs();
  });

  // On selecting a featured fund, create featured fund
  $('#featured_portfolio_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    $('#featured_portfolio_search').attr('data-clear-portfolios', 'false');
    let url = '/' + accountUrl + '/reports/' + reportId + '/featured_portfolios?';
    let params = {};
    params['portfolio_id'] = suggestion.value;
    $.ajax({
      type: 'POST',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        if (responseText.status === 400) {
          $('#featured_portfolio_search').typeahead('val','');
          ToastCustom('There was a problem', responseText["message"], 8000);
        } else {
          reloadWithAttrs();
        }
      },
    });
  });

  // On selecting a portfolio as benchmark, create benchmark portfolio
  $('#portfolio_benchmark_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    let url = '/' + accountUrl + '/reports/' + reportId + '/benchmark_portfolios?';
    let params = {};
    params['portfolio_id'] = suggestion.value;
    params['report_type'] = reportType;
    $.ajax({
      type: 'POST',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        if (responseText.status === 400) {
          ToastCustom('There was a problem', responseText["message"], 8000);
        } else {
          reloadWithAttrs();
        }
      },
    });
  });

  // Clear client and reload on click of clear button
  $('#clearReportCompany').click(function(ev, suggestion) {
    $('#featured_company_search').val('');
    $('#featured_company_search').typeahead('val', '');
    $('#featured_company_search').focus();
    $('#submitReport').attr('disabled', true);
  });

  // Clear client and reload on click of clear button
  $('#clearReportFund').click(function(ev, suggestion) {
    $('#featured_fund_search').val('');
    $('#featured_fund_search').typeahead('val', '');
    $('#featured_fund_search').focus();
    $('#submitReport').attr('disabled', true);
  });

  // Clear client and reload on click of clear button
  $('#clearReportPortfolio').click(function(ev, suggestion) {
    $('#featured_portfolio_search').val('');
    $('#featured_portfolio_search').attr('data-clear-portfolios', 'true');
    reloadWithAttrs();
  });

  // On remove of a mandatory field by manually typing
  $('#featured_fund_search, #featured_company_search, #featured_portfolio_search').keyup(function() {
    let val = $(this).val();
    if (val === '') {
      $('#submitReport').attr('disabled', true);
    } else {
      $('#submitReport').attr('disabled', false);
    }
  })

  // On selecting a featured fund, create featured fund
  $('#featured_fund_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    let url = '/' + accountUrl + '/reports/' + reportId + '/featured_funds?';
    let params = {};
    params['fund_id'] = suggestion.value;
    $.ajax({
      type: 'POST',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        if (responseText.status === 400) {
          ToastCustom('There was a problem', responseText["error"], 8000);
        } else {
          reloadWithAttrs();
        }
      },
    });
  });


  // On selecting a fund as benchmark, create benchmark fund
  $('#fund_benchmark_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    let url = '/' + accountUrl + '/reports/' + reportId + '/benchmark_funds?';
    let params = {};
    params['fund_id'] = suggestion.value;
    params['report_type'] = reportType;
    $.ajax({
      type: 'POST',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        if (responseText.status === 400) {
          ToastCustom('There was a problem', responseText["message"], 8000);
        } else {
          reloadWithAttrs();
        }
      },
    });
  });

  // On selecting a featured company, create featured company
  $('#featured_company_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    let url = '/' + accountUrl + '/reports/' + reportId + '/featured_companies?';
    let params = {};
    params['company_id'] = suggestion.value;
    $.ajax({
      type: 'POST',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        if (responseText.status === 400) {
          ToastCustom('There was a problem', responseText["error"], 8000);
        } else {
          reloadWithAttrs();
        }
      },
    });
  });


  // On selecting a company as benchmark, create benchmark company
  $('#company_benchmark_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    let url = '/' + accountUrl + '/reports/' + reportId + '/benchmark_companies?';
    let params = {};
    params['company_id'] = suggestion.value;
    params['report_type'] = reportType;
    $.ajax({
      type: 'POST',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        if (responseText.status === 400) {
          ToastCustom('There was a problem', responseText["message"], 8000);
        } else {
          reloadWithAttrs();
        }
      },
    });
  });


  // On selecting a portfolio item, create featured company
  $('#featured_portfolio_item_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    let url = '/' + accountUrl + '/reports/' + reportId + '/featured_companies?';
    let params = {};
    params['company_id'] = suggestion.company_id;
    $.ajax({
      type: 'POST',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        if (responseText.status === 400) {
          ToastCustom('There was a problem', responseText["error"], 8000);
        } else {
          reloadWithAttrs();
        }
      },
    });
  });



  // Remove included fund benchmarks
  $('.report-benchmark-fund-option').change(function() {
    if(this.checked) { $(this).prop("checked", false); }
    let url = '/' + accountUrl + '/reports/' + reportId + '/benchmark_funds?';
    let params = {};
    params['fund_id'] = this.value;
    $.ajax({
      type: 'DELETE',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        if (responseText.status === 400) {
          ToastCustom('There was a problem', responseText["message"], 8000);
        } else {
          reloadWithAttrs();
        }
      },
    });
  });

  // Remove included company benchmarks
  $('.report-benchmark-company-option').change(function() {
    if(this.checked) { $(this).prop("checked", false); }
    let url = '/' + accountUrl + '/reports/' + reportId + '/benchmark_companies?';
    let params = {};
    params['company_id'] = this.value;
    $.ajax({
      type: 'DELETE',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        if (responseText.status === 400) {
          ToastCustom('There was a problem', responseText["message"], 8000);
        } else {
          reloadWithAttrs();
        }
      },
    });
  });

  // Remove included portfolio benchmarks
  $('.report-benchmark-portfolio-option').change(function() {
    if(this.checked) { $(this).prop("checked", false); }
    let url = '/' + accountUrl + '/reports/' + reportId + '/benchmark_portfolios?';
    let params = {};
    params['portfolio_id'] = this.value;
    $.ajax({
      type: 'DELETE',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
        if (responseText.status === 400) {
          ToastCustom('There was a problem', responseText["message"], 8000);
        } else {
          reloadWithAttrs();
        }
      },
    });
  });


  // Show message if portfolio selected that has no metrics
  if ( ($('.report-benchmark-portfolio-option').length > 0) && ($('.report-benchmark-portfolio-option').attr('data-portfolio-no-metrics-message') === "true")) {
    ToastCustom('Not enough data', 'The portfolio you selected for a benchmark does not have enough data to be used. Please select a different portfolio or contact us for support.', 6000);
  }

  // Show message if portfolio selected that has no metrics
  if ( ($('.featured-portfolio-id-select').length > 0) && ($('.featured-portfolio-id-select').attr('data-selected-portfolio-insufficient-data-message') === "true")) {
    ToastCustom('Not enough data', 'The portfolio you selected does not have enough data to be used. Please select a different portfolio or contact us for support.', 6000);
  } else if ( ($('.featured-portfolio-id-select').length > 0) && ($('.featured-portfolio-id-select').attr('data-selected-portfolio-no-indicators-message') === "true")) {
    ToastCustom('No Adverse Indicators created', 'The portfolio you selected does not have adverse indicators. Create an SFDR snapshot on the portfolio profile page or contact us for support.', 6000);
  }


  // Render table
  if ( $('#report_fund_stories_table_wrapper').length === 0 ) {
    Spinner($('#reportChooseFundStories'));
    let table = new ReportFundStoriesTable($('#report_fund_stories_table'));
    $.when( table.render() ).then( status => table.setUp('report_fund_stories_table') )
  }

  // Must set manually to "chrome-off", otherwise not getting set
  // Must be "chrome-off": https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
  $('.typeahead').attr('autocomplete', "off");


  // My Causes option
  let myCausesOption = $('.include-my-causes-option');
  if (myCausesOption.length > 0) {
    $('.include-my-causes-option .card').click(function() {
      let selected = $(this).attr('data-option');
      $('#report_my_causes_option').val(selected);
      $('.include-my-causes-option .card').addClass('excluded');
      $('.my-causes-included-indicator').removeClass('d-flex').addClass('d-none');
      $(this).removeClass('excluded');
      $(this).find('.my-causes-included-indicator').removeClass('d-none').addClass('d-flex');
    });
  }

  // Top 10, 5 or all assets
  let assetsOption = $('.include-assets-option');
  if (assetsOption.length > 0) {
    $('.include-assets-option .card').click(function() {
      let selected = $(this).attr('data-option');
      $('#report_assets_option').val(selected);
      $('.include-assets-option .card').addClass('excluded');
      $('.assets-included-indicator').removeClass('d-flex').addClass('d-none');
      $(this).removeClass('excluded');
      $(this).find('.assets-included-indicator').removeClass('d-none').addClass('d-flex');
    });
  }

  // Top 10 or top 5 holdings
  let holdingsOption = $('.holdings-option');
  if (holdingsOption.length > 0) {
    $('.holdings-option .card').click(function() {
      let selected = $(this).attr('data-option');
      let formDetails = $(this).closest('.form-details');
      formDetails.find('.hidden-holdings-option').val(selected);
      formDetails.find('.card').addClass('excluded');
      formDetails.find('.holdings-included-indicator').removeClass('d-flex').addClass('d-none');
      $(this).removeClass('excluded');
      $(this).find('.holdings-included-indicator').removeClass('d-none').addClass('d-flex');
    });
  }

  // Include options default
  let includeOptions = $('.include-options');
  if (includeOptions.length > 0) {
    $('.include-options .card').click(function() {
      let col = $(this).closest('.include-opt-col');
      let includedOpt = col.find('.include-option');
      if (includedOpt.val() === 'true') {
        includedOpt.val('false');
        $(this).addClass('excluded');
        col.find('.included-indicator').removeClass('d-flex').addClass('d-none');
        col.find('.excluded-indicator').removeClass('d-none').addClass('d-flex');
      } else {
        includedOpt.val('true');
        $(this).removeClass('excluded');
        col.find('.excluded-indicator').removeClass('d-flex').addClass('d-none');
        col.find('.included-indicator').addClass('d-flex').removeClass('d-none');
      }
    });
  }

  // Include screen options - single
  let includeScreenOptions = $('.include-options-screen');
  if (includeScreenOptions.length > 0) {
    $('.include-options-screen .screen-option-row').click(function() {
      let optsInputs = $(this).closest('.form-details').find('.report-screen-options-inputs');
      let reportScreenId = $(this).attr('data-report-screen-id');
      let allRows = $('.screen-option-row[data-report-screen-id=' + reportScreenId + ']');
      if ($(this).hasClass('excluded')) {
        optsInputs.find('#report_screen_options_included_' + reportScreenId).val(true);
        allRows.removeClass('excluded');
        allRows.find('.excluded-indicator').removeClass('d-flex').addClass('d-none');
        allRows.find('.included-indicator').addClass('d-flex').removeClass('d-none');
      } else {
        optsInputs.find('#report_screen_options_included_' + reportScreenId).val(false);
        allRows.addClass('excluded');
        allRows.find('.included-indicator').removeClass('d-flex').addClass('d-none');
        allRows.find('.excluded-indicator').removeClass('d-none').addClass('d-flex');
      }
    });
  }

  // Include SDG options
  let includeSdgOptions = $('.include-options-sdgs');
  if (includeSdgOptions.length > 0) {
    $('.include-options-sdgs .card').click(function() {
      let col = $(this).closest('.include-opt-sdg-col');
      let reportSdgId = $(this).attr('data-report-sdg-id');
      let includedSdgOpts = $('.include-option-sdg[data-sdg-id=\'' + reportSdgId + '\']');
      let closestSdgOpt = col.find('.include-option-sdg');
      if (closestSdgOpt.val() === 'true') {
        includedSdgOpts.each(function() {
          $(this).val(false);
          let col = $(this).closest('.include-opt-sdg-col');
          col.find('.card').addClass('excluded');
          col.find('.included-indicator').removeClass('d-flex').addClass('d-none');
          col.find('.excluded-indicator').removeClass('d-none').addClass('d-flex');
        });
      } else {
        includedSdgOpts.each(function() {
          $(this).val(true);
          let col = $(this).closest('.include-opt-sdg-col');
          col.find('.card').removeClass('excluded');
          col.find('.included-indicator').addClass('d-flex').removeClass('d-none');
          col.find('.excluded-indicator').addClass('d-none').removeClass('d-flex');
        });
      }
    });
  }

  // Select all screens
  $('.select-all-screens').click(function() {
    let tab = $(this).closest('.include-options-screen');
    let toInclude = tab.find('.screen-option-row');
    let optsInputs = $(this).closest('.form-details').find('.report-screen-options-inputs');
    $.each(toInclude, function() {
      let reportScreenId = $(this).attr('data-report-screen-id');
      let allRows = $('.screen-option-row[data-report-screen-id=' + reportScreenId + ']');
      optsInputs.find('#report_screen_options_included_' + reportScreenId).val(true);
      allRows.removeClass('excluded');
      allRows.find('.excluded-indicator').removeClass('d-flex').addClass('d-none');
      allRows.find('.included-indicator').addClass('d-flex').removeClass('d-none');
    });
  });

  // Deselect all screens
  $('.deselect-all-screens').click(function() {
    let tab = $(this).closest('.include-options-screen');
    let toInclude = tab.find('.screen-option-row');
    let optsInputs = $(this).closest('.form-details').find('.report-screen-options-inputs');
    $.each(toInclude, function() {
      let reportScreenId = $(this).attr('data-report-screen-id');
      let allRows = $('.screen-option-row[data-report-screen-id=' + reportScreenId + ']');
      optsInputs.find('#report_screen_options_included_' + reportScreenId).val(false);
      allRows.addClass('excluded');
      allRows.find('.included-indicator').removeClass('d-flex').addClass('d-none');
      allRows.find('.excluded-indicator').removeClass('d-none').addClass('d-flex');
    });
  });

  // Update module title on blur
  $('.module-option .module-title-input').blur(function() {
    let moduleId = $(this).closest('.card-header').find('.delete-report-module').attr('data-module-id');
    let url = "/" + accountUrl + "/reports/" + reportId + "/modules/" + moduleId + "?";
    let params = {};
    params["report_module"] = {};
    params["report_module"]["title"] = $(this).val();
    $.ajax({
      type: 'PATCH',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
      }
    });
  });

  // Update section title on blur
  $('.section-option .card-header .col input[type=\'text\']').blur(function() {
    let sectionId = $(this).closest('.card-header').find('.delete-report-section').attr('data-section-id');
    let url = "/" + accountUrl + "/reports/" + reportId + "/sections/" + sectionId + "?";
    let params = {};
    params["report_section"] = {};
    params["report_section"]["title"] = $(this).val();
    $.ajax({
      type: 'PATCH',
      dataType: 'application/json',
      url: url + $.param(params),
      complete(result) { 
        let responseText = JSON.parse(result.responseText);
      }
    });
  });

  // Upload images
  ImagePicker();

  // Select an image on click
  $(".image-container").click(function() {
    
    // Toggle active classes
    $('.image-container.active').not($(this)).removeClass('active');
    $(this).toggleClass('active');

    // If active, enable submit button
    if ($(this).hasClass('active')) {
      $('.submit-button').prop('disabled', false)
      $('.submit-button').attr('data-title', '')
    } else {
      $('.submit-button').prop('disabled', true)
      $('.submit-button').attr('data-title', 'Select an image')
    }

    // Update hidden field
    let url = $(this).closest('.image-container').attr("data-image-url");
    $('#report_pdf_cover_image_url').val(url);

  });


  // Go back on click of submit for new forms only (new report opened in new window)  
  $('#submitReport').click(function() {
    if (!$(this).hasClass('disabled')) {
      if ( $('body').attr('data-page') === 'ReportsNew' ) {
        let url = $(this).attr('data-back-link');
        let accountUrl = $(this).attr('data-account-url');
        if (typeof url === 'undefined') {url = "/" + accountUrl + "/reports"};
        Turbolinks.visit(url);
      }
    }
  });

}