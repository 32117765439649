import Spinner from 'src/javascripts/components/utilities/Spinner';
import ResearchFilteringPublic from 'src/javascripts/components/ResearchFilteringPublic';

export default class PublicCompaniesIndex {

  constructor() {}

  render() {

    // Filtering functions
    ResearchFilteringPublic();

  }

}
