import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import AddRowHoverButtons from 'src/javascripts/components/tables/helpers/AddRowHoverButtons';
import AddRowClickLinks from 'src/javascripts/components/tables/actions/AddRowClickLinks';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';
import CheckPaging from 'src/javascripts/components/tables/helpers/CheckPaging';
import Swal from 'sweetalert2';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

export default class QuestionnaireResponseApprovalRequestsTable extends Table {

  render() {

    // Set a spinner to indicate chart loading
    if (this.el.closest('.card').find('.spinner-container').length === 0) {
      Spinner(this.el);
    }

    // Set table id
    const tableId = this.el.attr('id');

    // Build url, use to get filtered data with server-side processing
    let dataUrl = this.el.attr('data-api-url');
    let params = {};
    let userId = this.el.attr('data-user-id');
    let itemId = this.el.attr('data-item-id');
    let status = this.el.attr('data-status');
    let portfolioId = this.el.attr('data-portfolio-id');
    let questionnaireId = this.el.attr('data-questionnaire-id');
    let companyId = this.el.attr('data-company-id');
    if (typeof userId !== 'undefined') {params['user_id'] = userId}
    if (typeof questionnaireId !== 'undefined') {params['questionnaire_id'] = questionnaireId;}
    if (typeof itemId !== 'undefined') {params['item_id'] = itemId}
    if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId;}
    if (typeof status !== 'undefined') {params['status'] = status}
    if (typeof companyId !== 'undefined') {params['company_id'] = companyId;}
    dataUrl = dataUrl + '?' + $.param(params);

    // Set additional data vars (used below in table creation)
    let cols = GetCols(this.el);

    // Set height if in full-window mode
    let $height;
    let $card = this.el.closest('.card');
    let $cardBody = this.el.closest('.card-body');
    if ($card.hasClass('full-view')) {
      $height = $card.height() - 180;
    } else {
      $height = $cardBody.height() - 20;
    }

    // Sort order
    let orderCol = this.el.attr("data-order-col");
    if (typeof orderCol === 'undefined') {orderCol = 0;}
    let orderDir = this.el.attr("data-order-dir");
    if (typeof orderDir === 'undefined') {orderDir = 'asc';}

    // Render table
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      retrieve: true,
      serverSide: true,
      ajax: dataUrl,
      scrollY: $height,
      scrollX: true,
      scrollCollapse: true,
      fixedColumns: {leftColumns: 1, rightColumns: 1},
      paging: true,
      pageLength: 50,
      searching: true,
      order: [[orderCol, orderDir]],
      dom: 'tlp',
      columns: cols,
      responsive: { details: false },
      language: { 
        lengthMenu: "Show _MENU_ requests",
        infoEmpty: "No approval requests found. Add one or reach out to us for support.",
        emptyTable: "No approval requests found. Add one or reach out to us for support.",
        zeroRecords: "No approval requests found. Add one or reach out to us for support."
      },
      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        $(row).attr("data-response-id", data.response_id);
        $(row).attr("data-record-id", data.record_id);
        $(row).attr("data-account-url", data.account_url);

      },

      drawCallback: function( settings ) {

        // Remove spinner after table has drawn
        $('#' + tableId).closest('.card').find('.spinner-container').remove();
        $('#' + tableId).closest('.card-body').css('opacity', 1);

        // Confirm and delete an item on click
        $('.delete-approval-request').click(function(e) {

          // Set model and item ids
          let accountUrl = $('body').attr('data-account-url');
          let responseId = $(this).closest('tr').attr('data-response-id');
          let recordId = $(this).closest('tr').attr('data-record-id');
          let text = "Confirm that you would like to remove this request:"
          let url = '/' + accountUrl + '/data-collection/responses/' + responseId + '/approval_requests/' + recordId;

          // Validate
          return Swal.fire({
            title: "Are you sure?",
            text: text,
            animation: false,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-light border',
              popup: 'animated fadeIn faster'
            }
          }).then((result) => {
            
            if (result.value) {

              // Unlink the account
              $.ajax({
                type: 'DELETE',
                dataType: "application/json",
                url: url,
                beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
                complete(result) { 
                  let notice;
                  if (result.status === 200) {
                    notice = 'Request removed';
                  } else {
                    notice = 'There was a problem, please contact us for support';
                  }
                  let backPath = $('#questionnaire_response_approval_requests_table').attr('data-back-path');
                  return Turbolinks.visit(backPath + '?notice=' + notice);
                },
              });

            }

          });

        });        

      }


    });

    return 'rendered';
  }

  setUp(tableId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Set searchable function for table
    $('#search_' + tableId).keyup(function(){
      oTable.search( $(this).val() ).draw();
    });

    // Ensure search input starts blank
    $('#search_' + tableId).val('');

    // Set on click action for table rows
    AddRowClickLinks(tableId);

    return 'setup';

  }


}
