import Spinner from 'src/javascripts/components/utilities/Spinner';
import ResearchFiltering from 'src/javascripts/components/ResearchFiltering';

export default class AccountsResearchFavorites {

  constructor() {}

  render() {

    // Filtering functions
    ResearchFiltering();

  }

}